<template>
  <div class="city-filter-layer" @mousedown.stop>
    <div class="search-wrapper">
      <input
        type="text"
        class="text6"
        v-model="searchValue"
        placeholder="Search"
      />
      <FontIcon name="ui_search" class="search-icon" />
    </div>
    <FilterOption
      id="input--filter-city-around-me"
      class="around-me"
      :label="$t('common.filters.city.around-me')"
      icon="ui_geoloc"
      :value="!!geolocation"
      :disabled="pendingGeoloc"
      @update:value="toggleGeocode($event)"
      v-if="deviceLocation"
    />
    <div v-if="!sortedOptions.length" class="no-city text6">
      {{ $t("common.filters.city.no-result") }}
    </div>
    <div v-else>
      <FilterOption
        v-for="option in sortedOptions"
        :id="`input--filter-city-${option.key}`"
        :key="option.key"
        :label="option.key"
        :value="option.value || false"
        @mousedown.native.prevent
        @update:value="
          $emit('option-change', {
            filter: filter.key,
            option: option.key,
            value: $event,
          })
        "
      />
    </div>
  </div>
</template>

<style lang="scss">
.city-filter-layer {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: rem(200px);
  overflow: auto;
  @include setColor(--color-background1, background-color);
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
  z-index: 1;

  .filter-option {
    margin-left: rem(15px);
    margin-right: rem(15px);
  }

  .search-wrapper {
    position: relative;
    height: rem(46px);
    margin-bottom: rem(15px);

    input {
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      padding: rem(0 16px);
      @include setColor(--color-background2, background-color);
    }

    .search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(16px);
      @include setColor(--color-icon1);
      font-size: rem(22px);
    }
  }

  .around-me {
    padding-bottom: rem(9px);
    margin-bottom: rem(15px);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include setColor(--color-divider, border-bottom-color);
  }

  .no-city {
    padding: rem(0 15px 15px);
  }
}
</style>

<script>
import { mapState } from "vuex";
import { FontIcon } from "@johnpaul/jp-vue-components";
import FilterOption from "./FilterOption";

export default {
  components: { FilterOption, FontIcon },

  data() {
    return {
      pendingGeoloc: false,
      searchValue: "",
    };
  },

  props: {
    filter: {
      type: Object,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      deviceLocation: state => state.device.location,
    }),

    geolocation: function() {
      return this.collection.geolocation;
    },

    filteredOptions() {
      if (!this.searchValue) return this.filter.options;

      const searchValue = this.searchValue.toLowerCase();

      return this.filter.options.filter(option => {
        return option.key.toLowerCase().indexOf(searchValue) !== -1;
      });
    },

    sortedOptions() {
      return this.filteredOptions
        .concat() // prevent array mutation
        .sort((option1, option2) => (option1.key > option2.key ? 1 : -1));
    },
  },

  methods: {
    toggleGeocode() {
      if (!this.geolocation && navigator.geolocation && !this.pendingGeoloc) {
        this.pendingGeoloc = true;

        const geolocOptions = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(
          this.onGeocodeSuccess,
          this.onGeocodeError,
          geolocOptions,
        );
      } else {
        this.$emit("option-change", {
          filter: "geolocation",
          value: null,
        });
      }
    },

    onGeocodeSuccess(position) {
      this.pendingGeoloc = false;

      this.$emit("reset");
      this.$emit("option-change", {
        filter: "geolocation",
        value: position.coords,
      });
    },

    onGeocodeError() {
      this.pendingGeoloc = false;
    },
  },
};
</script>
