<template>
  <div class="date-label text9">
    <FontIcon name="ui_calendar" class="calendar-icon" />
    {{ formatted }}
  </div>
</template>

<style lang="scss">
.date-label {
  @include setColor(--color-text);
  display: flex;
  align-items: center;
  justify-content: center;

  .calendar-icon {
    font-size: rem(12px);
    margin: rem(-2px 5px 0 0);
  }
}
</style>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { FontIcon } from "@johnpaul/jp-vue-components";

import { formatDate } from "@/utilities/dates";

export default {
  components: { FontIcon },

  props: {
    date: {
      type: String,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
    }),

    formatted() {
      const date = moment(this.date);
      return formatDate(
        date.toDate(),
        this.locale,
        this.options || {
          month: "long",
          day: "numeric",
        },
      );
    },
  },
};
</script>
