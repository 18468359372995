<template>
  <component
    :is="blockTemplate"
    :title="title"
    :subtitle="subtitle"
    :buttonCards="buttonCards"
    @component-click="trackClick"
  />
</template>

<script>
import { BLOCK_TEMPLATE } from "../../blocks";
import ButtonListTemplate from "../../templates/blocks/button-list/ButtonListTemplate";

export default {
  components: {
    ButtonListTemplate,
  },

  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || ButtonListTemplate;
    },
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    buttonCards() {
      return this.components.filter(component => component?.type === "card");
    },
    textComponent() {
      return this.components.find(component => component?.type === "text");
    },
    title() {
      return this.textComponent?.title;
    },
    subtitle() {
      return this.textComponent?.subtitle;
    },
  },

  methods: {
    trackClick(component) {
      this.$root.$emit("dynamic-content.component:click", {
        name: component.subtitle.replace(" ", "_"),
        pageSlug: this.$router.currentRoute.path?.replace("/", ""),
        blockSlug: "button-list",
        blockDisplayType: "button-list",
      });
    },
  },
};
</script>
