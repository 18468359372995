<template>
  <div v-if="blockItems">
    <component
      :is="blockType"
      :slug="block.slug"
      :queryBlockItems="queryBlockItems"
      :simpleBlockItems="simpleBlockItems"
      :details="block"
      :startBgColor="startBgColor"
      :isMobileApp="isMobileApp"
    />
  </div>
</template>

<style lang="scss">
.background1 {
  @include setColor(--color-background1, background-color);
}

.background2 {
  @include setColor(--color-background2, background-color);
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { BLOCKS } from "../blocks";

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    startBgColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      blocksById: state => state.contentv2.blocksById,
      fetching: state => state.contentv2.fetchingComponents,
      isMobileApp: state => state.isMobileApp,
    }),
    blockItems() {
      return this.blocksById[this.block.id]?.results;
    },
    blockType() {
      return BLOCKS[this.block.type] || "div";
    },
    queryBlockItems() {
      return this.blockItems.filter(blockItem => blockItem.type === "query");
    },
    simpleBlockItems() {
      return this.blockItems.filter(blockItem => blockItem.type === "simple");
    },
  },
  methods: {
    ...mapActions({
      fetchBlock: "contentv2/fetchBlock",
    }),
  },
  async created() {
    await this.fetchBlock(this.block.id);
  },
};
</script>
