<template>
  <div class="vehicle">
    <HomeComponent
      v-for="(collection, index) in pageCollections"
      :key="collection.id"
      class="my-vehicle"
      :collection="collection"
      :components="getComponents(collection)"
      :bgColor="(bgColor.length > index && bgColor[index]) || 'background1'"
      @bgColorSet="setNextBgColor($event, index + 1)"
    />
  </div>
</template>

<script>
import HomeComponent from "@/components/home/HomeComponent";

import contentPageMixin from "@/mixins/contentPageMixin";

import { getModuleConfig } from "@/utilities/config";

export default {
  components: {
    HomeComponent,
  },

  mixins: [contentPageMixin],

  data() {
    const moduleConfig = getModuleConfig(this.$config, "my-vehicle");
    return {
      pageId: moduleConfig.options.pageId,
      bgColor: [],
    };
  },

  methods: {
    setNextBgColor(bgColor, index) {
      if (this.bgColor[index] !== bgColor) this.bgColor[index] = bgColor;
    },
  },

  mounted() {
    this.$root.$emit("my-vehicle:mounted");
    this.$emit("header", { transparent: false });
  },

  updated() {
    for (let i = 0; i < this.pageCollections.length; i++)
      if (!this.bgColor?.[i]) this.bgColor[i] = "background2";
  },
};
</script>
