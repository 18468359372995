<template>
  <div class="frame-busted-window">
    <div class="frame-busted-window__wrapper">
      <div class="frame-busted-window__textarea">
        <div class="frame-busted-window__title title1">
          {{ $t("common.frame-busted-window.title") }}
        </div>
        <div class="frame-busted-window__text text4">
          {{ $t("common.frame-busted-window.text") }}
        </div>
        <a
          :href="link"
          class="button button-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("common.return-website") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameBustedWindow",

  data() {
    return {
      link: window.self.location.href,
    };
  },
};
</script>

<style lang="scss">
.frame-busted-window {
  height: 100%;
  width: 100vw;
  position: absolute;
  z-index: 9999;
  @include setColor(--color-background1, background-color);

  &__wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__textarea {
    display: flex;
    flex-direction: column;
    margin: 0 3rem;
    text-align: center;
  }

  &__text {
    margin-bottom: 1.25rem;
  }
}
</style>
