import { getStore } from "@/store";
import router from "@/router";
import instance, { client } from "@johnpaul/jp-api-client";

import qs from "qs";
import jwtDecode from "jwt-decode";
import { exit } from "@/utilities/autoLogout";

let interceptorRequest = null;
let interceptorResponse = null;
let isRefreshingToken = false;
let _await;
const isBearerToken = token => token.includes("Bearer");
const isExpired = time => {
  return Date.now() > time * 1000;
};
const shouldRefreshToken = (accessTokenTime, refreshTokenTime, url, data) => {
  return (
    isExpired(accessTokenTime) &&
    !isExpired(refreshTokenTime) &&
    !url.includes("/auth/token") &&
    !url.includes("/auth/revoke") &&
    qs.parse(data)?.grant_type !== "refresh_token"
  );
};

export function setHeadersAndInterceptors(token = null, withProxy) {
  const store = getStore();
  if (token) {
    if (!withProxy && typeof token === "string") {
      instance.defaults.headers["Authorization"] = `Bearer ${token}`;

      interceptorRequest = instance.interceptors.request.use(
        request => {
          const accessToken = request.headers["Authorization"];
          let jwt;
          try {
            jwt = jwtDecode(accessToken);
          } catch (error) {
            return request;
          }

          // ("");

          if (jwt && isExpired(jwt.exp)) {
            return new Promise(async (resolve, reject) => {
              const currentJwt = store.state.auth.accessToken
                ? jwtDecode(store.state.auth.accessToken)
                : null;

              if (
                currentJwt &&
                shouldRefreshToken(
                  currentJwt.exp,
                  currentJwt.iat + store.state.auth.refreshTokenExpiration,
                  request.url,
                  request.data,
                )
              ) {
                try {
                  if (!isRefreshingToken) {
                    isRefreshingToken = true;
                    // refresh token here
                    _await = store.dispatch("login/refreshToken");
                    const result = await _await;
                    // at this step, store.state.auth.accessToken should get the new value
                    if (
                      isBearerToken(request.headers["Authorization"])
                    ) {
                      request.headers = {
                        ...request.headers,
                        Authorization: `Bearer ${store.state.auth.accessToken}`,
                      };
                    }
                    result.success ? resolve(request) : reject(request);
                    isRefreshingToken = false;
                    _await = undefined;
                  } else {
                    // should resolve await so the calls wait for current call
                    const result = await _await;
                    if (
                      isBearerToken(request.headers["Authorization"])
                    ) {
                      request.headers = {
                        ...request.headers,
                        Authorization: `Bearer ${store.state.auth.accessToken}`,
                      };
                    }
                    result.success ? resolve(request) : reject(request);
                  }
                } catch (e) {
                  reject(request);
                }
              } else {
                resolve(request);
              }
            });
          }
          return request;
        },
        error => error,
      );
    }

    instance.interceptors.request.use(request => {
      const { clientId, clientSecret } = store.state.config.env;

      request.headers["x-client"] = `Basic ${btoa(
        `${clientId}:${clientSecret}`,
      )}`;

      return request;
    });

    interceptorResponse = instance.interceptors.response.use(
      response => response,
      async error => {
        switch (error?.response?.status) {
          case 401:
          case 403:
            if (!error?.config?.headers?.["doNotForceExit"]) {
              if (
                store.getters["auth/loggedIn"] &&
                !error?.response.config.url.includes("/auth/revoke")
              ) {
                if (!router.currentRoute.fullPath.includes("/config-failure")) {
                  await store.dispatch("setRedirectUrl", router.currentRoute.fullPath);
                }
                await store.dispatch("auth/logout");
              }
              exit();
            }
            return Promise.reject(error);
          case 404:
            return router.push({ name: "errors-404" });
          case 500:
          case 503:
            return router.push({ name: "errors-500" });
          default:
            throw error;
        }
      },
    );
  } else {
    delete instance.defaults.headers["Authorization"];

    if (interceptorRequest) {
      instance.interceptors.request.eject(interceptorRequest);
      interceptorRequest = null;
    }
    if (interceptorResponse) {
      instance.interceptors.response.eject(interceptorResponse);
      interceptorResponse = null;
    }
  }
}

export default client;
