import errors, { DEFAULT_ERROR } from "@/constants/errors";

export function getErrorCode(error) {
  return (
    errors[error?.response?.data?.errors?.[0]?.code] ||
    errors[error?.response?.data?.error] ||
    DEFAULT_ERROR
  );
}

export function getPasswordError(error) {
  return (
    errors[error?.response?.data?.error_description?.previous_password?.[0]] ||
    errors[error?.response?.data?.error_description?.new_password?.[0]] ||
    DEFAULT_ERROR
  );
}

export function getSecretError(error) {
  return (
    errors[error?.response?.data?.error_description?.password?.[0]] ||
    errors[error?.response?.data?.error_description?.secret_question?.[0]] ||
    errors[error?.response?.data?.error_description?.secret_answer?.[0]] ||
    DEFAULT_ERROR
  );
}
