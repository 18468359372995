<template>
  <div class="my-account-interests container">
    <Alert
      variant="success"
      v-if="showTooltip && saveSuccess"
      v-model="showTooltip"
    >
      <span v-html="$t(`my-account.interests.success`)"></span>
    </Alert>
    <Alert
      variant="error"
      v-if="showTooltip && saveError"
      v-model="showTooltip"
    >
      <span v-html="$t(`my-account.interests.error`)"></span>
    </Alert>

    <p
      class="interests-description text6"
      v-html="$t('my-account.interests.text')"
    />

    <div v-if="!fetching" class="row">
      <InterestCard
        v-for="interest in interests"
        :key="interest.subfamily"
        class="account-interest-card col-4"
        :interest="interest"
        @click.native="
          setInterest({ ...interest, is_liked: !interest.is_liked })
        "
      />

      <div class="col-12">
        <ActivityIndicator v-if="saving" />
        <Button
          v-else
          id="button--interests-form"
          class="interests-submit"
          :disabled="!modified"
          @click.native="save"
        >
          {{ $t("my-account.interests.submit.label") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.my-account-interests {
  margin-top: rem(24px) !important;

  .interests-description {
    text-align: center;
    margin-bottom: rem(30px);
    @include setColor(--color-text);
  }

  @include respond-min("medium") {
    width: rem(700px) !important;

    .interests-submit {
      margin-top: rem(40px);
    }

    .account-interest-card {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .interests-submit {
    text-transform: uppercase;
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { ActivityIndicator, Button, Alert } from "@johnpaul/jp-vue-components";
import InterestCard from "@/components/InterestCard";

export default {
  components: {
    ActivityIndicator,
    Button,
    InterestCard,
    Alert,
  },

  data() {
    return {
      showTooltip: false,
    };
  },

  computed: {
    ...mapState({
      interests: state => state.interests.data,
      fetching: state => state.interests.fetching,
      modified: state => state.interests.modified,
      saving: state => state.interests.saving,
      saveError: state => state.interests.saveError,
      saveSuccess: state => state.interests.saveSuccess,
    }),
  },

  methods: {
    ...mapActions({
      fetchInterests: "interests/fetchInterests",
      saveInterests: "interests/saveInterests",
    }),

    ...mapMutations({
      setInterest: "interests/setInterest",
    }),

    async save() {
      await this.saveInterests();
      this.showTooltip = true;
    },
  },

  async mounted() {
    await this.fetchInterests();
    this.$root.$emit("my-account--interests:mounted");
  },
};
</script>
