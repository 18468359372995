<template>
  <div class="home-app-download" v-if="!isApplication">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>
    <div class="buttons">
      <Button
        v-for="component in components"
        :key="component.id"
        :url="component.url"
        :id="`link--app-download-${component.id}`"
        class="app-button"
        @click.native="sendAppDownloadClick(component)"
      >
        <img
          :src="component.medias[0].url"
          :alt="`Go to ${component.subname}`"
        />
        <div class="app-button-label text8">
          {{ component.name }}
        </div>
        <div class="app-button-name text5">
          {{ component.subname }}
        </div>
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.home-app-download {
  padding: rem(27px 0 20px);

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
    overflow-y: hidden;
    max-height: rem(67px);
  }

  h2 {
    @include setColor(--color-subtitle);
    margin-left: rem(15px);
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(30px);

    @include respond-min("medium") {
      flex-direction: row;
      justify-content: center;
    }
  }

  .app-button {
    display: inline-block;
    position: relative;
    height: auto;
    width: auto;
    min-width: rem(215px);
    padding: rem(15px 30px 15px 80px);
    text-align: left;
    margin: rem(10px 20px);
    border-radius: rem(4px);

    &:hover {
      height: inherit;
    }

    img {
      position: absolute;
      left: rem(20px);
      top: 50%;
      transform: translateY(-50%);
      height: rem(36px);
      width: rem(36px);
    }
  }
}
</style>

<script>
import { Button } from "@johnpaul/jp-vue-components";

export default {
  components: {
    Button,
  },

  data() {
    return {
      isApplication: navigator.userAgent.includes("jp-companion-app"),
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
  methods: {
    sendAppDownloadClick(component) {
      {
        const app = component.subname.replace(" ", "_");
        this.$root.$emit(`home-logged.app-download:click`, { app });
      }
    },
  },
};
</script>
