import { createLocalVue, mount } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import { securitySecretForm } from "./dataset";
import { passwordForm } from "./dataset";
import Vuex from "vuex";

import MyAccountSecurity from "../views/MyAccountSecurity";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

function getDefaultStoreOptions() {
  return {
    state: {
      i18n: {
        locale: "fr",
      },
      login: {},
      auth: {
        fingerprint: null,
      },
      member: {
        data: {
          username: "username@mail.com",
        },
      },
      password: {
        secretQuestion: null,
        readSecretQuestionError: null,
        resetError: null,
      },
      "my-account": {
        password: {
          updating: false,
          updated: false,
          error: null,
        },
        secret: {
          updating: false,
          updated: false,
          error: null,
        },
      },
    },
    actions: {
      "my-account/password/check": () => {},
      "my-account/password/update": () => {},
      "my-account/secret/update": () => {},
    },
    mutations: {
      "my-account/password/reset": () => {},
      "my-account/secret/reset": () => {},
      "auth/disableFingerprint": () => {},
    },
  };
}

function getStore(options) {
  return new Vuex.Store({ ...getDefaultStoreOptions(), ...options });
}

export default () => {
  describe("My Account Security", () => {
    let wrapper;
    const unknownErrorType = "unknown";

    describe("rendering", () => {
      describe("touchId/faceId", () => {
        it("should display touchId/faceId checkbox", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: true,
                authenticationType: "face",
                isFingerprintOptionEnabled: true,
              };
            },
            localVue,
          });
          const fastConnection = wrapper.find("#input--fast-connection");
          expect(fastConnection.element.tagName).toBe("INPUT");
          expect(fastConnection.attributes("type")).toBe("checkbox");
        });

        it("should not display touchId/faceId checkbox if device does not allow fingerprint", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: true,
                authenticationType: null,
                isFingerprintOptionEnabled: true,
              };
            },
            localVue,
          });
          const fastConnection = wrapper.find("#input--fast-connection");
          expect(fastConnection.exists()).toBeFalsy();
        });

        it("should not display touchId/faceId checkbox if cordova is not enabled", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                authenticationType: null,
                isFingerprintOptionEnabled: true,
              };
            },
            localVue,
          });
          const fastConnection = wrapper.find("#input--fast-connection");
          expect(fastConnection.exists()).toBeFalsy();
        });

        it("should not display touchId/faceId checkbox if fingerprint option is not enabled on project", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: true,
                authenticationType: "biometric",
                isFingerprintOptionEnabled: false,
              };
            },
            localVue,
          });
          const fastConnection = wrapper.find("#input--fast-connection");
          expect(fastConnection.exists()).toBeFalsy();
        });

        it("should display fast connection form when user try to enable fingerprint", async () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: true,
                authenticationType: "biometric",
                isFingerprintOptionEnabled: true,
              };
            },
            localVue,
          });
          expect(wrapper.vm.$data.showFastConnectionForm).toBeFalsy();
          await wrapper.vm.useFingerprint(true);
          expect(wrapper.vm.$data.showFastConnectionForm).toBeTruthy();
          expect(wrapper.find("#fast-connection__form")).toBeTruthy();
        });
      });

      describe("login change", () => {
        it("should display login change section with disabled text input", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeUsername: true,
              };
            },
            localVue,
          });
          const usernameInput = wrapper.find("#input--username");
          expect(usernameInput.element.tagName).toBe("INPUT");
          expect(usernameInput.attributes("type")).toBe("text");
          expect(usernameInput.attributes("disabled")).toBe("disabled");
        });

        it("should not display login change section", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeUsername: false,
              };
            },
            localVue,
          });
          const usernameInput = wrapper.find("#input--username");
          expect(usernameInput.exists()).toBeFalsy();
        });
      });

      describe("update secret", () => {
        it("should display secret section select input", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeSecret: true,
                secretForm: securitySecretForm,
              };
            },
            localVue,
          });
          const secretQuestionInput = wrapper.find("#input--secret-question");
          expect(secretQuestionInput.element.tagName).toBe("SELECT");
        });

        it("should display secret answer textarea input", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeSecret: true,
                secretForm: securitySecretForm,
              };
            },
            localVue,
          });
          const secretAnswerInput = wrapper.find("#input--secret-answer");
          expect(secretAnswerInput.element.tagName).toBe("TEXTAREA");
        });

        it("should display password input", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeSecret: true,
                secretForm: securitySecretForm,
              };
            },
            localVue,
          });
          const passwordInput = wrapper.find("#input--secret-password");
          expect(passwordInput.element.tagName).toBe("INPUT");
          expect(passwordInput.attributes("type")).toBe("password");
        });

        it("should alert success when secret successfully updated", () => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state["my-account"].secret.updated = true;

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeSecret: true,
                secretForm: securitySecretForm,
              };
            },
            localVue,
          });
          const successAlert = wrapper.find(
            "#alert--security-secret-update-success",
          );
          const errorAlert = wrapper.find(
            "#alert--security-secret-update-error",
          );
          expect(successAlert.exists()).toBeTruthy();
          expect(errorAlert.exists()).toBeFalsy();
        });

        it("should alert error when secret failed to update", () => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state["my-account"].secret.error = unknownErrorType;

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeSecret: true,
                secretForm: securitySecretForm,
              };
            },
            localVue,
          });
          const successAlert = wrapper.find(
            "#alert--security-secret-update-success",
          );
          const errorAlert = wrapper.find(
            "#alert--security-secret-update-error",
          );
          expect(successAlert.exists()).toBeFalsy();
          expect(errorAlert.exists()).toBeTruthy();
        });

        it("should not display update secret section", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangeSecret: true,
                secretForm: null,
              };
            },
            localVue,
          });
          const secretQuestionInput = wrapper.find("#input--secret-question");
          const secretAnswerInput = wrapper.find("#input--secret-answer");
          const passwordInput = wrapper.find("#input--secret-password");
          expect(secretQuestionInput.exists()).toBeFalsy();
          expect(secretAnswerInput.exists()).toBeFalsy();
          expect(passwordInput.exists()).toBeFalsy();
        });
      });

      describe("update password", () => {
        it("should display current password input", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangePassword: true,
                passwordForm: passwordForm,
              };
            },
            localVue,
          });
          // waiting the load of forms (passwordForm and secretForm)
          wrapper.vm.$nextTick(() => {
            const currentPasswordInput = wrapper.find("#input--password");
            expect(currentPasswordInput.element.tagName).toBe("INPUT");
            expect(currentPasswordInput.attributes("type")).toBe("password");
          });
        });

        it("should display new password input", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangePassword: true,
                passwordForm: passwordForm,
              };
            },
            localVue,
          });
          // waiting the load of forms (passwordForm and secretForm)
          wrapper.vm.$nextTick(() => {
            const newPasswordInput = wrapper.find("#input--newPassword");
            expect(newPasswordInput.element.tagName).toBe("INPUT");
            expect(newPasswordInput.attributes("type")).toBe("password");
          });
        });

        it("should alert success when password successfully updated", () => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state["my-account"].password.updated = true;

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangePassword: true,
                passwordForm: passwordForm,
              };
            },
            localVue,
          });
          const successAlert = wrapper.find(
            "#alert--security-password-update-success",
          );
          const errorAlert = wrapper.find(
            "#alert--security-password-update-error",
          );
          expect(successAlert.exists()).toBeTruthy();
          expect(errorAlert.exists()).toBeFalsy();
        });

        it("should alert error when password failed to update", () => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state["my-account"].password.error = unknownErrorType;

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangePassword: true,
                passwordForm: passwordForm,
              };
            },
            localVue,
          });
          const successAlert = wrapper.find(
            "#alert--security-password-update-success",
          );
          const errorAlert = wrapper.find(
            "#alert--security-password-update-error",
          );
          expect(successAlert.exists()).toBeFalsy();
          expect(errorAlert.exists()).toBeTruthy();
        });

        it("should not display update password section", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(MyAccountSecurity, {
            store: getStore(storeOptions),
            data() {
              return {
                isCordovaEnabled: false,
                canChangePassword: false,
                passwordForm: passwordForm,
              };
            },
            localVue,
          });
          const currentPasswordInput = wrapper.find("#input--password");
          const newPasswordInput = wrapper.find("#input--newPassword");
          expect(currentPasswordInput.exists()).toBeFalsy();
          expect(newPasswordInput.exists()).toBeFalsy();
        });
      });
    });
  });
};
