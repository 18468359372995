<template>
  <div class="my-account--security">
    <Alert
      v-if="passwordUpdated"
      id="alert--security-password-update-success"
      variant="success"
    >
      <span
        class="text8"
        v-html="$t(`my-account.security.password.success`)"
      ></span>
    </Alert>
    <Alert
      v-if="passwordError"
      id="alert--security-password-update-error"
      variant="error"
    >
      <span
        v-html="$t(`my-account.security.password.error.${passwordError}`)"
      ></span>
    </Alert>
    <Alert
      v-if="secretUpdated"
      id="alert--security-secret-update-success"
      variant="success"
    >
      <span
        class="text8"
        v-html="$t(`my-account.security.secret.success`)"
      ></span>
    </Alert>
    <Alert
      v-if="secretError"
      id="alert--security-secret-update-error"
      variant="error"
    >
      <span
        v-html="$t(`my-account.security.secret.error.${secretError}`)"
      ></span>
    </Alert>
    <Alert
      v-if="fingerprintChoiceUpdated && fingerprint"
      id="alert--fingerprint-enable-success"
      variant="success"
    >
      <span
        class="text8"
        v-html="$t(`my-account.security.fast-connection.enable.success`)"
      ></span>
    </Alert>
    <Alert
      v-if="fingerprintChoiceUpdated && !fingerprint"
      id="alert--fingerprint-disable-success"
      variant="success"
    >
      <span
        class="text8"
        v-html="$t(`my-account.security.fast-connection.disable.success`)"
      ></span>
    </Alert>
    <Alert
      v-if="fastConnectionError"
      id="alert--fast-connection-error"
      variant="error"
    >
      <span
        v-html="
          $t(`my-account.security.fast-connection.error.${fastConnectionError}`)
        "
      ></span>
    </Alert>

    <template
      v-if="
        isCordovaEnabled && isFingerprintOptionEnabled && authenticationType
      "
    >
      <b-row class="w-100 mx-0 fast-connection__selector">
        <b-col cols="10" class="text8 pl-0 pr-1">
          <h2 class="text5">
            {{
              $t(
                `my-account.security.fast-connection.${authenticationType}.title`,
              )
            }}
          </h2>
          <div class="text8">
            {{
              $t(
                `my-account.security.fast-connection.${authenticationType}.text`,
              )
            }}
          </div>
        </b-col>
        <b-col cols="2" class="px-1 text-right">
          <CheckboxInput
            checkboxType="switch"
            id="input--fast-connection"
            :intermediate-state="waitingForFingerprintValidation"
            :value="booleanFingerprintChoice"
            @update:value="useFingerprint"
          />
        </b-col>
      </b-row>
      <transition name="fast-connection__form-transition">
        <form
          v-show="showFastConnectionForm && !fingerprint"
          class="fast-connection__form container"
          @submit.prevent="fastConnectionCheckPassword"
        >
          <TextInput
            class="password"
            id="input--fast-connection-password"
            :label="
              $t('my-account.security.fast-connection.form.inputs.password')
            "
            type="password"
            :value.sync="fastConnectionPassword"
            :disabled="passwordChecking"
            required
          />
          <ActivityIndicator v-if="passwordChecking" />
          <Button
            v-else
            id="button--fast-connection-submit"
            nativeType="submit"
            :disabled="!fastConnectionPassword"
          >
            {{ $t(`my-account.security.fast-connection.form.buttons.submit`) }}
          </Button>
        </form>
      </transition>
      <hr class="my-4" />
    </template>

    <template v-if="canChangeUsername">
      <h2 class="text5">{{ $t("my-account.security.change-login.title") }}</h2>
      <div class="text6 title">
        {{ $t("my-account.security.change-login.link.pre") }}
        <a
          id="link--my-account-change-login"
          class="change-login-link text5"
          @click="$root.$emit('my-account.security.change-login.link:click')"
        >
          {{ $t("my-account.security.change-login.link.link") }}</a
        >
        {{ $t("my-account.security.change-login.link.post") }}
      </div>
      <TextInput
        class="username-input mt-2"
        id="input--username"
        :value="username"
        :disabled="true"
      />
      <hr class="my-4" />
    </template>

    <template v-if="canChangeSecret && secretForm">
      <h2 class="text5">{{ $t("my-account.security.secret.title") }}</h2>
      <DynamicForm
        class="secret-form"
        prefix-input-id="secret"
        :form="secretForm"
        :buttonText="$t('my-account.security.secret.form.buttons.submit')"
        :submitting="secretSubmitting"
        @submit="onSubmitSecret"
      >
      </DynamicForm>
      <hr class="my-4" />
    </template>

    <template v-if="canChangePassword && passwordForm">
      <h2 class="text5">{{ $t("my-account.security.password.title") }}</h2>
      <DynamicForm
        :form="passwordForm"
        :buttonText="$t('my-account.security.password.form.buttons.submit')"
        :submitting="passwordSubmitting"
        @submit="onSubmitPassword"
      >
      </DynamicForm>
    </template>
  </div>
</template>

<style lang="scss">
.my-account--security {
  @include setColor(--color-text);
  padding-bottom: rem(20px);

  .secret-form {
    textarea {
      resize: none;
    }
  }

  .container {
    padding: 0;
  }

  .username-input {
    opacity: 1 !important;

    input {
      padding: rem(7px 15px);
      font-weight: bold;
      background-color: #f8f9fa !important;
      @include setColor(--color-inactive, color, true);
    }
  }

  .change-login-link {
    @include setColor(--color-link);
    text-decoration: underline;
  }

  .fast-connection .text5 {
    margin-bottom: 0;
  }

  h2.text5 {
    margin-bottom: rem(18px);
  }

  .fast-connection__form-transition-leave-active,
  .fast-connection__form-transition-enter-active {
    transition: 0.2s;
  }
  .fast-connection__form-transition-enter,
  .fast-connection__form-transition-leave-to {
    opacity: 0;
  }

  .fast-connection__form {
    padding-top: rem(12px);
  }

  @include respond-min("medium") {
    width: auto;
    margin: rem(0px 16px 0px 16px);
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import {
  Alert,
  Button,
  CheckboxInput,
  TextInput,
  ActivityIndicator,
} from "@johnpaul/jp-vue-components";
import DynamicForm from "@/components/DynamicForm";
import formMixin from "@/mixins/formMixin";
import cordovaMixin from "@/mixins/cordovaMixin";
import scrollMixin from "@/mixins/scrollMixin";
import {
  USERNAME_KEY,
  PWD_KEY,
  removeFromSecureStorage,
  setInSecureStorage,
} from "@/utilities/cordova";
import { isCordovaEnabled } from "@/utilities/mobile";

export default {
  name: "MyAccountSecurity",

  mixins: [cordovaMixin, formMixin, scrollMixin],

  components: {
    Alert,
    Button,
    CheckboxInput,
    DynamicForm,
    TextInput,
    ActivityIndicator,
  },

  data() {
    const { options } = this.$modules["my-account"].modules.security;
    return {
      isCordovaEnabled: isCordovaEnabled(),
      canChangeUsername: options?.["change-username"] || false,
      canChangePassword: options?.["change-password"] || true,
      canChangeSecret: options?.["change-secret"] || false,
      authenticationType: null,
      passwordForm: null,
      secretForm: null,
      isFingerprintOptionEnabled: this.$config.options.fingerprint,
      showFastConnectionForm: false,
      fastConnectionPassword: null,
      fastConnectionError: null,
      fingerprintChoice: 0,
      fingerprintChoiceUpdated: false,
    };
  },

  computed: {
    ...mapState({
      username: state => state.member.data?.username,
      passwordSubmitting: state => state["my-account"].password.updating,
      passwordUpdated: state => state["my-account"].password.updated,
      passwordChecking: state => state["my-account"].password.checking,
      passwordCheckError: state => state["my-account"].password.checkError,
      passwordError: state => state["my-account"].password.error,
      secretSubmitting: state => state["my-account"].secret.updating,
      secretUpdated: state => state["my-account"].secret.updated,
      secretError: state => state["my-account"].secret.error,
      fingerprint: state => state.auth.fingerprint,
    }),
    passwordAttributes() {
      return this.passwordForm.fields.reduce((acc, val) => {
        return { ...acc, ...val };
      }, {});
    },
    secretAttributes() {
      return this.secretForm
        ? this.secretForm.fields.reduce((acc, val) => {
            return { ...acc, ...val };
          }, {})
        : null;
    },
    waitingForFingerprintValidation() {
      return this.fingerprintChoice === 1;
    },
    booleanFingerprintChoice() {
      return this.fingerprintChoice === 2;
    },
    failOrUpdateOccurred() {
      return (
        this.passwordError ||
        this.secretError ||
        this.fastConnectionError ||
        this.passwordUpdated ||
        this.secretUpdated ||
        this.fingerprintChoiceUpdated
      );
    },
    failOrUpdateList() {
      return `${this.passwordUpdated}
      |${this.passwordError}
      |${this.secretUpdated}
      |${this.secretError}
      |${this.fingerprintChoiceUpdated}
      |${this.fastConnectionError}`;
    },
  },

  methods: {
    ...mapActions({
      checkPassword: "my-account/password/check",
      updatePassword: "my-account/password/update",
      updateSecret: "my-account/secret/update",
    }),
    ...mapMutations({
      resetPassword: "my-account/password/reset",
      resetSecret: "my-account/secret/reset",
      disableFingerprint: "auth/disableFingerprint",
    }),
    async onSubmitPassword() {
      const { password, newPassword } = this.passwordAttributes;
      const { success, hash } = await this.updatePassword({
        currentPassword: password.value,
        newPassword: newPassword.value,
      });
      if (success) {
        if (
          this.isCordovaEnabled &&
          this.isFingerprintOptionEnabled &&
          this.fingerprint
        ) {
          // Update secure storage data
          setInSecureStorage(PWD_KEY, hash);
        }
        this.resetFormFields(this.passwordForm);
      }
    },
    async onSubmitSecret() {
      const { question, answer, password } = this.secretAttributes;
      const updateResult = await this.updateSecret({
        password: password.value,
        secret_question: question.value,
        secret_answer: answer.value,
      });
      if (updateResult.success) {
        this.resetFormFields(this.secretForm);
      } else {
        const passwordField = this.secretForm.fields.find(
          field => Object.keys(field)[0] === "password",
        );
        if (passwordField?.password) passwordField.password.value = null;
      }
    },
    useFingerprint(value) {
      this.fingerprintChoiceUpdated = false;
      if (value) {
        // User trying to enable fast connection. He must fill in his password, so we display the form
        this.showFastConnectionForm = true;
        this.fingerprintChoice = 1;
      } else {
        if (this.fingerprint) {
          // User trying to disable fast connection. We clear secure storage and then we disable fingerprint
          removeFromSecureStorage(PWD_KEY, () =>
            removeFromSecureStorage(USERNAME_KEY, () => {
              this.disableFingerprint();
              this.hideFastConnectionForm();
              this.fingerprintChoice = 0;
              this.fingerprintChoiceUpdated = true;
            }),
          );
        } else {
          // User just switching checkbox value
          this.hideFastConnectionForm();
          this.fingerprintChoice = 0;
        }
      }
    },
    async fastConnectionCheckPassword() {
      try {
        const result = await this.checkPassword({
          username: this.username,
          password: this.fastConnectionPassword,
        });
        if (result.success) {
          const authorizeResult = await this.authorizeFingerprint({
            username: this.username,
            pwd: result.hash,
          });
          if (authorizeResult?.success) {
            this.fingerprintChoice = 2;
            this.fingerprintChoiceUpdated = true;
            this.$root.$emit("my-account.switch:activate");
            this.hideFastConnectionForm();
            this.fastConnectionError = null;
          }
        } else {
          this.fastConnectionError = result.error;
        }
      } catch (error) {
        // Error while checking password
        this.fastConnectionError = error;
        this.hideFastConnectionForm();
      }
    },
    hideFastConnectionForm() {
      this.showFastConnectionForm = false;
      this.fastConnectionPassword = null;
    },
    resetFormFields(formToReset) {
      // Clear given form's inputs value
      formToReset?.fields?.forEach(field => {
        const firstKey = Object.keys(field)[0];
        if (field[firstKey]) field[firstKey].value = null;
      });
    },
  },

  watch: {
    failOrUpdateList: {
      handler() {
        if (this.failOrUpdateOccurred) this.moveUp();
      },
    },
  },

  async mounted() {
    try {
      this.authenticationType = await this.getAuthenticationType();
    } catch {
      // fingerprint not enabled
    }
    this.fingerprintChoice = this.fingerprint ? 2 : 0 || 0;
    this.passwordForm = await this.loadFormConfig("changePasswordForm", {
      componentFormName: "passwordForm",
    });
    this.secretForm = await this.loadFormConfig("changeSecretForm", {
      componentFormName: "secretForm",
    });
    this.resetPassword();
    this.resetSecret();
    this.$root.$emit("my-account--credentials:mounted");
  },

  beforeDestroy() {
    this.resetFormFields(this.secretForm);
    this.resetFormFields(this.passwordForm);
  },
};
</script>
