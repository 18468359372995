<template>
  <div class="my-past-requests">
    <ActivityIndicator v-if="fetchingRequests" />
    <div v-else>
      <StatusFilter
        v-if="filterStatuses.length > 1"
        :statuses="filterStatuses"
        :value.sync="selectedStatus"
      />
      <div
        v-if="filteredRequests.length === 0"
        class="no-request-content text6"
      >
        {{ $t("request.my-requests.past.no-request") }}
      </div>

      <RequestCard
        v-else
        v-for="request in filteredRequests"
        :key="request.id"
        class="mb-2"
        :request="request"
        :hasRequestMenu="false"
      />
    </div>
  </div>
</template>

<style lang="scss">
.my-past-requests {
  .no-request-content {
    text-align: center;
    margin-top: rem(38px);
  }
}
</style>

<script>
import { mapState } from "vuex";

import { ActivityIndicator } from "@johnpaul/jp-vue-components";

import StatusFilter from "../components/StatusFilter";
import RequestCard from "../components/RequestCard";

const STATUSES = ["closed", "cancelled"];

export default {
  components: {
    ActivityIndicator,
    RequestCard,
    StatusFilter,
  },

  data() {
    return {
      selectedStatus: null,
    };
  },

  computed: {
    ...mapState({
      requests: state => state.request.requests || [],
      fetchingRequests: state => state.request.fetchingRequests,
    }),

    ongoingRequests() {
      return this.requests.filter(request => STATUSES.includes(request.status));
    },

    filteredRequests() {
      return this.ongoingRequests
        .filter(r => !this.selectedStatus || r.status === this.selectedStatus)
        .sort((r1, r2) => {
          if (r1.status === r2.status) {
            return r2.start_date > r1.start_date ? -1 : 1;
          }
          return STATUSES.indexOf(r1.status) - STATUSES.indexOf(r2.status);
        });
    },

    filterStatuses() {
      return [...new Set(this.ongoingRequests.map(request => request.status))];
    },
  },

  mounted() {
    this.$root.$emit("my-requests.past:mounted");
  },
};
</script>
