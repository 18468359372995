<template>
  <div class="dynamic-content-faq">
    <ActivityIndicator v-if="!pageInit" />

    <template v-else>
      <div
        v-if="bannerBlock && bannerBlockItems"
        class="dynamic-content-faq__header"
      >
        <Header :simple-block-items="bannerBlockItems" :details="bannerBlock" />

        <div class="separator">
          <FontIcon name="ui_chevron_right" class="separator-chevron" />
        </div>
      </div>

      <ul
        v-if="tabBlocks.length"
        :class="{ centered: tabBlocks.length < 3 }"
        class="dynamic-content-faq__topics"
      >
        <li v-for="(tab, index) in tabBlocks" :key="tab.id">
          <router-link
            :to="{ name: 'page-content-faq', params: { topic: tab.slug } }"
            @click.native="onTopicClick(tab, index)"
          >
            <div
              class="dynamic-content-faq__topic text5"
              :class="{ selected: tab.slug === topic }"
            >
              {{ tab.title }}
            </div>
          </router-link>
        </li>
      </ul>

      <Tab
        v-if="isCurrentBlockInit"
        :simple-block-items="currentBlock.blockItems"
        :details="currentBlock"
        @expand-accordion="onSubtopicClick"
      />

      <div class="dynamic-content-faq__contact-us text6">
        <div class="dynamic-content-faq__contact-us-text">
          {{ $t("faq.contact-us") }}
        </div>

        <Button :id="`faq-not-found`" class="button" @click.native="contactUs">
          {{ $t("need-help.subtitle") }}
        </Button>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.dynamic-content-faq {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: rem(40px);

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: rem(40px);
    height: rem(375px);
    @include setColor(--color-title1-negative);
    text-align: center;
    background-size: cover;
    background-position: center;

    @include respond-min("medium") {
      margin-bottom: rem(57px);
    }

    .header-title {
      margin: rem(0 24px 17px);

      @include respond-min("medium") {
        margin: rem(0 0 25px);
      }
    }

    .header-text {
      margin: rem(0 48px);
    }

    .separator {
      position: absolute;
      bottom: rem(-18px);
      left: calc(50% - 18px);
      height: rem(36px);
      width: rem(36px);
      @include setColor(--color-title1-negative);
      @include setColor(--color-highlight, background-color);
      border-radius: 5px;
      line-height: rem(36px);
      transform: rotate(45deg);

      @include respond-min("medium") {
        bottom: rem(-38px);
      }

      .separator-chevron {
        margin-left: rem(2px);
        margin-bottom: rem(2px);
        vertical-align: middle;
        transform: rotate(45deg);
      }
    }
  }

  &__topics {
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    padding: rem(0 16px 20px);
    margin: rem(0 0 10px);
    list-style: none;

    &.centered {
      justify-content: center;
    }

    a {
      text-decoration: none !important;
    }

    @include respond-min("medium") {
      flex-wrap: wrap;
      margin: rem(0 auto 27px);
      max-width: 1000px;
      justify-content: center;
    }

    .dynamic-content-faq__topic {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: rem(120px);
      height: rem(120px);
      margin: rem(0 5px);
      padding: rem(2px);
      background-color: transparent;
      @include setColor(--color-cta);
      border: none;
      outline: none;
      box-shadow: 0 7px 19px rgba(0, 0, 0, 0.1);
      border-radius: 11px;
      text-align: center;
      line-height: 1.25;

      &:hover {
        box-shadow: 0 7px 19px rgba(0, 0, 0, 0.15);
      }

      &.selected {
        border: solid 2px;
        @include setColor(--color-selection, border-color);
      }

      @include respond-min("medium") {
        width: rem(237px);
        height: rem(155px);
        margin: rem(0 10px 24px);
      }
    }
  }

  &__contact-us {
    max-width: rem(700px);
    margin: rem(0 18px 32px);
    padding: rem(23px);
    text-align: center;
    border: solid 2px;
    @include setColor(--color-cta border-color);
    border-radius: rem(12px);

    @include respond-min("medium") {
      margin: rem(0 auto 23px);
    }

    .contact-us-text {
      @include respond-min("medium") {
        margin: 0 auto;
        max-width: 60%;
      }
    }

    .button {
      text-transform: uppercase;
      margin: rem(16px auto 0);
      max-width: rem(197px);
    }
  }
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import {
  ActivityIndicator,
  Button,
  FontIcon,
} from "@johnpaul/jp-vue-components";
import { getDynamicComponentVendorId } from "@/utilities/tracking";
import Header from "../components/blocks/Header";
import Tab from "../components/blocks/Tab";

export default {
  components: {
    ActivityIndicator,
    Button,
    FontIcon,
    Header,
    Tab,
  },

  props: {
    topic: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tabBlocks: [],
      currentBlockIndex: null,
      headerHeight: 0,
      pageInit: false,
    };
  },

  computed: {
    ...mapState({
      accessToken: state => state.auth.accessToken,
      pagesById: state => state.contentv2.pagesById,
      blocksById: state => state.contentv2.blocksById,
      fetchingBlock: state => state.contentv2.fetchingBlock,
    }),

    pageBlocks() {
      return this.pagesById["faq"]?.results || [];
    },

    bannerBlock() {
      return this.pageBlocks?.find(block => block.type === "header");
    },

    tabs() {
      return this.pageBlocks?.filter(block => block.type === "tab");
    },

    bannerBlockItems() {
      return this.blocksById[this.bannerBlock?.id]?.results
        ?.slice()
        ?.map(blockItem => ({
          ...blockItem,
          components: [
            {
              ...blockItem.components[0],
              title: "",
              subtitle: blockItem.components[0]?.title,
              description: blockItem.components[0]?.subtitle,
            },
          ],
        }))
        ?.filter(blockItem => blockItem.components?.[0]?.type !== "button");
    },

    currentBlock() {
      return this.tabBlocks[this.currentBlockIndex];
    },

    isCurrentBlockInit() {
      return this.currentBlockIndex !== null && this.currentBlock;
    },
  },

  methods: {
    ...mapActions({
      fetchPage: "contentv2/fetchPage",
      fetchBlock: "contentv2/fetchBlock",
      fetchClientToken: "auth/fetchClientToken",
    }),

    async loadPage() {
      if (!this.accessToken) {
        await this.fetchClientToken();
      }
      await this.fetchPage("faq");
      this.$emit("header", { transparent: false });
    },

    async loadBlocks() {
      await this.fetchBlock(this.bannerBlock.id);
      for (const tab of this.tabs) {
        const id = tab.id;
        if (!this.blocksById[id]?.result) await this.fetchBlock(id);
        this.tabBlocks.push({
          ...tab,
          title: this.blocksById[id]?.results
            ?.map(blockItem => blockItem.components?.[0])
            ?.find(component => component?.type === "text")?.title,
          blockItems: this.blocksById[id]?.results
            ?.slice()
            ?.sort(
              (blockItemA, blockItemB) => blockItemA.order - blockItemB.order,
            ),
        });
      }
    },

    onTopicClick(topic, index) {
      this.$root.$emit("faq.topic:display", {
        group: topic.title,
      });
      this.currentBlockIndex = index;
    },

    onSubtopicClick(component) {
      this.$root.$emit("faq.topic:display", {
        group: this.tabBlocks?.[this.currentBlockIndex]?.title,
        title: component.title,
        vendorId: getDynamicComponentVendorId(component),
      });
    },

    contactUs() {
      this.$root.$emit("dynamic-content.need-help:click");
    },
  },

  async created() {
    await this.loadPage();
    await this.loadBlocks();
    this.currentBlockIndex = this.tabs?.indexOf(
      this.tabs?.find(tab => tab.slug === this.topic),
    );
    this.pageInit = true;
  },

  mounted() {
    this.$root.$emit("faq:mounted");
  },
};
</script>
