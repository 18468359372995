const extractEnabledModules = modules => {
  if (!modules) {
    return {};
  }

  return Object.entries(modules).reduce(
    (acc, [currentModuleName, currentModule]) => {
      if (currentModule === false || currentModule.enabled === false) {
        return acc;
      }
      return { ...acc, [currentModuleName]: currentModule };
    },
    {},
  );
};

function mergeModules(defaultModules, projectModules = {}) {
  if (!defaultModules) return;

  const modules = Object.entries(defaultModules).reduce(
    (accModules, [moduleName, defaultModule]) => {
      const projectModule = projectModules[moduleName];

      if (
        projectModule === false ||
        (!projectModule && !defaultModule.enabled)
      ) {
        return accModules;
      }

      return {
        ...accModules,
        [moduleName]: mergeConfig(defaultModule, projectModule || true),
      };
    },
    {},
  );

  // Inject project-specific modules
  Object.entries(projectModules).forEach(([name, projectModule]) => {
    if (!defaultModules[name]) {
      modules[name] = projectModule;
    }
  });

  return modules;
}

function mergeConfig(defaultConfig, projectConfig = {}) {
  if (projectConfig === false) return;
  return {
    modules: mergeModules(defaultConfig.modules, projectConfig.modules),
    // TODO: add a test for type
    type: defaultConfig.type,

    ...["tracking", "assets", "options", "global"].reduce((acc, namespace) => {
      if (!defaultConfig[namespace]) return acc;

      return {
        ...acc,
        [namespace]: {
          ...defaultConfig[namespace],
          ...projectConfig[namespace],
        },
      };
    }, {}),
  };
}

function getEnabledModules(config) {
  return Object.keys(config.modules || {});
}

// TODO: add tests
function getEnabledWidgets(config, currentPath = "") {
  return Object.entries(config.modules || {})
    .map(([name, currentModule]) => {
      const path = `${currentPath}${name}/`;
      return currentModule.type === "widget"
        ? { name, path }
        : getEnabledWidgets(currentModule, path);
    })
    .reduce((acc, val) => acc.concat(val), []) // .flat() equivalent
    .filter(widgets => widgets.length !== 0);
}

function getModuleConfig(config, path) {
  if (!path) return null;

  const splitPath = path.split("/");
  let currentModule = config;

  splitPath.forEach(m => {
    currentModule = currentModule.modules[m];
  });

  return currentModule;
}

function sortModules(modules) {
  let keys = Object.keys(modules);
  keys =
    keys.sort(function(a, b) {
      const item1 = (modules[a].options && modules[a].options.position) || 0;
      const item2 = (modules[b].options && modules[b].options.position) || 0;
      return item1 - item2;
    }) || {};

  return keys.reduce((acc, val) => {
    return { ...acc, [val]: modules[val] };
  }, {});
}

module.exports = {
  extractEnabledModules,
  getEnabledModules,
  getEnabledWidgets,
  getModuleConfig,
  mergeConfig,
  sortModules,
};
