import Vue from "vue";
import Router from "vue-router";

import checkAuth from "./guards/checkAuth";
import redirectAuth from "./guards/redirectAuth";
import setTitle from "./guards/setTitle";

import { requireModule } from "@/utilities/modules";
// eslint-disable-next-line no-unused-vars
import { FEATURES, isFeatureEnabled } from "../utilities/features";

const errorsRoutes = require(`./routes/errors`).default;
let appAvailableRoutes = [];

const requireModules = moduleKeys => {
  let routes = [];

  moduleKeys.forEach(confModule => {
    const moduleRoutes = requireModule(confModule, "pages");
    if (moduleRoutes) {
      Array.isArray(moduleRoutes)
        ? (routes = [...routes, ...moduleRoutes])
        : routes.push(moduleRoutes);
    }
  });

  return routes;
};

export function getRoutes(config) {
  let configModules = config.modules;
  let contentModulesKeys = [];
  let nonContentModulesKeys = [];
  Object.keys(configModules).forEach(moduleKey => {
    if (configModules[moduleKey].type === "content")
      contentModulesKeys.push(moduleKey);
    else nonContentModulesKeys.push(moduleKey);
  });

  appAvailableRoutes = [
    ...requireModules(nonContentModulesKeys),
    ...errorsRoutes,
  ];

  if (isFeatureEnabled(FEATURES.ATHENA)) {
    // Enabled modules for Athena
    appAvailableRoutes = [
      ...appAvailableRoutes,
      ...requireModules(["dynamic-content"]),
    ];
  } else {
    appAvailableRoutes = [
      ...appAvailableRoutes,
      ...requireModules(contentModulesKeys),
    ];
  }
  return appAvailableRoutes;
}

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);

const mode =
  process.env.VUE_APP_ROUTER_USE_HASH === "true" ? "hash" : "history";

const router = new Router({
  mode,
  base: "/",
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  },
  routes: [],
});

router.beforeEach(checkAuth);
router.beforeEach(redirectAuth);
router.beforeEach(setTitle);

export function getAvailableRoutes() {
  return appAvailableRoutes;
}

export default router;
