const fallbackRows = [
  // 1 item
  ["item"],

  // 2 items:
  ["item", "item"],

  // 3 items:
  [[["item", "item"]], "item"],

  // 4 items:
  [
    ["item", "item"],
    ["item", "item"],
  ],
];

export function applyTemplate(template, items) {
  let itemIdx = 0;
  let rowIdx = 0;
  const results = [];

  const parseItem = item => {
    if (Array.isArray(item)) {
      return item.map(arrayItem => parseItem(arrayItem));
    }

    const mosaicItem = items[itemIdx];
    itemIdx++;

    return mosaicItem;
  };

  while (itemIdx < items.length) {
    const templateRowIdx = rowIdx % template.length;
    const flatRow = template[templateRowIdx].flat(4);

    if (flatRow.length > items.length - itemIdx) {
      // if not enough cities to fill the row, use fallbacks
      results.push(parseItem(fallbackRows[items.length - itemIdx - 1]));
    } else {
      results.push(parseItem(template[templateRowIdx]));
    }

    rowIdx++;
  }

  return results;
}
