var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'collection-filters',
    _vm.visible && 'visible',
    _vm.enabledFilters.length && 'filters',
    _vm.showSearch && 'search',
  ]},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"filters-row"},[(_vm.filters.length)?_c('div',{staticClass:"desktop-filters"},[_vm._l((_vm.filters),function(filter){return _c('FilterDropdown',{key:filter.key,attrs:{"filter":filter,"collection":_vm.collection},on:{"option-change":_vm.onOptionChange,"reset":function($event){return _vm.resetFilters(filter.key)},"close":_vm.trackPage}})}),_c('span',{class:[
              'desktop-filters-reset',
              'text7',
              _vm.showResetButton && 'visible',
            ],on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" "+_vm._s(_vm.$t("common.filters.reset-all"))+" ")])],2):_vm._e(),(_vm.showSearch)?_c('SearchInput',{attrs:{"collectionName":_vm.collectionName,"placeholder":_vm.$t(`${_vm.moduleName}.filters.search.placeholder`)},on:{"search":_vm.onSearch}}):_vm._e(),(_vm.enabledFilters.length && _vm.filters.length)?_c('FilterButton',{class:['filters-button', _vm.nbValues && 'active'],nativeOn:{"click":function($event){_vm.showOverlay = true;
            _vm.trackFilters();}}},[_c('FontIcon',{attrs:{"name":"ui_filter"}}),_vm._v(" "+_vm._s(_vm.$t("common.filters.toggle-button.label"))+" "+_vm._s(_vm.nbValues || "")+" ")],1):_vm._e(),(_vm.showOverlay)?_c('portal',{attrs:{"to":"portal-root"}},[_c('FiltersLayer',{attrs:{"collection":_vm.collection,"filters":_vm.filters},on:{"option-change":_vm.onOptionChange,"close":function($event){_vm.showOverlay = false;
              _vm.trackPage();},"reset":function($event){return _vm.resetFilters($event)}}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }