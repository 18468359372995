import { UL_LAUNCHED_APP } from "@/modules/universal-links";
import { getStore } from "@/store";
let routerRef;

const handlePushEvent = event => {
  const { eventData } = event?.detail;
  const path = eventData.hash || eventData.path;
  getStore().dispatch("setRedirectUrl", path);
  // router push here
  routerRef.push({
    path: path,
    query: {},
  });
};

export const ulRegisterEvents = router => {
  // créer la reference router
  routerRef = router;
  document.addEventListener(UL_LAUNCHED_APP, handlePushEvent);
};

export const ulUnregisterEvents = () => {
  routerRef = null;
  document.removeEventListener(UL_LAUNCHED_APP, handlePushEvent);
};
