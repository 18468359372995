import client from "@/plugins/jp-api-client";

import { getPasswordError, getErrorCode } from "@/utilities/errors";
import { getConfig } from "@/store";

export default {
  namespaced: true,

  state: {
    updating: false,
    error: null,
    updated: false,
    checking: false,
    checkError: null,
  },

  mutations: {
    reset(state) {
      state.updating = false;
      state.error = null;
      state.updated = false;
    },
    start(state) {
      state.updating = true;
      state.error = null;
      state.updated = false;
    },
    success(state) {
      state.updating = false;
      state.updated = true;
    },
    checkStart(state) {
      state.checking = true;
      state.error = null;
    },
    checkSuccess(state) {
      state.checking = false;
    },
    error(state, error) {
      state.updating = false;
      state.error = error;
      state.updated = false;
    },
    checkError(state, error) {
      state.checking = false;
      state.checkError = error;
    },
  },

  actions: {
    async check({ commit }, { username, password }) {
      commit("checkStart");
      try {
        const { hash } = await client.auth.passwordToken(
          getConfig().env.clientId,
          getConfig().env.clientSecret,
          username,
          password,
          { doNotForceExit: true },
        );
        commit("checkSuccess");
        return { success: true, hash };
      } catch (error) {
        const errorCode = getErrorCode(error);
        commit("checkError", errorCode);
        return { success: false, error: errorCode };
      }
    },
    async update({ commit, rootGetters }, { currentPassword, newPassword }) {
      commit("start");

      const externalId =
        rootGetters["subscriptions/primarySubscription"]?.attributes
          ?.specifics?.["subscription-external-id"];

      try {
        const { hash } = await client.password.update({
          username: rootGetters["member/username"]
            ? rootGetters["member/username"]
            : externalId,
          password: currentPassword,
          newPassword: newPassword,
        });
        commit("success");

        return { success: true, hash };
      } catch (error) {
        commit("error", getPasswordError(error));

        return { success: false };
      }
    },
  },
};
