<template>
  <div class="filter-dropdown">
    <FilterButton
      :id="`button--filter-${filter.key}`"
      :active="expanded"
      :selected="isFilterActive"
      class="filter-dropdown-button"
      @click.native="expanded = true"
    >
      <span class="button-label">
        {{ buttonLabel }}
      </span>
      <FontIcon
        name="ui_chevron_dropdown"
        :class="isFilterActive && 'hidden'"
      />
    </FilterButton>

    <template v-if="expanded">
      <CityFilterLayer
        v-if="filter.key === 'city'"
        class="city-filter-layer"
        :filter="filter"
        :collection="collection"
        @option-change="$emit('option-change', $event)"
        @reset="$emit('reset')"
      />
      <div v-else class="options-layer" @mousedown.stop>
        <div class="filter-name text5">
          {{ $t(`common.filters.name.${filter.key}`) }}
        </div>
        <div class="options-wrapper">
          <FilterOption
            v-for="option in filter.options"
            :id="`input--filter-${filter.key}-${option.key}`"
            :key="option.key"
            :label="$t(`common.filters.${filter.key}.${option.key}`)"
            :value="option.value || false"
            @mousedown.native.prevent
            @update:value="
              $emit('option-change', {
                filter: filter.key,
                option: option.key,
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div class="input-curtain" />
    </template>
    <FontIcon
      v-if="isFilterActive"
      name="ui_close"
      class="reset-icon"
      @click.native.stop="$emit('reset')"
    />
  </div>
</template>

<style lang="scss">
.filter-dropdown {
  position: relative;
  margin-right: rem(16px);

  .filter-dropdown-button {
    min-width: rem(150px);
    padding: rem(0 10px 0 16px);
    justify-content: space-between;

    .button-label {
      width: 100%;
    }

    .font-icon {
      width: rem(16px);
      min-width: rem(16px);
      margin-left: rem(5px);
      font-size: rem(16px);

      &.hidden {
        opacity: 0;
      }
    }
  }

  .city-filter-layer,
  .options-layer {
    width: rem(260px);
    margin-top: rem(8px);
  }

  .city-filter-layer {
    max-height: rem(400px);
  }

  .options-layer {
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: rem(15px);
    border-radius: 11px;
    @include setColor(--color-background1, background-color);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .options-wrapper {
    max-height: rem(300px);
    padding: rem(5px 10px 5px);
    overflow: auto;
  }

  .input-curtain {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .reset-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(10px);
    @include setColor(--color-cta-negative);
    cursor: pointer;
  }

  .filter-name {
    padding: rem(0 10px);
    @include setColor(--color-title1);
    margin-bottom: rem(25px);
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";
import CityFilterLayer from "./CityFilterLayer";
import FilterButton from "./FilterButton";
import FilterOption from "./FilterOption";

export default {
  components: {
    CityFilterLayer,
    FilterButton,
    FilterOption,
    FontIcon,
  },

  data() {
    return {
      expanded: false,
    };
  },

  props: {
    filter: {
      type: Object,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },

  computed: {
    geolocation() {
      return this.filter.key === "city" && this.collection?.geolocation;
    },

    selectedOptions() {
      return this.filter.options.filter(option => option.value);
    },

    isFilterActive() {
      return !!this.geolocation || this.selectedOptions.length > 0;
    },

    buttonLabel() {
      if (this.geolocation) {
        return this.$t("common.filters.city.around-me");
      }

      const nbFilters = this.selectedOptions.length;
      if (!nbFilters) {
        return this.$t(`common.filters.name.${this.filter.key}`);
      }

      const firstItemKey = this.selectedOptions[0].key;
      const activeFilter =
        this.filter.key === "city"
          ? firstItemKey
          : this.$t(`common.filters.${this.filter.key}.${firstItemKey}`);

      if (nbFilters === 1) {
        return activeFilter;
      }

      return (
        activeFilter +
        " " +
        this.$tc("common.filters.plus-others", nbFilters - 1, {
          n: nbFilters - 1,
        })
      );
    },
  },

  methods: {
    close() {
      if (this.expanded) {
        this.$emit("close");
      }
      this.expanded = false;
    },
  },

  mounted() {
    window.addEventListener("mousedown", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("mousedown", this.close);
  },
};
</script>
