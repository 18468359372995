import client from "@/plugins/jp-api-client";
import Vue from "vue";

import { getErrorCode } from "@/utilities/errors";
import { parseComponentImages } from "@/utilities/images";
import { getFiltersFromFacets } from "@/utilities/filters";

export default {
  namespaced: true,

  state: {
    pagesById: {},

    collections: {},

    fetchingComponents: false,
    fetchComponentsError: null,
    next: null,

    componentsById: {},
    fetchingComponentDetails: false,
    fetchComponentDetailsError: null,

    collectionsSearchResult: [],
  },

  mutations: {
    fetchComponentsStart(state) {
      state.fetchingComponents = true;
      state.fetchComponentsError = null;
    },
    fetchComponentsSuccess(state, { collection, data, next }) {
      state.fetchingComponents = false;

      const currentCollection = state.collections[collection] || {};

      // Parse filters from data facets only if filters are not already defined
      const filters =
        currentCollection.filters || getFiltersFromFacets(data.facets);

      Vue.set(state.collections, collection, {
        searchValue: "",
        geolocation: null,
        ...currentCollection,
        components: data.results.map(parseComponentImages),
        count: data.count,
        filters,
      });

      state.next = next;
    },
    fetchComponentsError(state, error) {
      state.fetchingComponents = false;
      state.fetchComponentsError = error;
      state.collections = {};
      state.next = null;
    },
    searchInCollectionsStart(state) {
      state.fetchingComponents = true;
      state.fetchComponentsError = null;
      state.collectionsSearchResult = [];
    },
    collectionsSearchSuccess(state, values) {
      state.fetchingComponents = false;
      state.collectionsSearchResult = values;
    },
    collectionsSearchError(state, error) {
      state.fetchingComponents = false;
      state.fetchComponentsError = error;
      state.collectionsSearchResult = [];
      state.next = null;
    },
    setSearchValue(state, { collection, value }) {
      Vue.set(state.collections[collection], "searchValue", value);
    },

    setFilterValue(state, { collection, filter, option, value }) {
      const stateCollection = state.collections[collection];
      if (filter === "geolocation") {
        stateCollection.geolocation = value;
      } else {
        const filters = stateCollection.filters || [];
        const stateFilter = filters.find(f => f.key === filter);
        const stateOption = stateFilter.options.find(o => o.key === option);
        stateOption.value = value;

        if (filter === "city") {
          stateCollection.geolocation = null;
        }
      }
    },
    resetFilterValues(state, { collection, filter }) {
      const filters = state.collections[collection].filters || [];
      const resetFilters = filters.map(stateFilter => {
        if (filter && filter !== stateFilter.key) return stateFilter;
        return {
          ...stateFilter,
          options: stateFilter.options.map(option => ({
            ...option,
            value: false,
          })),
        };
      });
      Vue.set(state.collections[collection], "filters", resetFilters);
    },

    fetchComponentDetailsStart(state) {
      state.fetchingComponentDetails = true;
      state.fetchComponentDetailsError = null;
    },
    fetchComponentDetailsSuccess(state, { component }) {
      state.fetchingComponentDetails = false;
      Vue.set(
        state.componentsById,
        component.id,
        parseComponentImages(component),
      );
    },
    fetchComponentDetailsError(state, error) {
      state.fetchingComponentDetails = false;
      state.fetchComponentDetailsError = error;
    },

    fetchPageSuccess(state, { id, page }) {
      Vue.set(state.pagesById, id, page);
      page.results.forEach(collection => {
        Vue.set(state.collections, collection.slug, collection);
      });
    },

    reset(state, { collection }) {
      Vue.delete(state.collections, collection);
      state.fetchingComponents = false;
      state.next = null;
    },
  },

  actions: {
    async fetchPage({ commit, state, rootState }, id) {
      if (state.pagesById?.[id]) {
        return { success: true };
      }

      try {
        const response = await client.content.pages.getCollections(id, {
          lang: rootState.i18n.locale,
        });

        commit("fetchPageSuccess", {
          id,
          page: response.data,
        });

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },

    async fetchComponent({ commit, state, rootState }, { id, type }) {
      if (state.componentsById?.[id]) {
        return { success: true };
      }

      commit("fetchComponentDetailsStart");

      try {
        const response = await client.content.components.get(type, id, {
          lang: rootState.i18n.locale,
        });

        commit("fetchComponentDetailsSuccess", {
          component: response.data,
        });

        return { success: true };
      } catch (error) {
        commit("fetchComponentDetailsError", getErrorCode(error));

        return { success: false };
      }
    },

    async fetchComponents({ commit, rootState, state }, { collectionName }) {
      commit("fetchComponentsStart");

      try {
        // generate filters query params
        const stateCollection = state.collections[collectionName] || {};
        const filters = stateCollection.filters || [];
        const queryFilters = filters.reduce((acc, filter) => {
          return {
            ...acc,
            [filter.key]: filter.options
              .filter(option => option.value)
              .map(option => option.key),
          };
        }, {});

        queryFilters.ordering = stateCollection.order_by;

        if (stateCollection.searchValue) {
          queryFilters.search = stateCollection.searchValue;
        }

        if (stateCollection.geolocation) {
          const { latitude, longitude } = stateCollection.geolocation;
          queryFilters.location__geo_distance = `10km__${latitude}__${longitude}`;
        }

        const response = await client.content.collections.getComponents(
          collectionName,
          { ...queryFilters, lang: rootState.i18n.locale, page_size: 100 },
        );

        commit("fetchComponentsSuccess", {
          collection: collectionName,
          data: response.data,
          next: response.next,
        });

        return { success: true };
      } catch (error) {
        commit("fetchComponentsError", getErrorCode(error));

        return { success: false };
      }
    },
    searchInCollections({ commit, rootState }, { search, collectionNames }) {
      commit("searchInCollectionsStart");
      const searches = search.split(" ");
      let requests = [];
      let slug = [];
      for (let searchFragment of searches) {
        if (searchFragment) {
          requests = requests.concat(
            collectionNames.map(c => {
              slug.push(c);
              return client.content.collections.getComponents(c, {
                search: searchFragment,
                lang: rootState.i18n.locale,
                page_size: 100,
              });
            }),
          );
        }
      }

      Promise.all(requests)
        .then(results => {
          for (let i = 0; i < slug.length; i++) {
            results[i].slug = slug[i];
          }

          let values = results
            .filter(r => r.data.count > 0)
            .reduce((acc, result) => {
              let data = result.data.results.map(r => {
                return { ...r, slug: result.slug };
              });
              acc = acc.concat(data);
              return acc;
            }, []);
          let ids = [];
          values = values.filter(value => {
            let alreadyFound = ids.includes(value.id);
            if (alreadyFound) return false;
            ids.push(value.id);
            return true;
          });
          commit("collectionsSearchSuccess", values);
          return { success: true };
        })
        .catch(error => {
          commit("collectionsSearchError", getErrorCode(error));
          return { success: false };
        });
    },
  },
};
