export function preventFrameBusting(attempts) {
  for (let i = 0; i < attempts; i++) {
    if (window.top !== window.self) {
      try {
        window.top.location.replace(window.self.location.href);
      } catch (error) {
        continue;
      }
    }

    if (window.top === window.self) return false;
  }

  if (window.top !== window.self) return true;
}
