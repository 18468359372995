export const interestImages = {
  "sports/collective":
    "https://res.cloudinary.com/john-paul/image/upload/h_100,q_auto,dpr_2/v1536943899/visa_platinum/interests/collectif.jpg",
  "sports/individual":
    "https://res.cloudinary.com/john-paul/image/upload/h_100,q_auto,dpr_2/v1536943914/visa_platinum/interests/individuel.jpg",
  "sports/spectator":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943933/visa_platinum/interests/spectateur.jpg",
  "sports/sportsman":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943930/visa_platinum/interests/sportif.jpg",
  "gourmet/bistro":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943894/visa_platinum/interests/bistrot.jpg",
  "gourmet/gastronomy":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943913/visa_platinum/interests/gastronomie.jpg",
  "gourmet/unusual":
    "https://res.cloudinary.com/john-paul/image/upload/h_100,q_auto,dpr_2/v1536943921/visa_platinum/interests/insolite.jpg",
  "gourmet/traditional":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943899/visa_platinum/interests/classique.jpg",
  "arts-culture/temporary-exhibitions":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943912/visa_platinum/interests/expositions_temporaires.jpg",
  "arts-culture/historical-exhibitions":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943924/visa_platinum/interests/monuments_historiques.jpg",
  "arts-culture/theater":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943932/visa_platinum/interests/theatre.jpg",
  "arts-culture/concert":
    "https://res.cloudinary.com/john-paul/image/upload/h_100,q_auto,dpr_2/v1536943904/visa_platinum/interests/concert.jpg",
  "shopping/capricious-shopping":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943893/visa_platinum/interests/achat_caprice.jpg",
  "shopping/useful-shopping":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943893/visa_platinum/interests/achat_utile.jpg",
  "shopping/clothes":
    "https://res.cloudinary.com/john-paul/image/upload/h_100,q_auto,dpr_2/v1536943933/visa_platinum/interests/vetements.jpg",
  "shopping/decoration":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943906/visa_platinum/interests/decoration.jpg",
  "travel/discovery":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943909/visa_platinum/interests/decouverte.jpg",
  "travel/laze":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943913/visa_platinum/interests/farniente.jpg",
  "travel/city":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1536943933/visa_platinum/interests/ville.jpg",
  "travel/nature":
    "https://res.cloudinary.com/john-paul/image/upload/w_100,q_auto,dpr_2/v1542236080/visa_apac/Interests/travel/nature.jpg",
  "univers/sports":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/AB_bienetre_sport.jpg",
  "univers/gourmet":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/AB_gourmet.jpg",
  "univers/shopping":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/AB_shopping.jpg",
  "univers/travel":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/AB_voyage.jpg",
  "univers/arts-culture":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/AB_culture.jpg",
  "univers/daily-services":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/AB_daily.jpg",
  "marques-hotelieres/marque-adagio":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Adagio.jpg",
  "marques-hotelieres/marque-banyan-tree":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Banyantree.jpg",
  "marques-hotelieres/marque-fairmont":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Fairmont.jpg",
  "marques-hotelieres/marque-sofitel-legend":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Sofitel_Legend.jpg",
  "marques-hotelieres/marque-so-sofitel":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_So_Sofitel.jpg",
  "marques-hotelieres/marque-sofitel":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/v1676477053/allheartists/Sofitel_Interests.jpg",
  "marques-hotelieres/marque-onefinestay":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_OFS.jpg",
  "marques-hotelieres/marque-rixos":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Rixos.jpg",
  "marques-hotelieres/marque-m-gallery":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Mgallery.jpg",
  "marques-hotelieres/marque-pullman":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Pullman.jpg",
  "marques-hotelieres/marque-swissotel":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Swiss_tel.jpg",
  "marques-hotelieres/marque-angsana":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Angsana_Velavaru.jpg",
  "marques-hotelieres/marque-twenty-five-hours-hotels":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_25H_hotel.jpg",
  "marques-hotelieres/marque-grand-mercure":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Grand_Mercure.jpg",
  "marques-hotelieres/marque-the-sebel":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_The_Sebel.jpg",
  "marques-hotelieres/marque-novotel":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Novotel.jpg",
  "marques-hotelieres/marque-mercure":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Mercure.jpg",
  "marques-hotelieres/marque-mama-shelter":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Mama_Shelter.jpg",
  "marques-hotelieres/marque-ibis":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Ibis.jpg",
  "marques-hotelieres/marque-ibis-budget":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Ibis_Budget.jpg",
  "marques-hotelieres/marque-ibis-styles":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Ibis_Budget.jpg",
  "marques-hotelieres/marque-jo-joe":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Jo_Joe.jpg",
  "marques-hotelieres/marque-hotelf1":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_F1.jpg",
  "marques-hotelieres/marque-thalassa-sea-spa":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Thalassa.jpg",
  "marques-hotelieres/marque-raffles":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_200,w_300/accor_bienvenue/AB_Raffles.jpg",
  "destinations/sun-sea":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_150/accor_bienvenue/mer.jpg",
  "destinations/snow-skiing":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/montagne.jpg",
  "destinations/nature-outdoors":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/campagne.jpg",
  "destinations/city":
    "https://res.cloudinary.com/john-paul/image/upload/c_fill,g_auto,h_100/accor_bienvenue/urban_ville.jpg",
};
