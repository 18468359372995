import set from "lodash.set";
import moment from "moment-timezone";
import i18nInstance from "@/i18n";
import { parseGoogleAddress } from "./parsers";

function setApiValue(results, keys = [], value) {
  keys.forEach(key => {
    set(results, key, value);
  });
  return results;
}

function getDateWithTimezone(date, timeInMinutes, address) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const hours = parseInt(timeInMinutes / 60, 10);
  const minutes = timeInMinutes % 60;

  return moment
    .tz([year, month, day, hours, minutes], address?.timezone_id)
    .format();
}

function parseDate(fields, options) {
  const hour = options.forceHour || 12;

  const dateValue = fields[options.dateField]?.value;
  const addressValue = fields[options.addressField]?.value;

  if (!dateValue) return;

  const tzDate = getDateWithTimezone(dateValue, hour * 60, addressValue);
  return setApiValue({}, options.apiKeys, tzDate);
}

function parseDatetime(fields, options) {
  const dateValue = fields[options.dateField]?.value;
  const timeValue = fields[options.timeField]?.value;
  const addressValue = fields[options.addressField]?.value;

  if (!dateValue || timeValue === null) return;

  const tzDate = getDateWithTimezone(dateValue, timeValue, addressValue);
  return setApiValue({}, options.apiKeys, tzDate);
}

function parseApiKeys(fields) {
  const apiValues = {};
  for (const fieldName in fields) {
    const field = fields[fieldName];
    const apiKeys = field.api || [];
    setApiValue(apiValues, apiKeys, field.value);
  }
  return apiValues;
}

function parseLocation(fields, options) {
  const { apiKeys, field } = options;
  const googleLocation = fields[field].value;

  if (!googleLocation) return;

  const location = parseGoogleAddress(googleLocation.address);
  location.timezone_id = googleLocation.timezone_id;

  return setApiValue({}, apiKeys, location);
}

function setEstablishmentSubject(fields, options) {
  const location = fields[options.field].value;
  if (!location) return;

  if (location.types.includes("establishment")) {
    return setApiValue({}, ["subject"], location.name);
  }
}

function parseBudget(fields, options) {
  const { apiKeys, field } = options;
  const budgetField = fields[field];

  if (!budgetField.value) return;

  const value =
    budgetField.value > budgetField.maximum
      ? budgetField.maximum + 0.01
      : budgetField.value;
  return setApiValue({}, apiKeys, Math.ceil(value * 100));
}

function aggregateValues(fields, options) {
  const values = options.fields.map(f => {
    const field = fields[f];
    if (field.type === "checkbox") {
      const key = field.value ? "common.yes" : "common.no";
      return `${i18nInstance.t(field.label)}:${i18nInstance.t(key)}`;
    }
    return `${i18nInstance.t(field.label)}:${field.value}`;
  });
  return setApiValue({}, options.apiKeys, values.join("\n"));
}

export default {
  parseBudget,
  parseApiKeys,
  parseDate,
  parseDatetime,
  parseLocation,
  setEstablishmentSubject,
  aggregateValues,
};
