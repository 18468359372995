<template>
  <component
    :is="blockTemplate"
    :id="details.slug"
    :cards="cards"
    :text="text"
    :button="button"
    @component-display="trackDisplay"
    @component-click="trackClick"
  />
</template>

<script>
import {
  getDynamicComponentId,
  getDynamicComponentVendorId,
} from "@/utilities/tracking";
import { BLOCK_TEMPLATE } from "../../blocks";
import SelectionMosaicTemplate from "../../templates/blocks/selection/SelectionMosaicTemplate";
import SelectionPatchworkTemplate from "../../templates/blocks/selection/SelectionPatchworkTemplate";
import SelectionPushTemplate from "../../templates/blocks/selection/SelectionPushTemplate";
import { mapState } from "vuex";

export default {
  components: {
    SelectionMosaicTemplate,
    SelectionPatchworkTemplate,
    SelectionPushTemplate,
  },

  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
    }),
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || "div";
    },
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    button() {
      return this.components.find(component => component?.type === "button");
    },
    cards() {
      return this.components.filter(
        component =>
          component?.type &&
          component.type !== "text" &&
          component.type !== "button",
      );
    },
    text() {
      return this.components.find(component => component?.type === "text");
    },
  },

  methods: {
    getPublisherParams(idx) {
      const component = this.cards[idx];
      return {
        pageSlug: this.$router.currentRoute.path?.replace("/", ""),
        memberId: this.memberId,
        nbItems: this.cards.length,
        contentId: getDynamicComponentId(component),
        positionItem: idx + 1,
        vendorId: getDynamicComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },
    trackClick(idx) {
      this.tracker.publishers.dynamic.clickDynamic(
        this.getPublisherParams(idx),
      );
    },
    trackDisplay(idx) {
      this.tracker.publishers.dynamic.dynamic(this.getPublisherParams(idx));
    },
  },

  created() {
    this.cards.forEach(component => {
      component.link_template =
        component.content_ct === "editorial"
          ? "editorial-details"
          : "page-details-content";
      component.link_id = component.parent_id;
    });
  },
};
</script>
