<template>
  <div class="status-filter">
    <Button
      v-for="status in computedStatuses"
      :key="status"
      :id="`button--status-filter-${status}`"
      :class="[
        'status-button',
        selectedStatus === status ? 'text7 selected' : 'text8',
      ]"
      @click.native="$emit('update:value', status !== 'all' ? status : null)"
    >
      {{ $t(`request.my-requests.status.${status}`) }}
    </Button>
  </div>
</template>

<style lang="scss">
.status-filter {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 15px;

  @include respond-to("medium") {
    margin: rem(0 -16px);
    padding: rem(0 5px 15px);
  }

  .status-button {
    display: inline-block;
    border-radius: 1000px;
    height: rem(32px);
    width: auto;
    min-width: rem(92px);
    margin: rem(0 9px);
    padding: rem(0 10px);
    @include setColor(--color-background1, background-color);
    @include setColor(--color-text);
    border-width: 1px;
    border-style: solid;
    @include setColor(--color-divider, border-color);
    transition: 200ms background-color ease;

    &:hover {
      height: rem(32px);
    }

    &.selected {
      @include setColor(--color-cta, background-color);
      @include setColor(--color-text-negative);
    }
  }
}
</style>

<script>
import { Button } from "@johnpaul/jp-vue-components";

export default {
  components: {
    Button,
  },

  props: {
    statuses: {
      type: Array,
      required: true,
    },

    value: {
      type: String,
      required: false,
    },
  },

  computed: {
    computedStatuses() {
      return ["all", ...this.statuses];
    },

    selectedStatus() {
      return this.value || "all";
    },
  },
};
</script>
