<template>
  <div class="my-card-numbers">
    <div class="title text3">
      {{ $t("my-card.section.numbers.title") }}
    </div>

    <MyCardService
      v-for="(number, idx) in numbers"
      :key="number.type"
      :ref="`number-${idx}`"
      class="text6"
      :headerText="$t(`my-card.section.numbers.${number.type}.title`)"
      :expanded="expandedIdx === idx"
      @toggle="toggle(idx)"
    >
      {{ $t(`my-card.section.numbers.${number.type}.description`) }}
      <div class="number-wrapper">
        <div>
          {{ $t(`my-card.section.numbers.${number.type}.number-description`) }}
        </div>

        <Button
          :url="`tel:${number.number}`"
          class="phone-number title7 d-md-none"
        >
          <FontIcon name="ui_phone" class="phone-icon" />
          {{ number.number }}
        </Button>
        <div class="phone-number title7 d-none d-md-inline-flex">
          <FontIcon name="ui_phone" class="phone-icon" />
          {{ number.number }}
        </div>
      </div>
    </MyCardService>
  </div>
</template>

<style lang="scss">
.my-card-numbers {
  width: rem(900px);
  margin: auto;
  padding: rem(28px 100px 200px);
  @include setColor(--color-text);
  overflow-y: hidden;

  .title {
    margin-bottom: rem(19px);
    color: setColor(--color-subtitle);
  }

  .number-wrapper {
    margin-top: rem(30px);
    padding: rem(23px);
    text-align: center;
    border-width: 2;
    border-style: solid;
    @include setColor(--color-selection, border-color);
    border-radius: 12px;
  }

  .phone-number {
    display: inline-flex;
    width: auto;
    margin: rem(16px auto 8px);
    align-items: center;
    justify-content: center;

    .phone-icon {
      font-size: rem(24px);
      margin-right: rem(16px);
    }

    @include respond-min("medium") {
      @include setColor(--color-cta);
    }
  }

  @include respond-to("medium") {
    width: auto;
    margin-top: 0;
    padding: rem(18px);
    padding-bottom: rem(100px);

    .title {
      margin-bottom: rem(18px);
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import easyScroll from "easy-scroll";

import { Button, FontIcon } from "@johnpaul/jp-vue-components";
import MyCardService from "./MyCardService";

import { isMobile } from "@/utilities/responsive";

export default {
  components: {
    Button,
    FontIcon,
    MyCardService,
  },

  data() {
    return {
      expandedIdx: 0,
    };
  },

  computed: {
    ...mapGetters({
      subscription: "subscriptions/primarySubscription",
    }),

    numbers() {
      return this.subscription?.attributes["external-numbers"] || [];
    },
  },

  methods: {
    toggle(idx) {
      this.expandedIdx = this.expandedIdx === idx ? null : idx;
      if (this.expandedIdx !== null && isMobile()) {
        setTimeout(() => {
          const el = this.$refs[`number-${idx}`][0].$el;
          const position = el.getBoundingClientRect();
          const headerHeight = 60;
          easyScroll({
            scrollableDomEle: window,
            direction: "bottom",
            duration: 200,
            easingPreset: "easeInQuad",
            scrollAmount: position.top - headerHeight,
          });
        }, 10);
      }
    },
  },
};
</script>
