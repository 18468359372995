<template>
  <div class="concierge-form" tabindex="1">
    <Alert variant="error" v-if="createRequestError">
      <span v-html="$t(`request.concierge.error.${createRequestError}`)"></span>
    </Alert>

    <DynamicForm
      v-if="form"
      prefixInputId="concierge"
      :form="form"
      :buttonText="$t('request.concierge.form.buttons.submit')"
      :submitting="creatingRequest"
      :moduleState="moduleState"
      @submit="onSubmit"
    />
  </div>
</template>

<style lang="scss">
.concierge-form {
  outline: none;
  padding: rem(16px 12px);
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import formMixin from "@/mixins/formMixin";
import { Alert } from "@johnpaul/jp-vue-components";
import DynamicForm from "@/components/DynamicForm";

import { getTrackingCategory } from "@/utilities/tracking";

export default {
  components: { Alert, DynamicForm },
  mixins: [formMixin],

  data() {
    return {
      form: null,
    };
  },

  computed: {
    ...mapState({
      moduleState: state => state.request,
      category: state => state.request.category,
      creatingRequest: state => state.request.creatingRequest,
      createRequestError: state => state.request.createRequestError,
    }),
  },

  methods: {
    ...mapActions({
      createRequest: "request/createRequest",
    }),
    ...mapMutations({
      resetErrors: "request/resetErrors",
    }),

    async onSubmit(apiValues) {
      this.$root.$emit("mars.form:submit", {
        category: this.category.familyId,
        chapters: this.form.tracking.chapters,
      });

      const { success } = await this.createRequest({
        attributes: {
          ...apiValues,
          subject:
            apiValues.subject ||
            this.$t(`request.concierge.form.subject.${this.category.name}`),
        },
      });

      if (success) {
        this.$emit("nav-next");
      }
    },
  },

  async mounted() {
    const { form, i18nVariant } = this.category;
    await this.loadFormConfig(`request/concierge/${form}`, {
      i18nVariant: i18nVariant,
    });
    this.$root.$emit("mars.form:mounted", {
      category: getTrackingCategory(this.category),
      chapters: this.form.tracking.chapters,
    });
  },

  beforeDestroy() {
    this.resetErrors();
  },
};
</script>
