<template>
  <button
    :class="[
      'filter-button',
      'text8',
      active && 'active',
      selected && 'selected',
    ]"
  >
    <slot />
  </button>
</template>

<style lang="scss">
.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(40px);
  padding: rem(0 12px);
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  box-shadow: none;
  @include setColor(--color-divider, border-color);
  @include setColor(--color-background1, background-color);
  @include setColor(--color-text);

  &:focus {
    outline: none;
  }

  &.active {
    @include setColor(--color-selection, border-color);
  }

  &.selected {
    @include setColor(--color-cta-negative);
    @include setColor(--color-selection, background-color);
  }
}
</style>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
