export default {
  namespaced: true,

  state: {
    location: true,
  },

  mutations: {
    enableLocation(state) {
      state.location = true;
    },
    disableLocation(state) {
      state.location = false;
    },
  },
};
