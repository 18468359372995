import Vue from "vue";
import VueI18n from "vue-i18n";
import client from "@johnpaul/jp-api-client";
import { fetchResources } from "@/utilities/assetsLoader";
import { FEATURES, isFeatureEnabled } from "@/utilities/features";

Vue.use(VueI18n);
let loadedLanguages = [];
const instance = new VueI18n({
  silentTranslationWarn: true,
  locale: "en",
  messages: {},
});

function setI18nLanguage(lang) {
  instance.locale = lang;
  client.defaults.headers["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang, config) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const i18nUrl = config?.env?.i18nUrl ? `${config.env.i18nUrl}/${lang}.json` : `${lang}.json`;

  // If the language hasn't been loaded yet
  return fetchResources(/* webpackChunkName: "lang-[request]" */ i18nUrl)
    .then(res => res.json())
    .then(messages => {
      instance.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
}

export default instance;
