import { createLocalVue, mount, shallowMount } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import * as dataSet from "./dataset";
import Vuex from "vuex";
import { createStoreMocks } from "@/store";

import MyAccountInformations from "../views/MyAccountInformations";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

export default config => {
  function getDefaultStoreOptions() {
    return {
      state: {
        config: config,
        i18n: {
          locale: "fr",
        },
        login: {},
        auth: {},
        member: {},
        "my-account": {},
      },
      actions: {
        "member/fetch": () => {},
        "my-account/update": () => {},
      },
      mutations: {
        "my-account/reset": () => {},
      },
    };
  }

  function getStore(options) {
    return createStoreMocks(getDefaultStoreOptions(), options);
  }

  function getAllInputsClasses(inputs) {
    let inputsClasses = [];
    for (let i = 0; i < inputs.length; i++) {
      inputs
        .at(i)
        .classes()
        .forEach(item => inputsClasses.push(item));
    }
    return inputsClasses;
  }

  function getAllInputsDisabledValue(inputs) {
    let disabledValues = [];
    for (let i = 0; i < inputs.length; i++) {
      disabledValues.push(inputs.at(i).attributes("disabled"));
    }
    return disabledValues;
  }

  describe("My Account Information", () => {
    let wrapper;

    describe("rendering", () => {
      describe("details", () => {
        beforeEach(() => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state.member =
            dataSet.informationWithDetails.state.member.data;

          wrapper = mount(MyAccountInformations, {
            store: getStore(storeOptions),
            data() {
              return {
                forms: dataSet.informationWithDetails.formConfig,
              };
            },
            localVue,
          });
        });

        it("should display details form", () => {
          const informationDetails = wrapper.find(".informations");
          expect(informationDetails.exists()).toBeTruthy();
        });

        it("should display salutation select input", () => {
          const salutationInput = wrapper.find("#input--my-account-salutation");
          expect(salutationInput.exists()).toBeTruthy();
          expect(salutationInput.element.tagName).toBe("SELECT");
        });

        it("should display firstname text input", () => {
          const firstnameInput = wrapper.find("#input--my-account-firstname");
          expect(firstnameInput.exists()).toBeTruthy();
          expect(firstnameInput.element.tagName).toBe("INPUT");
          expect(firstnameInput.attributes("type")).toBe("text");
        });

        it("should display lastname text input", () => {
          const lastnameInput = wrapper.find("#input--my-account-lastname");
          expect(lastnameInput.exists()).toBeTruthy();
          expect(lastnameInput.element.tagName).toBe("INPUT");
          expect(lastnameInput.attributes("type")).toBe("text");
        });
      });

      describe("phones", () => {
        it("should display phone form", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: dataSet.informationWithPersonalPhones.data.formConfig,
                phones: dataSet.informationWithPersonalPhones.data.phones,
              };
            },
            localVue,
          });

          const informationPhones = wrapper.find(".phones");
          expect(informationPhones.exists()).toBeTruthy();
        });

        it("should first display the required phone", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: dataSet.informationWithPersonalPhones.data.formConfig,
                phones: dataSet.informationWithPersonalPhones.data.phones,
              };
            },
            stubs: ["TelInput"],
            localVue,
          });

          const informationPhones = wrapper.find(".phones");
          const informationPhonesTelInputs = informationPhones.findAll(
            "telinput-stub",
          );
          expect(informationPhonesTelInputs.at(0).attributes("id")).toBe(
            "input--my-account-personal-mobile",
          );
        });

        it("should allow not required phone deletion", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: dataSet.informationWithPersonalPhones.data.formConfig,
                phones: dataSet.informationWithPersonalPhones.data.phones,
              };
            },
            localVue,
            stubs: ["TelInput"],
          });
          const deleteMobilePhone = wrapper.find(
            "#link-delete-phone-personal-other",
          );
          expect(deleteMobilePhone.exists()).toBeTruthy();
        });

        it("should not allow required phone deletion", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: dataSet.informationWithPersonalPhones.data.formConfig,
                phones: dataSet.informationWithPersonalPhones.data.phones,
              };
            },
            localVue,
            stubs: ["TelInput"],
          });
          const deleteMobilePhone = wrapper.find(
            "#link-delete-phone-personal-mobile",
          );
          expect(deleteMobilePhone.exists()).toBeFalsy();
        });

        it("should display add cta for each not filled in available phone", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms:
                  dataSet.informationWithPersonalAndProfessionalPhones.data
                    .formConfig,
                phones: [],
              };
            },
            localVue,
            stubs: ["TelInput"],
          });

          const addPhoneLinksLength = wrapper.findAll(".add-phone-link").length;
          expect(addPhoneLinksLength).toBe(4);
        });
      });

      describe("addresses", () => {
        it("should display addresses form", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: dataSet.informationWithAddresses.data.enabled.formConfig,
                addresses:
                  dataSet.informationWithAddresses.data.enabled.addresses,
              };
            },
            localVue,
          });

          const informationAddresses = wrapper.find(".addresses");
          expect(informationAddresses.exists()).toBeTruthy();
        });

        it("should not display addresses form", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: [],
                addresses: [],
              };
            },
            localVue,
          });

          const informationAddresses = wrapper.find(".addresses");
          expect(informationAddresses.exists()).toBeFalsy();
        });

        it("should display normal address fields", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms: dataSet.informationWithAddresses.data.enabled.formConfig,
                addresses:
                  dataSet.informationWithAddresses.data.enabled.addresses,
              };
            },
            stubs: ["TextInput"],
            localVue,
          });

          const addressesContent = wrapper.find(".addresses-content");
          const informationAddressesContentTextInputs = addressesContent.findAll(
            "textinput-stub",
          );
          const informationAddressesContentSelectInputs = addressesContent.findAll(
            "selectinput-stub",
          );
          let inputsClasses = getAllInputsClasses(
            informationAddressesContentTextInputs,
          );
          getAllInputsClasses(
            informationAddressesContentSelectInputs,
          ).forEach(item => inputsClasses.push(item));
          expect(inputsClasses.includes("address-input--disabled")).toBe(false);
        });

        it("should disable address form", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms:
                  dataSet.informationWithAddresses.data.disabled.formConfig,
                addresses:
                  dataSet.informationWithAddresses.data.disabled.addresses,
              };
            },
            stubs: ["TextInput", "SelectInput"],
            localVue,
          });

          const addressesContent = wrapper.find(".addresses-content");
          const informationAddressesContentTextInputs = addressesContent.findAll(
            "textinput-stub",
          );
          const informationAddressesContentSelectInputs = addressesContent.findAll(
            "selectinput-stub",
          );
          let disabledValues = getAllInputsDisabledValue(
            informationAddressesContentTextInputs,
          );
          getAllInputsDisabledValue(
            informationAddressesContentSelectInputs,
          ).forEach(item => disabledValues.push(item));

          expect(disabledValues.filter(item => item === "true").length).toBe(
            informationAddressesContentTextInputs.length +
              informationAddressesContentSelectInputs.length,
          );
        });

        it("should display disabled address fields", () => {
          wrapper = mount(MyAccountInformations, {
            store: getStore(),
            data() {
              return {
                forms:
                  dataSet.informationWithAddresses.data.disabled.formConfig,
                addresses:
                  dataSet.informationWithAddresses.data.disabled.addresses,
              };
            },
            stubs: ["TextInput"],
            localVue,
          });

          const addressesContent = wrapper.find(".addresses-content");
          const informationAddressesContentInputs = addressesContent.findAll(
            "textinput-stub",
          );
          const inputsClasses = getAllInputsClasses(
            informationAddressesContentInputs,
          );
          expect(
            inputsClasses.filter(item => item === "address-input--disabled")
              .length,
          ).toBe(informationAddressesContentInputs.length);
        });
      });
    });

    describe("actions", () => {
      it("should remove a not required phone", async () => {
        wrapper = shallowMount(MyAccountInformations, {
          store: getStore(dataSet.informationWithPersonalPhones.state),
          data() {
            return {
              forms: dataSet.informationWithPersonalPhones.data.formConfig,
              phones: dataSet.informationWithPersonalPhones.data.phones,
            };
          },
          stubs: ["TelInput"],
          localVue,
        });
        const deleteMobilePhoneLink = wrapper.find(
          "#link-delete-phone-personal-other",
        );
        expect(
          wrapper.find("#input--my-account-personal-other").exists(),
        ).toBeTruthy();
        expect(deleteMobilePhoneLink.exists()).toBeTruthy();

        await deleteMobilePhoneLink.trigger("click");

        expect(
          wrapper.find("#input--my-account-personal-other").exists(),
        ).toBeFalsy();
        expect(
          wrapper.find("#link-delete-phone-personal-other").exists(),
        ).toBeFalsy();
      });

      it("should create new tel-input when clicking on add cta", async () => {
        wrapper = mount(MyAccountInformations, {
          store: getStore(),
          data() {
            return {
              forms: dataSet.informationWithPersonalPhones.data.formConfig,
            };
          },
          stubs: ["TelInput"],
          localVue,
        });

        const addPhoneLinks = wrapper.findAll(".add-phone-link");

        expect(
          wrapper.find("#input--my-account-personal-mobile").exists(),
        ).toBeFalsy();

        await addPhoneLinks
          .at(0)
          .find("a")
          .trigger("click");

        expect(
          wrapper.find("#input--my-account-personal-mobile").exists(),
        ).toBeTruthy();
      });
    });
  });
};
