<template>
  <div
    v-if="showPopIn"
    :class="[
      'pop-in-layout',
      fullScreenOnSmallDevice && 'pop-in-layout--full-screen',
      closable && 'pop-in-layout--closable',
    ]"
    @click.self="close"
  >
    <div class="pop-in-layout__container">
      <div class="pop-in-layout__header">
        <div class="pop-in-layout__header-icons">
          <div class="header-logo">
            <slot name="logo" />
          </div>
          <FontIcon v-if="closable" name="ui_close" @click.native="close" />
        </div>
        <h2 class="pop-in-layout__header-title title7">
          {{ title }}
        </h2>
      </div>
      <div class="pop-in-layout__content">
        <slot name="alert" />
        <p class="pop-in-layout__content-subtitle text6">
          {{ subtitle }}
        </p>
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.pop-in-layout {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto 5%;
    padding-bottom: rem(12px);
    border-radius: rem(12px);
    overflow-y: auto;
    @include setColor(--color-background1, background-color);
  }

  &__header {
    @include setColor(--color-background1, background-color);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.103065);

    &-title {
      padding: rem(0 16px 16px);
    }

    &-icons {
      position: relative;

      img {
        max-width: rem(80px);
        padding: rem(8px 0 17px);
        margin: 0 auto;
      }

      .font-icon {
        position: absolute;
        top: rem(6px);
        right: rem(8px);
        font-size: rem(24px);
      }
    }
  }

  &__content {
    &-subtitle {
      padding: rem(8px) rem(16px) 0;
      text-align: left;
    }
  }

  @include respond-min("medium") {
    display: block;
    background-color: rgba(0, 0, 0, 0.7);

    &__container {
      margin: 5% auto 0;
      max-width: rem(700px);
      height: 80vh;
      border-radius: rem(12px);
    }

    &__header {
      &-title {
        padding: rem(36px 0 16px);
      }

      &-icons {
        .header-logo {
          display: none;
        }

        .font-icon {
          font-size: rem(32px);
        }
      }
    }

    &__content {
      overflow-y: auto;

      &-subtitle {
        width: rem(340px);
        margin: rem(23px auto 0);
        padding: rem(8px) rem(16px);
        text-align: center;
      }
    }
  }

  &--full-screen {
    display: block;
    padding: 0;
    .pop-in-layout__container {
      height: 100vh;
      margin: 0;
      padding-bottom: 0;
      border-radius: unset;
    }

    @include respond-min("medium") {
      .pop-in-layout__container {
        margin: 5% auto 0;
        max-width: rem(700px);
        height: 80vh;
        border-radius: rem(12px);
      }
    }
  }

  &--closable {
    .pop-in-layout__header {
      &-title {
        padding: rem(0 16px 16px);
      }
    }

    @include respond-min("medium") {
      .pop-in-layout__header {
        &-title {
          padding: rem(36px 0 16px);
        }
      }
    }
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  name: "PopInLayout",

  components: {
    FontIcon,
  },

  props: {
    showPopIn: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    fullScreenOnSmallDevice: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      if (this.closable) this.$emit("close");
    },
  },
};
</script>
