<template>
  <div ref="accordion" class="accordion">
    <div class="accordion-header text5" @click="toggle">
      {{ title }}
      <FontIcon :name="showAccordionContent ? 'ui_moins' : 'ui_plus'" />
    </div>
    <div v-if="showAccordionContent" class="accordion-content text6">
      <div v-html="description" class="accordion-description" />
    </div>
  </div>
</template>

<style lang="scss">
.accordion {
  width: 100%;

  .accordion-header {
    position: relative;
    min-height: rem(50px);
    padding: rem(15px 40px 15px 15px);
    @include setColor(--color-cta);
    @include setColor(--color-background1, background-color);
    text-transform: uppercase;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: pointer;

    .font-icon {
      position: absolute;
      right: rem(15px);
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(24px);
    }
  }

  .accordion-content {
    padding: rem(20px);
    @include setColor(--color-text);
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.065);

    p {
      margin: 0;
    }

    .accordion-description {
      strong {
        font-weight: bold;
      }

      .highlight {
        @include setColor(--color-highlight);
      }
    }
  }
}
</style>

<script>
import easyScroll from "easy-scroll";
import { isMobile } from "@/utilities/responsive";
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showAccordionContent: this.expanded,
    };
  },

  methods: {
    toggle() {
      this.$emit("toggle");

      if (isMobile()) {
        setTimeout(() => {
          const el = this.$refs["accordion"];
          const position = el.getBoundingClientRect();
          const headerHeight = 60;

          easyScroll({
            scrollableDomEle: window,
            direction: "bottom",
            duration: 200,
            easingPreset: "easeInQuad",
            scrollAmount: position.top - headerHeight,
          });
        }, 10);
      }
    },
  },
};
</script>
