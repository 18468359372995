<template>
  <div class="cityguide" v-if="pageCollections">
    <Header :component="detailComponent" />

    <CityDescription
      v-if="detailComponent"
      :description="detailComponent.description"
    />

    <div class="city-suggestions">
      <div class="title-container">
        <h1 v-if="listCollections.length" class="addresses-title text1">
          {{ $t("city-guides.details.addresses.title") }}
        </h1>
        <hr class="title-separator" />
      </div>
      <List
        v-for="(listCollection, i) in listCollections"
        :key="`component-${i}`"
        :title="listCollection.name"
        :slug="listCollection.slug"
        :components="getComponents(listCollection)"
      />
    </div>
  </div>
</template>

<style lang="scss">
.cityguide {
  position: relative;

  .title-container {
    position: relative;
    margin: rem(24px auto);
    text-align: center;
  }

  .addresses-title {
    display: inline-block;
    position: relative;
    padding: 0 8px;
    @include setColor(--color-title1);
    @include setColor(--color-background1, background-color);
    z-index: 1;
  }

  .title-separator {
    position: absolute;
    top: 50%;
    left: rem(15px);
    right: rem(15px);
    margin: 0;
    @include setColor(--color-divider, border-color);
  }

  @include respond-min("medium") {
    .city-suggestions {
      width: 90vw;
      max-width: rem(1200px);
      margin: auto;
    }
  }
}
</style>

<script>
import CityDescription from "../components/CityDescription";
import Header from "../components/Header";
import List from "../components/List";

import { getComponentVendorId } from "@/utilities/tracking";

import contentPageMixin from "@/mixins/contentPageMixin";

const descriptionLimit = 260;

export default {
  name: "CityGuideDetails",

  components: {
    CityDescription,
    Header,
    List,
  },

  mixins: [contentPageMixin],

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    pageId() {
      return this.id;
    },

    detailComponent() {
      const collection = this.pageCollections.find(c => c.type === "detail");
      return this.getComponents(collection)[0];
    },

    listCollections() {
      return this.pageCollections.filter(c => c.type === "list");
    },

    showToggleButton() {
      if (!this.detailComponent) return false;
      return this.detailComponent?.description?.length > descriptionLimit;
    },

    shortDescription() {
      if (!this.showToggleButton) return;
      const { description } = this.detailComponent;
      const limit = description.indexOf(" ", descriptionLimit);
      return limit > -1 ? description.substr(0, limit) : description;
    },
  },

  watch: {
    detailComponent() {
      this.$root.$emit("city-guides.details:mounted", {
        city: getComponentVendorId(this.detailComponent),
      });
    },
  },

  mounted() {
    this.$emit("header", { transparent: true });
  },
};
</script>
