const MyCard = () => import(/* webpackChunkName: "my-card" */ "./MyCard");

export default {
  path: "/my-card",
  name: "my-card",
  component: MyCard,
  meta: {
    title: "common.head.title.my-card",
    requiresAuth: true,
    template: "my-card",
  },
};
