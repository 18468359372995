<template>
  <div class="editorial">
    <Header :component="componentObject" />

    <EditorialDescription v-if="description" :description="description" />

    <div class="editorial-suggestions">
      <div class="title-container">
        <h1 v-if="contentLists.length" class="addresses-title text1">
          {{ $t("dynamic-content.editorial.details.addresses.title") }}
        </h1>
        <hr class="title-separator" />
      </div>
      <List
        v-for="(contentList, i) in contentLists"
        :key="`component-${i}`"
        :category="contentList.category"
        :items="contentList.items"
      />
    </div>
  </div>
</template>

<style lang="scss">
.editorial {
  position: relative;

  .title-container {
    position: relative;
    margin: rem(24px auto);
    text-align: center;
  }

  .addresses-title {
    display: inline-block;
    position: relative;
    padding: 0 8px;
    @include setColor(--color-title1);
    @include setColor(--color-background1, background-color);
    z-index: 1;
  }

  .title-separator {
    position: absolute;
    top: 50%;
    left: rem(15px);
    right: rem(15px);
    margin: 0;
    @include setColor(--color-divider, border-color);
  }

  @include respond-min("medium") {
    .editorial-suggestions {
      width: 90vw;
      max-width: rem(1200px);
      margin: auto;
    }
  }
}
</style>

<script>
import EditorialDescription from "../components/editorials/EditiorialDescription";
import Header from "../components/editorials/Header";
import List from "../components/editorials/List";

import { getDynamicComponentId } from "@/utilities/tracking";
import { parseBlockItemComponentMedias } from "@/utilities/images";
import { mapActions, mapState } from "vuex";

const descriptionLimit = 260;

export default {
  name: "EditorialDetails",

  components: {
    EditorialDescription,
    Header,
    List,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      componentsById: state => state.contentv2.componentsById,
    }),
    componentObject() {
      return this.componentsById[this.id]
        ? parseBlockItemComponentMedias(this.componentsById[this.id])
        : this.componentsById[this.id];
    },
    contentLists() {
      const contentLists = this.componentObject?.content_lists || [];
      return contentLists?.sort((listA, listB) => listA.order - listB.order);
    },
    description() {
      return this.componentObject?.description || "";
    },
    showToggleButton() {
      if (!this.description) return false;
      return this.description.length > descriptionLimit;
    },
    shortDescription() {
      if (!this.showToggleButton) return;
      const result = this.componentObject?.description;
      const limit = result.indexOf(" ", descriptionLimit);
      return limit > -1 ? result.substr(0, limit) : result;
    },
  },

  watch: {
    "$route.path": function() {
      this.fetchComponent({ id: this.id });
    },
    componentObject() {
      this.$root.$emit("dynamic-content.page:loaded", {
        contentId: getDynamicComponentId(this.componentObject),
      });
    },
  },

  methods: {
    ...mapActions({
      fetchComponent: "contentv2/fetchComponent",
    }),
  },

  mounted() {
    this.fetchComponent({ id: this.id });
    this.$emit("header", { transparent: true });
  },
};
</script>
