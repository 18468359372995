<template>
  <section class="selection-push-template">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>
    <div class="list">
      <div class="cards-wrapper">
        <ContentCardLink
          v-for="(card, index) in optimizedCards.slice(0, maxOfferPerRow)"
          :key="card.id"
          :component="card"
          :in-viewport-active="true"
          :in-viewport-once="true"
          class="offer-link"
          @display="$emit('component-display', index)"
          @mousedown.native="$emit('component-click', index)"
        />
      </div>
    </div>

    <EventLink
      v-if="button"
      :id="`button--selection--push--${id}`"
      :event="event"
      :linkComponent="button"
      class="see-more-button"
      type="button"
    />
  </section>
</template>

<style lang="scss">
.selection-push-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20px 0);

  @include respond-min("medium") {
    padding: rem(40px 0);
  }

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
  }

  h2 {
    @include setColor(--color-subtitle);
    text-align: center;
  }

  .list {
    width: 100%;
    overflow-x: auto;
    max-width: rem(1400px);
    padding-bottom: rem(14px);
    margin: rem(27px auto 10px);
  }

  .cards-wrapper {
    position: relative;
    display: inline-flex;
    padding-left: rem(15px);
  }

  .offer-link {
    display: inline-block;
    width: rem(300px);
    min-width: rem(300px);
    margin-right: rem(15px);

    > * {
      height: 100%;
    }
  }

  .see-more-button {
    display: inline-flex;
    width: auto;
    margin: auto;
    padding: rem(0 50px);
  }

  @include respond-min("medium") {
    overflow-x: none;

    .list {
      margin-bottom: rem(26px);
    }

    .cards-wrapper {
      width: 100%;
      justify-content: space-between;
    }

    .offer-link {
      min-width: none;
      width: 32%;
      text-decoration: none;
    }
  }
}
</style>

<script>
import EventLink from "@/components/EventLink";
import ContentCardLink from "@/modules/dynamic-content/components/ContentCardLink";
import { parseBlockItemComponentMedias } from "@/utilities/images";

export default {
  components: {
    EventLink,
    ContentCardLink,
  },

  data() {
    return {
      maxOfferPerRow: 4,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    text: {
      type: Object,
      default: null,
    },
    button: {
      type: Object,
      default: null,
    },
  },

  computed: {
    title() {
      return this.text?.title || "";
    },
    subtitle() {
      return this.text?.subtitle || "";
    },
    buttonType() {
      return this.button?.button_type;
    },
    event() {
      return this.buttonType === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },
    optimizedCards() {
      return this.cards.map(component =>
        parseBlockItemComponentMedias(component),
      );
    },
  },
};
</script>
