import notifications from "./modules/notifications";
import password from "./modules/password";
import secret from "./modules/secret";

import client from "@/plugins/jp-api-client";

import { getErrorCode } from "@/utilities/errors";
import clonedeep from "lodash.clonedeep";

export default {
  namespaced: true,

  modules: {
    notifications,
    password,
    secret,
  },

  state: {
    updating: false,
    updateError: null,
    updated: false,
  },

  mutations: {
    reset(state) {
      state.updating = false;
      state.updateError = null;
      state.updated = false;
    },
    updateStart(state) {
      state.updating = true;
      state.updateError = null;
      state.updated = false;
    },
    updateSuccess(state) {
      state.updating = false;
      state.updated = true;
    },
    updateError(state, updateError) {
      state.updating = false;
      state.updateError = updateError;
      state.updated = false;
    },
  },

  actions: {
    async update({ commit, rootGetters, rootState }, attributes) {
      commit("updateStart");

      const member = clonedeep(rootState.member.data);

      try {
        const phones = Object.values(attributes.phones).map(phone => ({
          type: phone.type,
          phone: phone.value,
          "is-preferred-contact-method": false,
        }));

        const {
          lastname,
          firstname,
          marital_status,
          organization,
          salutation,
        } = attributes;

        if (salutation) member.salutation = salutation;
        if (lastname) member.lastname = lastname;
        if (firstname) member.firstname = firstname;
        if (organization) member.organization = organization;
        if (marital_status) member.marital_status = marital_status;

        member.phones = phones.filter(phone => phone.phone);
        member.addresses =
          attributes.addresses?.map(address => address.value) || [];

        await client.members.update(rootGetters["member/id"], member);
        commit("updateSuccess");
        commit("member/update", member, { root: true });

        return { updateSuccess: true };
      } catch (error) {
        commit("member/update", rootState.member.data, { root: true });
        commit("updateError", getErrorCode(error));

        return { updateSuccess: false };
      }
    },
  },
};
