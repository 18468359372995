const Home = () => import(/* webpackChunkName: "home" */ "./Home");

export default {
  path: "/home",
  name: "home",
  component: Home,
  meta: {
    title: "common.head.title.home",
    requiresAuth: true,
    template: "home-page-online",
  },
};
