import { createLocalVue, mount } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import * as dataset from "./dataset";
import Vuex from "vuex";
import { createStoreMocks } from "@/store";

import ResetPassword from "../views/Reset";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

export default (config, conductor) => {
  function getDefaultStoreOptions() {
    return {
      state: {
        config: config,
        conductorConfig: conductor,
        i18n: {
          locale: "fr",
        },
        login: {},
        auth: {},
        password: {
          secretQuestion: null,
          readSecretQuestionError: null,
          resetError: null,
        },
        "my-account": {
          password: {
            updating: false,
            updated: false,
            error: null,
          },
        },
      },
      actions: {
        "password/getSecretQuestion": () => {},
        "password/reset": () => {},
      },
    };
  }

  function getStore(options) {
    return createStoreMocks(getDefaultStoreOptions(), options);
  }

  describe("Reset Password", () => {
    let wrapper;

    describe("rendering", () => {
      it("should display field to set new password", () => {
        let storeOptions = getDefaultStoreOptions();

        wrapper = mount(ResetPassword, {
          store: getStore(storeOptions),
          propsData: {
            token: "token",
          },
          localVue,
        });
        const passwordField = wrapper.find("#input--password-reset-password");
        expect(passwordField.element.tagName).toBe("INPUT");
        expect(passwordField.attributes("type")).toBe("password");
      });

      it("should display field to confirm new password", () => {
        let storeOptions = getDefaultStoreOptions();

        wrapper = mount(ResetPassword, {
          store: getStore(storeOptions),
          propsData: {
            token: "token",
          },
          localVue,
        });
        const passwordConfirmationField = wrapper.find(
          "#input--password-reset-password-confirmation",
        );
        expect(passwordConfirmationField.element.tagName).toBe("INPUT");
        expect(passwordConfirmationField.attributes("type")).toBe("password");
      });

      it("should display confirmation section if password successfully reset", () => {
        let storeOptions = getDefaultStoreOptions();

        wrapper = mount(ResetPassword, {
          store: getStore(storeOptions),
          propsData: {
            token: "token",
          },
          data() {
            return {
              showConfirmation: true,
            };
          },
          localVue,
        });

        expect(
          wrapper.find("#link--password-reset-back").exists(),
        ).toBeTruthy();
      });

      it("should display an error if reset password failed", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.state.password.resetError = "unknown";

        wrapper = mount(ResetPassword, {
          store: getStore(storeOptions),
          propsData: {
            token: "token",
          },
          localVue,
        });

        expect(wrapper.find("#alert--password-reset-error").exists()).toBe(
          true,
        );
      });

      describe("with secret question", () => {
        it("should display secret question", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataWithSecretQuestion;
            },
            localVue,
          });
          const secretQuestion = wrapper.find(".reset-password-question");
          expect(secretQuestion.exists()).toBeTruthy();
        });

        it("should display secret answer field as textarea", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataWithSecretQuestion;
            },
            localVue,
          });
          const secretAnswer = wrapper.find("#input--password-reset-answer");
          expect(secretAnswer.exists()).toBeTruthy();
          expect(secretAnswer.element.tagName).toBe("TEXTAREA");
        });

        it("should disable submit button if missing required answer", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataMissingSecretAnswer;
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBe("disabled");
        });

        it("should disable submit button if missing required password", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataMissingPassword;
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBe("disabled");
        });

        it("should disable submit button if password is different than the confirmation one", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataPasswordsNotEquals.withSecret;
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBe("disabled");
        });

        it("should enable submit button", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataFilledIn.withSecret;
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBeUndefined();
        });
      });

      describe("without secret question", () => {
        it("should not display secret question", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            localVue,
          });
          const secretQuestion = wrapper.find(".reset-password-question");
          expect(secretQuestion.exists()).toBe(false);
        });

        it("should not display secret answer field", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            localVue,
          });
          const secretAnswer = wrapper.find("#input--password-reset-answer");
          expect(secretAnswer.exists()).toBe(false);
        });

        it("should disable submit button if missing required password", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBe("disabled");
        });

        it("should disable submit button if password is different than the confirmation one", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataPasswordsNotEquals.withoutSecret;
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBe("disabled");
        });

        it("should enable submit button", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(ResetPassword, {
            store: getStore(storeOptions),
            propsData: {
              token: "token",
            },
            data() {
              return dataset.dataFilledIn.withoutSecret;
            },
            localVue,
          });
          const submitButton = wrapper.find("#button--password-reset-submit");
          expect(submitButton.attributes("disabled")).toBeUndefined();
        });
      });
    });
  });
};
