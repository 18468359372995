<template>
  <EventLink
    :id="`link--${component.id}`"
    class="content-card-link"
    event="common.cms.link:click"
    :linkComponent="component"
  >
    <ContentCard
      :id="component.id"
      :src="src('image')"
      :logo="src('logo')"
      :title="title"
      :subtitle="subtitle"
      :date="date"
      :fixedHeight="fixedHeight"
    />
  </EventLink>
</template>

<style lang="scss">
.content-card-link {
  text-decoration: none !important;
}
</style>

<script>
import { mapState } from "vuex";
import inViewport from "vue-in-viewport-mixin";
import EventLink from "@/components/EventLink";
import { ContentCard } from "@johnpaul/jp-vue-components";
import { formatDate } from "@/utilities/dates";
import { getComponentBookingProcess } from "@/utilities/parsers";

export default {
  components: {
    EventLink,
    ContentCard,
  },

  mixins: [inViewport],

  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    "in-viewport-active": {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
    }),

    date() {
      const endDate = this.component.end_date;
      return endDate
        ? this.$t("common.content-card.end-date", {
            date: formatDate(new Date(endDate), this.locale),
          })
        : null;
    },

    title() {
      return this.component.title || this.component.name || "";
    },

    contentType() {
      return this.component.content_ct;
    },

    contentSubType() {
      return this.component.type;
    },

    subtitle() {
      if (getComponentBookingProcess(this.component) !== "concierge") {
        return this.component.vendor?.name || "";
      }

      return this.component.address?.formatted_address;
    },
  },

  methods: {
    src(mediaType) {
      const contentMedia = this.component?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        "",
      );
      const vendorMedia = this.component?.vendor?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        "",
      );
      return contentMedia ? contentMedia : vendorMedia;
    },
  },

  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        this.$emit("display");
      }
    },
  },
};
</script>
