<template>
  <div>
    <component
      :is="componentType"
      :components="mediaComponents.concat(vendorComponents)"
      :id="collection.slug"
      :title="textComponent.name"
      :subtitle="textComponent.subname"
      :button="buttonComponent"
      :class="bgColor"
      :startBgColor="bgColor"
      @component-display="trackComponentDisplay"
      @component-click="trackComponentClick"
      @bgColorSet="$emit('bg-color-set', $event)"
    />
  </div>
</template>

<style lang="scss">
.background1 {
  @include setColor(--color-background1, background-color);
}

.background2 {
  @include setColor(--color-background2, background-color);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import HomeAppDownload from "./HomeAppDownload";
import HomeEditorial from "./HomeEditorial";
import HomeInspirational from "./HomeInspirational";
import HomePartners from "./HomePartners";
import HomePush from "./HomePush";
import HomeSlider from "./HomeSlider";
import HomeList from "./HomeList";
import HomeBanner from "./HomeBanner";
import HomeBadgeBanner from "./HomeBadgeBanner";
import HomeTeaser from "./HomeTeaser";
import HomeContrastPush from "./HomeContrastPush";

import {
  getComponentVendorId,
  getComponentBenefitId,
} from "@/utilities/tracking";

const COMPONENTS = {
  editorial: "HomeEditorial",
  inspirational: "HomeInspirational",
  push: "HomePush",
  slider: "HomeSlider",
  "app-download": "HomeAppDownload",
  partners: "HomePartners",
  list: "HomeList",
  banner: "HomeBanner",
  "badge-banner": "HomeBadgeBanner",
  "partners-teasing": "HomeTeaser",
  "contrast-push": "HomeContrastPush",
};

export default {
  components: {
    HomeAppDownload,
    HomeEditorial,
    HomeInspirational,
    HomePartners,
    HomePush,
    HomeSlider,
    HomeList,
    HomeBanner,
    HomeBadgeBanner,
    HomeTeaser,
    HomeContrastPush,
  },

  data() {
    return {
      background: "",
      componentsTracked: {},
    };
  },

  props: {
    collection: {
      type: Object,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    bgColor: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
    }),

    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),

    componentType() {
      return COMPONENTS[this.collection.type] || "div";
    },

    mediaComponents() {
      const mediaTypes = ["media", "static"];
      return this.components.filter(component =>
        mediaTypes.includes(component.type),
      );
    },

    vendorComponents() {
      return this.components.filter(component => component.object === "vendor");
    },

    textComponent() {
      return this.components.find(component => component.type === "text") || {};
    },

    buttonComponent() {
      return this.components.find(component => component.type === "button");
    },
  },

  methods: {
    getPublisherParams(idx) {
      const component = this.mediaComponents[idx];
      const benefitId = getComponentBenefitId(component);
      return {
        format: this.collection.type,
        memberId: this.memberId,
        nbItems: this.mediaComponents.length,
        offerId: benefitId ? `Offer_${benefitId}` : null,
        positionItem: idx + 1,
        vendorId: getComponentVendorId(component),
        loggedIn: this.loggedIn,
        url: encodeURIComponent(window.location.toString()),
      };
    },

    trackComponentDisplay(idx) {
      if (!this.componentsTracked[idx]) {
        this.componentsTracked[idx] = true;
        this.tracker.publishers.home.display(this.getPublisherParams(idx));
      }
    },

    trackComponentClick(idx) {
      this.tracker.publishers.home.click(this.getPublisherParams(idx));
    },
  },

  updated() {
    this.$emit(
      "bgColorSet",
      this.bgColor === "background1" ? "background2" : "background1",
    );
  },
};
</script>
