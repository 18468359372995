import client from "@/plugins/jp-api-client";

import { getErrorCode } from "@/utilities/errors";
import { isEmail } from "@/utilities/validators";
import { getConfig } from "@/store";

export default {
  namespaced: true,

  state: {
    fetching: false,
    error: null,
  },

  mutations: {
    fetchStart(state) {
      state.fetching = true;
      state.error = null;
    },
    fetchSuccess(state) {
      state.fetching = false;
    },
    error(state, error) {
      state.fetching = false;
      state.error = error;
    },
  },

  actions: {
    async fetchToken(
      { commit, rootState, dispatch },
      { username, password, alreadyHashed = false },
    ) {
      commit("fetchStart");

      // If the username is a card number, add the Login-Type header
      const headers = !isEmail(username)
        ? { "Login-Type": "subscription-external-id" }
        : {};

      try {
        let config = getConfig();
        const { tokenData, hash } = await client.auth.passwordToken(
          config.env.clientId,
          config.env.clientSecret,
          username,
          password,
          headers,
          alreadyHashed,
        );

        if (rootState.config.env.proxy) {
          commit("auth/setAuthenticated", null, { root: true });
          commit(
            "member/setLastConnectionDate",
            tokenData.data.last_connexion_date,
            { root: true },
          );
        } else {
          commit(
            "auth/setTokens",
            {
              accessToken: tokenData.data.access_token,
              refreshToken: tokenData.data.refresh_token,
            },
            { root: true },
          );
        }
        commit(
          "auth/setRefreshTokenExpiration",
          tokenData.data.refresh_token_expires_in,
          { root: true },
        );

        // Fetch config after a login to get the logged config file
        // The logged config file can be different due to member eligibility
        const { success: successfullyFetchedConfig } = await dispatch(
          "config/fetchConfig",
          {},
          { root: true },
        );

        commit("fetchSuccess");
        return { success: true, successfullyFetchedConfig, hash };
      } catch (error) {
        const errorCode = getErrorCode(error);
        commit("error", errorCode);
        return { success: false, error: errorCode };
      }
    },
    async refreshToken({ commit, rootState }) {
      commit("fetchStart");

      try {
        let config = getConfig();
        const response = await client.auth.refreshToken(
          config.env.clientId,
          config.env.clientSecret,
          rootState.config.env.proxy ? "" : rootState.auth.refreshToken,
        );
        if (rootState.config.env.proxy) {
          commit("auth/setAuthenticated", null, { root: true });
        } else {
          commit(
            "auth/setTokens",
            {
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
            },
            { root: true },
          );
        }
        commit(
          "auth/setRefreshTokenExpiration",
          response.data.refresh_token_expires_in,
          { root: true },
        );
        commit("fetchSuccess");
        return { success: true };
      } catch (error) {
        commit("error", getErrorCode(error));

        return { success: false };
      }
    },
  },
};
