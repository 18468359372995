<template>
  <div class="vendor-detail" v-if="vendor">
    <div
      class="img d-flex justify-content-center align-items-center"
      :style="{
        backgroundImage: `url(${src('detail', 'image')})`,
      }"
    >
      <div class="image-gradient"></div>
      <a href="" class="back-link" @click.prevent="back">
        <FontIcon name="ui_chevron_left" />
      </a>
    </div>
    <b-container :class="['content', !!src('logo') && 'content-with-logo']">
      <b-row>
        <b-col cols="12" md="7">
          <DateLabel
            v-if="showDate"
            class="top-date-label"
            :date="vendor.date_event"
          />

          <h1 class="title7">{{ vendor.name }}</h1>

          <h2 v-if="onlineOffer && type !== 'suggestion'" class="text6">
            {{ $t("common.details.subtitle.online") }}
          </h2>
          <h2 v-else-if="address" class="text6">{{ address }}</h2>

          <template v-if="offerDescription">
            <div>
              <div class="section-title text5 mt-4 mb-2">
                {{ $t(`${moduleName}.details.your-privilege.title`) }}
              </div>
              <div
                class="section-text text6"
                v-html="addTargetBlankOnLinks(offerDescription)"
              />
            </div>
          </template>

          <template v-if="type !== 'suggestion'">
            <hr />
            <div class="section-title text5 mt-4 mb-2">
              {{ $t(`${moduleName}.details.about.title`) }}
            </div>
          </template>
          <div
            class="section-text text6 mt-4"
            v-html="addTargetBlankOnLinks(vendor.description)"
          />

          <template v-if="offerConditions">
            <hr />
            <div>
              <div class="section-title text5 mb-2">
                {{ $t(`${moduleName}.details.conditions.title`) }}
              </div>
              <div
                class="section-text text6"
                v-html="addTargetBlankOnLinks(offerConditions)"
              />
            </div>
          </template>

          <template v-if="showInformation && addressUrl">
            <hr />
            <div class="section-title text5">
              {{ $t(`${moduleName}.details.useful-info.title`) }}
            </div>

            <div class="address-link text7">
              <FontIcon name="ui_pin" />
              <a
                id="link--vendor-google-map"
                :href="addressUrl"
                target="_blank"
                @click="trackMapLink"
                rel="noopener noreferrer"
                >{{ vendor.address.formatted_address }}</a
              >
            </div>
            <Map
              :familyId="vendor.family"
              :marker="{
                lat: vendor.address.latitude,
                lng: vendor.address.longitude,
              }"
            />
          </template>

          <template v-if="legalNotices">
            <hr />
            <div>
              <div class="section-title text5 mb-2">
                {{ $t(`${moduleName}.details.legal-notice.title`) }}
              </div>
              <div
                class="section-text text6"
                v-html="addTargetBlankOnLinks(legalNotices)"
              />
            </div>
          </template>

          <template v-if="offerPraticalInformation">
            <hr />
            <div>
              <div class="section-title text5 mb-2">
                {{ $t(`${moduleName}.details.pratical-information.title`) }}
              </div>
              <div
                class="section-text text6"
                v-html="offerPraticalInformation"
              />
            </div>
          </template>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      :class="['right-container', isCTAFixed && 'fixed']"
      :style="{ top: ctaBlockTop }"
    >
      <b-row>
        <b-col cols="12" md="4">
          <div class="right-content">
            <div class="title text6">
              <DateLabel v-if="showDate" :date="vendor.date_event" />
              <template v-else>
                {{ rightBlocTitle }}
              </template>
            </div>
            <div class="detail">
              <div v-if="src('logo')" class="detail__logo">
                <img :src="src('logo')" :alt="vendor.name" />
              </div>
              <template v-if="moduleName !== 'news'">
                <div v-if="type === 'suggestion'" class="offer-name text5">
                  {{ $t(`${moduleName}.details.cta.description`) }}
                </div>
                <div v-else class="offer-name text5">
                  {{ offer.name || vendor.name }}
                </div>
              </template>
              <Button
                v-if="onlineOffer"
                id="button--vendor-cta"
                :url="offerLink"
                target="_blank"
                @click.native.prevent="viewOnline(offerLink)"
              >
                {{ $t(`${moduleName}.details.cta.external-link`) }}
              </Button>
              <Button
                v-else
                id="button--vendor-cta"
                class="offer-link-button--internal-offer"
                @click.native="createRequest"
              >
                {{ $t(`${moduleName}.details.cta.create-request`) }}

                <FontIcon name="ui_bell" />
              </Button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.vendor-detail {
  position: relative;
  margin-top: rem(-72px);

  .top-date-label {
    justify-content: flex-start;
    margin-bottom: rem(4px);

    @include respond-min("medium") {
      display: none;
    }
  }

  h1 {
    @include setColor(--color-title1);
  }

  .section-title {
    @include setColor(--color-section-title);
  }

  h2 {
    @include setColor(--color-subtitle);
  }

  .section-text {
    @include setColor(--color-text);
  }

  .img {
    width: 100%;
    height: rem(315px);
    background-size: cover;
    background-position: center center;
    margin-bottom: rem(5px);

    @include respond-min("medium") {
      margin-bottom: rem(20px);
    }

    .image-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem(160px);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.0001) 21.59%,
        #000000 132.91%
      );
    }
  }

  .back-link {
    position: absolute;
    top: rem(66px);
    left: rem(16px);
    text-decoration: none;

    .font-icon {
      display: block;
      width: rem(34px);
      height: rem(34px);
      padding-top: rem(5px);
      padding-left: rem(4px);
      @include setColor(--color-cta-negative);
      background: rgba(0, 0, 0, 0.5);
      font-size: rem(24px);
      border-radius: 50%;
    }

    @include respond-min("medium") {
      top: rem(94px);
      left: rem(20px);
    }
  }

  .content {
    @include setColor(--color-background1, background-color);
    padding: rem(15px 15px 80px);

    @include respond-to("medium") {
      padding-bottom: rem(150px);
    }

    &-with-logo {
      @include respond-to("medium") {
        padding-bottom: rem(194px);
      }
    }
  }

  .address-link,
  .offer-link {
    position: relative;
    margin: rem(14px 0 20px);

    .font-icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem(20px);
    }

    a {
      display: block;
      padding-left: rem(25px);
      @include setColor(--color-link);
    }

    &-button--internal-offer {
      position: relative;

      .font-icon {
        position: absolute;
        top: rem(12px);
        right: rem(18px);
        font-size: rem(20px);
      }
    }
  }

  .right-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    &.fixed {
      position: fixed;
    }

    .row {
      flex-direction: row-reverse;
    }

    .right-content {
      width: 100%;
      @include setColor(--color-background1, background-color);
      border-radius: 11px;
      box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1);
      pointer-events: initial;

      .calendar-icon {
        margin-right: rem(10px);
        font-size: rem(16px) !important;
      }

      .offer-name {
        margin: rem(12px 0 30px);
        @include setColor(--color-highlight);
      }
    }

    .title {
      height: rem(53px);
      text-align: center;
      @include setColor(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      @include setColor(--color-divider, border-bottom-color);
    }

    .detail {
      padding: rem(26px);
      @include setColor(--color-highlight);
      text-align: center;

      &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: rem(80px);
        height: rem(44px);

        img {
          object-fit: cover;
          max-height: 100%;
          height: auto;
          max-width: 100%;
          width: auto;
        }
      }
    }

    @include respond-to("medium") {
      position: fixed;
      width: 100%;
      top: auto !important;
      right: 0;
      bottom: 0;
      left: 0;
      transform: none;

      .col-12 {
        padding: 0;
      }

      .right-content {
        border-radius: 0;
        box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.3);
      }

      .title {
        display: none;
      }

      .detail {
        padding: rem(18px 15px 15px);
      }

      button,
      a {
        margin-top: rem(10px);
      }
    }
  }

  @include respond-to("xlarge") {
    margin-top: rem(-56px);
  }
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import router from "@/router";

import DateLabel from "@/components/DateLabel";
import Map from "@/components/Map/Map";
import { Button, FontIcon } from "@johnpaul/jp-vue-components";

import scrollMixin from "@/mixins/scrollMixin";

import { getGoogleLocationLink } from "@/utilities/address";
import { replaceNewLineWithTag } from "@/utilities/text";
import {
  getComponentBenefitId,
  getComponentVendorId,
} from "@/utilities/tracking";
import {
  getComponentBookingProcess,
  addTargetBlankOnLinks,
} from "@/utilities/parsers";
import { isApplication } from "@/utilities/mobile";

export default {
  mixins: [scrollMixin],

  components: {
    Button,
    DateLabel,
    FontIcon,
    Map,
  },

  data() {
    return {
      minTopPosition: 100,
      defaultTopPosition: 260,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    listPage: {
      type: String,
      default: null,
    },
    moduleName: {
      type: String,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showInformation: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      vendors: state => state.content.componentsById,
    }),
    vendor() {
      return this.vendors?.[this.id] || null;
    },
    offer() {
      return this.vendor?.offers?.[0] || {};
    },
    offerDescription() {
      return replaceNewLineWithTag(this.offer?.description);
    },
    offerConditions() {
      return replaceNewLineWithTag(this.offer?.conditions);
    },
    offerPraticalInformation() {
      return replaceNewLineWithTag(this.offer?.pratical_information);
    },
    legalNotices() {
      return replaceNewLineWithTag(
        this.offer?.legal_notice
          ? this.offer?.legal_notice
          : this.vendor?.legal_notice
          ? this.vendor?.legal_notice
          : "",
      );
    },
    vendorDescription() {
      return replaceNewLineWithTag(this.vendor?.description);
    },
    onlineOffer() {
      return getComponentBookingProcess(this.vendor) !== "concierge";
    },
    rightBlocTitle() {
      return this.onlineOffer
        ? this.$t(`${this.moduleName}.details.cta.online.title`)
        : this.$t(`${this.moduleName}.details.cta.place.title`);
    },
    offerLink() {
      const { booking_processes, offers, url } = this.vendor;
      const bookingProcess = offers?.length
        ? offers?.[0]?.booking_processes?.find(bp => bp.type === "website")
        : booking_processes?.find(bp => bp.type === "website");
      return bookingProcess ? bookingProcess.website : url;
    },
    cleanedLink() {
      const startingAuthParameterIndex = this.offerLink?.indexOf("?auth=");
      const authParameter =
        startingAuthParameterIndex !== -1
          ? this.offerLink?.substr(startingAuthParameterIndex)
          : "";
      return this.offerLink?.replace(authParameter, "");
    },
    addressUrl() {
      if (!this.vendor.address) return;
      return getGoogleLocationLink(this.vendor.address);
    },
    img() {
      return this.vendor?.medias?.[0]?.url || null;
    },
    address() {
      return this.showAddress && this.vendor?.address?.formatted_address;
    },
    isCTAFixed() {
      return this.scrollY >= this.defaultTopPosition - this.minTopPosition;
    },
    ctaBlockTop() {
      const top = this.isCTAFixed
        ? this.minTopPosition
        : this.defaultTopPosition;
      return `${top}px`;
    },
  },

  watch: {
    vendor() {
      this.$root.$emit("vendor.details:mounted", {
        vendorId: getComponentVendorId(this.vendor),
        benefitId: getComponentBenefitId(this.vendor),
        fulfillmentMode: getComponentBookingProcess(this.vendor),
      });
    },
  },

  methods: {
    ...mapActions({
      fetchComponent: "content/fetchComponent",
      fetchGaumontToken: "auth/fetchGaumontToken",
    }),

    createRequest() {
      this.trackClick("vendor.details.create-request:click");
    },

    viewOnline(offerLink) {
      this.trackClick("vendor.details.view-online:click");
      this.insertTokenAndRedirect(offerLink);
    },

    trackClick(event) {
      this.$root.$emit(event, {
        vendor: this.vendor,
        stepNumber: 0,
        vendorId: getComponentVendorId(this.vendor),
        benefitId: getComponentBenefitId(this.vendor),
        fulfillmentMode: getComponentBookingProcess(this.vendor),
      });
    },

    trackMapLink() {
      this.$root.$emit("vendor.details.map:click", {
        fulfillmentMode: getComponentBookingProcess(this.vendor),
      });
    },

    back() {
      if (window.history.length > 2) {
        router.go(-1);
      } else {
        this.$root.$emit("vendor.details.back.fallback:click");
      }
    },

    src(mediaType, fallback) {
      let offerMedia = this.offer?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        null,
      );
      let vendorMedia = this.vendor?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        "",
      );

      if (!(offerMedia || vendorMedia)) {
        offerMedia = this.offer?.medias?.reduce(
          (acc, media) => (media.type === fallback ? media.url : acc),
          null,
        );
        vendorMedia = this.vendor?.medias?.reduce(
          (acc, media) => (media.type === fallback ? media.url : acc),
          "",
        );
      }

      return offerMedia ? offerMedia : vendorMedia;
    },
    dirtyResponsive(url) {
      return url.replace("upload/", "upload/h_200,");
    },

    addTargetBlankOnLinks,

    async insertTokenAndRedirect(url) {
      if (url.includes("{token-gaumont}")) {
        const { success, gaumont_token } = await this.fetchGaumontToken();
        if (success) {
          url = url.replace("{token-gaumont}", gaumont_token);
        }
      }
      if (isApplication()) {
        window.location.href = url;
      } else {
        window.open(url, "_blank");
      }
    },
  },

  mounted() {
    this.fetchComponent({
      id: this.id,
      type: this.type,
    });
  },
};
</script>
