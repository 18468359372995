const MyRequests = () =>
  import(
    /* webpackChunkName: "my-requests" */ "./my-requests/views/MyRequests"
  );
const MyOngoingRequests = () =>
  import(
    /* webpackChunkName: "my-ongoing-requests" */ "./my-requests/views/MyOngoingRequests"
  );
const MyPastRequests = () =>
  import(
    /* webpackChunkName: "my-past-requests" */ "./my-requests/views/MyPastRequests"
  );

export default {
  path: "/my-requests",
  component: MyRequests,
  meta: {
    requiresAuth: true,
    title: "common.head.title.my-requests.home",
  },
  children: [
    {
      name: "my-requests",
      path: "",
      component: MyOngoingRequests,
    },
    {
      name: "my-requests/past",
      path: "past",
      component: MyPastRequests,
    },
  ],
};
