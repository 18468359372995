<template>
  <div
    :class="[
      'collection-filters',
      visible && 'visible',
      enabledFilters.length && 'filters',
      showSearch && 'search',
    ]"
  >
    <b-container>
      <b-row>
        <b-col class="filters-row">
          <div v-if="filters.length" class="desktop-filters">
            <FilterDropdown
              v-for="filter in filters"
              :key="filter.key"
              :filter="filter"
              :collection="collection"
              @option-change="onOptionChange"
              @reset="resetFilters(filter.key)"
              @close="trackPage"
            />
            <span
              :class="[
                'desktop-filters-reset',
                'text7',
                showResetButton && 'visible',
              ]"
              @click="resetFilters()"
            >
              {{ $t("common.filters.reset-all") }}
            </span>
          </div>

          <SearchInput
            v-if="showSearch"
            :collectionName="collectionName"
            :placeholder="$t(`${moduleName}.filters.search.placeholder`)"
            @search="onSearch"
          />

          <FilterButton
            v-if="enabledFilters.length && filters.length"
            :class="['filters-button', nbValues && 'active']"
            @click.native="
              showOverlay = true;
              trackFilters();
            "
          >
            <FontIcon name="ui_filter" />
            {{ $t("common.filters.toggle-button.label") }} {{ nbValues || "" }}
          </FilterButton>

          <portal v-if="showOverlay" to="portal-root">
            <FiltersLayer
              :collection="collection"
              :filters="filters"
              @option-change="onOptionChange"
              @close="
                showOverlay = false;
                trackPage();
              "
              @reset="resetFilters($event)"
            />
          </portal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.collection-filters {
  position: -webkit-sticky;
  position: sticky;
  top: rem(56px);

  @include respond-min("xlarge") {
    top: rem(72px);
  }

  width: 100%;
  opacity: 0;
  z-index: 1;
  transition: 200ms opacity ease;
  @include setColor(--color-background2, background-color);

  &.visible {
    opacity: 1;
  }

  @include respond-min("medium") {
    opacity: 1;
  }

  .filters-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(57px);
  }

  .desktop-filters {
    display: none;
  }

  .filters-button {
    margin-left: rem(5px);
    white-space: nowrap;

    @include respond-min("medium") {
      display: none;
    }

    .font-icon {
      font-size: rem(16px);
      margin-right: rem(5px);
    }
  }

  @include respond-min("medium") {
    &.search .filters-row {
      justify-content: flex-end;
    }

    &.filters .filters-row {
      justify-content: flex-start;
    }

    &.search .search-input {
      max-width: rem(300px);
    }

    &.filters.search {
      .filters-row {
        justify-content: space-between;
      }
    }

    .desktop-filters {
      display: flex;
      align-items: center;
    }

    .desktop-filters-reset {
      margin-left: rem(6px);
      @include setColor(--color-cta);
      opacity: 0;
      transition: 150ms opacity ease;
      cursor: pointer;

      &.visible {
        opacity: 1;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import scrollMixin from "@/mixins/scrollMixin";

import { FontIcon } from "@johnpaul/jp-vue-components";
import FilterButton from "./FilterButton";
import FilterDropdown from "./FilterDropdown";
import FiltersLayer from "./FiltersLayer";
import SearchInput from "./SearchInput";

import { getFilterValues } from "@/utilities/tracking";

export default {
  components: {
    FilterButton,
    FilterDropdown,
    FiltersLayer,
    FontIcon,
    SearchInput,
  },

  mixins: [scrollMixin],

  data() {
    return {
      visible: true,
      showOverlay: false,
    };
  },

  props: {
    collectionName: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    enabledFilters: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      collection(state) {
        return state.content.collections[this.collectionName];
      },
    }),

    filters() {
      if (!this.collection?.filters) return [];

      return this.enabledFilters
        .map(filterName => {
          return this.collection.filters.find(f => f.key === filterName);
        })
        .filter(Boolean);
    },

    trackingCategories() {
      const category = this.collection.filters?.find(f => f.key === "family");
      return getFilterValues(category);
    },

    trackingCities() {
      const city = this.collection.filters?.find(f => f.key === "city");
      return getFilterValues(city, "global_key");
    },

    showResetButton() {
      return (
        this.collection?.geolocation ||
        this.filters.some(filter => filter.options.some(option => option.value))
      );
    },

    nbValues() {
      let count = 0;
      this.filters.forEach(filter =>
        filter.options.forEach(option => {
          if (option.value) count++;
        }),
      );

      if (this.collection?.geolocation) {
        count++;
      }

      return count;
    },
  },

  watch: {
    scrollY(newValue, previousValue) {
      this.visible = newValue <= 30 || newValue < previousValue;
    },
  },

  methods: {
    ...mapActions({
      fetchComponents: "content/fetchComponents",
    }),

    ...mapMutations({
      resetFilterValues: "content/resetFilterValues",
      setFilterValue: "content/setFilterValue",
    }),

    onSearch() {
      if (this.searchValue) {
        this.$root.$emit(`${this.moduleName}.list.search:submit`, {
          category: this.trackingCategories,
          city: this.trackingCities,
        });
      }
      this.trackPage();
    },

    trackOption(filter, option, value) {
      if (value && option) {
        this.$root.$emit(`${this.moduleName}.list.filters.${filter}:select`, {
          option: option.replace(" ", "_"),
        });
      }
    },

    trackFilters() {
      this.$root.$emit(`${this.moduleName}.list.filters:open`);
    },

    trackPage() {
      if (this.collection.searchValue) {
        this.$root.$emit(`${this.moduleName}.list.search:mounted`, {
          chapter2: "search",
        });
      } else {
        this.$root.$emit(`${this.moduleName}.list.all:mounted`, {
          category: this.trackingCategories,
          city: this.trackingCities,
        });
      }
    },

    resetFilters(filter) {
      if (!filter || filter === "city") {
        // reset geolocation
        this.setFilterValue({
          collection: this.collectionName,
          filter: "geolocation",
          value: null,
        });
      }
      this.resetFilterValues({ collection: this.collectionName, filter });
      this.fetchComponents({
        collectionName: this.collectionName,
      });
    },

    onOptionChange({ filter, option, value }) {
      this.trackOption(filter, option, value);

      this.setFilterValue({
        collection: this.collectionName,
        filter,
        option,
        value,
      });
      this.fetchComponents({
        collectionName: this.collectionName,
      });
    },
  },

  mounted() {
    this.trackPage();
  },
};
</script>
