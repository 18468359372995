<template>
  <section class="ie-11-decommissioning" v-if="isWarningVisible">
    <div class="ie-header">
      <img :src="require('assets/images/logo.png')" class="logo" alt="logo" />
    </div>
    <div class="decommissioning-title text1">
      {{ $t("common.ie11.warning.update") }}
    </div>
    <div class="decommissioning-content text6">
      {{ $t("common.ie11.warning.content") }}
    </div>
    <div class="navigator-capsules">
      <div class="navigator-capsule edge">
        <img
          class="navigator-logo"
          src="~assets/images/ie11-warning/edge.svg"
          alt="Edge logo"
        />
        <a
          class="navigator-link text7"
          href="https://www.microsoft.com/en-us/edge"
          >EDGE</a
        >
      </div>
      <div class="navigator-capsule chrome">
        <img
          class="navigator-logo"
          src="~assets/images/ie11-warning/chrome.svg"
          alt="Chrome logo"
        />
        <a class="navigator-link text7 " href=" https://www.google.com/chrome/"
          >CHROME</a
        >
      </div>
      <div class="navigator-capsule firefox">
        <img
          class="navigator-logo"
          src="~assets/images/ie11-warning/firefox.svg"
          alt="Firefox logo"
        />
        <a
          class="navigator-link text7 "
          href="https://www.mozilla.org/en-US/firefox/"
          >FIREFOX</a
        >
      </div>
      <div class="navigator-capsule opera">
        <img
          class="navigator-logo"
          src="~assets/images/ie11-warning/opera.svg"
          alt="Opera logo"
        />
        <a class="navigator-link text7 " href="https://www.opera.com/fr"
          >OPERA</a
        >
      </div>
    </div>
    <span
      class="decommissioning-continue link1"
      @click="iEWarningVisible(false)"
    >
      {{ $t("common.ie11.warning.continue") }}
    </span>
  </section>
</template>
<style lang="scss">
.ie-11-decommissioning {
  position: absolute;
  z-index: 9999;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include setColor(--color-background1, background-color);

  .ie-header {
    box-shadow: 0px 0px 4px rgba(74, 96, 117, 0.17);
    height: rem(60px);
    margin-bottom: rem(80px);
    text-align: left;

    img {
      width: 5rem;
      max-height: 2.8125rem;
    }
  }

  .decommissioning-title {
    margin-bottom: rem(17px);
  }

  .decommissioning-content {
    margin-bottom: rem(39px);
  }

  .navigator-capsule {
    display: inline-block;
    margin: rem(0 20px);
    padding: rem(33px 45px);
    border-radius: rem(8px);
    margin-bottom: rem(39px);

    &.edge {
      background-color: rgba(14, 119, 179, 0.2);
    }

    &.chrome {
      background-color: rgba(249, 205, 14, 0.21);
    }
    &.firefox {
      background-color: rgba(143, 80, 244, 0.1);
    }
    &.opera {
      background-color: rgba(252, 26, 44, 0.11);
    }

    .navigator-logo,
    .navigator-link {
      display: block;
    }

    .navigator-logo {
      margin-bottom: rem(17px);
    }

    .navigator-link {
      @include setColor(--color-cta);
    }
  }
  .decommissioning-continue {
    border-bottom: solid 1px;
  }
}
</style>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      isIEWarningVisible: state => state["ie11-warning"].isIEWarningVisible,
    }),
    isWarningVisible() {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf("MSIE "); // IE 10 or older
      const trident = ua.indexOf("Trident/"); //IE 11
      return this.isIEWarningVisible && (msie > 0 || trident > 0);
    },
  },
  methods: {
    ...mapMutations({
      iEWarningVisible: "ie11-warning/iEWarningVisible",
    }),
  },
};
</script>
