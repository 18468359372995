<template>
  <div class="details">
    <div
      :style="{ backgroundImage: backgroundImage }"
      class="details__img d-flex justify-content-center align-items-center"
    >
      <div class="details__image-gradient"></div>
      <a href="" class="details__back-link" @click.prevent="back">
        <FontIcon name="ui_chevron_left" />
      </a>
    </div>
    <b-container
      :class="['details__content', !!imgLogo && 'details__content--with-logo']"
    >
      <b-row>
        <b-col cols="12" md="7">
          <DateLabel v-if="showDate" :date="startDate" class="top-date-label" />

          <h1 class="title7">{{ title }}</h1>

          <h2 v-if="isOnline" class="text6">
            {{ subtitle }}
          </h2>
          <h2 v-else-if="address" class="text6">{{ address }}</h2>

          <template v-if="description">
            <div>
              <div class="section-title text5 mt-4 mb-2">
                {{ $t(`common.${contentType}.details.your-privilege.title`) }}
              </div>
              <div
                v-html="addTargetBlankOnLinks(description)"
                class="section-text text6"
              />
            </div>
          </template>

          <template>
            <hr />
            <div class="section-title text5 mt-4 mb-2">
              {{ subtitle }}
            </div>
          </template>
          <div
            v-html="addTargetBlankOnLinks(about)"
            class="section-text text6 mt-4"
          />

          <template v-if="conditions || legalNotices">
            <hr />
            <div>
              <div class="section-title text5 mb-2">
                {{ $t(`common.${contentType}.details.conditions.title`) }}
              </div>
              <div
                v-if="conditions"
                v-html="addTargetBlankOnLinks(conditions)"
                class="section-text text6"
              />
              <div
                v-if="legalNotices"
                v-html="addTargetBlankOnLinks(legalNotices)"
                class="section-text text6"
              />
            </div>
          </template>

          <template v-if="showInformation">
            <template v-if="addressUrl">
              <hr />
              <div class="section-title text5">
                {{ $t(`common.${contentType}.details.useful-info.title`) }}
              </div>
              <div class="address-link text7">
                <FontIcon name="ui_pin" />
                <a
                  :href="addressUrl"
                  rel="noopener noreferrer"
                  id="link--vendor-google-map"
                  target="_blank"
                  @click="$emit('trackMapLink')"
                >
                  {{ address }}
                </a>
              </div>
              <Map
                :familyId="family"
                :marker="{
                  lat: marker.lat,
                  lng: marker.lng,
                }"
              />
            </template>
          </template>

          <template v-if="practicalInformation">
            <hr />
            <div>
              <div class="section-title text5 mb-2">
                {{
                  $t(`common.${contentType}.details.pratical-information.title`)
                }}
              </div>
              <div class="section-text text6" v-html="practicalInformation" />
            </div>
          </template>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      :class="['details__right-container', isCTAFixed && 'fixed']"
      :style="{ top: ctaBlockTop }"
    >
      <b-row>
        <b-col cols="12" md="4">
          <div class="right-content">
            <div class="title text6">
              <DateLabel v-if="showDate" :date="startDate" />
              <template v-else>
                {{ rightBlockTitle }}
              </template>
            </div>
            <div class="detail">
              <div v-if="imgLogo" class="detail__logo">
                <img :src="imgLogo" :alt="title" />
              </div>
              <Button
                v-if="isOnline"
                :url="link"
                id="button--vendor-cta"
                target="_blank"
                @click.native.prevent="viewOnline(link)"
              >
                {{ $t(`common.${contentType}.details.cta.external-link`) }}
              </Button>
              <Button
                v-else
                id="button--vendor-cta"
                class="content-link-button--internal-offer"
                @click.native="createRequest"
              >
                {{ $t(`common.${contentType}.details.cta.create-request`) }}

                <FontIcon name="ui_bell" />
              </Button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.details {
  position: relative;
  margin-top: rem(-72px);

  .top-date-label {
    justify-content: flex-start;
    margin-bottom: rem(4px);

    @include respond-min("medium") {
      display: none;
    }
  }

  h1 {
    @include setColor(--color-title1);
  }

  .section-title {
    @include setColor(--color-section-title);
  }

  h2 {
    @include setColor(--color-subtitle);
  }

  .section-text {
    @include setColor(--color-text);
  }

  &__img {
    width: 100%;
    height: rem(315px);
    background-size: cover;
    background-position: center center;
    margin-bottom: rem(5px);

    @include respond-min("medium") {
      margin-bottom: rem(20px);
    }
  }

  &__image-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(160px);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.0001) 21.59%,
      #000000 132.91%
    );
  }

  &__back-link {
    position: absolute;
    top: rem(66px);
    left: rem(16px);
    text-decoration: none !important;

    .font-icon {
      display: block;
      width: rem(34px);
      height: rem(34px);
      padding-top: rem(5px);
      padding-left: rem(4px);
      @include setColor(--color-cta-negative);
      background: rgba(0, 0, 0, 0.5);
      font-size: rem(24px);
      border-radius: 50%;
    }

    @include respond-min("medium") {
      top: rem(94px);
      left: rem(20px);
    }
  }

  &__content {
    @include setColor(--color-background1, background-color);
    padding: rem(15px 15px 80px);

    @include respond-to("medium") {
      padding-bottom: rem(150px);
    }

    &--with-logo {
      @include respond-to("medium") {
        padding-bottom: rem(194px);
      }
    }
  }

  .address-link,
  .content-link {
    position: relative;
    margin: rem(14px 0 20px);

    .font-icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem(20px);
    }

    a {
      display: block;
      padding-left: rem(25px);
      @include setColor(--color-link);
    }

    &-button--internal-offer {
      position: relative;

      .font-icon {
        position: absolute;
        top: rem(12px);
        right: rem(18px);
        font-size: rem(20px);
      }
    }
  }

  &__right-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    &.fixed {
      position: fixed;
    }

    .row {
      flex-direction: row-reverse;
    }

    .right-content {
      width: 100%;
      @include setColor(--color-background1, background-color);
      border-radius: 11px;
      box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1);
      pointer-events: initial;

      .calendar-icon {
        margin-right: rem(10px);
        font-size: rem(16px) !important;
      }
    }

    .title {
      height: rem(53px);
      text-align: center;
      @include setColor(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      @include setColor(--color-divider, border-bottom-color);
    }

    .detail {
      padding: rem(26px);
      @include setColor(--color-highlight);
      text-align: center;

      &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto rem(30px);
        width: rem(80px);
        height: rem(44px);

        img {
          object-fit: cover;
          max-height: 100%;
          height: auto;
          max-width: 100%;
          width: auto;
        }
      }
    }

    @include respond-to("medium") {
      position: fixed;
      width: 100%;
      top: auto !important;
      right: 0;
      bottom: 0;
      left: 0;
      transform: none;

      .col-12 {
        padding: 0;
      }

      .right-content {
        border-radius: 0;
        box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.3);
      }

      .title {
        display: none;
      }

      .detail {
        padding: rem(18px 15px 15px);
      }

      button,
      a {
        margin-top: rem(10px);
      }
    }
  }

  @include respond-to("xlarge") {
    margin-top: rem(-56px);
  }
}
</style>

<script>
import { mapActions } from "vuex";
import router from "@/router";

import DateLabel from "@/components/DateLabel";
import Map from "@/components/Map/Map";
import { Button, FontIcon } from "@johnpaul/jp-vue-components";

import scrollMixin from "@/mixins/scrollMixin";

import { addTargetBlankOnLinks } from "@/utilities/parsers";
import { isApplication } from "@/utilities/mobile";

export default {
  mixins: [scrollMixin],

  components: {
    Button,
    DateLabel,
    FontIcon,
    Map,
  },

  data() {
    return {
      minTopPosition: 100,
      defaultTopPosition: 260,
    };
  },

  props: {
    contentType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: null,
    },
    about: {
      type: String,
      default: null,
    },
    conditions: {
      type: String,
      default: null,
    },
    imgDetail: {
      type: String,
    },
    imgLogo: {
      type: String,
    },
    startDate: {
      type: String,
    },
    address: {
      type: String,
      default: null,
    },
    addressUrl: {
      type: String,
      default: null,
    },
    family: {
      type: String,
      default: null,
    },
    marker: {
      type: Object,
      default: null,
    },
    legalNotices: {
      type: String,
      default: null,
    },
    practicalInformation: {
      type: String,
      default: null,
    },
    rightBlockTitle: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showInformation: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isCTAFixed() {
      return this.scrollY >= this.defaultTopPosition - this.minTopPosition;
    },
    ctaBlockTop() {
      const top = this.isCTAFixed
        ? this.minTopPosition
        : this.defaultTopPosition;
      return `${top}px`;
    },
    backgroundImage() {
      return "url('" + this.imgDetail + "')";
    },
  },

  methods: {
    ...mapActions({
      fetchGaumontToken: "auth/fetchGaumontToken",
    }),
    async insertTokenAndRedirect(url) {
      if (url?.includes("{token-gaumont}")) {
        const { success, gaumont_token } = await this.fetchGaumontToken();
        if (success) {
          url = url.replace("{token-gaumont}", gaumont_token);
        }
      }
      if (isApplication()) {
        window.location.href = url;
      } else {
        window.open(url, "_blank");
      }
    },
    createRequest() {
      this.$emit("trackClick", "vendor.details.create-request:click");
    },
    viewOnline(link) {
      this.$emit("trackClick", "vendor.details.view-online:click");
      this.insertTokenAndRedirect(link);
    },
    back() {
      if (window.history.length > 2) {
        router.go(-1);
      } else {
        this.$root.$emit("vendor.details.back.fallback:click");
      }
    },
    addTargetBlankOnLinks,
  },

  mounted() {
    this.$emit("header", { transparent: true });
  },
};
</script>
