<template>
  <ImageHeaderLayout
    ref="header"
    class="my-account"
    :imageSrc="imageHeaderSrc()"
    :avatar="true"
  >
    <template slot="title">
      <div class="title-fullname text1">{{ fullName }}</div>
      <div v-if="!hideContract" class="title-contract text6">
        {{ contract }}
      </div>
    </template>

    <template slot="menu">
      <router-link
        class="header-link text7"
        v-for="(module, moduleName) in sections"
        :id="`link--my-account-${moduleName}`"
        :key="`menu-${moduleName}`"
        :to="{ name: `my-account/${moduleName}` }"
      >
        <FontIcon :name="module.options.icon" class="d-none d-md-block" />
        {{ $t(`my-account.menu.items.${moduleName}`) }}
      </router-link>
    </template>

    <router-view class="content--inner" />
  </ImageHeaderLayout>
</template>

<style lang="scss">
.my-account {
  margin-bottom: rem(80px);

  .header {
    height: rem(177px);

    @include respond-min("medium") {
      height: rem(315px);
    }
  }

  .header-link {
    display: flex;
    flex-direction: column;

    .font-icon {
      margin-bottom: rem(8px);
      font-size: rem(20px);
    }
  }

  .title-fullname {
    margin-bottom: rem(6px);
    @include setColor(--color-title1-negative);
  }

  .title-contract {
    @include setColor(--color-text-negative);
  }

  .content--inner {
    width: rem(500px);
    margin: rem(52px) auto 0;

    @include respond-to("medium") {
      width: auto;
      margin: rem(26px 16px 0px 16px);
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

import ImageHeaderLayout from "@/components/layout/ImageHeaderLayout";
import { FontIcon } from "@johnpaul/jp-vue-components";

import { getModuleConfig, sortModules } from "@/utilities/config";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  name: "MyAccount",

  components: {
    FontIcon,
    ImageHeaderLayout,
  },

  data() {
    const { assets, modules, options } = getModuleConfig(
      this.$config,
      "my-account",
    );
    return {
      assets,
      sections: sortModules(modules),
      imageHeaderUrl: assets.headerImg,
      headerHeight: 0,
      hideContract: options.hideContract || false,
    };
  },

  methods: {
    imageHeaderSrc() {
      return getCloudinaryResponsiveUrl(this.imageHeaderUrl).replace(
        "upload/",
        `upload/h_${this.headerHeight},`,
      );
    },
  },

  computed: {
    ...mapGetters({
      fullName: "member/fullName",
      subscription: "subscriptions/primarySubscription",
    }),
    contract() {
      return this.subscription?.attributes?.contract;
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
    this.$root.$emit("my-account:mounted");
    const headerElm = this.$refs.header.$el.querySelector(".header");
    this.headerHeight = headerElm.clientHeight;
  },
};
</script>
