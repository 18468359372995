const Authorize = () => import(/* webpackChunkName: "home" */ "./Authorize");

export default {
  path: "/authorize",
  name: "authorize",
  component: Authorize,
  meta: {
    title: "common.head.title.consent",
    requiresAuth: true,
    hideWidgets: true,
    footerVisible: true,
  },
};
