import { render, staticRenderFns } from "./ConnectionButtons.vue?vue&type=template&id=5af8b947"
import script from "./ConnectionButtons.vue?vue&type=script&lang=js"
export * from "./ConnectionButtons.vue?vue&type=script&lang=js"
import style0 from "./ConnectionButtons.vue?vue&type=style&index=0&id=5af8b947&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports