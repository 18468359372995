<template>
  <section :class="['picture-list-template', darkTheme && 'dark']">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>
    <div class="picture-list-template__list-wrapper">
      <div class="picture-list-template__list" ref="scrollContainer">
        <div class="picture-list-template__cards-wrapper">
          <span
            v-for="card in pictureCards"
            :key="card.id"
            class="picture-list-template__card"
          >
            <img :src="getCardMediaSrc(card)" :alt="card.title" />
          </span>
        </div>
      </div>
      <template v-if="showControls">
        <Button
          class="picture-list-template__navigation-button previous"
          :id="`button--picture-list--prev--${id}`"
          @click.native="scroll('left')"
        >
          <FontIcon name="ui_chevron_left" />
        </Button>
        <Button
          class="picture-list-template__navigation-button next"
          :id="`button--picture-list--next--${id}`"
          @click.native="scroll('right')"
        >
          <FontIcon name="ui_chevron_right" />
        </Button>
      </template>
    </div>
  </section>
</template>

<style lang="scss">
.picture-list-template {
  padding: rem(20px 0 26px);

  &.dark {
    @include setColor(--color-background4, background-color);

    h1 {
      @include setColor(--color-text-negative);
    }
  }

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
  }

  h2 {
    @include setColor(--color-subtitle);
    text-align: center;
  }

  &__list {
    position: relative;
    width: 100%;
    overflow-x: auto;
    padding: rem(14px 0);
    margin: rem(15px auto 10px);
    text-align: center;
  }

  &__cards-wrapper {
    position: relative;
    display: inline-flex;
    padding: rem(0 8px);
  }

  &__card {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: rem(110px);
    width: rem(150px);
    min-width: rem(150px);
    margin: rem(0 7px);
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .picture-list-template__navigation-button,
  .button {
    display: none;
  }

  @include respond-min("medium") {
    padding: rem(40px 0);

    &__list-wrapper {
      position: relative;
      margin: rem(0 50px);
    }

    &__list {
      height: rem(138px);
      overflow: hidden;
    }

    &__card {
      margin: rem(0 23px);
    }

    .picture-list-template__navigation-button,
    .button {
      display: block;
      position: absolute;
      top: 50%;
      width: auto;
      padding: rem(0 10px);
      transform: translateY(-50%);
      font-size: rem(24px);
      border-radius: 0;

      &.previous {
        left: rem(-50px);
      }

      &.next {
        right: rem(-50px);
      }
    }
  }
}
</style>

<script>
import easyScroll from "easy-scroll";
import { Button, FontIcon } from "@johnpaul/jp-vue-components";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

const CARD_WIDTH = 196;

export default {
  components: {
    Button,
    FontIcon,
  },
  data() {
    return {
      offsetWidth: 0,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    pictureCards: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showControls() {
      return this.offsetWidth < CARD_WIDTH * this.pictureCards.length;
    },
  },
  methods: {
    getCardMediaSrc(card) {
      return getCloudinaryResponsiveUrl(
        card.medias?.[0]?.url,
        card.medias?.[0]?.type,
      );
    },
    scroll(direction) {
      easyScroll({
        scrollableDomEle: this.$refs.scrollContainer,
        direction,
        duration: 150,
        easingPreset: "easeInQuad",
        scrollAmount: CARD_WIDTH,
      });
    },
  },
  mounted() {
    const el = this.$refs?.scrollContainer;
    this.offsetWidth = el.offsetWidth;
  },
};
</script>
