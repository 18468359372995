<template>
  <component
    :is="blockTemplate"
    :cards="cards"
    :details="details"
    :startBgColor="startBgColor"
    @component-display="trackDisplay"
    @component-click="trackClick"
  />
</template>

<script>
import { mapState } from "vuex";
import {
  getDynamicComponentId,
  getDynamicComponentVendorId,
} from "@/utilities/tracking";
import { BLOCK_TEMPLATE } from "../../blocks";
import PostListAlternateTemplate from "../../templates/blocks/post-list/PostListAlternateTemplate";
import PostListCarouselTemplate from "../../templates/blocks/post-list/PostListCarouselTemplate";

export default {
  components: {
    PostListAlternateTemplate,
    PostListCarouselTemplate,
  },

  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    startBgColor: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
    }),
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || "div";
    },
    cards() {
      return this.simpleBlockItems
        .map(simpleBlockItem => simpleBlockItem.components?.[0])
        .filter(component => component?.type === "card");
    },
  },

  methods: {
    getPublisherParams(idx) {
      const component = this.cards[idx];
      return {
        pageSlug: this.$router.currentRoute.path?.replace("/", ""),
        memberId: this.memberId,
        nbItems: this.cards.length,
        contentId: getDynamicComponentId(component),
        positionItem: idx + 1,
        vendorId: getDynamicComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },
    trackClick(idx) {
      this.tracker.publishers.dynamic.clickDynamic(
        this.getPublisherParams(idx),
      );
    },
    trackDisplay(idx) {
      this.tracker.publishers.dynamic.dynamic(this.getPublisherParams(idx));
    },
  },
};
</script>
