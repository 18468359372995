<template>
  <div class="concierge-breakpoint-error">
    <img src="~assets/images/rotate.png" alt="Error" />
    <div class="title text5">
      {{ $t("request.concierge.breakpoint-error.title") }}
    </div>
    <div class="description text8">
      {{ $t("request.concierge.breakpoint-error.description") }}
    </div>
  </div>
</template>

<style lang="scss">
.concierge-breakpoint-error {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include setColor(--color-text-negative);
  @include setColor(--color-background4, background-color);
  pointer-events: initial;
  z-index: 1050;

  .title {
    margin-top: rem(40px);
  }

  .description {
    margin-top: rem(13px);
    max-width: rem(400px);
    text-align: center;
  }

  img {
    height: 40vh;
    max-height: rem(120px);
  }
}
</style>

<script>
export default {};
</script>
