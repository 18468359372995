<template>
  <img
    v-if="url"
    :src="url"
    alt="Avatar"
    :style="{ height: `${height}px`, width: `${width}px` }"
  />
</template>

<script>
import { mapGetters } from "vuex";

import { getCloudinaryUrl } from "@/utilities/images";

export default {
  name: "Avatar",

  props: {
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      primarySubscription: "subscriptions/primarySubscription",
    }),

    contractNumber() {
      return this?.primarySubscription?.attributes?.["contract-number"];
    },

    url() {
      if (!this.contractNumber || !this.$config.assets.cardImage) return;
      return getCloudinaryUrl(this.$config.assets.cardImage, {
        name: this.contractNumber,
        size: this.size,
      });
    },

    size() {
      // both values
      if (this.width && this.height) {
        return `w_${this.width},h_${this.height},dpr_2`;
      }
      // only width
      else if (this.width) {
        return `w_${this.width},h_auto,dpr_2`;
      }
      // only height
      else if (this.height) {
        return `h_${this.height},w_auto,dpr_2`;
      }
      return null;
    },
  },
};
</script>
