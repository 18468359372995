<template>
  <section class="list-template list">
    <BlockItemFilters
      v-if="queryBlockItem && (enabledFilters.length || showSearch)"
      :id="blockItemId"
      :enabledFilters="enabledFilters"
      :showSearch="showSearch"
      class="list-template__filters"
    />
    <b-container>
      <template v-if="components">
        <b-row>
          <b-col>
            <h1 class="super-title3 mt-3">
              {{ title }}
            </h1>
          </b-col>
        </b-row>
        <div v-if="fetchingBlockItem || (components && components.length)">
          <b-row class="list-template__components">
            <b-col
              v-for="(component, index) in components"
              :key="`component-${index}`"
              class="card-wrapper"
              cols="12"
              md="4"
            >
              <ContentCardLink
                :component="component"
                :in-viewport-active="true"
                :in-viewport-once="true"
                @display="$emit('component-display', index)"
                @mousedown.native="$emit('component-click', index)"
              />
            </b-col>
          </b-row>
          <b-pagination
            v-show="numberOfPages > 1"
            :value="currentPage"
            :total-rows="numberOfContents"
            :limit="5"
            align="center"
            first-number
            last-number
            pills
            per-page="21"
            @page-click="onPageClick"
          >
            <template #prev-text>
              <span class="prev-button-class-test">
                <FontIcon name="ui_chevron_left" />
              </span>
            </template>
            <template #next-text>
              <span class="next-button-class-test">
                <FontIcon name="ui_chevron_right" />
              </span>
            </template>
          </b-pagination>
        </div>
        <b-row v-else>
          <b-col>
            <SearchNoResult
              :collection="queryBlockItem"
              :show-concierge-link="showConciergeLink"
              class="no-result"
              module-name="dynamic-content"
            />
          </b-col>
        </b-row>
      </template>
      <ActivityIndicator v-if="fetchingBlockItem" />
    </b-container>
  </section>
</template>

<style lang="scss">
.list-template {
  margin-top: 0;
  padding-top: 0;
  position: relative;
  @include setColor(--color-background1, background-color);

  &__filters {
    @include setColor(--color-background2, background-color);
  }

  h1 {
    @include setColor(--color-title1);
  }

  &__components {
    margin-top: rem(24px);
  }

  .card-wrapper {
    display: flex;
  }

  .content-card-link {
    flex: 1;
    margin-bottom: rem(15px);

    @include respond-min("medium") {
      margin-bottom: rem(34px);
    }
  }

  .offer-card {
    height: 100%;
  }

  .pagination {
    .page-item {
      // Border-radius value used by Bootstrap-vue
      border-radius: 50rem;

      & .page-link {
        color: inherit;
        background-color: inherit;
        border: 1px solid transparent;
        height: 42px;
        width: 42px;
        padding: 0.75rem;
        text-align: center;
        transition: none;

        &:hover {
          border-color: var(--color-background2);
          background-color: var(--color-background2);
        }

        &:last-child {
          margin-left: 0;
        }
      }

      &.active {
        font-weight: bold;
      }

      &:first-child,
      &:last-child {
        border: 1px solid;
        border-color: var(--color-background2);
        background-color: var(--color-background2);

        & .page-link {
          line-height: 1.5rem;
          font-size: 1.5rem;
          padding: 7px;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:first-child {
        margin-right: 50px;
      }

      &:last-child {
        margin-left: 50px;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { ActivityIndicator, FontIcon } from "@johnpaul/jp-vue-components";
import SearchNoResult from "@/components/filters/SearchNoResult";
import ContentCardLink from "../../../components/ContentCardLink";
import BlockItemFilters from "../../../components/filters/BlockItemFilters";
import { parseBlockItemComponentMedias } from "@/utilities/images";

export default {
  components: {
    ActivityIndicator,
    BlockItemFilters,
    FontIcon,
    ContentCardLink,
    SearchNoResult,
  },
  props: {
    queryBlockItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      fetchingBlockItem: state => state.contentv2.fetchingBlockItem,
      blockItemsById: state => state.contentv2.blockItemsById,
    }),
    queryBlockItem() {
      return this.queryBlockItems[0];
    },
    blockItemId() {
      return this.queryBlockItem?.id;
    },
    currentStoredBlockItem() {
      return this.blockItemsById[this.blockItemId];
    },
    components() {
      const blockItemComponents = this.currentStoredBlockItem?.components || [];
      if (!blockItemComponents.length) return [];
      return blockItemComponents.slice().map(component => ({
        ...parseBlockItemComponentMedias(component),
        link_template: "page-details-content",
        link_id: component.parent_id,
      }));
    },
    enabledFilters() {
      return this.currentStoredBlockItem?.enabledFilters || [];
    },
    showSearch() {
      return this.currentStoredBlockItem?.enableSearch;
    },
    showConciergeLink() {
      return this.currentStoredBlockItem?.enableConciergeLink;
    },
    currentPage() {
      return this.blockItemId ? this.currentStoredBlockItem?.currentPage : 1;
    },
    numberOfPages() {
      if (
        this.currentStoredBlockItem?.count &&
        this.currentStoredBlockItem?.pageSize
      ) {
        return Math.ceil(
          this.currentStoredBlockItem.count /
            this.currentStoredBlockItem.pageSize,
        );
      }
      return 1;
    },
    numberOfContents() {
      return this.currentStoredBlockItem?.count;
    },
  },
  methods: {
    ...mapActions({
      fetchBlockItem: "contentv2/fetchBlockItem",
    }),
    async fetchComponents(page) {
      if (this.queryBlockItem?.id)
        await this.fetchBlockItem({ id: this.queryBlockItem.id, page });
      scrollTo(0, 0);
    },
    async onPageClick(_, pageNum) {
      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: pageNum !== 1 ? pageNum : undefined,
        },
      });
    },
  },
  watch: {
    "$route.query.page": function(page) {
      this.fetchComponents(page);
    },
  },
  created() {
    if (!this.enabledFilters.length && !this.showSearch) {
      this.$route.query.page
        ? this.fetchComponents(this.$route.query.page)
        : this.fetchComponents();
    }
  },
  beforeDestroy() {
    if (this.currentPage > 1) this.fetchComponents();
  },
};
</script>
