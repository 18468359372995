<template>
  <div v-if="value" class="request-criteria">
    <FontIcon class="criteria-icon" :name="maskCriteria.icon" />
    <span class="request-criteria-description">
      <span class="text7 mr-1">{{ $t(maskCriteria.label) }}</span>
      <a
        v-if="link"
        class="text7"
        :href="link.href"
        target="_blank"
        rel="noopener noreferrer"
        @click="$root.$emit(link.event)"
        >{{ value }}</a
      >
      <span class="text8" v-else>{{ value }}</span>
    </span>
  </div>
</template>

<style lang="scss">
.request-criteria {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: rem(6px);

  .criteria-icon {
    position: relative;
    top: rem(2px);
    left: 0;
    margin-right: rem(9px);
    font-size: rem(16px);
    @include setColor(--color-icon2);
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-description {
    margin: 0;
    padding-top: rem(2px);
    display: inline-block;
    line-height: rem(18px);
  }

  a {
    @include setColor(--color-link);
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

import {
  getCriteriaValue,
  getLocationLink,
} from "../utilities/requestCriteria";

export default {
  components: {
    FontIcon,
  },

  props: {
    request: {
      type: Object,
      required: true,
    },
    maskCriteria: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },

  computed: {
    value() {
      return getCriteriaValue(this.maskCriteria, this.request, this.locale);
    },

    link() {
      if (this.maskCriteria.type === "location") {
        return getLocationLink(this.request, this.maskCriteria);
      }
      return null;
    },
  },
};
</script>
