<template>
  <section class="button-list-template" v-if="showBlock">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>
    <div class="button-list-template__buttons">
      <Button
        v-for="component in optimizedButtonCards"
        :key="component.id"
        :url="component.button_link"
        :id="`button--button-list--${component.id}`"
        class="button-list-template__button"
        @click.native="$emit('component-click', component)"
      >
        <img
          :src="component.medias[0].url"
          :alt="`Go to ${component.subtitle}`"
        />
        <div class="button-label text8">
          {{ component.title }}
        </div>
        <div class="button-name text5">
          {{ component.subtitle }}
        </div>
      </Button>
    </div>
  </section>
</template>

<style lang="scss">
.button-list-template {
  padding: rem(27px 0 20px);

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
    max-height: rem(67px);
  }

  h2 {
    @include setColor(--color-subtitle);
    margin: rem(0 15px);
    text-align: center;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(30px);

    @include respond-min("medium") {
      flex-direction: row;
      justify-content: center;
    }
  }

  .button-list-template__button,
  .button {
    display: inline-block;
    position: relative;
    height: auto;
    width: auto;
    min-width: rem(215px);
    padding: rem(15px 30px 15px 80px);
    text-align: left;
    margin: rem(10px 20px);
    border-radius: rem(4px);

    &:hover {
      height: inherit;
    }

    img {
      position: absolute;
      left: rem(20px);
      top: 50%;
      transform: translateY(-50%);
      height: rem(36px);
      width: rem(36px);
    }
  }
}
</style>

<script>
import { Button } from "@johnpaul/jp-vue-components";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  components: {
    Button,
  },
  data() {
    return {
      isApplication: navigator.userAgent.includes("jp-companion-app"),
    };
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    buttonCards: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showBlock() {
      return (
        (this.isAppDownloadBlock && !this.isApplication) ||
        !this.isAppDownloadBlock
      );
    },
    isAppDownloadBlock() {
      // TODO: remove this computed when disabled device attribute will be added in Athena
      return this.buttonCards.some(
        component =>
          component.button_link?.includes("https://apps.apple.com/") ||
          component.button_link?.includes("https://play.google.com/"),
      );
    },
    optimizedButtonCards() {
      return this.buttonCards.map(component => ({
        ...component,
        medias: component.medias.map(media => ({
          ...media,
          url: getCloudinaryResponsiveUrl(
            component.medias?.[0]?.url,
            component.medias?.[0]?.type,
          ),
        })),
      }));
    },
  },
};
</script>
