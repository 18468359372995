export default {
  namespaced: true,

  state: {
    ids: [],
  },

  mutations: {
    addId(state, id) {
      state.ids.push(id);
    },
  },

  persistPaths: ["news-banner.ids"],
};
