<template>
  <button
    :id="`button--category-${this.category.name}`"
    :class="[
      'category-button',
      selected && 'selected',
      displayed && 'displayed',
    ]"
    :style="{ top }"
  >
    <div class="background" :class="{ disabled: disabled }">
      <FontIcon
        class="close-icon"
        name="ui_close"
        :class="{ disabled: disabled }"
      />
    </div>
    <FontIcon
      class="category-icon"
      :name="category.icon"
      :class="{ disabled: disabled }"
    />
    <span :class="['category-label', selected ? 'text5' : 'text6']">
      {{ label }}
    </span>
    <span
      :class="['category-highlight', selected ? 'text5' : 'text6']"
      v-if="highlightText"
    >
      {{ highlightText }}
    </span>
  </button>
</template>

<style lang="scss">
$transition-duration: 200ms;

.category-button {
  position: absolute;
  height: 90px;
  width: 50%;
  transition: $transition-duration all ease;
  overflow: visible;
  opacity: 0;
  cursor: pointer;
  @include setColor(--color-background1, background-color);
  outline: none !important;
  padding: 0;
  border: none;
  pointer-events: none;

  &:focus {
    z-index: 100;
  }

  &.displayed {
    opacity: 1;
    pointer-events: initial;
  }

  .background {
    position: absolute;
    border-width: 1px;
    border-style: solid;
    @include setColor(--color-divider, border-color);
    border-top: none;
    border-left: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include setColor(--color-background1, background-color);
    transition: $transition-duration all ease;

    &.disabled {
      @include setColor(--color-divider, background-color);
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    .background {
      border-top-width: 1px;
      border-top-style: solid;
      @include setColor(--color-divider, border-top-color);
    }
  }
  &:nth-child(2n + 1),
  &:first-child {
    .background {
      border-left-width: 1px;
      border-left-style: solid;
      @include setColor(--color-divider, border-left-color);
    }
  }
  &:nth-child(2n) {
    left: 50%;
  }

  .category-icon {
    position: absolute;
    top: rem(30px);
    left: 50%;
    font-size: rem(24px);
    transform: translate(-50%, -50%);
    @include setColor(--color-cta);
    transition: $transition-duration all ease;

    &.disabled {
      @include setColor(--color-inactive);
    }
  }

  .category-label {
    position: absolute;
    top: rem(55px);
    left: 50%;
    width: fit-content;
    max-width: 90%;
    transform: translate(-50%, 0%);
    @include setColor(--color-text);
    line-height: rem(14px);
    transition: $transition-duration all ease;
  }

  .category-highlight {
    position: absolute;
    top: rem(70px);
    left: 50%;
    width: fit-content;
    max-width: 90%;
    transform: translate(-50%, 0%);
    @include setColor(--color-highlight);
    line-height: rem(14px);
    transition: $transition-duration all ease;
  }

  .close-icon {
    position: absolute;
    height: rem(16px);
    width: rem(16px);
    top: rem(10px);
    right: rem(10px);
    border-width: 1px;
    border-style: solid;
    @include setColor(--color-text-negative, border-color);
    @include setColor(--color-text-negative);
    border-radius: 50%;
    font-size: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      display: none;
    }
  }

  &.selected {
    top: 0 !important;
    left: 0 !important;
    z-index: 2;

    .background {
      border-width: 1px;
      border-style: solid;
      @include setColor(--color-selection, border-color);
      @include setColor(--color-selection, background-color);
      width: 200%;
    }

    .category-icon {
      @include setColor(--color-text-negative);
      top: 50%;
    }

    .category-label {
      top: 50%;
      left: calc(25% + 80px);
      transform: translate(0, -50%);
      @include setColor(--color-text-negative);
    }
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: { FontIcon },

  props: {
    category: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    position: {
      type: Number,
      required: true,
    },

    topDelta: {
      type: Number,
      default: 0,
    },

    displayed: {
      type: Boolean,
      required: true,
    },

    panel: {
      type: String,
      default: "family",
    },

    highlightText: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    label() {
      return this.$t(
        `request.concierge.${this.panel}.${this.category.name}.label`,
      );
    },

    top() {
      return `${this.topDelta + 90 * Math.floor(this.position / 2)}px`;
    },
  },
};
</script>
