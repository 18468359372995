export default {
  namespaced: true,

  state: {
    isIEWarningVisible: true,
  },

  mutations: {
    iEWarningVisible(state, payload) {
      state.isIEWarningVisible = payload;
    },
  },
};
