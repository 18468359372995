<template>
  <Details
    v-if="componentObject"
    :title="title"
    :subtitle="subtitle"
    :about="description"
    :conditions="conditions"
    :img-detail="imgDetail"
    :img-logo="imgLogo"
    :start-date="componentObject.start_date"
    :address="address"
    :address-url="addressUrl"
    :family="family"
    :marker="marker"
    :legal-notices="legalNotices"
    :practical-information="information"
    :right-block-title="rightBlockTitle"
    :is-online="false"
    content-type="vendor"
    showInformation
    @trackClick="trackClick"
    @trackMapLink="trackMapLink"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import router from "@/router";

import Details from "../templates/Details";

import { getGoogleLocationLink } from "@/utilities/address";
import { replaceNewLineWithTag } from "@/utilities/text";
import { getDynamicComponentId } from "@/utilities/tracking";
import { parseBlockItemComponentMedias } from "@/utilities/images";

export default {
  components: {
    Details,
  },

  data() {
    return {
      minTopPosition: 100,
      defaultTopPosition: 260,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      componentsById: state => state.contentv2.componentsById,
    }),
    componentObject() {
      return this.componentsById[this.id]
        ? parseBlockItemComponentMedias(this.componentsById[this.id])
        : this.componentsById[this.id];
    },
    title() {
      return this.componentObject?.title;
    },
    description() {
      return replaceNewLineWithTag(this.componentObject?.description);
    },
    conditions() {
      return replaceNewLineWithTag(this.componentObject?.conditions);
    },
    family() {
      return this.componentObject?.family || "";
    },
    marker() {
      return {
        lat: this.componentObject?.address?.latitude,
        lng: this.componentObject?.address?.longitude,
      };
    },
    contentType() {
      return this.componentObject?.content_ct;
    },
    subtitle() {
      return this.$t(`common.${this.contentType}.details.subtitle.online`);
    },
    legalNotices() {
      return replaceNewLineWithTag(this.componentObject?.legal_notice);
    },
    information() {
      return this.componentObject?.information;
    },
    rightBlockTitle() {
      return this.onlineComponent
        ? this.$t(`common.${this.contentType}.details.cta.online.title`)
        : this.$t(`common.${this.contentType}.details.cta.place.title`);
    },
    componentTitle() {
      return this.componentObject?.title;
    },
    addressUrl() {
      if (!this.componentObject?.address) return;
      return this.componentObject.google_place?.url
        ? this.componentObject.google_place.url
        : getGoogleLocationLink(this.componentObject.address);
    },
    address() {
      return this.componentObject?.address?.formatted_address;
    },
    imgDetail() {
      return this.src("detail", "image");
    },
    imgLogo() {
      return this.src("logo");
    },
  },

  watch: {
    "$route.path": function() {
      this.fetchComponent({ id: this.id, isVendorComponent: true });
    },
    componentObject() {
      this.$root.$emit("dynamic-content.page:loaded", {
        slug: this.slug,
        vendorId: null,
        contentId: getDynamicComponentId(this.componentObject),
      });
    },
  },

  methods: {
    ...mapActions({
      fetchComponent: "contentv2/fetchComponent",
    }),
    trackClick(event) {
      this.$root.$emit(event, {
        vendor: this.componentObject,
        stepNumber: 0,
        vendorId: null,
        contentId: getDynamicComponentId(this.componentObject),
        fulfillmentMode: "concierge",
      });
    },
    trackMapLink() {
      this.$root.$emit("vendor.details.map:click", {
        fulfillmentMode: "concierge",
      });
    },
    back() {
      if (window.history.length > 2) {
        router.go(-1);
      } else {
        this.$root.$emit("vendor.details.back.fallback:click");
      }
    },
    src(mediaType, fallback) {
      let componentMedia = this.componentObject?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        null,
      );
      if (!componentMedia) {
        componentMedia = this.componentObject?.medias?.reduce(
          (acc, media) => (media.type === fallback ? media.url : acc),
          null,
        );
      }
      return componentMedia;
    },
  },

  mounted() {
    this.fetchComponent({ id: this.id, isVendorComponent: true });
    this.$emit("header", { transparent: true });
  },
};
</script>
