<template>
  <div class="register-activation" v-if="success">
    <h1 class="super-title3">
      {{ $t("register.activation.success.title") }}
    </h1>
    <p class="activation-description text6">
      <FontIcon name="ui_done" />
      {{ $t("register.activation.success.text") }}
    </p>
    <p>
      <a
        class="text5"
        id="link--register-activation-back"
        @click="$root.$emit('register.activation.links.back:click')"
      >
        {{ $t("register.activation.links.back") }}
      </a>
    </p>
  </div>
  <div class="register-activation" v-else>
    <h1 class="super-title3">
      {{ $t("register.activation.title") }}
    </h1>

    <p
      class="activation-description text6"
      v-html="$t('register.activation.text', { email: escape(email) })"
    ></p>

    <Alert variant="error" v-if="error" id="alert--register-activation-error">
      <span v-html="$t(`register.activation.error.${error}`)"></span>
    </Alert>

    <form @submit.prevent="onSubmit" novalidate>
      <TextInput
        id="input--register-activation-email"
        :label="$t('register.form.inputs.email')"
        :value="email"
        :disabled="submitting"
        :readonly="true"
      />

      <ActivityIndicator v-if="submitting" />
      <Button
        v-else
        id="button--register-activation-submit"
        class="button"
        type="primary"
        nativeType="submit"
        :disabled="submitting"
      >
        {{ $t("register.activation.form.submit") }}
      </Button>
    </form>
  </div>
</template>

<style lang="scss">
.register-activation {
  h1 {
    @include setColor(--color-title1);
  }

  .activation-description {
    @include setColor(--color-text);
  }

  a {
    @include setColor(--color-link);
  }
}
</style>

<script>
import escape from "lodash.escape";

import {
  ActivityIndicator,
  Alert,
  Button,
  FontIcon,
  TextInput,
} from "@johnpaul/jp-vue-components";

import { mapActions, mapState } from "vuex";

export default {
  name: "RegisterForm",

  components: {
    ActivityIndicator,
    Alert,
    Button,
    FontIcon,
    TextInput,
  },

  computed: {
    ...mapState({
      submitting: state => state.register.activation.sendingEmail,
      error: state => state.register.activation.sendEmailError,
      success: state => state.register.activation.sendEmailSuccess,
    }),
  },

  props: {
    email: {
      type: String,
      required: true,
    },
  },

  methods: {
    ...mapActions({
      sendEmail: "register/activation/sendEmail",
    }),
    async onSubmit() {
      await this.sendEmail(this.email);
      if (this.success) this.$root.$emit("register.activation:success");
    },
    escape(str) {
      return escape(str);
    },
  },

  mounted() {
    this.$root.$emit("register.activation:mounted");
  },
};
</script>
