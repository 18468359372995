import client from "@/plugins/jp-api-client";

import { getErrorCode } from "@/utilities/errors";

export default {
  namespaced: true,

  state: {
    sendingEmail: false,
    sendEmailSuccess: false,
    sendEmailError: null,
  },

  mutations: {
    sendEmailStart(state) {
      state.sendingEmail = true;
      state.sendEmailSuccess = false;
      state.sendEmailError = null;
    },

    sendEmailError(state, error) {
      state.sendingEmail = false;
      state.sendEmailSuccess = false;
      state.sendEmailError = error;
    },

    sendEmailSuccess(state) {
      state.sendingEmail = false;
      state.sendEmailSuccess = true;
    },
  },

  actions: {
    async sendEmail({ commit, dispatch }, email) {
      commit("sendEmailStart");

      const { success, access_token_client } = await dispatch(
        "auth/fetchClientToken",
        {},
        { root: true },
      );

      if (success) {
        return client.auth.verification
          .createToken(email, access_token_client)
          .then(() => {
            commit("sendEmailSuccess");
            return true;
          })
          .catch(error => {
            commit("sendEmailError", getErrorCode(error));
            return false;
          });
      } else {
        commit("sendEmailError");
        return false;
      }
    },
  },
};
