<template>
  <div class="error-page">
    <Error :type="httpCode" />
  </div>
</template>

<style lang="scss">
.error-page {
  .error-layer {
    margin-top: rem(100px);
    position: inherit;
    z-index: 0;
  }
}
</style>

<script>
import Error from "@/components/Error";

export default {
  components: {
    Error,
  },

  props: {
    httpCode: {
      type: String,
      default: "500",
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
  },
};
</script>
