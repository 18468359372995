// View imports
import ButtonList from "./components/blocks/ButtonList";
import CardMember from "./components/blocks/CardMember";
import Detail from "./components/blocks/Detail";
import Header from "./components/blocks/Header";
import HeaderVisual from "./components/blocks/HeaderVisual";
import List from "./components/blocks/List";
import PictureList from "./components/blocks/PictureList";
import PostList from "./components/blocks/PostList";
import Selection from "./components/blocks/Selection";
import Tab from "./components/blocks/Tab";
// Template imports
import BannerTemplate from "./templates/blocks/header/BannerTemplate";
import ButtonListTemplate from "./templates/blocks/button-list/ButtonListTemplate";
import CardMemberTemplate from "./templates/blocks/card-member/CardMemberTemplate";
import DetailTemplate from "./templates/blocks/detail/DetailTemplate";
import ListTemplate from "./templates/blocks/list/ListTemplate";
import MosaicListTemplate from "@/modules/dynamic-content/templates/blocks/list/MosaicListTemplate";
import PictureListTemplate from "./templates/blocks/picture-list/PictureListTemplate";
import PostListAlternateTemplate from "./templates/blocks/post-list/PostListAlternateTemplate";
import PostListCarouselTemplate from "./templates/blocks/post-list/PostListCarouselTemplate";
import SelectionMosaicTemplate from "./templates/blocks/selection/SelectionMosaicTemplate";
import SelectionPatchworkTemplate from "./templates/blocks/selection/SelectionPatchworkTemplate";
import SelectionPushTemplate from "./templates/blocks/selection/SelectionPushTemplate";
import TabTemplate from "./templates/blocks/tab/TabTemplate";
import VisualBannerTemplate from "@/modules/dynamic-content/templates/blocks/header/VisualBannerTemplate";

export const BLOCKS = {
  "button-list": ButtonList,
  "card-member": CardMember,
  detail: Detail,
  header: Header,
  "header-visual": HeaderVisual,
  list: List,
  "picture-list": PictureList,
  "post-list": PostList,
  selection: Selection,
  tab: Tab,
};

export const BLOCK_TEMPLATE = {
  "button-list": ButtonListTemplate,
  "card-member": CardMemberTemplate,
  detail: DetailTemplate,
  header: BannerTemplate,
  "header-visual": VisualBannerTemplate,
  list: ListTemplate,
  "extended-mosaic": MosaicListTemplate,
  "picture-list": PictureListTemplate,
  "post-list-alternate": PostListAlternateTemplate,
  "post-list-carousel": PostListCarouselTemplate,
  "selection-mosaic": SelectionMosaicTemplate,
  "selection-patchwork": SelectionPatchworkTemplate,
  "selection-push": SelectionPushTemplate,
  tab: TabTemplate,
};
