import { mount, createLocalVue } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import Vuex from "vuex";
import MyAccountContact from "../views/MyAccountContact";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

export default () => {
  describe("MyAccountContact.vue", () => {
    let wrapper;

    it("should mount", () => {
      wrapper = mount(MyAccountContact, { localVue });

      expect(wrapper.vm).toBeTruthy();
    });
  });
};
