const MyAccount = () =>
  import(/* webpackChunkName: "my-account" */ "./views/MyAccount");
const MyAccountInformations = () =>
  import(/* webpackChunkName: "my-account" */ "./views/MyAccountInformations");
const MyAccountInterests = () =>
  import(/* webpackChunkName: "my-account" */ "./views/MyAccountInterests");
const MyAccountSecurity = () =>
  import(/* webpackChunkName: "my-account" */ "./views/MyAccountSecurity");
const MyAccountPreferences = () =>
  import(/* webpackChunkName: "my-account" */ "./views/MyAccountPreferences");
const MyAccountContact = () =>
  import(/* webpackChunkName: "my-account" */ "./views/MyAccountContact");

export default {
  path: "/my-account",
  component: MyAccount,
  meta: {
    requiresAuth: true,
    title: "common.head.title.my-account.home",
  },
  children: [
    {
      name: "my-account/informations",
      path: "",
      component: MyAccountInformations,
      meta: {
        title: "common.head.title.my-account.informations",
      },
    },
    {
      name: "my-account/security",
      path: "security",
      component: MyAccountSecurity,
      meta: {
        title: "common.head.title.my-account.security",
      },
    },
    {
      name: "my-account/preferences",
      path: "preferences",
      component: MyAccountPreferences,
      meta: {
        title: "common.head.title.my-account.preferences",
      },
    },
    {
      name: "my-account/contact",
      path: "contact",
      component: MyAccountContact,
      meta: {
        title: "common.head.title.my-account.contact",
      },
    },
    {
      name: "my-account/interests",
      path: "interests",
      component: MyAccountInterests,
      meta: {
        title: "common.head.title.my-account.interests",
      },
    },
  ],
};
