import client from "@/plugins/jp-api-client";
import { getErrorCode } from "@/utilities/errors";
import jwt_decode from "jwt-decode";

export default {
  namespaced: true,

  state: {
    data: null,
    fetching: false,
    fetchError: null,
    lastConnectionDate: null,
  },

  getters: {
    fullName(state) {
      return state.data
        ? `${state.data.firstname} ${state.data.lastname}`.trim()
        : null;
    },
    username(state) {
      return state?.data?.username;
    },
    id(state) {
      return state.data ? state.data.id : null;
    },
    lastConnectionDate(state, getters, rootState) {
      if (rootState.config.env.proxy) {
        return state.lastConnectionDate;
      } else {
        const decoded = jwt_decode(rootState.auth.accessToken);
        return decoded["last-connection-date"];
      }
    },
  },

  mutations: {
    fetchStart(state) {
      state.fetching = true;
      state.fetchError = null;
    },

    fetchError(state, errorCode) {
      state.data = null;
      state.fetching = false;
      state.fetchError = errorCode;
    },

    fetchSuccess(state, data) {
      state.data = data;
      state.fetching = false;
      state.fetchError = null;
    },

    fetchCancel(state) {
      state.fetching = false;
    },

    update(state, data) {
      state.data = data;
    },

    setLastConnectionDate(state, date) {
      state.lastConnectionDate = date;
    },
  },

  actions: {
    async fetch({ commit, state }) {
      if (state.data) {
        return { success: true };
      }

      commit("fetchStart");
      try {
        const data = await client.members.get("me");
        commit("fetchSuccess", data);
        return { success: true };
      } catch (error) {
        if (client.isCancel(error)) {
          commit("fetchCancel");
        } else {
          commit("fetchError", getErrorCode(error));
        }
        return { success: false };
      }
    },
  },

  persistPaths: ["member.lastConnectionDate"],
};
