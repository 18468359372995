<template>
  <div class="home-slider">
    <b-carousel
      class="home-slider-carousel"
      :id="id"
      :interval="5000"
      fade
      indicators
      v-model="activeSlide"
      ref="homeCarousel"
      @sliding-end="emitSlideDisplay"
    >
      <b-carousel-slide img-blank v-for="(slide, index) in slides" :key="index">
        <template v-slot:img>
          <div
            class="slide-image"
            :style="{ backgroundImage: `url(${slide.img})` }"
          />
        </template>
        <div class="slide-content" v-show="activeSlide === index">
          <h2 class="title text1">{{ slide.title }}</h2>
          <h3 class="subtitle text6 mt-1">{{ slide.subtitle }}</h3>
          <p class="slide-description text6 mt-3" v-html="slide.description" />

          <EventLink
            :id="`button--slider--${id}--${slide.id}`"
            class="button button-primary"
            :event="slideEvent(slide)"
            @mousedown.native="$emit('component-click', index)"
            :linkComponent="slide"
          >
            {{ slide.button_name }}
          </EventLink>
        </div>
      </b-carousel-slide>
    </b-carousel>
    <div class="slider-controls">
      <a class="control-prev" @click="prev">
        <FontIcon name="ui_chevron_left" />
      </a>
      <a class="control-next" @click="next">
        <FontIcon name="ui_chevron_right" />
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.home-slider {
  position: relative;
  height: rem(540px);

  .home-slider-carousel {
    height: rem(240px);
  }

  .slide-image {
    width: 100%;
    background-size: cover;
    background-position: center center;
  }

  .carousel-indicators {
    position: absolute !important;
    right: rem(110px);
    margin: rem(0 16px);
    bottom: rem(-50px);
    display: flex;
    z-index: 1;

    > li {
      margin: rem(14px 2px);
      border-width: rem(10px);
      flex-grow: 1;
      @include setColor(--color-inactive, background-color);
      transition: 200ms background-color linear;

      &.active {
        @include setColor(--color-selection, background-color);
      }
    }
  }

  .slider-controls {
    position: absolute;
    height: rem(50px);
    width: rem(110px);
    top: rem(240px);
    right: 0;
    @include setColor(--color-cta-negative);
    z-index: 1;

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include setColor(--color-cta-negative);
      width: rem(55px);
      height: rem(50px);
      font-size: rem(24px);
      text-decoration: none;

      @include setColor(--color-cta, background-color);
      transition: 0.1s opacity linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .control-prev {
      border-radius: 3px 0 0 3px;
    }

    .control-next {
      border-radius: 0 3px 3px 0;
    }
  }

  .carousel-inner {
    overflow: visible;
  }

  .carousel-item-right.active,
  .carousel-item-left.active {
    transition: 600ms opacity linear;
  }

  .carousel-caption {
    position: absolute;
    height: rem(300px);
    width: 100%;
    margin: 0;
    bottom: rem(-300px);
    left: 0;
    top: auto;
    padding: rem(64px 16px 16px);

    .slide-content {
      width: 100%;
      height: 100%;
      text-align: left;

      .title,
      .subtitle,
      .slide-description {
        background-color: inherit;
      }

      .title {
        @include setColor(--color-title1);
      }

      .subtitle {
        max-height: rem(42px);
        overflow: hidden;
        @include setColor(--color-subtitle);
      }

      .slide-description {
        overflow: hidden;
        @include setColor(--color-text);
      }

      .button {
        position: absolute;
        left: rem(16px);
        bottom: rem(30px);

        width: auto;
        margin-top: rem(20px);
        padding: rem(0 50px);
      }
    }
  }

  @include respond-min("medium") {
    height: rem(500px);

    .home-slider-carousel {
      height: 100%;
    }

    .slide-image {
      position: relative;
      top: O;
      right: 0;
      bottom: 0;
      left: 35%;
      width: 65%;
    }

    .carousel-indicators {
      right: auto;
      bottom: 0;
      left: 0;
      width: 35%;
      margin: 0;
      padding: rem(0 42px);
    }

    .slider-controls {
      top: 50%;
      left: 35%;
      transform: translate(-50%, -50%);
    }

    .carousel-caption {
      top: 0;
      left: 0;
      width: 35%;
      height: 100%;
      padding: rem(42px 100px 42px 42px);

      .slide-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .slide-description {
          width: 100%;
        }

        .button {
          position: relative;
          left: auto;
          bottom: auto;
          margin-top: rem(60px);
        }
      }
    }
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";

import inViewport from "vue-in-viewport-mixin";

export default {
  components: {
    EventLink,
    FontIcon,
  },

  mixins: [inViewport],

  data() {
    return {
      activeSlide: 0,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    slides() {
      return this.components
        .filter(c => c.medias.length > 0)
        .map(component => {
          return {
            ...component,
            id: component.id,
            title: component.name,
            subtitle: component.subname,
            description: component.description,
            img: component.medias?.[0]?.url,
          };
        });
    },
  },

  watch: {
    "inViewport.now": function() {
      this.emitSlideDisplay();
    },

    slides() {
      this.emitSlideDisplay();
    },
  },

  methods: {
    emitSlideDisplay() {
      if (this.inViewport.now && this.slides.length) {
        this.$emit("component-display", this.activeSlide);
      }
    },

    slideEvent(slide) {
      return slide.link_template === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },

    prev() {
      this.$refs.homeCarousel.prev();
    },

    next() {
      this.$refs.homeCarousel.next();
    },
  },
};
</script>
