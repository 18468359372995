export default {
  // Accommodation
  accommodation: {
    familyId: "accommodation",
    icon: "category_hotel",
  },
  accommodation_hotel: {
    familyId: "accommodation",
    subfamilyId: "hotel",
    icon: "category_hotel",
  },

  // Assistance
  "assistance_legal-information": {
    familyId: "assistance",
    subfamilyId: "legal-information",
    icon: "category_papers",
  },

  // Child-care
  "daily-services_child-care": {
    familyId: "daily-services",
    subfamilyId: "child-care",
    icon: "category_babysitting",
  },

  // Bars-clubs
  "bars-club": {
    familyId: "bars-club",
    icon: "category_clubbing",
  },
  "bars-club_bar": {
    familyId: "bars-club",
    subfamilyId: "bar",
    icon: "category_clubbing",
  },
  "bars-club_nightclub-club": {
    familyId: "bars-club",
    subfamilyId: "nightclub-club",
    icon: "category_clubbing",
  },

  // Dry-cleaning
  "daily-services_dry-cleaning": {
    familyId: "daily-services",
    subfamilyId: "dry-cleaning",
    icon: "category_cleaning",
  },

  // Culture
  "arts-entertainment": {
    familyId: "arts-entertainment",
    icon: "category_culture",
  },
  "arts-entertainment_exhibitions-museum": {
    familyId: "arts-entertainment",
    subfamilyId: "exhibitions-museum",
    icon: "category_museum",
  },
  "arts-entertainment_concert": {
    familyId: "arts-entertainment",
    subfamilyId: "concert",
    icon: "category_concert",
  },
  "arts-entertainment_monuments-visits": {
    familyId: "arts-entertainment",
    subfamilyId: "monuments-visits",
    icon: "category_leisure",
  },
  "arts-entertainment_convention-fair": {
    familyId: "arts-entertainment",
    subfamilyId: "convention-fair",
    icon: "category_salons",
  },

  // Dining
  dining: {
    familyId: "dining",
    icon: "category_dining",
  },

  // Gourmet
  gourmet_other: {
    familyId: "gourmet",
    subfamilyId: "other",
    icon: "category_dining",
  },

  // Other
  "daily-services": {
    familyId: "daily-services",
    icon: "ui_more",
  },

  "daily-services_other": {
    familyId: "daily-services",
    subfamilyId: "other",
    icon: "ui_more",
  },
  "daily-services_messenger": {
    familyId: "daily-services",
    subfamilyId: "messenger",
    icon: "category_messenger",
  },
  "daily-services_learning-training": {
    familyId: "daily-services",
    subfamilyId: "learning-training",
    icon: "category_meeting",
  },

  // Repair-maintenance
  "repair-maintenance_electrician": {
    familyId: "repair-maintenance",
    subfamilyId: "electrician",
    icon: "category_electrician",
  },
  "repair-maintenance_plumber": {
    familyId: "repair-maintenance",
    subfamilyId: "plumber",
    icon: "category_plumber",
  },
  "repair-maintenance_locksmith": {
    familyId: "repair-maintenance",
    subfamilyId: "locksmith",
    icon: "category_key",
  },

  // Shopping
  shopping: {
    familyId: "shopping",
    icon: "category_shopping",
  },
  shopping_other: {
    familyId: "shopping",
    subfamilyId: "other",
    icon: "category_shopping",
  },

  // Sports events
  "sports-events_other": {
    familyId: "sports-events",
    subfamilyId: "other",
    icon: "category_leisure",
  },

  // Tours
  "tours_bespoke-travel-planning": {
    familyId: "tours",
    subfamilyId: "bespoke-travel-planning",
    icon: "category_stay",
  },

  // Travel
  transportation: { familyId: "transportation", icon: "category_plane" },
  transportation_flights: {
    familyId: "transportation",
    subfamilyId: "flights",
    icon: "category_plane",
  },
  transportation_train: {
    familyId: "transportation",
    subfamilyId: "train",
    icon: "category_train",
  },
  "transportation_car-rental": {
    familyId: "transportation",
    subfamilyId: "car-rental",
    icon: "category_car_rental",
  },
  transportation_other: {
    familyId: "transportation",
    subfamilyId: "other",
    icon: "ui_more",
  },

  // Ground transfer
  "ground-transfer": {
    familyId: "ground-transfer",
    icon: "category_taxi",
  },
  "ground-transfer_limo": {
    familyId: "ground-transfer",
    subfamilyId: "limo",
    icon: "category_taxi",
  },
  "ground-transfer_car-service": {
    familyId: "ground-transfer",
    subfamilyId: "car-service",
    icon: "category_taxi",
  },
  "ground-transfer_taxi": {
    familyId: "ground-transfer",
    subfamilyId: "taxi",
    icon: "category_taxi",
  },
  "ground-transfer_moto-cab": {
    familyId: "ground-transfer",
    subfamilyId: "moto-cab",
    icon: "category_taxis_moto",
  },

  // special-occasion
  "special-occasion": {
    familyId: "special-occasion",
    icon: "category_bestof",
  },
};
