<template>
  <div class="image-header-layout">
    <div
      class="header"
      :style="{
        backgroundImage: 'url(' + imageSrc + ')',
      }"
    >
      <div v-if="darken" class="header__darken-background" />
      <Avatar v-if="avatar" :height="69" class="image" />

      <div class="title">
        <slot name="title" />
      </div>
    </div>

    <div class="content">
      <div v-if="$slots.menu" class="menu-wrapper">
        <div class="menu">
          <slot name="menu" />
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.image-header-layout {
  .header {
    position: relative;
    height: rem(315px);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__darken-background {
      position: absolute;
      background-color: black;
      height: inherit;
      width: 100vw;
      opacity: 0.3;
    }

    .image {
      position: relative;
      margin-right: rem(15px);
      margin-left: rem(15px);
      overflow: hidden;
    }

    .title {
      position: relative;
    }

    @include respond-to("medium") {
      flex-direction: column;
      text-align: center;

      .image {
        margin-bottom: rem(15px);
      }
    }
  }

  .content {
    max-width: rem(900px);
    margin: auto;
    @include setColor(--color-background1, background-color);
    margin-top: rem(-70px);
    position: relative;

    .menu-wrapper {
      overflow-y: hidden;
    }

    .menu {
      display: flex;
      width: rem(900px);
      padding-bottom: 15px;
      margin-bottom: -15px;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      @include setColor(--color-background1, background-color);

      @include respond-to("medium") {
        width: 100%;
      }

      a {
        display: flex;
        height: rem(70px);
        padding: 0 rem(24px);
        white-space: nowrap;
        text-align: center;
        flex: 1;
        align-items: center;
        justify-content: center;
        @include setColor(--color-inactive);
        text-transform: uppercase;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        @include setColor(--color-divider, border-bottom-color);
        transition: 200ms border-color ease;

        @include respond-to("medium") {
          height: rem(48px);
        }

        &:hover {
          text-decoration: none;
        }

        &.router-link-exact-active {
          @include setColor(--color-selection);
          border-bottom-width: 2px;
          border-bottom-style: solid;
          @include setColor(--color-selection, border-bottom-color);
        }
      }
    }

    @include respond-to("medium") {
      width: 100%;
      margin: 0 auto;
    }
  }
}
</style>

<script>
import Avatar from "@/components/Avatar";

export default {
  components: {
    Avatar,
  },

  props: {
    imageSrc: {
      type: String,
      default: null,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    darken: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
