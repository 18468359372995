import client from "@/plugins/jp-api-client";
import { getErrorCode } from "@/utilities/errors";

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    fetchError: null,

    saving: false,
    saveError: null,
    saveSuccess: null,

    modified: false,
  },

  mutations: {
    fetchStart(state) {
      state.fetching = true;
      state.fetchError = null;
    },
    fetchError(state, errorCode) {
      state.data = null;
      state.fetching = false;
      state.fetchError = errorCode;
    },
    fetchSuccess(state, data) {
      state.data = data;
      state.fetching = false;
      state.fetchError = null;
    },

    saveStart(state) {
      state.saving = true;
      state.saveError = null;
      state.saveSuccess = null;
    },
    saveError(state, errorCode) {
      state.saving = false;
      state.saveError = errorCode;
      state.saveSuccess = null;
    },
    saveSuccess(state, data) {
      state.data = data;
      state.saving = false;
      state.saveSuccess = true;
      state.saveError = null;
      state.modified = false;
    },

    setInterest(state, interest) {
      const index = state.data.findIndex(
        i => i.family === interest.family && i.subfamily === interest.subfamily,
      );

      state.data.splice(index, 1, interest);
      state.modified = true;
    },
  },

  actions: {
    async fetchInterests({ commit, state }) {
      if (state.data) {
        return { success: true };
      }

      commit("fetchStart");
      try {
        const res = await client.members.interests.get("me");
        commit("fetchSuccess", res.data.results);
        return { success: true };
      } catch (error) {
        commit("fetchError", getErrorCode(error));
        return { success: false };
      }
    },

    async saveInterests({ commit, state }) {
      commit("saveStart");
      try {
        const res = await client.members.interests.put(state.data);
        commit("saveSuccess", res.data.results);
        return { success: true };
      } catch (error) {
        commit("saveError", getErrorCode(error));
        return { success: false };
      }
    },
  },
};
