<template>
  <div class="mosaic-list-template">
    <b-row class="mosaic-list-template__container">
      <b-col cols="12">
        <h1 class="super-title3 mt-3">{{ title }}</h1>
        <div class="mosaic-list-template__content">
          <div
            v-for="(row, rowIdx) in templatizedItems"
            :key="`row-${rowIdx}`"
            class="mosaic-row level1"
          >
            <!-- row -->
            <template v-if="Array.isArray(row)">
              <div
                v-for="(col, colIdx) in row"
                :key="`col-${colIdx}`"
                class="mosaic-col level2"
                :style="{ 'min-width': `${100 / row.length}%` }"
              >
                <!-- col -->
                <template v-if="Array.isArray(col)">
                  <div
                    v-for="(row2, row2Idx) in col"
                    :key="`row2-${row2Idx}`"
                    class="mosaic-row level3"
                  >
                    <template v-if="Array.isArray(row2)">
                      <div
                        v-for="(col2, col2Idx) in row2"
                        :key="`col2-${col2Idx}`"
                        class="mosaic-col mosaic-cell level4"
                        :style="{ 'min-width': `${100 / row2.length}%` }"
                      >
                        <MosaicItem
                          :item="col2"
                          @display="trackDisplay(col2)"
                          @mousedown.native="trackClick(col2)"
                        />
                      </div>
                    </template>
                    <div v-else class="mosaic-cell">
                      <MosaicItem
                        :item="row2"
                        @display="trackDisplay(row2)"
                        @mousedown.native="trackClick(row2)"
                      />
                    </div>
                  </div>
                </template>
                <div v-else class="mosaic-cell">
                  <MosaicItem
                    :item="col"
                    @display="trackDisplay(col)"
                    @mousedown.native="trackClick(col)"
                  />
                </div>
              </div>
            </template>
            <div v-else class="mosaic-cell">
              <MosaicItem
                :item="row"
                @display="trackDisplay(row)"
                @mousedown.native="trackClick(row)"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <ActivityIndicator v-if="fetchingBlockItem" />
  </div>
</template>

<style lang="scss">
.mosaic-list-template {
  margin-top: 0 !important;
  padding-top: 0 !important;
  @include setColor(--color-background1, background-color);

  &__container {
    margin: rem(0 auto);

    @include respond-min("medium") {
      margin: rem(0 auto 64px);
      max-width: 85vw;
    }

    @include respond-min("xlarge") {
      margin: rem(0 auto 100px);
    }
  }

  h1 {
    @include setColor(--color-title1);
  }

  &__content {
    margin-top: rem(44px);
  }

  .img {
    position: relative;
    height: rem(180px);
    background-size: cover;
    background-position: center center;
    text-align: center;
    @include setColor(--color-text-negative);
    text-transform: uppercase;
    margin-bottom: rem(15px);

    &:hover {
      text-decoration: none;
    }

    p {
      margin: 0;
      z-index: 1;
    }

    .city-name {
      margin-bottom: rem(8px);
    }

    @include respond-min("medium") {
      height: rem(307px);
      margin-bottom: rem(30px);
    }
  }

  .mosaic-item__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
  }

  .mosaic-row {
    display: flex;
    flex-grow: 1;
  }

  .mosaic-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .mosaic-cell {
    flex-grow: 1;
    height: rem(323px);
    padding: rem(8px);
  }

  @include respond-to("medium") {
    .mosaic-row {
      flex-direction: column;
    }

    .mosaic-cell {
      height: rem(196px);
      padding: rem(8px 0);
    }
  }
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { ActivityIndicator } from "@johnpaul/jp-vue-components";
import { applyTemplate } from "@/modules/dynamic-content/components/mosaic/mosaic-template";
import MosaicItem from "@/modules/dynamic-content/components/mosaic/MosaicItem";
import { parseBlockItemComponentMedias } from "@/utilities/images";

const template = [
  ["item", ["item", "item"]],
  [["item", "item"], "item"],
  [[["item", "item"]], "item"],
  ["item", [["item", "item"]]],
  [[["item", "item", "item", "item"]]],
];

export default {
  components: {
    ActivityIndicator,
    MosaicItem,
  },

  props: {
    queryBlockItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
      fetchingBlockItem: state => state.contentv2.fetchingBlockItem,
      blockItemsById: state => state.contentv2.blockItemsById,
    }),
    queryBlockItem() {
      return this.queryBlockItems[0];
    },
    blockItemId() {
      return this.queryBlockItem?.id;
    },
    components() {
      const blockItemComponents =
        this.blockItemsById[this.blockItemId]?.components || [];
      if (!blockItemComponents.length) return [];
      return blockItemComponents.slice().map(component => ({
        ...parseBlockItemComponentMedias(component, true),
        link_template: "editorial-details",
        link_id: component.parent_id,
      }));
    },
    templatizedItems() {
      if (!this.components) return [];
      return applyTemplate(template, this.components);
    },
  },

  methods: {
    ...mapActions({
      fetchBlockItem: "contentv2/fetchBlockItem",
    }),
    async fetchComponents() {
      if (this.queryBlockItem?.id)
        await this.fetchBlockItem({
          id: this.queryBlockItem.id,
          page: 1,
          disablePagination: true,
        });
    },
    trackDisplay(component) {
      this.$emit("component-display", this.components.indexOf(component));
    },
    trackClick(component) {
      this.$emit("component-click", this.components.indexOf(component));
    },
  },

  created() {
    this.fetchComponents();
  },
};
</script>
