import { getModuleConfig } from "@/utilities/config";

import { getConfig } from "@/store";
const legalConfig = getModuleConfig(getConfig(), "legal");

const Home = () => import(/* webpackChunkName: "legal" */ "./index");

const legalModules = legalConfig.modules || {};
export default Object.keys(legalModules).map(section => ({
  name: `legal-${section}`,
  path: `/legal/${section}`,
  component: Home,
  props: {
    section: section,
  },
}));
