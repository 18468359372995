<template>
  <div class="home-inspirational">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>

    <MosaicLayout class="list">
      <template v-for="(component, idx) in components" :slot="`slot-${idx}`">
        <OfferCardLink
          :key="idx"
          class="offer-link"
          :component="component"
          :fixedHeight="idx === 0"
          :in-viewport-active="true"
          :in-viewport-once="true"
          @display="$emit('component-display', idx)"
          @mousedown.native="$emit('component-click', idx)"
        />
      </template>
    </MosaicLayout>

    <EventLink
      v-if="button"
      :id="`button--inspirational--${id}`"
      class="see-more-button"
      type="button"
      event="common.cms.link:click"
      :linkComponent="button"
    />
  </div>
</template>

<style lang="scss">
.home-inspirational {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20px 0);

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
  }

  h2 {
    @include setColor(--color-subtitle);
    text-align: center;
  }

  .list {
    width: 100%;
    overflow-x: auto;
    max-width: rem(1400px);
    padding: rem(0 0 16px 15px);
    margin-bottom: rem(10px);
  }

  .cards-wrapper {
    position: relative;
    display: inline-flex;
    padding-left: rem(15px);
  }

  .offer-link {
    display: inline-block;
    width: rem(300px);
    margin-right: rem(15px);

    > * {
      height: 100%;
    }
  }

  .offer-card {
    margin-bottom: rem(15px);
  }

  .see-more-button {
    width: auto;
    margin: auto;
    padding: rem(0 50px);
  }

  @include respond-min("medium") {
    padding: rem(40px 0);

    .cards-wrapper {
      width: 100%;
      display: flex;
      padding: 0;
    }

    .card-large .offer-link {
      position: absolute;
      width: 100%;
      min-width: rem(200px);
      height: 100%;
    }

    .offer-link {
      display: block;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: rem(16px);
      text-decoration: none;
    }

    .offer-card {
      margin: 0;
      height: 100%;
    }
  }
}
</style>

<script>
import EventLink from "@/components/EventLink";
import OfferCardLink from "@/components/OfferCardLink";
import MosaicLayout from "@/components/MosaicLayout";

export default {
  components: {
    EventLink,
    MosaicLayout,
    OfferCardLink,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    button: {
      type: Object,
      default: null,
    },
  },
};
</script>
