import router from "@/router";

function getTemplateMap(routes) {
  return routes.reduce((acc, route) => {
    if (!route.meta?.template) return acc;
    return {
      ...acc,
      [route.meta.template]: route.name,
      ...(route.children ? getTemplateMap(route.children) : null),
    };
  }, {});
}

let routeTemplates;

export function getTemplateRoute(template) {
  if (!routeTemplates) routeTemplates = getTemplateMap(router.getRoutes());
  return routeTemplates[template];
}
