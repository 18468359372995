<template>
  <div class="confirmation-layer">
    <div class="background" />
    <div class="text8 content-wrapper">
      <FontIcon name="ui_check" class="check-icon" />
      {{ message }}
      <Button
        id="button--my-requests-confirmation-button"
        class="confirmation-button"
        type="secondary-negative"
        @click.native="$emit('close')"
      >
        {{ $t("request.my-requests.confirmation.button") }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.confirmation-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: rem(0 30px);
  z-index: 1;
  display: flex;
  align-items: center;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    @include setColor(--color-status-success, background-color);
    opacity: 0.95;
  }

  .content-wrapper {
    text-align: center;
    max-width: rem(320px);
    margin: 0 auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include setColor(--color-text-negative);
  }

  .check-icon {
    padding: rem(5px);
    font-size: rem(20px);
    border-width: 1px;
    border-style: solid;
    @include setColor(--color-text-negative);
    @include setColor(--color-text-negative, border-color);
    border-radius: 50%;
    margin-bottom: rem(10px);
  }

  .confirmation-button {
    margin-top: rem(10px);
    width: auto;
    min-width: rem(100px);
  }
}
</style>

<script>
import { FontIcon, Button } from "@johnpaul/jp-vue-components";

export default {
  components: {
    Button,
    FontIcon,
  },

  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
