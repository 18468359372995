<template>
  <div class="concierge-home">
    <div class="text8">
      {{ $t("request.concierge.home.subtitle") }}
    </div>
    <div class="title9">
      {{ $t("request.concierge.home.title") }}
    </div>
    <div class="concierge-home__buttons">
      <CategoryButton
        v-for="button in enabledButtons"
        :key="button.position"
        class="concierge-home__button"
        :class="[
          { isOngoing: button.isOngoing },
          `concierge-home__button--${button.name}`,
        ]"
        :category="{ name: button.name, icon: button.icon }"
        :position="button.position * 2"
        :displayed="true"
        :panel="'home'"
        @mousedown.native="button.callback"
        :disabled="button.isInactive"
        :highlightText="button.highlightText"
      />
    </div>
  </div>
</template>

<style lang="scss">
.concierge-home {
  padding: 1rem 0.75rem;
  position: relative;

  &__buttons {
    margin-top: 0.75rem;
  }

  &__button {
    width: 100%;
    left: initial !important;
    top: initial !important;
    position: relative;

    > .background {
      border: 1px solid !important;
      @include setColor(--color-divider, border-color, true);
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &--whatsapp {
      .category-icon {
        @include setColor(--color-icon-whatsapp);
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

import CategoryButton from "./CategoryButton";
import { getModuleConfig } from "@/utilities/config";

export default {
  data() {
    let config = this.$config;
    const moduleConfig = getModuleConfig(config, "request/concierge");
    return {
      mail: config.options.conciergeEmail,
      whatsAppLink: config.options.conciergeWhatsAppLink,
      buttons: [
        {
          name: "ask",
          icon: "ui_send",
          position: 0,
          callback: this.ask,
          enabled: moduleConfig.options.home.data.ask.enabled,
          isActive: true,
          isOngoing: false,
        },
        {
          name: "send-mail",
          icon: "ui_contact",
          position: 1,
          callback: this.sendMail,
          enabled: moduleConfig.options.home.data.mail.enabled,
          isActive: true,
          isOngoing: false,
        },
        {
          name: "call",
          icon: "ui_phone",
          position: 2,
          callback: this.call,
          enabled: moduleConfig.options.home.data.phone.enabled,
          isActive: true,
          isOngoing: false,
        },
        {
          name: "whatsapp",
          icon: "ui_whatsapp",
          position: 3,
          callback: this.whatsApp,
          enabled: moduleConfig.options.home.data.whatsapp.enabled,
          isActive: true,
          isOngoing: false,
        },
      ],
      isActive: true,
      isOngoing: false,
    };
  },
  components: { CategoryButton },
  methods: {
    ask() {
      this.$root.$emit("mars.bell.make-request:click");
      this.$emit("nav-next");
    },

    sendMail() {
      this.$root.$emit("mars.bell.email-request:click");
      window.open(`mailto:${this.mail}`);
    },

    call() {
      this.$root.$emit("mars.bell.call-request:click");
      window.open(`tel:${this.phone}`);
    },

    whatsApp() {
      this.$root.$emit("mars.bell.whatsapp-request:click");
      window.open(this.whatsAppLink);
    },
  },
  computed: {
    ...mapGetters({
      servicePhone: "subscriptions/servicePhone",
    }),

    enabledButtons: function() {
      return this.buttons.filter(b => b.enabled === true);
    },

    phone: function() {
      return this.servicePhone?.attributes?.specifics["service-phone"];
    },
  },
};
</script>
