<template>
  <EventLink
    :id="`button--mosaic-item--${item.id}`"
    class="mosaic-item"
    event="common.cms.link:click"
    :linkComponent="item"
  >
    <div class="bg-image" :style="{ backgroundImage: backgroundImage }" />
    <div class="mosaic-item__overlay" />
    <p class="mosaic-item__name text3">{{ title }}</p>
    <p class="text8">{{ subtitle }}</p>
  </EventLink>
</template>

<style lang="scss">
.mosaic-item {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @include setColor(--color-text-negative);
  text-transform: uppercase;
  overflow: hidden;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transform: scale(1);
    transition: 1s transform ease;
  }

  &:hover {
    text-decoration: none;
    @include setColor(--color-text-negative);

    .bg-image {
      transform: scale(1.05);
    }
  }

  p {
    margin: 0;
    z-index: 1;
  }

  &__name {
    margin-bottom: rem(8px) !important;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
  }
}
</style>

<script>
import inViewport from "vue-in-viewport-mixin";

import EventLink from "@/components/EventLink";
import { MediaType } from "@/constants/media";

export default {
  components: { EventLink },

  mixins: [inViewport],

  props: {
    item: {
      type: Object,
      required: true,
    },
    "in-viewport-once": {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    title() {
      return this.item.title || this.item.name || "";
    },
    subtitle() {
      return this.item.subtitle || this.item.subname || "";
    },
    url() {
      return (
        this.item.medias?.find(media => media.type === MediaType.LIST)?.url ||
        this.item.medias?.find(media => media.type === MediaType.DETAIL)?.url ||
        null
      );
    },
    backgroundImage() {
      return "url('" + this.url + "')";
    },
  },

  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        this.$emit("display");
      }
    },
  },
};
</script>
