<template>
  <section class="selection-patchwork-template">
    <div class="selection-patchwork-template__background">
      <div class="selection-patchwork-template__details">
        <h1 class="super-title3">{{ title }}</h1>
        <h2 v-if="subtitle" class="text4">
          {{ subtitle }}
        </h2>

        <EventLink
          v-if="button"
          :id="`button--selection--patchwork--${id}`"
          :event="event"
          :linkComponent="button"
          class="see-more-button"
          type="button"
        />
      </div>

      <div class="list">
        <div class="cards-wrapper">
          <InViewWrapper
            v-for="(card, idx) in cards"
            :key="card.id"
            @in-view="$emit('component-display', idx)"
          >
            <EventLink
              :id="`button--selection--patchwork--item--${card.id}`"
              :class="['offer-link', `offer-${idx}`]"
              :linkComponent="card"
              event="common.cms.link:click"
              @mousedown.native="$emit('component-click', idx)"
            >
              <ImageCard fixedHeight :src="getCardSrc(card)">
                <div class="card-content text4">
                  {{ getCardDescription(card) }}
                </div>
              </ImageCard>
            </EventLink>
          </InViewWrapper>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.selection-patchwork-template {
  padding: rem(27px 0 20px);

  &__background {
    &.background1 {
      @include setColor(--color-background2, background-color);
    }
    &.background2 {
      @include setColor(--color-background1, background-color);
    }
  }

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
  }

  h2 {
    @include setColor(--color-subtitle);
    margin-left: rem(15px);
    text-align: center;
  }

  .list {
    width: 100%;
    overflow-x: auto;
    max-width: rem(1400px);
    padding-bottom: rem(14px);
    margin: rem(27px auto 10px);
  }

  .cards-wrapper {
    position: relative;
    display: inline-flex;
    padding-left: rem(15px);
  }

  .offer-link {
    display: inline-block;
    width: rem(250px);
    min-width: rem(250px);
    margin-right: rem(15px);
    text-decoration: none;

    > * {
      height: 100%;
    }
  }

  .card-content {
    height: rem(50px);
    line-height: rem(50px);
    text-align: center;
    text-transform: uppercase;
    @include setColor(--color-title1);
  }

  .see-more-button {
    width: auto;
    margin: rem(18px auto 22px);
    padding: rem(0 50px);
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include respond-min("medium") {
    .home &.background1 {
      @include setColor(--color-background1, background-color);
    }
    .home &.background2 {
      @include setColor(--color-background2, background-color);
    }

    &__background {
      position: relative;
      max-width: rem(1000px);
      height: rem(437px);
      margin: rem(175px auto 120px);

      &.background1 {
        @include setColor(--color-background1, background-color);
      }
      &.background2 {
        @include setColor(--color-background2, background-color);
      }
    }
    h1,
    h2 {
      text-align: left;
    }

    &__details {
      position: absolute;
      right: rem(50px);
      width: rem(250px);
      top: 50%;
      transform: translateY(-50%);
      align-items: flex-start;
    }

    .list {
      overflow: visible;
    }

    .cards-wrapper {
      width: 100%;
    }

    .offer-link {
      min-width: rem(200px);
      position: absolute;
    }

    .offer-0 {
      width: rem(422px);
      height: rem(384px);
      left: -9%;
      top: rem(-115px);
    }

    .offer-1 {
      width: rem(197px);
      height: rem(296px);
      top: rem(106px);
      left: 22%;
      margin-left: 14vw;
    }

    .offer-2 {
      width: rem(188px);
      height: rem(237px);
      left: 20%;
      top: rem(260px);
    }

    .offer-3 {
      width: rem(200px);
      height: rem(226px);
      left: 31%;
      top: rem(-52px);
    }

    .offer-card {
      min-height: 0;
    }

    button {
      margin-left: rem(15px);
    }

    .see-more-button {
      margin: rem(18px 0 22px 15px);
    }
  }

  @include respond-to("medium") {
    .image-card > .img {
      height: rem(300px);
    }
  }
}
</style>

<script>
import { ImageCard } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";
import InViewWrapper from "@/components/InViewWrapper";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";
import { MediaType } from "@/constants/media";

export default {
  components: {
    EventLink,
    ImageCard,
    InViewWrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cards: {
      type: Array,
      default: () => [],
    },
    text: {
      type: Object,
      default: null,
    },
    button: {
      type: Object,
      default: null,
    },
  },

  computed: {
    title() {
      return this.text?.title || "";
    },
    subtitle() {
      return this.text?.subtitle || "";
    },
    buttonType() {
      return this.button?.button_type;
    },
    event() {
      return this.buttonType === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },
  },

  methods: {
    getCardSrc(component) {
      const componentMedia = component.medias?.find(
        media => media.type === MediaType.LIST,
      );
      return getCloudinaryResponsiveUrl(
        componentMedia?.url,
        componentMedia?.type,
      );
    },
    getCardDescription(card) {
      return card.name || card.title;
    },
  },
};
</script>
