<template>
  <div class="editorial-list">
    <p class="editorial-list__list-title text3">
      {{ $t(`common.content-list.category.${category.toLowerCase()}`) }}
    </p>
    <div class="editorial-list__list">
      <div class="editorial-list__cards-wrapper">
        <template v-for="(item, idx) in completeItems">
          <InViewWrapper :key="item.id" @in-view="trackDisplay(idx)">
            <EventLink
              :key="item.id"
              :id="`button--list-item--${item.id}`"
              class="editorial-list__item"
              event="common.cms.link:click"
              :linkComponent="item"
              @mousedown.native="trackClick(idx)"
            >
              <ContentCard
                :src="getContentCardMedia(item)"
                :title="getContentCardTitle(item)"
                :subtitle="getContentCardSubtitle(item)"
              />
            </EventLink>
          </InViewWrapper>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.editorial-list {
  margin: auto;

  &__list-title {
    margin: rem(0 15px 15px);
    @include setColor(--color-title1);
    text-transform: uppercase;
  }

  &__list {
    width: 100%;
    overflow-x: auto;
    padding-bottom: rem(24px);
  }

  &__cards-wrapper {
    display: inline-flex;
    padding-left: rem(15px);
  }

  &__item {
    display: inline-block;
    width: rem(300px);
    min-width: rem(300px);
    margin-right: rem(15px);

    > * {
      height: 100%;
    }
  }

  @include respond-min("medium") {
    &__cards-wrapper {
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: rem(40px);
    }

    &__item {
      display: block;
      width: 32%;
      text-decoration: none;
      margin-bottom: rem(15px);
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { ContentCard } from "@johnpaul/jp-vue-components";
import { MediaType } from "@/constants/media";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";
import {
  getDynamicComponentId,
  getDynamicComponentVendorId,
} from "@/utilities/tracking";
import EventLink from "@/components/EventLink";
import InViewWrapper from "@/components/InViewWrapper";

export default {
  components: {
    EventLink,
    ContentCard,
    InViewWrapper,
  },

  props: {
    title: {
      type: String,
    },
    slug: {
      type: String,
    },
    category: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
    }),
    completeItems() {
      const results = JSON.parse(JSON.stringify(this.items)) || [];
      results.forEach(component => {
        component.link_template = "vendor-details";
        component.link_id = component.parent_id;
        component.medias = component.medias?.map(media => ({
          ...media,
          url: getCloudinaryResponsiveUrl(media.url, media.type),
        }));
      });
      return results;
    },
  },

  methods: {
    getContentCardMedia(item) {
      return (
        item.medias?.find(
          media => media.is_active && media.type === MediaType.LIST,
        )?.url ||
        item.medias?.find(
          media => media.is_active && media.type === MediaType.DETAIL,
        )?.url ||
        item.medias?.find(media => media.type === MediaType.LIST)?.url ||
        ""
      );
    },
    getContentCardTitle(item) {
      return item.title || item.name;
    },
    getContentCardSubtitle(item) {
      return (
        item.google_place?.address?.formatted_address ||
        this.$t("dynamic-content.editorial.content.vendor.online")
      );
    },
    getPublisherParams(idx) {
      const component = this.items[idx];
      return {
        pageSlug: this.slug,
        memberId: this.memberId,
        nbItems: this.items.length,
        contentId: getDynamicComponentId(component),
        positionItem: idx + 1,
        vendorId: getDynamicComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },
    trackClick(idx) {
      this.tracker.publishers.dynamic.clickDynamic(
        this.getPublisherParams(idx),
      );
    },
    trackDisplay(idx) {
      this.tracker.publishers.dynamic.dynamic(this.getPublisherParams(idx));
    },
  },
};
</script>
