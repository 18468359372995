<template>
  <ImageHeaderLayout
    ref="header"
    class="my-requests"
    :imageSrc="imageHeaderSrc()"
  >
    <template slot="title">
      <span class="title3">
        {{ $t("request.my-requests.header.title") }}
      </span>
    </template>

    <template slot="menu">
      <router-link
        id="link--my-requests-ongoing"
        class="text7"
        :to="{ name: `my-requests` }"
      >
        {{ $t(`request.my-requests.menu.links.ongoing`) }}
      </router-link>
      <router-link
        id="link--my-requests-past"
        class="text7"
        :to="{ name: `my-requests/past` }"
      >
        {{ $t(`request.my-requests.menu.links.past`) }}
      </router-link>
    </template>

    <router-view class="my-requests-content" />
  </ImageHeaderLayout>
</template>

<style lang="scss">
.my-requests {
  margin-bottom: rem(50px);

  @include respond-to("medium") {
    margin-top: 0 !important;

    .header {
      display: none;
    }
  }

  .title3 {
    @include setColor(--color-title1-negative);
  }

  .my-requests-content {
    margin-top: rem(16px);
    padding: rem(0 16px);

    @include respond-min("medium") {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";

import ImageHeaderLayout from "@/components/layout/ImageHeaderLayout";
import { getModuleConfig } from "@/utilities/config";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  components: {
    ImageHeaderLayout,
  },

  data() {
    const { assets } = getModuleConfig(this.$config, "request");
    return {
      assets,
      imageHeaderUrl: assets.headerImg,
      headerHeight: 0,
    };
  },
  methods: {
    ...mapActions({
      fetchRequests: "request/fetchRequests",
    }),

    ...mapMutations({
      resetRequests: "request/resetRequests",
    }),

    imageHeaderSrc() {
      return getCloudinaryResponsiveUrl(this.imageHeaderUrl).replace(
        "upload/",
        `upload/h_${this.headerHeight},`,
      );
    },
  },

  mounted() {
    this.fetchRequests();
    this.$emit("header", { transparent: false, shadow: false });
    const headerElm = this.$refs.header.$el.querySelector(".header");
    this.headerHeight = headerElm.clientHeight;
  },

  beforeDestroy: function() {
    this.resetRequests();
  },
};
</script>
