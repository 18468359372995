<template>
  <div class="news-list">
    <CollectionFilters
      v-if="collection && showSearch"
      :collectionName="collectionName"
      :enabledFilters="enabledFilters"
      :showSearch="showSearch"
      moduleName="news"
    />
    <div class="container">
      <template v-if="collection">
        <div class="row">
          <div class="col">
            <h1 class="super-title3 mt-3">
              {{ $t("news.title") }}
            </h1>
          </div>
        </div>
        <div
          v-if="
            fetching || (collection.components && collection.components.length)
          "
          class="row"
        >
          <InViewWrapper
            v-for="(component, idx) in components"
            :key="component.id"
            @in-view="trackPublisherDisplay(idx)"
          >
            <EventLink
              :id="`link--news-list--${component.id}`"
              class="news-link col-12 col-md-4 mb-3"
              event="common.cms.link:click"
              :linkComponent="component"
              @mousedown.native="trackPublisherClick(idx)"
            >
              <ImageCard :src="getCardSrc(component)">
                <div class="card-content text7">
                  <DateLabel
                    v-if="component.date_event"
                    class="card-date"
                    :date="component.date_event"
                  />
                  <div class="card-title">
                    <div class="title text7">{{ component.name }}</div>
                    <div class="subtitle text8">{{ component.subname }}</div>
                  </div>
                </div>
              </ImageCard>
            </EventLink>
          </InViewWrapper>
        </div>
        <SearchNoResult
          v-else
          module-name="news"
          :collection="collection"
          :show-concierge-link="showConciergeLink"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.news-list {
  .news-link {
    display: inline-block;
    text-decoration: none;

    > * {
      height: 100%;
    }
  }

  .card-content {
    @include setColor(--color-title1);
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  .card-date {
    height: rem(28px);
    border-top-left-radius: rem(14px);
    position: absolute;
    right: 0;
    top: rem(-14px);
    @include setColor(--color-background1, background-color);
    padding: 0 rem(16px);
  }

  .card-icon {
    font-size: rem(16px) !important;
  }

  .card-title {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
import { mapState } from "vuex";

import contentPageMixin from "@/mixins/contentPageMixin";
import { ImageCard } from "@johnpaul/jp-vue-components";
import DateLabel from "@/components/DateLabel";
import EventLink from "@/components/EventLink";
import InViewWrapper from "@/components/InViewWrapper";
import CollectionFilters from "@/components/filters/CollectionFilters";
import SearchNoResult from "@/components/filters/SearchNoResult";
import { getConfig } from "@/config";
import { getModuleConfig } from "@/utilities/config";
import { getComponentVendorId } from "@/utilities/tracking";

export default {
  components: {
    CollectionFilters,
    DateLabel,
    EventLink,
    ImageCard,
    InViewWrapper,
    SearchNoResult,
  },

  mixins: [contentPageMixin],

  data() {
    const moduleConfig = getModuleConfig(getConfig(), "news");
    return {
      pageId: moduleConfig.options.pageId,
      showSearch: moduleConfig.options.search,
      showConciergeLink: moduleConfig.options.showConciergeLink,
      enabledFilters: moduleConfig.options.enabledFilters,
    };
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      memberId: state => state.member.data?.id || null,
      fetching: state => state.content.fetchingComponents,
    }),

    collectionName() {
      return this.pageCollections?.[0]?.slug;
    },

    collection() {
      return this.collections[this.pageCollections[0]?.slug];
    },

    components() {
      return this.collection?.components;
    },
  },

  methods: {
    getCardSrc(component) {
      return component.medias?.[0]?.url;
    },

    getPublisherParams(idx) {
      const component = this.components[idx];
      return {
        positionItem: idx + 1,
        nbItems: this.collection.count,
        memberId: this.memberId,
        vendorId: getComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },

    trackPublisherClick(idx) {
      const publisherParams = this.getPublisherParams(idx);
      this.tracker.publishers.contents.newsClicked(publisherParams);
    },

    trackPublisherDisplay(idx) {
      const publisherParams = this.getPublisherParams(idx);
      this.tracker.publishers.contents.newsDisplayed(publisherParams);
    },
  },

  mounted() {
    this.$root.$emit("news.list:mounted");
    this.$emit("header", { transparent: false });
  },
};
</script>
