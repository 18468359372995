export function getGoogleLocationLink(location) {
  if (location.formatted_address) {
    return (
      "https://maps.google.com/?q=" + encodeURI(location.formatted_address)
    );
  }

  const address = [
    location.street_number,
    location.street,
    location.zip_code,
    location.city,
  ]
    .filter(Boolean)
    .join(",");

  return "https://maps.google.com/?q=" + encodeURI(address);
}
