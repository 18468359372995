<template>
  <div class="account-card">
    <Avatar class="card-image" :height="30" />
    <span class="text6">{{ fullName }}</span>
  </div>
</template>

<style lang="scss">
.account-card {
  .card-image {
    height: rem(30px);
    margin-right: rem(14px);
  }
}
</style>

<script>
import { mapGetters } from "vuex";

import Avatar from "@/components/Avatar";

export default {
  components: {
    Avatar,
  },

  computed: {
    ...mapGetters({
      fullName: "member/fullName",
    }),
  },
};
</script>
