<template>
  <VendorDetailLayout
    type="vendor"
    :id="id"
    listPage="benefits"
    moduleName="benefits"
  />
</template>

<script>
import VendorDetailLayout from "@/components/layout/VendorDetailLayout";

export default {
  components: {
    VendorDetailLayout,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.$emit("header", { transparent: true });
  },
};
</script>
