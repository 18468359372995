<template>
  <ImageHeaderLayout class="my-card" :imageSrc="headerImage" :avatar="true">
    <template slot="title">
      <p class="contract-name text3">{{ contractName }}</p>
    </template>

    <template slot="menu" v-if="tabComponents.length > 1">
      <a
        v-for="(tabComponent, idx) in tabComponents"
        class="text7"
        :class="idx === sectionIdx && 'router-link-exact-active'"
        :key="idx"
        href="#"
        @click.prevent="selectSection(idx)"
      >
        {{ tabComponent }}
      </a>
    </template>

    <MyCardSection
      v-if="sectionComponents.length"
      :introComponent="introComponent"
      :components="sectionComponents"
    />
    <MyCardNumbers v-else-if="showNumbersTab" />
  </ImageHeaderLayout>
</template>

<style lang="scss">
.my-card {
  .header {
    width: 100%;
    height: rem(177px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    @include setColor(--color-text-negative);
    text-transform: uppercase;

    @include respond-min("medium") {
      height: rem(315px);
    }

    .contract-name {
      @include setColor(--color-title2-negative);
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

import ImageHeaderLayout from "@/components/layout/ImageHeaderLayout";
import MyCardNumbers from "./components/MyCardNumbers";
import MyCardSection from "./components/MyCardSection";

import contentPageMixin from "@/mixins/contentPageMixin";

import { getModuleConfig } from "@/utilities/config";

export default {
  components: {
    ImageHeaderLayout,
    MyCardNumbers,
    MyCardSection,
  },

  mixins: [contentPageMixin],

  data() {
    const config = this.$config;
    const moduleConfig = getModuleConfig(config, "my-card");
    return {
      pageId: moduleConfig.options.pageId,
      showNumbersTab: !!moduleConfig.options.numbersTab,
      expandedIdx: 0,
      sectionIdx: 0,
    };
  },

  computed: {
    ...mapGetters({
      subscription: "subscriptions/primarySubscription",
    }),

    headerImage() {
      const collection = this.pageCollections.find(c => c.type === "qa");
      return collection ? this.getComponents(collection)[0]?.medias[0].url : "";
    },

    listCollections() {
      return this.pageCollections
        .filter(c => c.type === "list")
        .map(c => this.getComponents(c))
        .filter(c => this.collectionHasContent(c));
    },

    sectionComponents() {
      return (
        this.listCollections[this.sectionIdx]?.filter(
          component => component.type === "media",
        ) || []
      );
    },

    tabComponents() {
      const tabs = this.listCollections.map(collection => {
        return collection.find(component => component.type === "text")?.name;
      });

      if (this.showNumbersTab) {
        tabs.push(this.$t("my-card.numbers.tab"));
      }

      return tabs;
    },

    introComponent() {
      const components = this.listCollections.map(collection => {
        return collection.find(component => component.type === "all");
      });

      return components[this.sectionIdx];
    },

    contractName() {
      return this?.subscription?.attributes?.contract;
    },
  },

  methods: {
    selectSection(idx) {
      if (this.sectionIdx !== idx) {
        this.sectionIdx = idx;
        this.expandedIdx = 0;
      }
    },
    collectionHasContent(collection) {
      return collection?.filter(
        component => component.type === "media" || component.type === "all",
      ).length;
    },
  },

  async mounted() {
    this.$root.$emit("my-card:mounted");
    this.$emit("header", { transparent: false });
  },
};
</script>
