import get from "lodash.get";

import i18nInstance from "@/i18n";

import { getTimeInMinutes, isToday } from "@/utilities/dates";
import { getBrowserCountryCode } from "@/utilities/i18n";
import { resolve } from "@/utilities/conductor";
import { getConfig } from "@/store";

function translate(key, options = {}) {
  return i18nInstance.t(key.replace("{i18nVariant}", options.i18nVariant));
}

function translateLabels(field, options) {
  const { placeholder, label, areaLabel } = field;

  return {
    ...field,
    placeholder: placeholder && translate(placeholder, options),
    label: label && translate(label, options),
    areaLabel: areaLabel && translate(areaLabel, options),
  };
}

function generateDate(field, options) {
  const { daysFromNow = 0, fieldProp } = options;
  const tspNow = new Date().setHours(0, 0, 0);
  const tspDate = tspNow + daysFromNow * 24 * 60 * 60 * 1000;

  return {
    ...field,
    [fieldProp]: new Date(tspDate),
    ...(field.value ? { value: field.value } : null),
  };
}

function generateHourOptions(field, params = {}, fields) {
  const {
    minTime = "00:00",
    maxTime = "23:59",
    interval = 60,
    hoursFromNow = 0,
    dateField,
  } = params;
  const splitRegex = /[:h]/;

  const [minHours, minMinutes] = minTime.split(splitRegex);
  const [maxHours, maxMinutes] = maxTime.split(splitRegex);

  const minTotalMinutes = minHours * 60 + parseInt(minMinutes, 10);
  const maxTotalMinutes = maxHours * 60 + parseInt(maxMinutes, 10);

  let currentTimeInMinutes = 0;
  if (dateField && isToday(fields[dateField].value)) {
    // Current time of the day in minutes, modulo for hours > 24
    currentTimeInMinutes = (getTimeInMinutes() + hoursFromNow * 60) % (24 * 60);
  }

  let diffInMinutes = maxTotalMinutes - minTotalMinutes;
  // If the hour max is beyond 00h or if min = max
  diffInMinutes += diffInMinutes > 0 ? 0 : 24 * 60;

  // Number of items in the select
  const nbOptions = diffInMinutes / interval + 1;

  // Generate options
  const options = Array.from(Array(nbOptions).keys())
    .map(idx => {
      // Get the option value in minutes
      // We're using modulo to reduce hours > 24
      const valueInMinutes = (idx * interval + minTotalMinutes) % (24 * 60);
      // Remove options prior to current time
      if (valueInMinutes < currentTimeInMinutes) return;

      let hours, minutes;
      const rawHour = parseInt(valueInMinutes / 60, 10);
      const languagesWithContinentalTimeFormat = ["fr", "it", "de", "pl"];

      if (languagesWithContinentalTimeFormat.includes(i18nInstance.locale)) {
        hours = `${rawHour}`.padStart(2, "0");
        minutes = `${valueInMinutes % 60}`.padStart(2, "0");
        return {
          text: `${hours}:${minutes}`,
          value: valueInMinutes,
        };
      } else {
        if (rawHour === 0) {
          hours = "12";
        } else if (rawHour >= 13) {
          hours = `${rawHour - 12}`;
        } else {
          hours = `${rawHour}`;
        }
        minutes = `${valueInMinutes % 60}`.padStart(2, "0");
        return {
          text: `${hours}:${minutes} ${rawHour < 12 ? "AM" : "PM"}`,
          value: valueInMinutes,
        };
      }
    })
    .filter(Boolean);

  return {
    ...field,
    options,
    value:
      field.value && options.find(option => option.value === field.value)
        ? field.value
        : options[0].value,
  };
}

function bindFieldValue(field, options, fields) {
  const targetValue = fields[options.bindedField].value;
  if (!targetValue) return field;

  return {
    ...field,
    [options.fieldProp]: targetValue,
  };
}

function bindStateValue(field, options) {
  const value = options.state?.[options.attribute];
  if (!value) return field;

  return {
    ...field,
    [options.fieldProp]: value,
  };
}

function getVisibleProps(field, visible) {
  return {
    ...field,
    visible,
    value: visible ? field.value : null,
    required: visible ? field.required : false,
  };
}

function visibleIfNot(field, options, fields) {
  return getVisibleProps(field, !fields[options.bindedField].value);
}

function visibleIf(field, options, fields) {
  return getVisibleProps(field, !!fields[options.bindedField].value);
}

function translateStringProperties(field, options) {
  options.properties.forEach(
    attr => (field[attr] = translate(field[attr], options)),
  );
  return field;
}

function translateOption(value, options) {
  return typeof value === "string" ? translate(value, options) : value;
}

function translateStringOptions(field, options) {
  // Convert object options to array options
  const fieldOptions =
    field.options instanceof Array
      ? field.options
      : Object.entries(field.options).map(([value, text]) => ({ value, text }));

  const selectOptions = fieldOptions.map(option => {
    if (typeof option === "object") {
      return {
        value: option.value,
        text: translateOption(option.text, options),
      };
    }
    return translateOption(option, options);
  });

  return { ...field, options: selectOptions };
}

function translateLabelWithLink(field, options) {
  const { pre, link, post } = translate(field.label, options);

  let label;

  const resolved = resolve(options.event);
  if (resolved)
    label = `${pre} <a href="${resolved.href}" target="_blank" rel="noopener noreferrer" class="text7">${link}</a> ${post}`;
  else label = `${pre} ${link} ${post}`;

  return {
    ...field,
    label,
  };
}

function ifStateValue(field, options) {
  const { key, value, props } = options;
  const stateValue = get(options.state, key);

  if ((value && stateValue === value) || (!value && stateValue)) {
    return {
      ...field,
      ...props,
      ...(field.value && props.visible !== false
        ? { value: field.value }
        : null),
    };
  }
  return field;
}

function setCountryCode(field, options) {
  return {
    ...field,
    [options.fieldProp]: options.customValue
      ? options.customValue
      : getBrowserCountryCode(),
  };
}

function initFromMember(field, options) {
  const { prop, from, state } = options;
  let initValue;
  if (field.type === "phone") {
    let phone = state.phones.find(p => p.type === from);
    if (phone?.phone)
      initValue = {
        [prop]: phone.phone,
      };
  } else {
    const value = get(state, from);
    if (value) initValue = { [prop]: value };
  }
  return { ...field, ...initValue };
}

function setCurrencyTemplate(field, options) {
  return {
    ...field,
    [options.property]: getConfig().options.currency.template,
  };
}

export function slugifyText(text) {
  return text
    ? text
        .toLowerCase()
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/  +/g, " ")
        .replace(/\s/g, "-")
    : null;
}

export default {
  bindFieldValue,
  bindStateValue,
  generateDate,
  generateHourOptions,
  ifStateValue,
  setCountryCode,
  translateLabels,
  translateLabelWithLink,
  translateStringProperties,
  translateStringOptions,
  visibleIfNot,
  visibleIf,
  initFromMember,
  slugifyText,
  setCurrencyTemplate,
};
