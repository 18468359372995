<template>
  <div class="faq">
    <div
      ref="faqHeader"
      class="faq-header"
      :style="{
        backgroundImage: `url(${imageHeaderSrc()})`,
      }"
    >
      <div class="header-title super-title3">
        {{ $t("faq.header.title") }}
      </div>
      <div class="searchbar">
        <SearchInput
          :placeholder="$t('faq.header.placeholder')"
          :autoRequest="false"
          :searched="search"
          @search="onSearch"
          @resetSearch="onReset"
          collectionName=""
          v-if="showSearch"
        />
      </div>
      <div class="header-text text6" v-if="showSearch">
        {{ $t("faq.header.text") }}
      </div>
      <div class="separator">
        <FontIcon name="ui_chevron_right" class="separator-chevron" />
      </div>
    </div>
    <ActivityIndicator v-if="fetching" />
    <div class="no-result" v-if="!fetching && search && noResult">
      <div class="no-result-title title3">{{ $t("faq.no-result.title") }}</div>
      <div class="no-result-text text6">
        {{ noResultText }}
      </div>
    </div>
    <div class="faq-result-title" v-if="search && !noResult">
      {{ `${$t("faq.search-title")} "${search}"` }}
    </div>
    <ul
      class="faq-topics"
      :class="{ centered: topicAssets.length < 3 }"
      v-if="topicAssets.length > 1 && ((search && noResult) || !search)"
    >
      <li v-for="topicAsset in topicAssets" :key="topicAsset.id">
        <router-link
          :to="{ name: 'faq/topic', params: { topic: topicAsset.slug } }"
          @click.native="onTopicClick(topicAsset)"
        >
          <div
            class="faq-topic text5"
            :class="{ selected: topicAsset.slug === topic }"
          >
            <img v-if="topic.img" :src="topic.img" alt="Faq topic image" />
            {{ topicAsset.name }}
          </div>
        </router-link>
      </li>
    </ul>
    <div class="topic-description">
      <div class="description-title title3">{{ topicAttributes.name }}</div>
      <div class="description-text text6">
        {{ topicAttributes.subname }}
      </div>
    </div>
    <AccordeonFaq
      class="selected-topic"
      :components="accordeonContent[contentToDisplay]"
      v-if="displayArticles"
      @expand-subtopic="onSubtopicClick"
    />
    <div class="contact-us text6">
      <div class="contact-us-text">{{ $t("faq.contact-us") }}</div>

      <Button :id="`faq-not-found`" @click.native="contactUs" class="button">
        {{ $t("need-help.subtitle") }}
      </Button>
    </div>
  </div>
</template>
<style lang="scss">
.faq {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: rem(90px);

  .faq-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: rem(40px);
    padding: rem(0 13px);
    height: rem(300px);
    @include setColor(--color-title1-negative);
    text-align: center;
    background-size: cover;
    background-position: center;

    @include respond-min("medium") {
      margin-bottom: rem(57px);
    }

    .header-title {
      margin: rem(0 24px 17px);

      @include respond-min("medium") {
        margin: rem(0 0 25px);
      }
    }

    .header-text {
      margin: rem(0 48px);
    }

    .searchbar {
      margin: rem(0 auto 30px);
      width: 100%;
      max-width: rem(349px);

      @include respond-min("medium") {
        margin: rem(0 auto 70px);
      }
    }

    .separator {
      position: absolute;
      bottom: rem(-18px);
      left: calc(50% - 18px);
      height: rem(36px);
      width: rem(36px);
      @include setColor(--color-title1-negative);
      @include setColor(--color-highlight, background-color);
      border-radius: 5px;
      line-height: rem(36px);
      transform: rotate(45deg);

      .separator-chevron {
        vertical-align: middle;
        transform: rotate(45deg);
      }
    }
  }

  .no-result {
    margin: rem(0 16px 30px);
    text-align: left;
    max-width: rem(700px);

    @include respond-min("medium") {
      margin: rem(0 auto 57px);
      text-align: center;
    }

    .no-result-title {
      margin-bottom: rem(11px);
    }
    .no-result-text {
      width: 80%;
      margin: 0;

      @include respond-min("medium") {
        margin: 0 auto;
      }
    }
  }

  .faq-result-title {
    text-align: center;
  }

  .faq-topics {
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    padding: rem(0 16px 20px);
    margin: rem(0 0 10px);
    list-style: none;

    &.centered {
      justify-content: center;
    }

    @include respond-min("medium") {
      flex-wrap: wrap;
      margin: rem(0 auto 27px);
      max-width: 1000px;
      justify-content: center;
    }

    .faq-topic {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: rem(120px);
      height: rem(120px);
      margin: rem(0 5px);
      background-color: transparent;
      @include setColor(--color-cta);
      border: none;
      outline: none;
      box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1);
      border-radius: 11px;
      text-align: center;
      line-height: 1.25;

      &.selected {
        border: solid 2px;
        @include setColor(--color-selection, border-color);
      }

      @include respond-min("medium") {
        width: rem(237px);
        height: rem(155px);
        margin: rem(0 10px 24px);
      }
    }
  }

  .topic-description {
    margin: rem(0 16px 26px);
    text-align: left;
    max-width: rem(700px);

    @include respond-min("medium") {
      margin: rem(0 auto 26px);
      text-align: center;
    }

    .description-title {
      margin-bottom: rem(11px);
    }
    .description-text {
      @include respond-min("medium") {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .selected-topic {
    max-width: rem(700px);
    margin: rem(0 16px 60px);

    @include respond-min("medium") {
      margin: rem(0 auto 26px);
    }
  }

  .contact-us {
    max-width: rem(700px);
    margin: rem(0 18px 32px);
    padding: rem(23px);
    text-align: center;
    border: solid 2px;
    @include setColor(--color-cta border-color);
    border-radius: rem(12px);

    @include respond-min("medium") {
      margin: rem(0 auto 23px);
    }

    .contact-us-text {
      @include respond-min("medium") {
        margin: 0 auto;
        max-width: 60%;
      }
    }

    .button {
      text-transform: uppercase;
      margin: rem(16px auto 0);
      max-width: rem(197px);
    }
  }
}
</style>
<script>
import SearchInput from "@/components/filters/SearchInput";
import AccordeonFaq from "./components/AccordeonFaq";
import {
  ActivityIndicator,
  Button,
  FontIcon,
} from "@johnpaul/jp-vue-components";
import contentPageMixin from "@/mixins/contentPageMixin";
import { getModuleConfig } from "@/utilities/config";
import { mapActions, mapState } from "vuex";
import clonedeep from "lodash.clonedeep";
import { getComponentVendorId } from "@/utilities/tracking";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

function trimSearch(search) {
  let searchWords = search.replace("%20", " ").split(" ");
  searchWords = searchWords.filter(w => w.length > 2);
  if (!searchWords.length) return null;
  return searchWords.join(" ");
}

export default {
  components: {
    FontIcon,
    SearchInput,
    AccordeonFaq,
    Button,
    ActivityIndicator,
  },

  mixins: [contentPageMixin],

  props: {
    topic: {
      type: String,
      default: "",
    },
    search: {
      type: String,
      default: null,
    },
  },

  data() {
    const moduleConfig = getModuleConfig(this.$config, "faq");
    return {
      pageId: moduleConfig.options.pageId,
      showSearch: moduleConfig.options.searchEnable,
      searchResults: [],
      searched: null,
      noResult: false,
      contentToDisplay: "",
      imageHeaderUrl: moduleConfig.assets.headerImg,
      headerHeight: 0,
    };
  },

  methods: {
    ...mapActions({
      searchInCollections: "content/searchInCollections",
    }),

    onSearch(search) {
      const toSearch = trimSearch(search);
      if (!toSearch || this.search === search) return;
      this.searchResults = [];
      this.$router.push({ name: "faq", query: { q: toSearch } });
    },

    onReset() {
      this.$router.push({
        name: "faq/topic",
        params: { topic: this.collectionNames[0] },
      });
    },

    onTopicClick(topic) {
      this.$root.$emit("faq.topic:display", {
        group: topic.name,
      });
    },

    onSubtopicClick(component) {
      this.$root.$emit("faq.topic:display", {
        group: this.topicAttributes.name,
        title: component.name,
        vendorId: getComponentVendorId(component),
      });
    },

    sendSearch(search, collectionNames) {
      const toSearch = trimSearch(search);
      if (!toSearch) return;
      this.searched = toSearch;
      this.searchInCollections({
        collectionNames: collectionNames,
        search: toSearch,
      });
    },

    contactUs() {
      this.$root.$emit("faq.need-help:click");
    },

    imageHeaderSrc() {
      return getCloudinaryResponsiveUrl(this.imageHeaderUrl).replace(
        "upload/",
        `upload/h_${this.headerHeight},`,
      );
    },
  },

  computed: {
    ...mapState({
      fetching: state => state.content.fetchingComponents,
      collectionsSearchResult: state => state.content.collectionsSearchResult,
    }),

    collectionsList() {
      return this.pageCollections
        .filter(c => c.type === "section")
        .map(c => {
          return {
            ...c,
            components: this.getComponents(c),
          };
        });
    },

    collectionNames() {
      return this.pageCollections.map(c => c.slug);
    },

    topicList() {
      if (!this.selectedCollection) return [];
      return (
        this.getComponents(this.selectedCollection).filter(
          c => c.type === "text",
        ) || []
      );
    },

    topicAssets() {
      return this.collectionsList.reduce((acc, collection) => {
        const topicArray = collection.components.filter(
          c => c.type === "media",
        );
        for (let topic of topicArray) topic.slug = collection.slug;
        return acc.concat(topicArray);
      }, []);
    },

    topicAttributes() {
      return (
        this.topicAssets.find(t => t.slug == this.topic) || {
          name: "",
          subname: "",
        }
      );
    },

    selectedCollection() {
      return this.pageCollections.find(c => c.slug === this.topic) || null;
    },

    accordeonContent() {
      return {
        topicList: this.topicList,
        resultList: this.searchResults,
      };
    },

    noResultText() {
      return this.$t("faq.no-result.text").replace("%key_word%", this.search);
    },

    displayArticles() {
      return (
        !this.fetching &&
        ((this.topic && this.topicList.length) ||
          (this.search && this.searchResults.length))
      );
    },
  },

  watch: {
    topic: {
      handler: function(newTopic) {
        if (!newTopic && !this.search && this.collectionNames?.[0]) {
          this.$router.replace({
            name: "faq/topic",
            params: { topic: this.collectionNames[0] },
          });
        }
        if (newTopic) {
          this.contentToDisplay = "topicList";
        }
      },
      immediate: true,
    },

    search: {
      handler: function(key) {
        if (!this.topic && !key && this.collectionNames?.[0]) {
          this.$router.push({
            name: "faq/topic",
            params: { topic: this.collectionNames[0] },
          });
          return;
        }

        if (!key) return;

        this.contentToDisplay = "resultList";
        if (this.collectionNames.length) {
          this.sendSearch(key, this.collectionNames);
        }
      },
      immediate: true,
    },

    collectionNames(names) {
      if (!this.topic && !this.search && names.length) {
        this.$router.replace({
          name: "faq/topic",
          params: { topic: names[0] },
        });
        return;
      }
      if (this.search) {
        this.sendSearch(this.search, names);
      }
    },

    collectionsSearchResult(newResults) {
      this.noResult = !newResults.length;
      let results = clonedeep(newResults);

      for (let result of results) {
        let name = this.pageCollections.find(c => c.slug === result.slug).name;
        result.link = `${this.$t("faq.in")} ${name}`;
        let searchKeys = this.searched.split(" ");
        for (let searchKey of searchKeys) {
          result.description = result.description.replace(
            searchKey,
            `<span class="highlight text5">${searchKey}</span>`,
          );
        }
      }
      this.searchResults = results;
    },
  },

  mounted() {
    this.$root.$emit("faq:mounted");
    this.$emit("header", {
      transparent: false,
    });
    this.headerHeight = this.$refs.faqHeader.clientHeight;
  },
};
</script>
