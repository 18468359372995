<template>
  <PopInLayout
    :showPopIn="showSecretQuestionPopin"
    :title="$t(`secret-question-popin.title`)"
    :subtitle="$t(`secret-question-popin.subtitle`)"
    :closable="false"
    fullScreenOnSmallDevice
  >
    <img slot="logo" :src="require('assets/images/logo.png')" alt="logo" />

    <Alert
      slot="alert"
      v-if="secretError"
      variant="error"
      class="popin-secret-error"
    >
      <span v-html="$t(`secret-question-popin.error.${secretError}`)"></span>
    </Alert>

    <DynamicForm
      slot="content"
      v-if="secretForm"
      class="popin-secret-form"
      prefix-input-id="popin-secret"
      btnContainerClass="popin-secret-btn"
      :form="secretForm"
      :buttonText="$t('my-account.security.secret.form.buttons.submit')"
      :submitting="secretSubmitting"
      @submit="onSubmitSecret"
    />
  </PopInLayout>
</template>

<style lang="scss">
.popin-secret {
  &-form {
    margin: rem(25px auto 0);
    text-align: left;

    .password {
      input {
        -webkit-text-security: disc;
      }
    }
  }

  &-btn {
    bottom: 0;
    left: rem(16px);
    right: rem(16px);
    padding: rem(20px);
    @include setColor(--color-background1, background-color);
    z-index: 10000;

    .col {
      padding: 0;
      margin: auto;
    }
  }
}

@include respond-min("medium") {
  .popin-secret {
    &-form {
      width: rem(340px);
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PopInLayout from "@/components/layout/PopInLayout";
import DynamicForm from "@/components/DynamicForm";
import formMixin from "@/mixins/formMixin";
import { Alert } from "@johnpaul/jp-vue-components";

export default {
  name: "SecretQuestionPopin",
  mixins: [formMixin],

  components: {
    Alert,
    DynamicForm,
    PopInLayout,
  },

  data() {
    return {
      noSecretDefined: false,
      secretResultError: false,
      secretForm: null,
    };
  },

  computed: {
    ...mapState({
      secretQuestion: state => state.password.secretQuestion,
      secretQuestionError: state => state.password.readSecretQuestionError,
      secretSubmitting: state => state["my-account"].secret.updating,
      secretUpdated: state => state["my-account"].secret.updated,
      secretError: state => state["my-account"].secret.error,
      member: state => state.member.data,
    }),
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      successfullyUpdated: "my-account/secret/successfullyUpdated",
    }),
    secretAttributes() {
      return this.secretForm.fields.reduce((acc, val) => {
        return { ...acc, ...val };
      }, {});
    },
    showSecretQuestionPopin() {
      return (
        this.loggedIn &&
        this.member &&
        this.noSecretDefined &&
        !this.secretResultError
      );
    },
  },

  methods: {
    ...mapActions({
      getSecretQuestion: "password/getSecretQuestion",
      updateSecret: "my-account/secret/update",
    }),

    async onSubmitSecret() {
      const { question, answer, password } = this.secretAttributes;
      const updateResult = await this.updateSecret({
        password: password.value,
        secret_question: question.value,
        secret_answer: answer.value,
      });
      if (updateResult.success) {
        this.secretForm.fields.forEach(field => {
          const firstKey = Object.keys(field)[0];
          if (field[firstKey]) field[firstKey].value = null;
        });
      } else {
        const passwordField = this.secretForm.fields.find(
          field => Object.keys(field)[0] === "password",
        );
        if (passwordField?.password) passwordField.password.value = null;
      }
    },
    async checkSecretQuestion() {
      try {
        const secretResult = await this.getSecretQuestion();
        if (secretResult?.success) this.secretResultError = false;
        if (!this.secretQuestion) this.noSecretDefined = true;
      } catch (e) {
        this.secretResultError = true;
      }
    },
  },

  watch: {
    loggedIn: {
      immediate: true,
      handler(logged) {
        if (logged) this.checkSecretQuestion();
      },
    },
    successfullyUpdated: {
      handler(secretUpdated) {
        if (secretUpdated) {
          this.noSecretDefined = false;
          this.secretResultError = false;
        }
      },
    },
  },

  async mounted() {
    await this.loadFormConfig("changeSecretForm", {
      componentFormName: "secretForm",
    });
  },
};
</script>
