<template>
  <section class="post-list-carousel-template">
    <b-carousel
      class="post-list-carousel-template__slider"
      :id="details.id"
      :interval="5000"
      fade
      indicators
      v-model="activeSlide"
      ref="carousel"
      @sliding-end="emitSlideDisplay"
    >
      <b-carousel-slide img-blank v-for="(slide, index) in slides" :key="index">
        <template v-slot:img>
          <div
            :style="{ backgroundImage: getBackgroundImage(slide) }"
            class="post-list-carousel-template__slide-image"
          />
        </template>
        <div
          class="post-list-carousel-template__slide"
          v-show="activeSlide === index"
        >
          <h2 class="post-list-carousel-template__slide-title text1">
            {{ slide.title }}
          </h2>
          <h3 class="post-list-carousel-template__slide-subtitle text6 mt-1">
            {{ slide.subtitle }}
          </h3>
          <p
            class="post-list-carousel-template__slide-description text6 mt-3"
            v-html="slide.description"
          />

          <EventLink
            :id="`button--post-list-carousel--${details.id}--${slide.id}`"
            :event="slideEvent(slide)"
            :linkComponent="slide"
            type="button"
            class="post-list-carousel-template__slide-button button-primary text6"
            @mousedown.native="$emit('component-click', index)"
          />
        </div>
      </b-carousel-slide>
    </b-carousel>
    <div class="post-list-carousel-template__slider-controls">
      <a class="post-list-carousel-template__control-prev" @click="prev">
        <FontIcon name="ui_chevron_left" />
      </a>
      <a class="post-list-carousel-template__control-next" @click="next">
        <FontIcon name="ui_chevron_right" />
      </a>
    </div>
  </section>
</template>

<style lang="scss">
.post-list-carousel-template {
  position: relative;
  height: rem(540px);

  &__slider {
    height: rem(240px);
  }

  &__slide-image {
    width: 100%;
    background-size: cover;
    background-position: center center;
  }

  &__slider-controls {
    position: absolute;
    height: rem(50px);
    width: rem(110px);
    top: rem(240px);
    right: 0;
    @include setColor(--color-cta-negative);
    z-index: 1;

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include setColor(--color-cta-negative);
      width: rem(55px);
      height: rem(50px);
      font-size: rem(24px);
      text-decoration: none;

      @include setColor(--color-cta, background-color);
      transition: 0.1s opacity linear;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__control-prev {
    border-radius: 3px 0 0 3px;
  }

  &__control-next {
    border-radius: 0 3px 3px 0;
  }

  .carousel-indicators {
    position: absolute !important;
    right: rem(110px);
    margin: rem(0 16px);
    bottom: rem(-50px);
    display: flex;
    z-index: 1;

    > li {
      margin: rem(14px 2px);
      border-width: rem(10px);
      flex-grow: 1;
      @include setColor(--color-inactive, background-color);
      transition: 200ms background-color linear;

      &.active {
        @include setColor(--color-selection, background-color);
      }
    }
  }

  .carousel-inner {
    overflow: visible;
  }

  .carousel-item-right.active,
  .carousel-item-left.active {
    transition: 600ms opacity linear;
  }

  .carousel-caption {
    position: absolute;
    height: rem(300px);
    width: 100%;
    margin: 0;
    bottom: rem(-300px);
    left: 0;
    top: auto;
    padding: rem(64px 16px 16px);

    .post-list-carousel-template__slide {
      width: 100%;
      height: 100%;
      text-align: left;

      &-title,
      &-subtitle,
      &-description {
        background-color: inherit;
      }

      &-title {
        @include setColor(--color-title1);
      }

      &-subtitle {
        max-height: rem(42px);
        overflow: hidden;
        @include setColor(--color-subtitle);
      }

      &-description {
        overflow: hidden;
        @include setColor(--color-text);
      }

      &-button {
        position: absolute;
        left: rem(16px);
        bottom: rem(30px);

        width: auto;
        margin-top: rem(20px);
        padding: rem(0 50px);
      }
    }
  }

  @include respond-min("medium") {
    height: rem(500px);

    &__slider {
      height: 100%;
    }

    &__slide-image {
      position: relative;
      top: O;
      right: 0;
      bottom: 0;
      left: 35%;
      width: 65%;
    }

    .carousel-indicators {
      right: auto;
      bottom: 0;
      left: 0;
      width: 35%;
      margin: 0;
      padding: rem(0 42px);
    }

    &__slider-controls {
      top: 50%;
      left: 35%;
      transform: translate(-50%, -50%);
    }

    .carousel-caption {
      top: 0;
      left: 0;
      width: 35%;
      height: 100%;
      padding: rem(42px 100px 42px 42px);

      .post-list-carousel-template__slide {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &-description {
          width: 100%;
        }

        &-button {
          position: relative;
          left: auto;
          bottom: auto;
          margin-top: rem(60px);
        }
      }
    }
  }
}
</style>

<script>
import inViewport from "vue-in-viewport-mixin";
import EventLink from "@/components/EventLink";
import { FontIcon } from "@johnpaul/jp-vue-components";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  components: {
    EventLink,
    FontIcon,
  },

  mixins: [inViewport],

  data() {
    return {
      activeSlide: 0,
    };
  },

  props: {
    cards: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    slides() {
      return this.cards
        .filter(card => card.medias.length > 0)
        .map(card => ({
          ...card,
          img: getCloudinaryResponsiveUrl(
            card.medias?.[0]?.url,
            card.medias?.[0]?.type,
            true,
          ),
        }));
    },
  },

  watch: {
    "inViewport.now": function() {
      this.emitSlideDisplay();
    },
    slides() {
      this.emitSlideDisplay();
    },
  },

  methods: {
    getBackgroundImage(slide) {
      return "url('" + slide.img + "')";
    },
    emitSlideDisplay() {
      if (this.inViewport.now && this.slides.length) {
        this.$emit("component-display", this.activeSlide);
      }
    },
    slideEvent(slide) {
      return slide.button_type === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>
