<template>
  <div :class="['login-view', background && 'has-background']">
    <div
      v-if="backgroundImage"
      class="login-view__background"
      :style="`background: linear-gradient(${linearGradient});`"
    >
      <img
        class="login-view__background-image"
        alt="Background login image"
        :src="responsiveBackgroundImageSrc"
        :style="`--original-app-height: ${originalAppHeight}px;`"
      />
    </div>

    <div class="login-view__container container">
      <div class="form">
        <div class="super-title3 title">{{ $t("login.page.title") }}</div>
        <form @submit.prevent="onSubmit" novalidate class="mx-auto">
          <ActivityIndicator v-if="verifying" />
          <Alert
            variant="success"
            v-else-if="verifySuccess"
            id="alert--login-verification-success"
          >
            {{ $t("login.verification.success") }}
          </Alert>
          <Alert
            variant="error"
            v-else-if="verifyError"
            id="alert--login-verification-error"
          >
            {{ $t("login.verification.error") }}
          </Alert>
          <Alert
            variant="error"
            v-else-if="error"
            id="alert--login-login-error"
          >
            <template v-if="error == LOGIN_ACCOUNT_NOT_VERIFIED">
              <span
                v-html="$t(`login.error.${LOGIN_ACCOUNT_NOT_VERIFIED}.text`)"
              ></span
              >&nbsp;
              <a
                class="text7"
                @click="
                  $root.$emit(
                    `login.error.${LOGIN_ACCOUNT_NOT_VERIFIED}:click`,
                    {
                      email: username,
                    },
                  )
                "
                v-html="$t(`login.error.${LOGIN_ACCOUNT_NOT_VERIFIED}.link`)"
              >
              </a>
            </template>
            <span v-else v-html="$t(`login.error.${error}`)"></span>
          </Alert>
          <TextInput
            id="input--login-username"
            :label="$t('login.form.inputs.username')"
            :value.sync="username"
            :disabled="fetching"
            @update:value="errors.username = null"
            @blur="checkUsername()"
          />
          <TextInput
            class="password"
            id="input--login-password"
            :label="$t('login.form.inputs.password')"
            type="password"
            :value.sync="password"
            :disabled="fetching"
            @update:value="errors.password = null"
            @blur="checkPassword()"
          >
          </TextInput>
          <div class="text-right">
            <a
              v-if="$modules.password"
              id="link--login-recover-password"
              class="text7"
              @click="$root.$emit('login.links.password:click', { username })"
            >
              {{ $t("login.links.recover-password") }}
            </a>
          </div>
          <div class="mt-4">
            <ActivityIndicator v-if="fetching" />
            <Button
              v-else
              id="button--login-submit"
              class="button text6"
              type="primary"
              nativeType="submit"
            >
              {{ $t("login.buttons.login") }}
            </Button>
          </div>
          <Button
            id="button--login-register"
            class="button mt-3 text6 login-button"
            type="secondary-negative"
            :disabled="fetching"
            nativeType="button"
            @click.native="$root.$emit('login.links.register:click')"
          >
            {{ $t("login.buttons.register") }}
          </Button>
          <div class="text8 text-center mt-1" v-if="$modules['need-help']">
            {{ $t("login.links.help.pre") }}
            <a
              id="link--login-need-help"
              class="text7"
              @click="$root.$emit('login.links.help:click')"
              >{{ $t("login.links.help.link") }}</a
            >
            {{ $t("login.links.help.post") }}
          </div>
        </form>
      </div>

      <FingerPrintModal
        v-if="showFingerprintModal"
        @authorize="authorize"
        @refuse="refuse"
        :type="authType"
      />
    </div>
  </div>
</template>

<style lang="scss">
.login-view {
  margin-top: rem(-56px);
  overflow-x: hidden;

  &__background {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
      position: relative;
      z-index: -1;
      object-fit: cover;
      /* Truly necessary to avoid background resizing when the keyboard is shown on mobile */
      height: var(--original-app-height);
      width: auto;
    }
  }

  &__container {
    position: absolute;
    max-width: 100% !important;
    top: rem(56px);
    left: 0;
  }

  /*IE hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .container {
      margin-top: rem(47px);
      margin-bottom: rem(47px);
    }
  }

  .form {
    padding-top: rem(100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    max-width: rem(540px);
    z-index: 2;

    .title {
      width: 100%;
      text-align: center;
      margin-bottom: rem(34px);

      @include respond-min("medium") {
        margin-bottom: rem(74px);
      }
    }

    form {
      width: rem(343px);
    }

    .text-input {
      @include setColor(--color-text);

      label {
        margin-left: 0;
        color: inherit;
      }

      input {
        border-color: none;
        background-color: transparent !important;
        border: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        @include setColor(--color-divider-negative, border-bottom-color);
        border-radius: 0;
        padding-left: rem(0px);
        color: inherit;
      }
    }

    .password.transition-input {
      margin-bottom: rem(10px);
    }

    .jp-alert {
      &.alert--error {
        @include setColor(--color-text-negative);
      }
    }
  }

  &.has-background {
    .text8 {
      @include setColor(--color-text-login);
    }

    a {
      @include setColor(--color-link);
    }

    .form {
      .title {
        @include setColor(--color-text-login);
      }

      .text-input {
        @include setColor(--color-text-login);

        input {
          @include setColor(--color-text-login, border-bottom-color);
          box-shadow: none;
        }
      }
    }
  }

  .login-button {
    @include setColor(--color-cta--login);
    @include setColor(--color-text--login, border-color);

    &:hover {
      @include setColor(--color-cta--login);
    }
  }

  @include respond-min("medium") {
    position: absolute !important;
    top: rem(56px);
    margin-top: rem(-56px) !important;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &__background-image {
      height: 100%;
      min-width: 100%;
    }

    &__container {
      top: 0;
    }

    .form {
      margin-top: rem(40px);
      margin-bottom: rem(80px);
      padding-top: 0;
    }

    .container {
      display: flex;
      max-width: 100% !important;
      height: 100%;
      justify-content: center;
      align-items: center;

      /*IE hack */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 0;
      }
    }
  }

  @include respond-to("medium") {
    .form {
      width: 100%;

      form {
        width: 90%;
      }

      .text-input input,
      a:not([href]) {
        @include setColor(--color-text-login);
      }
    }
  }

  @include respond-min("xlarge") {
    margin-top: rem(-72px);
  }
}
</style>

<script>
import cordovaMixin from "@/mixins/cordovaMixin";
import formMixin from "@/mixins/formMixin";

import { mapActions, mapMutations, mapState } from "vuex";

import { LOGIN_ACCOUNT_NOT_VERIFIED } from "@/constants/errors";

import {
  ActivityIndicator,
  Alert,
  Button,
  TextInput,
} from "@johnpaul/jp-vue-components";
import FingerPrintModal from "@/components/FingerPrintModal";
import {
  getFromSecureStorage,
  removeFromSecureStorage,
  PWD_KEY,
  USERNAME_KEY,
} from "@/utilities/cordova";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";
import { isArgon } from "@/utilities/validators";

export default {
  name: "Login",

  mixins: [cordovaMixin, formMixin],

  components: {
    ActivityIndicator,
    Alert,
    Button,
    TextInput,
    FingerPrintModal,
  },

  data() {
    return {
      username: null,
      password: null,
      hashedPwd: null,
      backgroundImage: this.$config.modules.login.options.img,
      linearGradient: this.$config.modules.login.options.linearGradient,
      originalAppHeight: window.innerHeight,
      isFingerprintOptionEnabled: this.$config.options.fingerprint,
      authType: null,
      showFingerprintModal: false,

      LOGIN_ACCOUNT_NOT_VERIFIED,

      errors: {
        username: null,
        password: null,
      },

      error: null,
      failToFetchConfig: false,
    };
  },

  props: {
    reload: { type: Boolean, default: false },
    path: { type: String, default: null },
  },

  computed: {
    ...mapState({
      loginError: state => state.login.error,
      fetching: state => state.login.fetching,
      redirect: state => state.route.query.redirect,
      verifying: state => state.auth.verifyingAccount,
      verifyError: state => state.auth.verifyAccountError,
      verifySuccess: state => state.auth.verifyAccountSuccess,
      vtoken: state => state.route.query.vtoken,
      refreshToken: state => state.auth.refreshToken,
      fingerprint: state => state.auth.fingerprint,
      redirectUrl: state => state.redirectUrl,
    }),
    canBeSubmitted() {
      return this.username && this.password;
    },
    background() {
      return !!this.$config.modules.login.options.img;
    },
    responsiveBackgroundImageSrc() {
      return getCloudinaryResponsiveUrl(this.backgroundImage);
    },
  },

  methods: {
    ...mapMutations({
      setTokens: "auth/setTokens",
      disableFingerprint: "auth/disableFingerprint",
      resetFingerprint: "auth/resetFingerprint",
      startFillingFingerprint: "auth/startFillingFingerprintAuthorization",
      stopFillingFingerprint: "auth/stopFillingFingerprintAuthorization",
    }),
    ...mapActions({
      fetchToken: "login/fetchToken",
      refreshAccessToken: "login/refreshToken",
      verifyAccount: "auth/verifyAccount",
      setRedirectUrl: "setRedirectUrl",
    }),

    checkUsername() {
      this.checkField("username", {
        required: this.$modules.login.options.username.required,
        validators: this.$modules.login.options.username.validators,
      });
    },

    checkPassword() {
      this.checkField("password", {
        required: this.$modules.login.options.password.required,
      });
    },

    async login(username, password, alreadyHashed = false) {
      const logged = await this.fetchToken({
        username,
        password,
        alreadyHashed,
      });
      this.failToFetchConfig = !logged.successfullyFetchedConfig;
      logged.success ? this.emitLoginSuccessEvents() : this.setTokens();
      return logged;
    },

    async loginWithFingerPrint() {
      getFromSecureStorage(USERNAME_KEY, username => {
        getFromSecureStorage(PWD_KEY, async pwd => {
          const result = await this.login(username, pwd, isArgon(pwd));
          if (!result.success) {
            this.error = result.error;
            removeFromSecureStorage(PWD_KEY, () =>
              removeFromSecureStorage(USERNAME_KEY, () => {
                this.resetFingerprint();
              }),
            );
          }
        });
      });
    },

    async onSubmit() {
      this.error = null;
      if (this.fetching) {
        return;
      }

      this.checkUsername();
      this.checkPassword();

      if (!this.canBeSubmitted) {
        this.error = "form.empty.error";
        this.$root.$emit("login.buttons.login:click", {
          success: false,
          error: "empty_form",
        });
        return;
      }

      if (this.errors.username) {
        this.error = "username.format.error";
        this.$root.$emit("login.buttons.login:click", {
          success: false,
          error: "bad_username_format",
        });
        return;
      }

      const { username, password } = this;
      this.startFillingFingerprint();
      const logged = await this.fetchToken({ username, password });
      this.failToFetchConfig = !logged.successfullyFetchedConfig;
      if (logged.success) {
        this.$root.$emit("login.buttons.login:click", {
          success: true,
        });
        if (
          window.cordova &&
          this.isFingerprintOptionEnabled &&
          this.fingerprint === null
        ) {
          this.hashedPwd = logged.hash;
          await this.requestFingerprintAuthorization();
        } else {
          this.stopFillingFingerprint();
          this.emitLoginSuccessEvents();
        }
      } else {
        this.stopFillingFingerprint();
        this.$root.$emit("login.buttons.login:click", {
          success: false,
          error: logged.error,
        });
        this.error = this.loginError;
      }
    },

    async requestFingerprintAuthorization() {
      try {
        const authType = await this.getAuthenticationType();
        if (authType != null) {
          this.showFingerprintModal = true;
          this.authType = authType;
        }
      } catch {
        // Device's fingerprint fast connection is not enabled or not available.
        this.stopFillingFingerprint();
        this.emitLoginSuccessEvents();
      }
    },

    async authorize() {
      await this.authorizeFingerprint({
        username: this.username,
        pwd: this.hashedPwd,
      });
      this.hideFingerprintModal();
      this.emitLoginSuccessEvents();
    },

    refuse() {
      this.disableFingerprint();
      this.hideFingerprintModal();
      this.emitLoginSuccessEvents();
    },

    emitLoginSuccessEvents() {
      let redirectUrl = this.path || this.redirectUrl;
      if (redirectUrl && redirectUrl !== "/config-failure") {
        this.$root.$emit("login.redirect:success", { path: redirectUrl });
        this.setRedirectUrl(null);
      } else {
        this.$root.$emit("login:success");
        this.setRedirectUrl(null);
      }
      if (this.failToFetchConfig) {
        this.$router.push({ name: "config-failure" });
      }
    },

    hideFingerprintModal() {
      this.showFingerprintModal = false;
      this.stopFillingFingerprint();
    },
  },

  created() {
    if (this.reload) document.location.reload();
  },

  async mounted() {
    this.error = null;

    if (this.vtoken) {
      const success = await this.verifyAccount(this.vtoken);
      if (success) this.$root.$emit("register.verification:success");
    } else {
      this.$root.$emit("login:mounted");
    }

    if (this.isFingerprintOptionEnabled && this.fingerprint) {
      getFromSecureStorage(USERNAME_KEY, async () => {
        try {
          await this.showFingerprint({
            title: this.$t(
              "common.plugin.fingerprint.login.usefingerprint.title",
            ),
            description: this.$t(
              "common.plugin.fingerprint.login.usefingerprint.text",
            ),
          }).then(async () => {
            await this.loginWithFingerPrint();
          });
        } catch {
          // fingerprint not enabled or refused
        }
      });
    }

    this.$emit("header", {
      transparent: this.$config.modules.login.options.transparentHeader,
    });
  },
};
</script>
