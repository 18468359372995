<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentTheme: null,
    };
  },

  computed: {
    ...mapGetters({
      subscription: "subscriptions/primarySubscription",
    }),
  },

  methods: {
    updateTheme() {
      const product = this.subscription.attributes.specifics["vces-product"];

      const classList = document.body.classList;
      classList.remove(this.currentTheme);
      this.currentTheme = product;
      classList.add(product);
    },
  },

  watch: {
    subscription() {
      this.updateTheme();
    },
  },

  mounted() {
    if (this.subscription) {
      this.updateTheme();
    }
  },
};
</script>
