import client from "@/plugins/jp-api-client";

import { fetchResourcesAsJson } from "@/utilities/assetsLoader";
import { getApplicationConfig } from "@/config";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    fetching: false,
    error: null,
  },

  mutations: {
    fetchStart(state) {
      state.fetching = true;
      state.error = null;
    },
    fetchSuccess(state) {
      state.fetching = false;
    },
    error(state, error) {
      state.fetching = false;
      state.error = error;
    },
  },

  actions: {
    async fetchConfig({ commit, dispatch }) {
      commit("fetchStart");

      try {
        const loggedConfigResponse = await client.content.v2.resources.get(
          process.env.VUE_APP_CONFIG_FILENAME || "config",
        );
        const completeConfig = getApplicationConfig(
          loggedConfigResponse?.data?.results?.[0]?.data,
          { platform: process.env.PLATFORM },
        );
        await dispatch("setConfig", completeConfig, { root: true });
        commit("setShouldFetchConfig", false, { root: true });
        commit("fetchSuccess");
        return { success: true };
      } catch (error) {
        commit("error", error);
        // If retrieving config failed, we load the embedded configFailure.json
        // This file contains the bare minimum to display an error page with pretty rendering
        const configFailure = fetchResourcesAsJson("/configFailure.json");
        const completeConfig = getApplicationConfig(configFailure, {
          platform: process.env.PLATFORM,
        });
        await dispatch("setConfig", completeConfig, { root: true });
        await router.push({ name: "config-failure" });
        commit("setShouldFetchConfig", true, { root: true });
        return { success: false };
      }
    },
  },
};
