var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"city-filter clearfix"},[_c('div',{staticClass:"position-relative mb-3"},[_c('TextInput',{class:['city-filter-input', _vm.hasFocus && 'has-focus'],attrs:{"id":`input--city-filter-${_vm.filter.key}`,"value":"","label":_vm.$t('common.filters.city.placeholder'),"readonly":""},nativeOn:{"click":function($event){_vm.hasFocus = true}}},[_c('template',{slot:"right"},[_c('FontIcon',{staticClass:"textinput-icon",attrs:{"name":"ui_chevron_dropdown"}})],1)],2),(_vm.hasFocus)?[_c('CityFilterLayer',{attrs:{"filter":_vm.filter,"collection":_vm.collection},on:{"option-change":function($event){return _vm.$emit('option-change', $event)},"reset":function($event){return _vm.$emit('reset')}}}),_c('a',{staticClass:"input-curtain",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();}}})]:_vm._e()],2),(_vm.collection.geolocation)?_c('div',{staticClass:"city-tag text7"},[_vm._v(" "+_vm._s(_vm.$t("common.filters.city.around-me"))+" "),_c('FontIcon',{attrs:{"name":"ui_close"},nativeOn:{"click":function($event){return _vm.$emit('option-change', {
          filter: 'geolocation',
          value: null,
        })}}})],1):_vm._e(),_vm._l((_vm.selectedOptions),function(option){return _c('div',{key:option.key,staticClass:"city-tag text7"},[_vm._v(" "+_vm._s(option.key)+" "),_c('FontIcon',{attrs:{"name":"ui_close"},nativeOn:{"click":function($event){return _vm.$emit('option-change', {
          filter: _vm.filter.key,
          option: option.key,
          value: false,
        })}}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }