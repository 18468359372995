import {
  isString,
  isEmail,
  isAllHeartistsCardNumber,
} from "@/utilities/validators";
import clonedeep from "lodash.clonedeep";
import { fetchResourcesAsJson } from "@/utilities/assetsLoader";
import Logger from "@/mixins/Logger";

const validatorFunctions = {
  isEmail,
  isAllHeartistsCardNumber,
};

export default {
  computed: {
    canBeSubmitted() {
      return (
        Object.values(this.errors).filter(error => error != null).length === 0
      );
    },
  },

  methods: {
    checkField(
      name,
      options = {
        required: false,
        validators: [],
      },
    ) {
      if (options.required) {
        this.errors[name] = !isString(this[name])
          ? this.$t("common.form.input.error.missing")
          : null;
        if (
          this.errors[name] == null &&
          options.validators &&
          options.validators.length > 0
        ) {
          const self = this;
          let valid = false;
          options.validators.forEach(function(validator) {
            let fun = Object.entries(validatorFunctions).find(
              entry => entry[0] === validator,
            )[1];
            if (fun) {
              valid |= fun(self[name]);
            }
          });

          if (!valid) {
            self.errors[name] = self.$t(
              `common.form.input.validators.${
                options.validators.length === 1
                  ? options.validators[0]
                  : "generic"
              }.error`,
            );
          }
        }
      }
    },

    async loadFormConfig(formName, params) {
      if (!formName) return;
      let formConfig;
      try {
        formConfig = await fetchResourcesAsJson(`/forms/${formName}.json`);
        const clonedForm = clonedeep(params?.moduleForm || formConfig.default);

        if (params?.i18nVariant) {
          clonedForm.i18nVariant = params.i18nVariant;
        }

        // In case of many forms in the same component, we need to manually assign value
        this[params?.componentFormName || "form"] = clonedForm;

        return clonedForm;
      } catch (e) {
        Logger.log(e.message);
      }
    },
  },
};
