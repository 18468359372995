<template>
  <section class="post-list-alternate-template">
    <InViewWrapper
      v-for="(card, index) in cards"
      :key="index"
      @in-view="$emit('component-display', index)"
    >
      <ListCard
        :src="imgSrc(card)"
        :position="index % 2 === 0 ? 'left' : 'right'"
        :class="backgroundClasses[index]"
      >
        <div class="super-title3">
          {{ card.title }}
        </div>
        <div class="text2">
          {{ card.subtitle }}
        </div>
        <div
          class="list-card-description text6"
          v-html="$t(card.description)"
        />
        <EventLink
          :id="`button--post-list-alternate--${card.id}`"
          :event="getEventFromCard(card)"
          :linkComponent="card"
          type="button"
          class="list-card-button button-primary text6"
          @mousedown.native="$emit('component-click', index)"
        />
      </ListCard>
    </InViewWrapper>
  </section>
</template>

<style lang="scss">
.post-list-alternate-template {
  .list-card {
    &-container {
      padding: rem(20px) rem(16px);
      max-width: rem(1060px);

      @include respond-min("medium") {
        padding: rem(20px) 0;
      }
    }

    &-description {
      margin-top: rem(8px);
    }

    &-button {
      width: inherit;

      @include respond-min("medium") {
        display: inline-flex;
        padding: 0 rem(40px);
      }
    }
  }
}
</style>

<script>
import { ListCard } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";
import InViewWrapper from "@/components/InViewWrapper";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  components: {
    ListCard,
    EventLink,
    InViewWrapper,
  },

  props: {
    cards: {
      type: Array,
      required: true,
    },
    startBgColor: {
      type: String,
      default: null,
    },
  },

  computed: {
    backgroundClasses() {
      const backgroundClassesArray = [];
      let isPrimaryBackground = this.startBgColor === "background1";
      this.cards.forEach(() => {
        isPrimaryBackground
          ? backgroundClassesArray.push("background1")
          : backgroundClassesArray.push("background2");
        isPrimaryBackground = !isPrimaryBackground;
      });
      return backgroundClassesArray;
    },
  },

  methods: {
    imgSrc(card) {
      return (
        getCloudinaryResponsiveUrl(
          card.medias?.[0]?.url,
          card.medias?.[0]?.type,
          true,
        ) || ""
      );
    },
    getEventFromCard(card) {
      return card.button_type === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },
  },
};
</script>
