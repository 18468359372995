<template>
  <div>
    <Block
      v-for="(block, index) in pageBlocks"
      :key="block.id"
      :block="block"
      :class="getBackgroundClass(index)"
      :startBgColor="getBackgroundClass(index)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Block from "@/modules/dynamic-content/views/Block";

export default {
  components: {
    Block,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  watch: {
    "$route.path": function() {
      this.loadComponent();
    },
  },
  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      pagesById: state => state.contentv2.pagesById,
      blocksById: state => state.contentv2.blocksById,
    }),
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),
    pageBlocks() {
      return this.pagesById[this.slug]?.results || [];
    },
    blockBackgrounds() {
      const backgroundArray = [];
      let nextIsPrimaryBackground = false;
      this.pageBlocks?.forEach(block => {
        backgroundArray.push(nextIsPrimaryBackground);
        if (block.display_type === "post-list-alternate") {
          const numberOfPost = this.blocksById[block.slug]?.count;
          nextIsPrimaryBackground =
            numberOfPost % 2 === 0
              ? nextIsPrimaryBackground
              : !nextIsPrimaryBackground;
        } else {
          nextIsPrimaryBackground = !nextIsPrimaryBackground;
        }
      });
      return backgroundArray;
    },
  },
  methods: {
    ...mapActions({
      fetchPage: "contentv2/fetchPage",
      fetchClientToken: "auth/fetchClientToken",
    }),
    async loadComponent() {
      if (!this.loggedIn) {
        await this.fetchClientToken();
      }
      await this.fetchPage(this.slug);
      this.$emit("header", { transparent: false });
      this.$root.$emit("dynamic-content.page:loaded", { slug: this.slug });
    },
    getBackgroundClass(index) {
      return this.blockBackgrounds[index] ? "background1" : "background2";
    },
  },
  async beforeMount() {
    await this.loadComponent();
  },
};
</script>
