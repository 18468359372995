<template>
  <div class="password-recover" v-if="showConfirmation">
    <h1 class="super-title3">
      {{ $t("password.recover.confirmation.title") }}
    </h1>
    <p class="recover-description text6">
      <FontIcon name="ui_done" />
      {{ $t("password.recover.confirmation.text", { email }) }}
    </p>
    <p>
      <a
        class="text5"
        id="link--password-recover-back"
        @click="$root.$emit('password.recover.confirmation.links.back:click')"
      >
        {{ $t("password.recover.confirmation.links.back") }}
      </a>
    </p>
  </div>
  <div v-else>
    <h1 class="super-title3">
      {{ $t("password.recover.title") }}
    </h1>
    <p class="recover-description text6">
      {{ $t("password.recover.text") }}
    </p>
    <DynamicForm
      v-if="form"
      :form="form"
      :buttonText="buttonText"
      :submitting="recovering"
      prefixInputId="password-recover"
      @submit="submit"
    >
      <template slot="header">
        <Alert
          variant="error"
          v-if="recoverError"
          id="alert--password-recover-error"
        >
          <span v-html="$t(`password.recover.errors.${recoverError}`)"></span>
        </Alert>
      </template>
    </DynamicForm>
  </div>
</template>

<style lang="scss">
.password-recover {
  h1 {
    @include setColor(--color-title1);
  }

  .recover-description {
    @include setColor(--color-text);
  }

  #link--password-recover-back {
    color: var(--color-link);
  }
}
</style>

<script>
import formMixin from "@/mixins/formMixin";

import DynamicForm from "@/components/DynamicForm";

import { mapState, mapActions } from "vuex";

import { Alert, FontIcon } from "@johnpaul/jp-vue-components";

export default {
  name: "RecoverPassword",

  mixins: [formMixin],

  components: {
    Alert,
    FontIcon,
    DynamicForm,
  },

  data() {
    return {
      showConfirmation: false,
      buttonText: this.$t(`password.recover.buttons.submit`),
      form: null,
    };
  },

  props: {
    username: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState({
      recovering: state => state.password.recovering,
      recoverError: state => state.password.recoverError,
    }),
    email() {
      return this.form.fields[0]?.email?.value;
    },
  },

  methods: {
    ...mapActions({
      recover: "password/recover",
    }),

    async submit() {
      const { success } = await this.recover({
        email: this.email,
      });
      this.showConfirmation = success;
      if (success) {
        this.$root.$emit("recover-password--confirmation:success");
      }
    },
  },

  async mounted() {
    await this.loadFormConfig("recoverPasswordForm");
    if (this.form.fields[0]?.email)
      this.form.fields[0].email.value = this.username;
    this.$root.$emit("recover-password:mounted");
  },
};
</script>
