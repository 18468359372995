import client from "@/plugins/jp-api-client";

import { getErrorCode } from "@/utilities/errors";
import clonedeep from "lodash.clonedeep";

export default {
  namespaced: true,

  state: {
    updating: false,
    error: null,
  },

  mutations: {
    start(state) {
      state.updating = true;
      state.error = null;
    },
    success(state) {
      state.updating = false;
    },
    error(state, error) {
      state.updating = false;
      state.error = error;
    },
  },

  actions: {
    async update({ commit, rootState }, values) {
      commit("start");

      const member = clonedeep(rootState.member.data);
      member.marketing = values;

      try {
        await client.members.update(member.id, { marketing: values }, "patch");
        commit("member/update", member, { root: true });

        commit("success");

        return { success: true };
      } catch (error) {
        commit("error", getErrorCode(error));

        return { success: false };
      }
    },
  },
};
