<template>
  <HomePartners v-bind="$props" :darkTheme="darkTheme" />
</template>

<script>
import HomePartners from "./HomePartners";
export default {
  components: {
    HomePartners,
  },

  data() {
    return {
      darkTheme: this.$config.modules.home.options.darkTheme,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>
