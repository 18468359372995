<template>
  <EventLink
    :id="`button--city-guide--${city.id}`"
    class="city-card"
    event="common.cms.link:click"
    :linkComponent="city"
  >
    <div
      class="bg-image"
      :style="{
        backgroundImage: `url(${url})`,
      }"
    />
    <div class="city-overlay" />
    <p class="city-name text3">{{ city.name }}</p>
    <p class="text8">{{ city.subname }}</p>
  </EventLink>
</template>

<style lang="scss">
.city-card {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @include setColor(--color-text-negative);
  text-transform: uppercase;
  overflow: hidden;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transform: scale(1);
    transition: 1s transform ease;
  }

  &:hover {
    text-decoration: none;
    @include setColor(--color-text-negative);

    .bg-image {
      transform: scale(1.05);
    }
  }

  p {
    margin: 0;
    z-index: 1;
  }

  .city-name {
    margin-bottom: rem(8px);
  }

  .city-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
  }
}
</style>

<script>
import inViewport from "vue-in-viewport-mixin";

import EventLink from "@/components/EventLink";

export default {
  components: { EventLink },

  mixins: [inViewport],

  props: {
    city: {
      type: Object,
      required: true,
    },
    "in-viewport-once": {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    url() {
      return this.city?.medias?.[0]?.url || null;
    },
  },

  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        this.$emit("display");
      }
    },
  },
};
</script>
