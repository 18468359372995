<template>
  <section class="tab-template">
    <div class="tab-template__description">
      <div v-if="title" class="description-title title3">
        {{ title }}
      </div>
      <div v-if="subtitle" class="description-text text6">
        {{ subtitle }}
      </div>
    </div>

    <div class="tab-template__accordions">
      <div v-for="(card, index) in cards" :key="index">
        <Accordion
          :key="componentKey"
          :title="card.title"
          :description="card.description"
          :expanded="expandedAccordions[index]"
          class="tab-template__accordion"
          @toggle="toggleAccordion(index)"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.tab-template {
  display: flex;
  flex-direction: column;
  max-width: rem(700px);
  margin: 0 auto;

  &__description {
    margin: rem(0 16px 26px);
    text-align: left;

    @include respond-min("medium") {
      margin: rem(0 auto 26px);
      text-align: center;
    }

    .description-title {
      margin-bottom: rem(11px);
    }

    .description-text {
      @include respond-min("medium") {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  &__accordions {
    margin: rem(0 16px 40px);
  }

  &__accordion {
    margin-bottom: rem(16px);

    @include respond-min("medium") {
      margin: rem(0 auto 26px);
    }
  }
}
</style>

<script>
import Accordion from "../../../components/accordion/Accordion";

export default {
  components: {
    Accordion,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    cards: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    cards: {
      handler() {
        this.collapseAll();
      },
      immediate: true,
    },
  },

  data() {
    return {
      expandedAccordions: [],
      componentKey: 0,
    };
  },

  methods: {
    collapseAll() {
      this.cards.forEach(
        (value, index) => (this.expandedAccordions[index] = false),
      );
      this.componentKey++;
    },

    toggleAccordion(expandedIndex) {
      const newValue = !this.expandedAccordions[expandedIndex];
      this.collapseAll();
      this.expandedAccordions[expandedIndex] = newValue;
      this.componentKey++;
      if (newValue) {
        this.$emit("expand-accordion", this.cards[expandedIndex]);
      }
    },
  },

  mounted() {
    this.collapseAll();
  },
};
</script>
