import { createLocalVue, mount } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import { secretQuestionPopinForm } from "./dataset";
import Vuex from "vuex";

import SecretQuestionPopin from "../index";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

function getDefaultStoreOptions() {
  return {
    state: {
      i18n: {
        locale: "fr",
      },
      login: {},
      auth: {},
      password: {
        secretQuestion: null,
        readSecretQuestionError: null,
      },
      "my-account": {
        secret: {
          updating: false,
          updated: false,
          error: null,
        },
      },
      member: {
        data: {
          someDummyData: "data",
        },
        fetching: false,
        fetchError: null,
        lastConnectionDate: null,
      },
    },
    getters: {
      "auth/loggedIn": () => {},
      "my-account/secret/successfullyUpdated": () => {},
    },
    actions: {
      "password/getSecretQuestion": () => {},
      "my-account/secret/update": () => {},
    },
  };
}

function getStore(options) {
  return new Vuex.Store({ ...getDefaultStoreOptions(), ...options });
}

export default () => {
  describe("Secret Question Popin", () => {
    let wrapper;

    describe("rendering", () => {
      it("should pop if logged and no secret question is defined", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          data() {
            return {
              noSecretDefined: true,
            };
          },
          localVue,
        });
        const popin = wrapper.find(".pop-in-layout");
        expect(popin.exists()).toBeTruthy();
      });

      it("should not pop if not logged", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => false;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          localVue,
        });
        const popin = wrapper.find(".pop-in-layout");
        expect(popin.exists()).toBe(false);
      });

      it("should not pop if logged and secret question is defined", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;
        storeOptions.state.password.secretQuestion = "secret";

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          localVue,
        });
        const popin = wrapper.find(".pop-in-layout");
        expect(popin.exists()).toBe(false);
      });

      it("should not pop if member.data is null", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;
        storeOptions.state.member.data = null;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          localVue,
        });
        const popin = wrapper.find(".pop-in-layout");
        expect(popin.exists()).toBe(false);
      });

      it("should disappear when secret question successfully updated", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;
        storeOptions.getters["my-account/secret/successfullyUpdated"] = () =>
          true;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          localVue,
        });
        const popin = wrapper.find(".pop-in-layout");
        expect(popin.exists()).toBe(false);
      });

      it("should render question field as select", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          data() {
            return {
              secretForm: secretQuestionPopinForm,
              noSecretDefined: true,
            };
          },
          localVue,
        });
        const secretQuestionField = wrapper.find(
          "#input--popin-secret-question",
        );
        expect(secretQuestionField.element.tagName).toBe("SELECT");
      });

      it("should render answer field as text-area", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          data() {
            return {
              secretForm: secretQuestionPopinForm,
              noSecretDefined: true,
            };
          },
          localVue,
        });
        const secretAnswerField = wrapper.find("#input--popin-secret-answer");
        expect(secretAnswerField.element.tagName).toBe("TEXTAREA");
      });

      it("should render password field as password input", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          data() {
            return {
              secretForm: secretQuestionPopinForm,
              noSecretDefined: true,
            };
          },
          localVue,
        });
        const passwordField = wrapper.find("#input--popin-secret-password");
        expect(passwordField.element.tagName).toBe("INPUT");
        expect(passwordField.attributes("type")).toBe("password");
      });

      it("should display an error if secret update failed", () => {
        let storeOptions = getDefaultStoreOptions();
        storeOptions.getters["auth/loggedIn"] = () => true;
        storeOptions.state["my-account"].secret.error = "unknown";

        wrapper = mount(SecretQuestionPopin, {
          store: getStore(storeOptions),
          data() {
            return {
              secretForm: secretQuestionPopinForm,
              noSecretDefined: true,
            };
          },
          localVue,
        });

        expect(wrapper.find(".popin-secret-error").exists()).toBeTruthy();
      });
    });
  });
};
