<template>
  <div>
    <div class="google-map" ref="googleMap" style="height:400px"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<style lang="scss">
.google-map * {
  color: white !important;
  font-size: 20px !important;
}
</style>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import categories from "@/constants/categories";
import pinImage from "assets/images/google-map-pin.png";

export default {
  props: {
    marker: {
      type: Object,
      required: true,
    },

    familyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      google: null,
      map: null,
    };
  },

  async mounted() {
    this.google = await GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    });
    this.initializeMap();
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;

      const map = new window.google.maps.Map(mapContainer, {
        zoom: 11,
        minZoom: 3,
        maxZoom: 15,
        center: this.marker,
        mapTypeId: this.google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
      });

      const image = {
        url: pinImage,
        size: new window.google.maps.Size(32, 41),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(16, 41),
        labelOrigin: new window.google.maps.Point(16, 18),
      };

      const categoryIcon = categories[this.familyId]?.icon;

      new window.google.maps.Marker({
        position: this.marker,
        map: map,
        icon: image,
        label: {
          fontFamily: "Categories",
          text: categoryIcon,
        },
      });
    },
  },
};
</script>
