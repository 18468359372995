import i18nInstance, { loadLanguageAsync } from "@/i18n";
import { getStore } from "@/store";

const BASE_TITLE = "common.head.title";

export default async function setTitle(to, _from, next) {
  const defaultTitle = `${BASE_TITLE}.default`;

  let titleKey;
  if (to.params?.slug) {
    titleKey = `${BASE_TITLE}.${to.params?.slug}`;
  } else {
    titleKey = to.meta?.title || defaultTitle;
  }
  const store = getStore();
  const lang = store.getters["i18n/getLocale"];
  await loadLanguageAsync(lang, store?.state?.config);
  document.title = i18nInstance.t(titleKey);
  next();
}
