<template>
  <AutocompleteInput
    :class="['address-input', showGeolocButton && 'has-geolocation']"
    :id="id"
    :value.sync="value"
    :label="label"
    :autofocus="autofocus"
    :disabled="disabled"
    :error="predictionSelected ? null : error"
    :predictions="autocompletePredictions"
    @debouncedValueChange="onValueChange"
    @clearPredictions="predictions = []"
    @select="onPredictionSelect"
    @blur="$emit('blur')"
  >
    <FontIcon
      v-if="showGeolocButton"
      :class="['geoloc-icon', pendingGeoloc && 'disabled']"
      name="ui_geoloc"
      @mousedown.native.prevent="geocode"
    />
  </AutocompleteInput>
</template>

<style lang="scss">
.address-input {
  .geoloc-icon {
    position: absolute;
    display: none;
    right: 0;
    top: 50%;
    font-size: rem(20px);
    transform: translateY(-50%);

    &.disabled {
      opacity: 0.3;
    }
  }

  @include respond-to("medium") {
    .geoloc-icon {
      display: block;
    }

    &.has-geolocation .icon-error {
      display: none !important;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";

import client from "@/plugins/jp-api-client";

import { AutocompleteInput, FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    AutocompleteInput,
    FontIcon,
  },

  data() {
    return {
      value: "",
      predictions: [],
      pendingGeoloc: false,
      lastGeocodedAddress: null,
      predictionSelected: false,
    };
  },

  props: {
    id: { type: String, required: true },
    label: { type: String, required: false },
    addressType: { type: String, default: null },
    geolocation: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: null },
  },

  computed: {
    ...mapState({
      deviceLocation: state => state.device.location,
    }),

    showGeolocButton() {
      return this.deviceLocation && this.geolocation && navigator.geolocation;
    },

    autocompletePredictions() {
      return this.predictions.map(({ attributes }) => ({
        id: attributes["place-id"],
        value: attributes.description,
      }));
    },
  },

  methods: {
    geocode() {
      if (this.deviceLocation && navigator.geolocation && !this.pendingGeoloc) {
        this.pendingGeoloc = true;

        const geolocOptions = {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 10000,
        };

        navigator.geolocation.getCurrentPosition(
          this.onBrowserGeoloc,
          this.onGeolocError,
          geolocOptions,
        );
      }
    },

    async onBrowserGeoloc(position) {
      try {
        // Fetch addresses at this position
        const { data } = await client.addresses.geocode(position.coords);

        // Remove unacurrate addresses
        const addresses = data.data.filter(
          address => address.attributes.street,
        );

        if (!addresses.length) {
          this.pendingGeoloc = false;
          return;
        }

        // Fetch address detail
        const addressData = await client.addresses.place(addresses[0].id);

        const { formatted_address } = addressData.data || {};
        this.value = formatted_address;
        this.lastGeocodedAddress = addressData.data;
        this.pendingGeoloc = false;

        this.predictions = [];
        this.predictionSelected = true;
        this.updateValue({
          ...addressData.data,
          types: [], // empty address type (could contain "establishment"...)
        });
      } catch (e) {
        this.onGeolocError();
      }
    },

    onGeolocError() {
      this.pendingGeoloc = false;
    },

    async onValueChange() {
      if (!this.predictionSelected) {
        this.$emit("update:value", this.value);
      }

      if (
        !this.value ||
        this.value === this.lastGeocodedAddress?.formatted_address
      )
        return;

      const results = await client.addresses.autocomplete({
        input: this.value,
        type: this.addressType,
      });
      this.predictions = results?.data?.data || [];
    },

    async onPredictionSelect(prediction) {
      this.predictions = [];
      this.value = prediction.value;

      this.predictionSelected = true;
      const addressData = await client.addresses.place(prediction.id);

      this.updateValue(addressData.data);
    },

    updateValue(addressData) {
      if (addressData) {
        this.$emit("update:value", addressData);
      }
    },
  },
};
</script>
