const VendorDetails = () =>
  import(/* webpackChunkName: "vendor-details" */ "./VendorDetails");

export default [
  {
    path: "/vendor/details/:id",
    name: "vendor/details",
    component: VendorDetails,
    props: true,
    meta: {
      requiresAuth: true,
      hideWidgets: true,
      template: "vendor-details",
    },
  },
];
