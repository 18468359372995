const Faq = () => import(/* webpackChunkName: "faq" */ "./views/Faq");

export default [
  {
    path: "/faq",
    name: "faq",
    component: Faq,
    meta: {
      title: "common.head.title.faq",
      template: "faq",
    },
    props: route => ({ search: route.query.q }),
  },
  {
    path: "/faq/:topic",
    name: "faq/topic",
    component: Faq,
    props: true,
    meta: {
      title: "common.head.title.faq",
      template: "faq",
    },
  },
];
