<template>
  <div class="vendor-list">
    <p class="list-title text3">{{ textComponent.name }}</p>
    <div class="list">
      <div class="cards-wrapper">
        <div
          v-for="component in mediaComponents"
          :key="component.id"
          :id="`button--list-item--${component.id}`"
          class="vendor"
          @click="createRequest(component.id)"
        >
          <ContentCard
            :src="prepareBenefit(component).url"
            :title="component.name"
            :subtitle="component.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.vendor-list {
  margin: auto;

  .list {
    width: 100%;
    overflow-x: auto;
    padding-bottom: rem(24px);
  }

  .cards-wrapper {
    display: inline-flex;
    padding-left: rem(15px);
  }

  .list-title {
    margin: rem(0 15px 15px);
    @include setColor(--color-title1);
    text-transform: uppercase;
  }

  .vendor {
    display: inline-block;
    width: rem(300px);
    min-width: rem(300px);
    margin-right: rem(15px);

    > * {
      height: 100%;
    }
  }

  @include respond-min("medium") {
    .cards-wrapper {
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: rem(40px);
    }

    .vendor {
      display: block;
      width: 32%;
      text-decoration: none;
      margin-bottom: rem(15px);
    }
  }
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { ContentCard } from "@johnpaul/jp-vue-components";
import {
  getComponentBenefitId,
  getComponentVendorId,
} from "@/utilities/tracking";
import { getComponentBookingProcess } from "@/utilities/parsers";

export default {
  components: {
    ContentCard,
  },

  props: {
    title: {
      type: String,
    },
    components: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  computed: {
    ...mapState({
      vendors: state => state.content.componentsById,
    }),
    mediaComponents() {
      return this.components.filter(c => c.type !== "text");
    },
    textComponent() {
      return this.components.find(c => c.type === "text") || {};
    },
  },

  methods: {
    ...mapActions({
      fetchComponent: "content/fetchComponent",
    }),

    prepareBenefit(benefit) {
      return {
        offerName: benefit?.offers?.[0]?.name || null,
        url: benefit?.medias?.[0]?.url || null,
        id: benefit.id,
      };
    },

    getVendor(id) {
      return this.vendors?.[id] || null;
    },

    async createRequest(id) {
      await this.fetchComponent({
        id: id,
        type: "vendor",
      });

      const vendor = this.getVendor(id);
      this.$root.$emit("mars.myAssistant:click", {
        category: vendor.family,
        subCategory: vendor.subFamily,
      });
      this.$root.$emit("vendor.details.create-request-notrack:click", {
        vendor,
        myAssistant: true,
        stepNumber: 0,
        vendorId: getComponentVendorId(this.getVendor(id)),
        benefitId: getComponentBenefitId(this.getVendor(id)),
        fulfillmentMode: getComponentBookingProcess(this.getVendor(id)),
      });
    },
  },
};
</script>
