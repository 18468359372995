<template>
  <ColumnLayout class="register">
    <template slot="left" v-if="$modules.register.options.icon">
      <div class="text-right">
        <img src="~assets/images/register/icon.svg" alt="Register" />
      </div>
    </template>
    <template slot="right" class="right">
      <router-view @resend="resendEmail" />
    </template>
  </ColumnLayout>
</template>

<style lang="scss">
.register {
  margin-bottom: rem(80px);

  .left {
    img {
      width: rem(180px);
    }
  }
}
</style>

<script>
import ColumnLayout from "@/components/layout/ColumnLayout";

import { mapMutations } from "vuex";

export default {
  name: "Register",

  components: {
    ColumnLayout,
  },

  methods: {
    ...mapMutations({
      reset: "register/reset",
    }),
    resendEmail(params) {
      this.$router.push({ name: "register/activation", params });
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
    this.reset();
  },
};
</script>
