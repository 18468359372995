// The Companion object is created in src/main.js
// This file handle all features flipping methods

export const FEATURES = {
  ATHENA: "ATHENA",
};
// Use this in query or localstorage to add/overrid a feature

// if you need to activate athena mode for example, just add SYS_FEATURE_ATHENA=true in query params or in localstorage before refresh
const SYS_FEATURE_PREFIX = "SYS_FEATURE_";

export function registerFeatures(features) {
  features = features || {};

  /** Hidden production features */

  // GET Hidden production features from localstorage
  // example, if we don't have ATHENA in the feature object and we need to test in a running app, we can use localstorage to
  // enable feature manually
  for (let key of Object.keys(features)) {
    let item = localStorage.getItem(SYS_FEATURE_PREFIX + key);
    if (item !== null) {
      features[key] = item === "true";
    }
  }

  // GET Hidden production features from query
  // example, if we don't have ATHENA in the feature object and we need to test in a running app, we can use the query url
  // FEATURE_ATHENA=true
  const params = new URLSearchParams(window.location.search);
  for (let key of Object.keys(params)) {
    if (key.startsWith(SYS_FEATURE_PREFIX)) {
      features[key.substring(SYS_FEATURE_PREFIX.length)] = params.get(key);
    }
  }

  // GET Hidden production features from localstorage
  // example, if we don't have ATHENA in the feature object and we need to test in a running app, we can use an url with
  // FEATURE_ATHENA=true
  Companion.features = features; // eslint-disable-line no-undef
}
/**
 * Check whether a feature is enabled
 * @param {string} feature
 * @returns any
 */
export function isFeatureEnabled(feature) {
  return window.Companion?.features[feature];
}
/**
 * Manually set feature,
 * Only works for boolean
 */

export function setFeatureValue(
  feature /* feature: string */,
  value /*default to true*/,
) {
  window.Companion.features[feature] = value;
}
/**
 * Shortcut to enable boolean features
 * Only works for boolean
 * @param {string} feature
 */
export function enableFeature(feature /* feature: string */) {
  setFeatureValue(feature, true);
}
