import { mount, createLocalVue } from "@vue/test-utils";
import Vuex from "vuex";
import NewsBanner from "..";
import * as dataSet from "./dataset";
import Vue from "vue";
import EventLink from "@/components/EventLink";

const localVue = createLocalVue();
localVue.use(Vuex);
let ignoredElements;

const getDefaultStore = () => ({
  state: {
    "news-banner": {
      ids: [1, 2],
    },
    auth: {},
    content: {
      pagesById: {
        news: {
          results: [
            {
              name: "Banner",
              url: "https://google.com",
              button_name: "mon bouton",
              id: 0,
              type: "banner",
              slug: "banner",
            },
          ],
        },
      },
      fetchingComponents: false,
      collections: [],
    },
  },
  mutations: {
    "news-banner/addId": () => {},
  },
  actions: {
    "auth/fetchClientToken": () => {},
    "content/fetchComponents": () => {},
    "content/fetchPage": () => {},
  },
});

const makeWrapper = ({ data, store }) =>
  mount(NewsBanner, {
    store,
    localVue,
    data,
    stubs: ["EventLink"],
  });

export default () => {
  describe("News banner", () => {
    let wrapper;
    let data = () => {
      return {
        pageId: "news",
      };
    };

    beforeEach(() => {
      ignoredElements = Vue.config.ignoredElements;
      Vue.config.ignoredElements = ["portal"];
    });
    afterEach(() => {
      Vue.config.ignoredElements = ignoredElements;
    });

    it("should display the banner if there is new news", async () => {
      let store = getDefaultStore();
      store.state.content.collections = dataSet.newsWithUrl.content.collections;

      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });

      const news = wrapper.findComponent(NewsBanner);

      expect(news.exists()).toBe(true);
    });

    it("should display a link if the news has an url", () => {
      let store = getDefaultStore();
      store.state.content.collections = dataSet.newsWithUrl.content.collections;

      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });
      const link = wrapper.find("a.news-banner-link");
      expect(link.exists()).toBe(true);
    });

    it("should display an event-link if the news has no url", () => {
      let store = getDefaultStore();
      store.state.content.collections =
        dataSet.newsWithoutUrl.content.collections;

      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });
      const link = wrapper.findComponent(EventLink);
      expect(link.exists()).toBe(true);
    });

    it("should not display a news if it has already been read", () => {
      let store = getDefaultStore();
      store.state.content.collections =
        dataSet.newsAlreadyRead.content.collections;

      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });
      const news = wrapper.find(".news-banner");
      expect(news.exists()).toBe(false);
    });
  });
};
