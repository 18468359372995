<template>
  <div v-if="component" class="my-assistant-header">
    <div
      class="img"
      :style="{
        backgroundImage: `url(${prepareBenefit(component).img})`,
      }"
    >
      <div class="image-gradient"></div>
      <p class="title title5">{{ component.name }}</p>
      <p class="text6 mb-0">{{ component.subname }}</p>

      <a href="" class="back-link" @click.prevent="back">
        <FontIcon name="ui_chevron_left" />
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.my-assistant-header {
  .img {
    width: 100%;
    height: rem(315px);
    padding-top: rem(50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    @include setColor(--color-text-negative);
    text-transform: uppercase;

    @include respond-to("medium") {
      padding: rem(15px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .image-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(160px);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.0001) 21.59%,
      #000000 132.91%
    );
  }

  .title {
    margin-bottom: 0;
    @include setColor(--color-title2-negative);
  }

  .back-link {
    position: absolute;
    top: rem(66px);
    left: rem(16px);
    text-decoration: none;

    .font-icon {
      display: block;
      width: rem(34px);
      height: rem(34px);
      padding-top: rem(5px);
      padding-left: rem(4px);
      @include setColor(--color-cta-negative);
      background: rgba(0, 0, 0, 0.5);
      font-size: rem(24px);
      border-radius: 50%;
    }

    @include respond-min("medium") {
      top: rem(94px);
      left: rem(20px);
    }
  }
}
</style>

<script>
import router from "@/router";

import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    prepareBenefit(benefit) {
      return {
        img: benefit?.medias?.[0]?.url || null,
      };
    },

    back() {
      if (window.history.length > 2) {
        router.go(-1);
      } else {
        this.$root.$emit("city.details.back.fallback:click");
      }
    },
  },
};
</script>
