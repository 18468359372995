<template>
  <div
    :class="[
      'concierge-widget',
      currentView,
      isOpen && 'is-open',
      openedFromConcierge && 'position-right',
    ]"
    v-if="loggedIn"
  >
    <div class="container concierge-container" v-if="isOpen">
      <ConciergeBreakpointError />
      <div class="row concierge-row">
        <div class="col col-12 col-md-4 concierge-col">
          <div class="content-wrapper">
            <ConciergeHeader
              :backButton="showBackButton"
              :lightBackground="currentView === 'ConciergeForm'"
              @back-click="onBackClick"
              @close-click="closeModal"
            />

            <div class="scrollable-content">
              <transition v-for="view in views" name="fade" :key="view">
                <component
                  v-if="view === currentView"
                  :is="currentView"
                  @nav-next="currentStep++"
                  @close-click="closeModal"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade-tooltip">
      <Tooltip
        :classes="['concierge__tooltip']"
        @jp-tooltip-close="closeTooltip"
        v-if="showTooltip"
      >
        <div class="text7 concierge__tooltip-title">
          {{ $t("request.concierge.bell.tooltip.title") }}
        </div>
        <div class="text8 concierge__tooltip-content">
          {{ $t("request.concierge.bell.tooltip.content") }}
        </div>
      </Tooltip>
    </transition>
    <FontIcon
      v-if="showToggleButton && !this.enabledHomeWithoutOptions"
      :name="isOpen ? 'ui_moins' : 'ui_bell'"
      class="toggle-concierge-icon"
      @click.native.stop="isOpen ? minimizeModal() : onBellClick()"
    />
  </div>
</template>

<style lang="scss">
.concierge-widget {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  z-index: 1039;
  pointer-events: none;

  @include respond-to("medium") {
    z-index: 1040;
  }

  .fade-enter-active,
  .fade-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .concierge-container {
    height: 100%;
  }

  .concierge-row {
    position: relative;
    height: 100%;
    bottom: 0;
    padding: 0;
  }

  .concierge-col {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 0;

    @include respond-to("medium") {
      width: 100%;
      left: 0;
    }
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include setColor(--color-background1, background-color);
    overflow: hidden;
    opacity: 0;
    transition: opacity 100ms linear;
    z-index: 1040;
    box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.17);
    pointer-events: inherit;

    @include respond-min("medium") {
      top: 100px;
      bottom: 80px;
      height: auto;
      border-radius: 11px;
    }

    .scrollable-content {
      height: calc(100vh - 64px);
      overflow-y: auto;
    }
  }

  &.is-open .content-wrapper {
    opacity: 1;
    pointer-events: initial;
  }

  .concierge__tooltip {
    position: absolute;
    min-height: rem(124px);
    bottom: rem(93px);
    right: rem(24px);
    left: rem(24px);
    margin: rem(24px) 0;
    max-width: none;
    z-index: 1051;

    @include respond-min("medium") {
      left: auto;
      max-width: rem(335px);
    }
  }

  .fade-tooltip-enter-active,
  .fade-tooltip-leave-active {
    position: absolute;
    transition: opacity 0.3s;
  }
  .fade-tooltip-enter,
  .fade-tooltip-leave-to {
    opacity: 0;
  }
  .concierge__tooltip-title {
    margin-right: rem(24px);
    @include setColor(--color-title1);
  }

  .concierge__tooltip-content {
    margin-top: rem(13px);
    @include setColor(--color-text);
  }

  .toggle-concierge-icon {
    position: absolute;
    width: rem(60px);
    height: rem(60px);
    bottom: rem(20px);
    right: rem(20px);
    border-radius: 50%;
    @include setColor(--color-floating-button, background-color);
    @include setColor(--color-text-negative);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(30px);
    cursor: pointer;
    pointer-events: initial;
    z-index: 1041;
  }

  @include respond-to("medium") {
    &.ConciergeConfirmation .toggle-concierge-icon {
      display: none;
    }
  }

  @include respond-min("medium") {
    &.position-right {
      .concierge-container {
        max-width: 100%;
      }

      .content-wrapper {
        right: rem(20px);
        bottom: rem(100px);
        width: rem(400px);
        max-width: rem(400px);
      }
    }
  }

  .concierge-breakpoint-error {
    display: none;
  }

  @media (min-width: map-get($breakpoints, "medium")) and (max-height: 485px) {
    z-index: 1040;

    .concierge-breakpoint-error {
      display: flex;
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { FontIcon, Tooltip } from "@johnpaul/jp-vue-components";
import { getModuleConfig } from "@/utilities/config";
import {
  findConciergeCategory,
  parseConciergeCategories,
} from "@/utilities/categories";
import { lockScroll, unlockScroll } from "@/utilities/scroll-lock";
import ConciergeBreakpointError from "./ConciergeBreakpointError";
import ConciergeConfirmation from "./ConciergeConfirmation";
import ConciergeHeader from "./ConciergeHeader";
import ConciergeHome from "./ConciergeHome";
import ConciergeFamilies from "./ConciergeFamilies";
import ConciergeForm from "./ConciergeForm";

const views = ["ConciergeFamilies", "ConciergeForm", "ConciergeConfirmation"];

export default {
  components: {
    FontIcon,
    ConciergeBreakpointError,
    ConciergeConfirmation,
    ConciergeFamilies,
    ConciergeForm,
    ConciergeHeader,
    ConciergeHome,
    Tooltip,
  },

  data() {
    const moduleConfig = getModuleConfig(this.$config, "request/concierge");

    return {
      openedFromConcierge: true,
      isOpen: false,
      currentStep: -1,
      views,
      showToggleButton: !this.$route.meta?.hideWidgets,
      categories: parseConciergeCategories(moduleConfig.options.categories),
      isTooltipClosed: false,
      isToolTip: moduleConfig.options.tooltip,
      isHomeEnabled: moduleConfig.options.home.enabled,
    };
  },

  computed: {
    ...mapState({
      firstCampaignVisit: state => state.request.firstCampaignVisit,
    }),

    ...mapGetters({
      loggedIn: "auth/loggedIn",
      firstConnectedVisit: "request/firstConnectedVisit",
    }),

    showBackButton() {
      return this.currentView !== views[0];
    },

    currentView() {
      return views[this.currentStep];
    },
    showTooltip() {
      return (
        this.showToggleButton &&
        this.isToolTip &&
        this.firstConnectedVisit &&
        !this.isTooltipClosed &&
        !this.enabledHomeWithoutOptions
      );
    },

    enabledHomeWithoutOptions() {
      const conciergeHomeConfig = getModuleConfig(
        this.$config,
        "request/concierge",
      ).options.home;

      return (
        conciergeHomeConfig.enabled &&
        !conciergeHomeConfig.data.ask.enabled &&
        !conciergeHomeConfig.data.phone.enabled &&
        !conciergeHomeConfig.data.mail.enabled &&
        !conciergeHomeConfig.data.chat.enabled
      );
    },
  },

  watch: {
    $route(to) {
      // Handle AT campaign name
      const campaignName = this.$route.query.at_campaign;
      if (campaignName) {
        this.setCampaignName(campaignName);
      }

      // Close modal if concierge was opened using external button
      if (this.isOpen && (!this.openedFromConcierge || to.meta?.hideWidgets)) {
        this.closeModal();
      }

      // hide widget
      this.showToggleButton = !to.meta?.hideWidgets;
    },
    isOpen(value) {
      if (value) {
        lockScroll();
      } else {
        unlockScroll();
      }
    },
  },

  methods: {
    ...mapMutations({
      setCampaignName: "request/setCampaignName",
      resetRequest: "request/resetRequest",
      setCategory: "request/setCategory",
      setVendor: "request/setVendor",
      setContent: "request/setContent",
    }),

    ...mapActions({
      setFirstConnectedVisit: "request/setFirstConnectedVisit",
    }),

    onBellClick() {
      this.$root.$emit("mars.bell.open:click");
      this.closeTooltip();
      this.openModal();
    },

    openModal(params = {}) {
      this.openedFromConcierge = !params.vendor || params.myAssistant;

      if (params.vendor) {
        const category = findConciergeCategory(
          this.categories,
          params.vendor.family,
          params.vendor.subfamily,
        );
        if (category) {
          this.currentStep = 1 + (this.isHomeEnabled ? 1 : 0);
          this.setCategory(category);
          if (!params.myAssistant) {
            this.setVendor(params.vendor);
            this.setContent(params.content);
          }
        }
      }

      this.isOpen = true;
      if (this.currentStep === -1) {
        this.currentStep = 0;
      }
    },

    minimizeModal() {
      this.$root.$emit("mars.bell:reduce");
      this.isOpen = false;
    },

    closeModal() {
      this.$root.$emit("mars.bell:close");
      this.resetRequest();
      this.isOpen = false;

      // unmount view to purge state
      this.currentStep = -1;
    },

    onBackClick() {
      this.currentStep--;
    },
    closeTooltip() {
      this.isTooltipClosed = true;
    },

    onMemberLogin() {
      this.isTooltipClosed = false;
      // reset campaign name
      if (!this.firstCampaignVisit) {
        this.setCampaignName(null);
      }
    },
  },

  beforeMount() {
    if (this.isHomeEnabled && !this.views.includes("ConciergeHome")) {
      this.views.unshift("ConciergeHome");
    }
  },

  mounted() {
    this.$root.$on("login:success", this.onMemberLogin);
    this.$root.$on("mars-open", this.openModal);
  },

  beforeDestroy() {
    this.$root.$off("mars-open", this.openModal);
  },
};
</script>
