<template>
  <div>
    <div class="column-layout">
      <div v-if="$slots.left" class="left">
        <slot name="left" />
      </div>
      <div class="right">
        <div class="content">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.column-layout {
  position: relative;
  margin-top: rem(100px);
  display: flex;
  flex-direction: row;
  justify-content: center;

  > .left,
  > .right {
    width: 50%;
  }

  > .left {
    padding-right: rem(60px);
    margin-right: rem(60px);
    border-right: 1px solid #d5dee7;
  }

  > .right {
    > .content {
      max-width: rem(340px);
    }
  }

  @include respond-to("medium") {
    > .left {
      display: none;
    }

    > .right {
      width: 100%;
      margin-left: rem(15px);
      margin-right: rem(15px);
    }

    .content {
      margin: auto;
    }
  }
}
</style>

<script>
export default {
  name: "ColumnLayout",
};
</script>
