import companion from "./main.js";

window.companion = companion;

const environment = process.env.VUE_APP_ENVIRONMENT || "development";
const platform =
  process.env.PLATFORM || process.env.VUE_APP_DEFAULT_PLATFORM || "android";

companion({
  debug: environment === "development",
  environment,
  platform,
  settings: {},
});
