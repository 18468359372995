export default {
  log: message => {
    // 1. Pass the css styles in an array
    const styles = ["color: black", "background: yellow"].join(";");
    window["console"]["log"](
      "%c %s",
      styles,
      typeof message === typeof "" ? message : JSON.stringify(message),
    );
  },
};
