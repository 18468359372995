<template>
  <form class="search-input" @submit.prevent="search" action="">
    <input
      v-model="searchValue"
      :placeholder="placeholder"
      id="input--search"
      class="text8"
      type="search"
      maxlength="500"
    />
    <FontIcon
      v-if="searchValue"
      name="ui_close"
      class="search-icon empty"
      @click.native="resetSearch"
    />
    <FontIcon
      name="ui_search"
      :class="['search-icon', (searchValue || searchedValue) && 'clickable']"
      @click.native="search"
    />
  </form>
</template>

<style lang="scss">
.search-input {
  position: relative;
  margin-bottom: 0;
  height: rem(40px);
  width: 100%;

  input {
    width: 100%;
    border-radius: 100px;
    height: rem(40px);
    border-width: 1px;
    border-style: solid;
    @include setColor(--color-divider, border-color);
    @include setColor(--color-background1, background-color);
    outline: none;
    padding: rem(0 70px 0 16px);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder {
      @include setColor(--color-inactive);
    }
  }

  .search-icon {
    position: absolute;
    right: rem(3px);
    top: 50%;
    transform: translateY(-50%);
    @include setColor(--color-icon1);
    font-size: rem(22px);
    transition: 200ms background-color ease;
    padding: rem(7px);
    border-radius: 100px;

    &.empty {
      right: rem(37px);
      cursor: pointer;
    }

    &.clickable {
      @include setColor(--color-cta-negative);
      @include setColor(--color-cta, background-color);
      cursor: pointer;
    }
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: { FontIcon },

  data() {
    return {
      searchValue: "",
    };
  },

  props: {
    blockItem: {
      type: Object,
      required: true,
    },
    autoRequest: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    searched: {
      type: String,
      default: "",
    },
    initialValue: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState({
      searchedValue() {
        return this.blockItem.searchValue || "";
      },
    }),
  },

  methods: {
    ...mapActions({
      fetchBlockItem: "contentv2/fetchBlockItem",
    }),

    ...mapMutations({
      setSearchValue: "contentv2/setSearchValue",
    }),

    search() {
      if (this.autoRequest) {
        if (this.searchValue === this.searchedValue) return;
        this.setSearchValue({
          blockItem: this.blockItem.id,
          value: this.searchValue,
        });
        this.fetchBlockItem({ id: this.blockItem.id, page: 1 });
      }

      this.$emit("search", this.searchValue);
    },

    resetSearch() {
      this.searchValue = "";
      if (this.searchedValue) {
        this.search();
      }
      this.$emit("resetSearch");
    },
  },

  mounted() {
    if (this.autoRequest) this.searchValue = this.searchedValue;
  },

  beforeDestroy() {
    this.setSearchValue({
      blockItem: this.blockItem.id,
      value: "",
    });
  },

  watch: {
    searched: {
      handler: function(search) {
        if (!this.autoRequest) this.searchValue = search;
      },
      immediate: true,
    },
    initialValue: {
      handler: function(value) {
        if (!this.searchValue) {
          this.searchValue = value;
        }
      },
      immediate: true,
    },
  },
};
</script>
