const Password = () =>
  import(/* webpackChunkName: "password" */ "./views/Password");
const Recover = () =>
  import(/* webpackChunkName: "password" */ "./views/Recover");
const Reset = () => import(/* webpackChunkName: "password" */ "./views/Reset");

export default {
  path: "/password",
  component: Password,
  children: [
    {
      path: ":username?",
      alias: "password/recover",
      name: "password",
      component: Recover,
      props: true,
    },
    {
      path: "reset/:token",
      name: "password/reset",
      component: Reset,
      props: true,
    },
  ],
};
