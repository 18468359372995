import { getConfig } from "@/config";

export function getBrowserCountryCode() {
  const { navigator } = window;
  const language = navigator.userLanguage || navigator.language || "";
  const configLocale =
    getConfig().options.formatLocales[language.slice(0, 2)] || "";

  const countryCode =
    language.substr(3, 4) || // If navigator.language is a complete locale
    configLocale.substr(3, 4) || // Fallback on mapped language/locale from the config
    language; // Fallback on language

  return countryCode.toUpperCase();
}
