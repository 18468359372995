/**
 *
 * @param src
 * @param options
 * @returns {HTMLScriptElement}
 */
// eslint-disable-next-line no-unused-vars
function getScriptElement(src, options) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = src;
  return script;
}

export function installATInternet({ id }) {
  let src = `https://tag.aticdn.net/${id}/smarttag.js`;
  let script = getScriptElement(src);

  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(script, s);
  document.body.appendChild(script);
  return new Promise(resolve => {
    script.onload = () => resolve();
  });
}
