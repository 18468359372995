import client from "@/plugins/jp-api-client";
import { getErrorCode } from "@/utilities/errors";

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    fetchError: null,
  },

  getters: {
    primarySubscription(state) {
      return state.data
        ? state.data.find(sub => sub.attributes["is-primary"])
        : null;
    },
    servicePhone(state) {
      return state.data
        ? state.data.find(sub => sub.attributes.specifics["service-phone"])
        : null;
    },
  },

  mutations: {
    fetchStart(state) {
      state.fetching = true;
      state.fetchError = null;
    },

    fetchError(state, errorCode) {
      state.data = null;
      state.fetching = false;
      state.fetchError = errorCode;
    },

    fetchSuccess(state, data) {
      state.data = data;
      state.fetching = false;
      state.fetchError = null;
    },

    fetchCancel(state) {
      state.fetching = false;
    },

    update(state, data) {
      state.data = data;
    },
  },

  actions: {
    async fetch({ commit, state }) {
      if (state.data) {
        return { success: true };
      }

      commit("fetchStart");
      try {
        const me = await client.members.subscriptions.get("me");
        commit("fetchSuccess", me.data.data);
        return { success: true };
      } catch (error) {
        if (client.isCancel(error)) {
          commit("fetchCancel");
        } else {
          commit("fetchError", getErrorCode(error));
        }
        return { success: false };
      }
    },
  },
};
