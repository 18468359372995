import { getStore } from "@/store";
import router from "@/router";

let autoLogoutTimeout;

export const exit = () => {
  const store = getStore();
  if (store.state.config.env.proxy) {
    store.commit("auth/revokeSession");
  } else {
    store.commit("auth/resetAccessToken");
  }
  router.push({ name: "login", params: { reload: true } });
};

export async function setAutoLogoutTimeout() {
  const store = getStore();
  if (store?.state?.config?.options?.autoLogout) {
    const autoLogoutTimer = store.state.auth.refreshTokenExpiration
      ? (store.state.auth.refreshTokenExpiration + 1) * 1000
      : null;
    if (store.getters["auth/loggedIn"]) {
      if (autoLogoutTimer) {
        if (autoLogoutTimeout) {
          window.clearTimeout(autoLogoutTimeout);
        }
        autoLogoutTimeout = window.setTimeout(async () => {
          await store.dispatch("auth/logout");
          exit();
        }, autoLogoutTimer);
      }
    }
  }
}
