<template>
  <div class="search-no-result">
    <h2 class="search-no-result__title text5">
      {{ $t("common.search.no-result.title") }}
    </h2>
    <div class="search-no-result__text text6">
      {{ noResultText }}
    </div>
    <div v-if="showConciergeLink" class="search-no-result__concierge text6">
      {{ $t("common.search.no-result.concierge.pre") }}
      <a
        id="link--no-result-open-concierge"
        class="text5"
        @click="$root.$emit(`${moduleName}.no-result.create-request:click`)"
      >
        {{ $t("common.search.no-result.concierge.link") }}
      </a>
      {{ $t("common.search.no-result.concierge.post") }}
    </div>
  </div>
</template>

<style lang="scss">
.search-no-result {
  &__title {
    margin-bottom: rem(20px);
    @include setColor(--color-title1);
  }

  &__concierge {
    margin-top: rem(16px);

    a {
      @include setColor(--color-link);
    }
  }
}
</style>

<script>
import { mapState } from "vuex";

export default {
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    collection: {
      type: Object,
      default: () => {},
    },
    showConciergeLink: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState({
      fetching: state => state.content.fetchingComponents,
    }),
    noResultText() {
      if (
        !this.collection ||
        this.collection.components?.length ||
        this.fetching
      )
        return "";

      const hasActiveFilters =
        this.collection.geolocation ||
        this.collection?.filters?.some(filter =>
          filter.options.some(option => !!option.value),
        );

      if (this.collection?.searchValue && hasActiveFilters) {
        return this.$t("common.search.no-result.filters-and-search", {
          search: this.collection.searchValue,
        });
      } else if (hasActiveFilters) {
        return this.$t("common.search.no-result.filters");
      }
      return this.$t(`${this.moduleName}.no-result.search`, {
        search: this.collection.searchValue,
      });
    },
  },
};
</script>
