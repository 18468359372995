import { mount, createLocalVue } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import Vuex from "vuex";
import MyAccount from "../views/MyAccount";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

export default config => {
  const moduleConfig = config.modules["my-account"];
  const submodules = Object.keys(moduleConfig.modules);

  describe("MyAccount.vue", () => {
    let wrapper;
    let store;

    const fullName = "test fullName";
    const primarySubscription = {
      attributes: {
        contract: "My Contract",
        "contract-number": "I-1029",
      },
    };

    beforeEach(() => {
      store = new Vuex.Store({
        state: {
          notifications: {
            updating: false,
            error: null,
          },
          password: {
            updating: false,
            error: null,
          },
        },
        getters: {
          "member/fullName": () => fullName,
          "subscriptions/primarySubscription": () => primarySubscription,
        },
      });

      wrapper = mount(MyAccount, {
        store,
        localVue,
        mocks: {
          $config: config,
          $modules: config.modules,
        },
        stubs: {
          "router-link": true,
          "router-view": true,
        },
      });
    });

    it("should mount", () => {
      expect(wrapper.vm).toBeTruthy();
    });

    it("should render member fullname", () => {
      expect(wrapper.text()).toContain(fullName);
    });

    if (config.assets.cardImage) {
      it("should render the card image", () => {
        const logo = config.assets.cardImage
          .replace("{size}", "h_69,w_auto,dpr_2")
          .replace("{name}", primarySubscription.attributes["contract-number"]);

        expect(wrapper.find(`img[src="${logo}"]`).exists()).toBe(true);
      });
    }

    submodules.forEach(submodule => {
      it(`should render a link to the ${submodule} module`, () => {
        const link = wrapper.find(`#link--my-account-${submodule}`);
        expect(link.exists()).toBe(true);
      });
    });
  });
};
