import {
  mcUnregisterUser,
  mcRegisterUser,
  mcRegisterEvents,
} from "@/modules/sfmc";
import { ulSubscribe } from "@/modules/universal-links";

export const mobilePlugin = {
  onMobileReady: () => {
    if (window.MCCordovaPlugin) mcRegisterEvents();
    if (window.universalLinks) ulSubscribe();
  },
  onLogout: () => {
    if (window.MCCordovaPlugin) mcUnregisterUser();
  },
  onLogin: (sid, callback) => {
    if (window.MCCordovaPlugin) mcRegisterUser(sid, callback);
  },
};
