<template>
  <div :class="['footer-wrapper', footerVisible && 'is-visible']">
    <LegalNav
      :class="[
        'footer-secondary-nav',
        'd-none',
        'd-xl-block',
        isLogoOffset && 'footer-secondary-nav--offset',
      ]"
    />
    <LanguageSwitcher
      v-if="locales.length > 1"
      :options="
        locales.map(language => ({
          value: language,
          text: this.$t(`common.languages.${language}`),
        }))
      "
      :locale="locale"
      @update:locale="setLocale"
    />
    <a :class="['logo', isLogoOffset && 'logo--offset']" @click="onLogoClick">
      <img :src="logo" alt="logo" />
    </a>
  </div>
</template>

<style lang="scss">
.footer-wrapper {
  position: absolute;
  top: 100%;
  width: 100%;
  height: rem(69px);
  @include setColor(--color-background4, background-color);
  display: flex;
  align-items: center;
  z-index: 2;
  flex-direction: row;

  &.is-visible {
    position: fixed;
    top: auto;
    bottom: 0;
  }

  @include respond-to("xlarge") {
    display: none;
  }

  .secondary-nav {
    flex: 1;
    padding-left: 5%;
  }

  .footer-secondary-nav {
    @include setColor(--color-text-negative);

    &--offset {
      .secondary-link {
        margin-right: rem(32px);
      }
    }
  }

  .logo {
    margin-right: 1rem;
    text-align: center;

    img {
      max-height: rem(21px);
    }

    &--offset {
      margin-right: 12rem;
    }
  }

  .language-switcher {
    width: rem(216px);
    height: rem(26px);
    margin-right: 3rem;
    @include setColor(--color-cta-negative);

    .font-icon {
      @include setColor(--color-cta-negative);
    }

    .language-select {
      position: relative;
      height: rem(21px);
      min-height: rem(21px);

      select {
        padding: 0;
        padding-left: rem(45px);
        background-color: transparent;
        border: none;
        height: auto;
        color: inherit;
      }

      &::after {
        top: rem(7px);
      }
    }
  }
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import LegalNav from "../LegalNav";
import { LanguageSwitcher } from "@johnpaul/jp-vue-components";

export default {
  name: "Footer",

  components: {
    LegalNav,
    LanguageSwitcher,
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      shouldFetchConfig: state => state.shouldFetchConfig,
    }),
    logo() {
      return this.$config.global?.footer?.logo;
    },
    locales() {
      return this.$config.options.locales;
    },

    footerVisible() {
      return this.$route.meta?.footerVisible;
    },

    isLogoOffset() {
      return this.$config.env.sid;
    },
  },

  methods: {
    ...mapActions({
      setLocale: "i18n/setLocale",
    }),

    onLogoClick() {
      this.$root.$emit("footer.logo:click");
      if (this.shouldFetchConfig) {
        this.$router.go(0);
      }
    },
  },
};
</script>
