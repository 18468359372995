import router from "@/router";

export const getFiltersFromFacets = facets =>
  facets
    ? Object.entries(facets)
        .map(([facet, options]) => {
          if (options.length < 2) return null;
          return {
            key: facet,
            options: options.map(option => ({
              global_key: option.global_key,
              key: option.key,
              value: false,
              count: option.count,
            })),
          };
        })
        .filter(Boolean)
    : [];

export const updateRouteQuery = async blockItem => {
  const currentRoutePath = router.currentRoute.path;
  const queryFilters = blockItem?.filters?.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.key]:
        filter.options
          .filter(option => option.value)
          .map(option => option.key)
          .join() || [],
    };
  }, {});
  if (blockItem?.searchValue) {
    queryFilters.search = blockItem.searchValue;
  }
  if (
    router.currentRoute.fullPath !==
    router.resolve({ path: currentRoutePath, query: queryFilters }).route
      .fullPath
  ) {
    await router.replace({
      path: currentRoutePath,
      query: queryFilters,
    });
  }
};
