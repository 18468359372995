import CATEGORIES from "@/constants/categories";

export function parseConciergeCategories(config = []) {
  return config.map(({ category, categories, ...params }) => ({
    ...(category ? CATEGORIES[category] : null),
    categories: parseConciergeCategories(categories),
    ...params,
  }));
}

function isVendorCategory(category, family, subfamily) {
  return category.familyId === family && category.subfamilyId === subfamily;
}

// Retrieve mars category from concierge categories configuration
export function findConciergeCategory(config, family, subfamily) {
  let configCategory;

  config.forEach(category => {
    configCategory = isVendorCategory(category, family, subfamily)
      ? category
      : configCategory;

    if (category.categories) {
      category.categories.forEach(subcategory => {
        configCategory = isVendorCategory(subcategory, family, subfamily)
          ? subcategory
          : configCategory;
      });
    }
  });

  if (!configCategory && subfamily) {
    return findConciergeCategory(config, family);
  }

  return configCategory;
}

export function getCategory(familyId, subfamilyId) {
  const categoryName = [familyId, subfamilyId].filter(Boolean).join("_");
  return CATEGORIES[categoryName] || CATEGORIES[familyId];
}
