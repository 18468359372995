import { getStore } from "@/store";
import { FEATURES, isFeatureEnabled } from "@/utilities/features";

export default async function redirect(to, _from, next) {
  const loggedIn = getStore().getters["auth/loggedIn"];
  const homePath = !isFeatureEnabled(FEATURES.ATHENA)
    ? { name: "home" }
    : {
        name: "page-content",
        params: {
          slug: "home",
        },
      };
  if (to.matched.some(record => record.meta.redirectAuth) && loggedIn) {
    next(homePath);
  } else {
    next();
  }
}
