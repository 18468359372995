<template>
  <div class="mosaic-layout" :class="mobileLayout">
    <div class="card-col card-large" v-if="$slots['slot-0']">
      <slot name="slot-0" />
    </div>

    <div class="card-col" v-if="$slots['slot-1']">
      <div class="card-row">
        <slot name="slot-1" />
      </div>

      <div class="card-row d-flex" v-if="$slots['slot-2']">
        <div class="card-col">
          <slot name="slot-2" />
        </div>

        <div class="card-col" v-if="$slots['slot-3']">
          <slot name="slot-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.mosaic-layout {
  position: relative;
  display: inline-flex;

  .card-row {
    display: flex;
  }

  .card-col {
    display: flex;
  }

  @include respond-min("medium") {
    padding: rem(40px 0 0);

    .card-row {
      display: flex;
      width: 100%;
    }

    .card-col {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 50%;
    }
  }

  @include respond-to("medium") {
    width: 100%;

    &.column {
      flex-direction: column;

      .card-col,
      .card-row {
        width: 100%;
        flex-direction: column;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    mobileLayout: {
      type: String,
      default: "",
    },
  },
};
</script>
