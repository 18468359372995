import { mount, createLocalVue, createWrapper } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import Vuex from "vuex";

import * as fixtures from "./fixtures";
import BenefitDetails from "../views/BenefitDetails";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

export default config => {
  if (!config.modules.benefits) return;

  function getWrapper(storeFixture = {}) {
    const store = new Vuex.Store({
      state: {
        i18n: {
          locale: "en",
        },
        content: {
          componentsById: {
            [storeFixture.id]: storeFixture,
          },
        },
      },
      actions: {
        "content/fetchComponent": () => {},
      },
    });

    return mount(BenefitDetails, {
      store,
      localVue,
      mocks: {
        $config: config,
        $modules: config.modules,
      },
      propsData: {
        id: storeFixture.id,
      },
      stubs: {
        "router-link": true,
        "router-view": true,
      },
    });
  }

  describe("when the offer has 'online' type", () => {
    let wrapper;
    beforeEach(() => {
      wrapper = getWrapper(fixtures.offerOnline);
    });

    it("should render a page with the component details", () => {
      const wrapperText = wrapper.text();
      expect(wrapperText).toContain(fixtures.offerOnline.name);
      expect(wrapperText).toContain(fixtures.offerOnline.description);
      expect(wrapperText).toContain(fixtures.offerOnline.offers[0].name);
      expect(wrapperText).toContain(fixtures.offerOnline.offers[0].description);
      expect(wrapperText).toContain(fixtures.offerOnline.offers[0].conditions);
    });

    it("should render a link to the partner's offer", () => {
      const partnerCTA = wrapper.find("#button--vendor-cta");
      expect(partnerCTA.element.href).toEqual(
        fixtures.offerOnline.offers[0].booking_processes[0].website,
      );
    });

    it("should not render vendor address", () => {
      const vendorAddress = wrapper.find("#link--vendor-google-map");
      expect(vendorAddress.exists()).toBeFalsy();
    });
  });

  describe("when the offer has 'places' type", () => {
    let wrapper;
    beforeEach(() => {
      wrapper = getWrapper(fixtures.offerPlaces);
    });

    it("should render a page with the component details", () => {
      const wrapperText = wrapper.text();
      expect(wrapperText).toContain(fixtures.offerPlaces.name);
      expect(wrapperText).toContain(fixtures.offerPlaces.description);
      expect(wrapperText).toContain(fixtures.offerPlaces.offers[0].name);
      expect(wrapperText).toContain(fixtures.offerPlaces.offers[0].conditions);
    });

    it("should render a map with a link to the address", () => {
      const map = wrapper.find(".google-map");
      expect(map.exists()).toBe(true);

      const mapLink = wrapper.find("#link--vendor-google-map");
      expect(mapLink.exists()).toBe(true);
    });

    it("should render a button to open the concierge layer", async () => {
      const rootWrapper = createWrapper(wrapper.vm.$root);
      await wrapper.find("#button--vendor-cta").trigger("click");
      expect(
        rootWrapper.emitted("vendor.details.create-request:click"),
      ).toBeTruthy();
    });
  });
};
