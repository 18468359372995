var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'jp-header',
    _vm.hasTransparentHeader && 'transparent',
    _vm.hasShadow && 'has-shadow',
    _vm.navbarOpened && 'navbar-open',
  ]},[(!_vm.shouldFetchConfig)?_c('FontIcon',{class:['menu-toggle-icon', 'd-xl-none', _vm.navbarOpened && 'd-none'],attrs:{"name":"ui_burger"},nativeOn:{"click":function($event){_vm.navbarOpened = !_vm.navbarOpened}}}):_vm._e(),_c('img',{staticClass:"logo",attrs:{"id":"link--header-link-logo","src":_vm.logo,"alt":"logo"},on:{"click":_vm.onLogoClick}}),(_vm.hasLogoBackground)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransparentHeader),expression:"!hasTransparentHeader"}],staticClass:"logo-background",style:(`background-image: url('${_vm.logoBackground}')`)}):_vm._e(),(!_vm.shouldFetchConfig)?[(_vm.loggedIn)?_c('AccountMenu',{attrs:{"transparent":_vm.hasTransparentHeader},on:{"logout":function($event){return _vm.$emit('logout')}}}):_vm._e(),_c('div',{staticClass:"menu-overlay",on:{"click":function($event){_vm.navbarOpened = false}}}),_c('div',{staticClass:"header-menu"},[_c('FontIcon',{staticClass:"close-icon d-xl-none",attrs:{"name":"ui_close"},nativeOn:{"click":function($event){_vm.navbarOpened = false}}}),_c('div',{staticClass:"scrollable-content"},[(_vm.loggedIn)?_c('AccountCard',{staticClass:"d-xl-none"}):_c('ConnectionButtons',{attrs:{"transparent":_vm.hasTransparentHeader}}),(_vm.loggedIn)?_c('PagesNav',{staticClass:"pages-nav"}):_vm._e(),_c('LegalNav',{staticClass:"header-secondary-nav d-xl-none",attrs:{"sideNav":""}}),(_vm.locales.length > 1)?_c('LanguageSwitcher',{staticClass:"language-switcher d-xl-none",attrs:{"options":_vm.locales.map(language => ({
              value: language,
              text: this.$t(`common.languages.${language}`),
            })),"locale":_vm.locale},on:{"update:locale":_vm.setLocale}}):_vm._e()],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }