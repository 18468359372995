<template>
  <div class="app-update">
    <img id="link--app-update-logo" :src="logo" class="logo" alt="logo" />
    <div class="title text1">
      {{ $t(`common.app-update.title`) }}
    </div>
    <div class="text text6">
      {{ $t(`common.app-update.text`) }}
    </div>
    <Button
      id="button--app-update"
      class="button nav-item text6"
      :url="url"
      target="_blank"
    >
      {{ $t(`common.app-update.button`) }}
    </Button>
  </div>
</template>

<style lang="scss">
.app-update {
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 rem(30px);

  .logo {
    width: 100%;
  }

  .title {
    margin-top: rem(62px);
    margin-bottom: rem(12px);
  }

  .text {
    margin-bottom: rem(61px);
  }

  .button {
    width: rem(194px);
  }
}
</style>

<script>
import { Button } from "@johnpaul/jp-vue-components";

export default {
  components: {
    Button,
  },

  data() {
    return {};
  },

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  computed: {
    logo() {
      return require(`assets/images/logo.png`);
    },
  },
};
</script>
