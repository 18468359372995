import { getConfig as getStoreConfig } from "@/store";
import { extractEnabledModules } from "@/utilities/config";
export const getConfig = getStoreConfig;

// Get environment config by platform
export const getEnvironmentConfig = (platform = "web") => {
  const envConfig = {
    i18nUrl: process.env.VUE_APP_I18N_URL,
    versionsUrl: process.env.VUE_APP_VERSIONS_URL,
  };

  if (platform === "ios") {
    envConfig.apiUrl = process.env.VUE_APP_IOS_API_URL;
    envConfig.clientId = process.env.VUE_APP_IOS_CLIENT_ID;
    envConfig.clientSecret = process.env.VUE_APP_IOS_CLIENT_SECRET;
    envConfig.proxy = process.env.VUE_APP_IOS_USE_PROXY === "true";
    envConfig.ATInternetID = process.env.VUE_APP_IOS_AT_INTERNET_ID;
  } else if (platform === "android") {
    envConfig.apiUrl = process.env.VUE_APP_ANDROID_API_URL;
    envConfig.clientId = process.env.VUE_APP_ANDROID_CLIENT_ID;
    envConfig.clientSecret = process.env.VUE_APP_ANDROID_CLIENT_SECRET;
    envConfig.proxy = process.env.VUE_APP_ANDROID_USE_PROXY === "true";
    envConfig.ATInternetID = process.env.VUE_APP_ANDROID_AT_INTERNET_ID;
  } else {
    envConfig.apiUrl = process.env.VUE_APP_WEB_API_URL;
    envConfig.clientId = process.env.VUE_APP_WEB_CLIENT_ID;
    envConfig.clientSecret = process.env.VUE_APP_WEB_CLIENT_SECRET;
    envConfig.proxy = process.env.VUE_APP_WEB_USE_PROXY === "true";
    envConfig.ATInternetID = process.env.VUE_APP_WEB_AT_INTERNET_ID;
  }

  return envConfig;
};

// Get global application config (with enabled modules only and env config)
export const getApplicationConfig = (appConfig, options) => {
  const applicationConfig = { ...appConfig };

  // Get enabled modules only
  applicationConfig.modules = extractEnabledModules(applicationConfig?.modules);

  // return base config with environment config
  return { ...applicationConfig, env: getEnvironmentConfig(options.platform) };
};
