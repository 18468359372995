import { getConfig } from "@/config";

export function isToday(date) {
  if (!date) return false;

  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function getTimeInMinutes(date = new Date()) {
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();

  return currentHours * 60 + currentMinutes;
}

export function formatDate(date, lang, options) {
  const formatLocale = getConfig().options.formatLocales[lang] || lang;
  return date.toLocaleDateString(formatLocale, options);
}
