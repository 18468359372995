<template>
  <div class="my-ongoing-requests">
    <ActivityIndicator v-if="fetchingRequests" />
    <div v-else>
      <StatusFilter
        v-if="statuses.length > 1"
        :statuses="statuses"
        :value.sync="selectedStatus"
      />
      <div v-if="filteredRequests.length === 0" class="no-request-content">
        <div class="text6 text-center">
          {{ $t("request.my-requests.ongoing.no-request") }}
        </div>

        <a
          href=""
          @click.prevent="$root.$emit('my-requests.create-request:click')"
          class="create-request-link text5"
        >
          <FontIcon class="create-request-icon" name="ui_plus" />
          <span>
            {{ $t("request.my-requests.ongoing.create-request") }}
          </span>
        </a>
      </div>

      <RequestCard
        v-else
        v-for="request in filteredRequests"
        :key="request.id"
        class="mb-2"
        :request="request"
      />
    </div>
  </div>
</template>

<style lang="scss">
.my-ongoing-requests {
  .no-request-content {
    text-align: center;
    margin-top: rem(38px);
  }

  .create-request-link {
    margin-top: rem(38px);
    display: inline-block;
    cursor: pointer;
    @include setColor(--color-text);
  }

  .create-request-icon {
    @include setColor(--color-cta, background-color);
    @include setColor(--color-text-negative);
    padding: rem(5px);
    border-radius: 50%;
    margin-right: rem(10px);
  }
}
</style>

<script>
import { mapState } from "vuex";

import { ActivityIndicator, FontIcon } from "@johnpaul/jp-vue-components";
import StatusFilter from "../components/StatusFilter";
import RequestCard from "../components/RequestCard";

// Map "request status" => "filter status"
const STATUSES = {
  submitted: "submitted",
  "in-progress": "in-progress",
  recommended: "in-progress",
  "cancellation-requested": "in-progress",
  "modification-requested": "in-progress",
  booked: "booked",
};

export default {
  components: {
    ActivityIndicator,
    FontIcon,
    RequestCard,
    StatusFilter,
  },

  data() {
    return {
      selectedStatus: null,
    };
  },

  computed: {
    ...mapState({
      requests: state => state.request.requests || [],
      fetchingRequests: state => state.request.fetchingRequests,
    }),

    ongoingRequests() {
      return this.requests.filter(request =>
        Object.keys(STATUSES).some(status => status === request.status),
      );
    },

    filteredRequests() {
      return this.ongoingRequests
        .filter(
          r =>
            !this.selectedStatus || STATUSES[r.status] === this.selectedStatus,
        )
        .sort((r1, r2) => (r1.start_date > r2.start_date ? 1 : -1));
    },

    statuses() {
      const availableStatuses = this.ongoingRequests.map(
        request => STATUSES[request.status],
      );

      // Deduplicates statuses
      return [...new Set(availableStatuses)];
    },
  },

  mounted() {
    this.$root.$emit("my-requests.ongoing:mounted");
  },
};
</script>
