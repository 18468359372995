<template>
  <section
    class="home-banner"
    :style="{ backgroundImage: `url(${imgUrl})` }"
    v-if="component"
  >
    <div class="text6 title">{{ title }}</div>
    <div v-if="subtitle" class="super-title3 subtitle">
      {{ subtitle }}
    </div>
    <div
      v-if="description"
      class="text6 home-banner__description"
      v-html="description"
    ></div>
    <EventLink
      :id="`button--home-banner--${component.id}`"
      class="see-more-banner"
      type="button"
      event="common.cms.link:click"
      :linkComponent="component"
    />
  </section>
</template>

<style lang="scss">
.home-banner {
  height: rem(380px);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 rem(16px);

  .title {
    @include setColor(--color-text-negative);
    margin-top: rem(72px);
  }

  .subtitle {
    @include setColor(--color-title1-negative);
    text-align: center;
  }

  &__description {
    max-width: rem(575px);
    @include setColor(--color-title1-negative);
    text-align: center;
    padding: 0 rem(15px);
  }

  .see-more-banner,
  .see-more-banner:hover {
    margin-top: rem(12px);
    @include setColor(--color-cta-negative, border-color);
    @include setColor(--color-cta-negative);
    border: 1px solid;
    background-color: transparent;
    max-width: rem(343px);
  }

  @include respond-min("medium") {
    height: rem(415px);
    .title {
      margin-top: 0;
    }

    .description {
      margin-top: rem(29px);
      padding: 0;
    }
  }
}
</style>

<script>
import EventLink from "@/components/EventLink";
export default {
  components: {
    EventLink,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    component() {
      return this.components?.[0] || null;
    },
    imgUrl() {
      return this.components?.[0]?.medias?.[0]?.url || "";
    },
    title() {
      return this.components?.[0]?.name || "";
    },
    subtitle() {
      return this.components?.[0]?.subname || "";
    },
    description() {
      return this.components?.[0]?.description || "";
    },
  },
};
</script>
