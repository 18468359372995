import client from "@/plugins/jp-api-client";
import { getErrorCode } from "@/utilities/errors";
import clonedeep from "lodash.clonedeep";

export default {
  namespaced: true,

  state: {
    updating: false,
    updateError: null,
    updated: false,
    status: null,
  },

  mutations: {
    updateStart(state) {
      state.updating = true;
      state.updateError = null;
      state.updated = false;
    },
    updateSuccess(state) {
      state.updating = false;
      state.updated = true;
    },
    updateError(state, updateError) {
      state.updating = false;
      state.updateError = updateError;
      state.updated = false;
    },
    setStatus(state, status) {
      state.status = status;
    },
  },

  actions: {
    async update({ commit, rootState }, attributes) {
      commit("updateStart");

      const member = clonedeep(rootState.member.data);

      try {
        for (const [key, value] of Object.entries(attributes)) {
          member[key] = value;
        }

        await client.members.update("me", member);
        commit("updateSuccess");
        commit("member/update", member, { root: true });
        return { updateSuccess: true };
      } catch (error) {
        commit("member/update", rootState.member.data, { root: true });
        commit("updateError", getErrorCode(error));
        return { updateSuccess: false };
      }
    },
  },

  persistPaths: ["onboarding.status"],
};
