export function getTrackingCategory(category) {
  return [category.familyId, category.subfamilyId].filter(Boolean).join("|");
}

export function getFilterValues(filter, key = "key") {
  if (!filter?.options?.length) return null;

  return (
    filter.options
      .map(o => o.value && o[key].replace(" ", "_"))
      .filter(Boolean)
      .join("|") || null
  );
}

export function getComponentVendorId(component) {
  const vendorPrefix = component?.object?.substring(0, 3).toUpperCase();
  return `${vendorPrefix}${component?.object_id}`;
}

export function getComponentBenefitId(component) {
  const offerId = component?.offers?.[0]?.id;
  return offerId ? `OFF${offerId}` : null;
}

const dynamicComponentPrefixes = {
  offer: "OFF",
  event: "EVE",
  editorial: "EDI",
  card: "CAR",
  vendor: "VEN",
};

export function getDynamicComponentVendorId(component) {
  const vendorId = component?.vendor?.id;
  return vendorId ? `VEN${vendorId}` : null;
}

export function getDynamicComponentId(component) {
  const componentId = component?.parent_id;
  const componentPrefix = component?.content_ct
    ? dynamicComponentPrefixes[component.content_ct]
    : "";
  return componentId ? componentPrefix + componentId : null;
}
