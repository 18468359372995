import { mount, createLocalVue } from "@vue/test-utils";
import Vuex from "vuex";
import { BootstrapVue } from "bootstrap-vue";
import NeedHelp from "../NeedHelp";
import DynamicForm from "@/components/DynamicForm";
import flushPromises from "flush-promises";
import Vue from "vue";

// à ameliorer
// ici on force le formulaire de VP pour toutes les instances de companion
import form from "./dataset/form";

import formData from "./dataset/formular_data";
import payload from "./dataset/payload";
import memberData from "./dataset/member_data";
import loggedPayload from "./dataset/logged_payload";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

let mockAssistance = jest.fn(async () => {
  return Promise.resolve();
});

jest.mock("@/plugins/jp-api-client", () => {
  const originalClient = jest.requireActual("@/plugins/jp-api-client");
  const auth = {
    ...originalClient.auth,
    assistance: async params => {
      const result = await mockAssistance(params);
      return result;
    },
  };
  return {
    ...originalClient,
    auth,
  };
});

const getDefaultStore = () => ({
  state: {
    member: {
      data: null,
    },
    route: {
      query: "",
    },
    i18n: {
      locale: "fr",
    },
  },

  actions: {
    "auth/fetchClientToken": () => {},
  },
  getters: {
    "subscriptions/primarySubscription": () => ({
      attributes: {
        specifics: {
          "last-digits": "1234",
        },
      },
    }),
    "auth/loggedIn": () => false,
  },
});

const makeWrapper = ({ data, store }) =>
  mount(NeedHelp, {
    store,
    localVue,
    data,
  });

export default config => {
  const moduleConfig = config.modules["need-help"];
  moduleConfig.form = form;

  describe("Need help", () => {
    let wrapper;
    let data = () => {
      return {
        success: false,
        form,
        submitting: false,
        error: null,
        options: moduleConfig,
      };
    };

    it("should mount and display a form", async () => {
      let store = getDefaultStore();

      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });

      await Vue.nextTick();

      const needHelpForm = wrapper.findComponent(DynamicForm);
      expect(needHelpForm.exists()).toBe(true);
    });

    it("should send the unlogged form", async () => {
      let store = getDefaultStore();
      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });

      await Vue.nextTick();

      const options = wrapper.find("#input--need-help-type").findAll("option");
      await options.at(1).setSelected();
      options.trigger("blur");
      await Vue.nextTick();
      const firstname = wrapper.find("#input--need-help-firstname");
      firstname.setValue(formData.firstname);
      firstname.trigger("blur");
      await Vue.nextTick();
      const name = wrapper.find("#input--need-help-lastname");
      name.setValue(formData.name);
      name.trigger("blur");
      await Vue.nextTick();
      const email = wrapper.find("#input--need-help-email");
      email.setValue(formData.email);
      email.trigger("blur");
      await Vue.nextTick();
      const phone = wrapper.find(
        "#input--need-help-personal-mobile > .input-phone-number > input",
      );
      phone.setValue(formData.phone);
      phone.trigger("blur");
      await Vue.nextTick();
      const lastDigit = wrapper.find("#input--need-help-lastDigits");
      lastDigit.setValue(formData.last_digit);
      lastDigit.trigger("blur");
      await Vue.nextTick();
      const msg = wrapper.find("#input--need-help-message");
      msg.setValue(formData.infos);
      msg.trigger("blur");
      await Vue.nextTick();

      const submitBtn = wrapper.find("#button--need-help-submit");
      const isDisabled = submitBtn.attributes("disabled");
      const needHelpForm = wrapper.findComponent(DynamicForm);
      needHelpForm.trigger("submit");
      expect(isDisabled).not.toBe("disabled");
      await flushPromises();
      await expect(mockAssistance.mock.calls[0]).toEqual([payload]);
    });

    it("should send the logged form with member data", async () => {
      let store = getDefaultStore();
      store.state.member.data = memberData;
      store.getters["auth/loggedIn"] = () => true;
      wrapper = makeWrapper({
        data,
        store: new Vuex.Store(store),
      });

      await Vue.nextTick();

      const options = wrapper.find("#input--need-help-type").findAll("option");
      await options.at(1).setSelected();
      options.trigger("blur");
      await Vue.nextTick();
      const firstname = wrapper.find("#input--need-help-firstname");
      firstname.setValue(formData.firstname);
      firstname.trigger("blur");
      await Vue.nextTick();
      const name = wrapper.find("#input--need-help-lastname");
      name.setValue(formData.name);
      name.trigger("blur");
      await Vue.nextTick();
      const email = wrapper.find("#input--need-help-email");
      email.setValue(formData.email);
      email.trigger("blur");
      await Vue.nextTick();
      const phone = wrapper.find(
        "#input--need-help-personal-mobile > .input-phone-number > input",
      );
      phone.setValue(formData.phone);
      phone.trigger("blur");
      await Vue.nextTick();
      const lastDigit = wrapper.find("#input--need-help-lastDigits");
      lastDigit.setValue(formData.last_digit);
      lastDigit.trigger("blur");
      await Vue.nextTick();
      const msg = wrapper.find("#input--need-help-message");
      msg.setValue(formData.infos);
      msg.trigger("blur");
      await Vue.nextTick();

      const submitBtn = wrapper.find("#button--need-help-submit");
      const isDisabled = submitBtn.attributes("disabled");
      const needHelpForm = wrapper.findComponent(DynamicForm);
      needHelpForm.trigger("submit");
      expect(isDisabled).not.toBe("disabled");
      await flushPromises();
      await expect(mockAssistance.mock.calls[1]).toEqual([loggedPayload]);
    });
  });
};
