<template>
  <section class="home-badge-banner">
    <div class="title title5">
      {{ title }}
    </div>
    <div class="jp-badges">
      <div class="jp-badge" v-for="(badge, index) in components" :key="index">
        <img class="img" :src="imgSrc(badge)" :alt="badge.name" />
        <div class="text5 content">
          {{ badge.name }}
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.home-badge-banner {
  padding: rem(28px) 0 rem(18px);
  margin: 0 auto;

  @include respond-min("medium") {
    padding: rem(62px) 0 rem(20px);
  }

  .title {
    padding-bottom: rem(47px);
    @include setColor(--color-title1);
    text-align: center;
  }

  .jp-badges {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: auto;

    @include respond-min("medium") {
      flex-wrap: nowrap;
      justify-content: center; /* IE 11 */
      justify-content: space-evenly;
    }

    .jp-badge {
      width: 100%;
      min-width: rem(250px);
      margin-bottom: rem(43px);
      text-align: center;

      @include respond-min("medium") {
        max-width: rem(350px);
      }

      .img {
        width: rem(94px);
        margin-bottom: rem(20px);
      }
      .content {
        margin: 0 rem(27px);
        @include setColor(--color-text);
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
  },

  methods: {
    imgSrc(capsule) {
      return capsule?.medias?.[0]?.url || "";
    },
  },
};
</script>
