import { getConfig } from "@/store";

import { parsePhoneNumberFromString } from "libphonenumber-js";

export function isString(value) {
  if (typeof value !== "string") {
    return false;
  } else {
    return value && value.trim() ? true : false;
  }
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmail(value) {
  return emailRegex.test(String(value).toLowerCase());
}

export function isStrongPassword(value) {
  if (!value) return false;

  const rules = getConfig().options.policies.password || null;
  if (rules === null) return true;

  for (const rule of rules) {
    const regex = new RegExp(rule.regex);
    const result = regex.test(String(value)) || false;
    if (!result) return false;
  }
  return true;
}

const visaCreditCardRegex = /\b4[0-9]{3}[ -]*[0-9]{4}[ -]*[0-9]{4}[ -]*[0-9](?:[0-9]{3})?/;

export function isVisaCreditCard(value) {
  return visaCreditCardRegex.test(String(value));
}

export function isPhoneNumber(value) {
  const phoneNumber = parsePhoneNumberFromString(value);
  return !phoneNumber || phoneNumber.isValid();
}

export function isBoolean(value) {
  return typeof value === "boolean";
}

export function isObject(value) {
  return typeof value === "object" && value !== null;
}

export function isLocationSelected(value) {
  return isObject(value);
}

export function isLastDigits(value) {
  return value.length === 4;
}

export function isAtLeastFourCharLong(value) {
  return value.match(/[a-zA-Z0-9]/g)?.length >= 4;
}

export function isAllHeartistsCardNumber(value) {
  return /^[A-Z0-9 ]+$/g.test(value);
}

const argonRegex = /^argon2\$argon2i\$v=(?:16|19)\$m=\d{1,10},t=\d{1,10},p=\d{1,3}(?:,keyid=[A-Za-z0-9+/]{0,11}(?:,data=[A-Za-z0-9+/]{0,43})?)?\$[A-Za-z0-9+/]{11,64}\$[A-Za-z0-9+/]{16,86}$/i;
export function isArgon(value) {
  return argonRegex.test(String(value));
}

export default {
  isBoolean,
  isEmail,
  isObject,
  isPhoneNumber,
  isString,
  isStrongPassword,
  isVisaCreditCard,
  isLocationSelected,
  isLastDigits,
  isAtLeastFourCharLong,
  isAllHeartistsCardNumber,
  isArgon,
};
