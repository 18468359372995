import { getStore } from "@/store";
import { setAutoLogoutTimeout } from "@/utilities/autoLogout";

export default async function checkAuth(to, _from, next) {
  let store = getStore();
  if (store.getters["auth/loggedIn"]) {
    await setAutoLogoutTimeout();
    next();
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Redirect to login page
      // If user is in login page, next methods will not update path login props.
      // So "path" will be null after user login
      store.dispatch("setRedirectUrl", to.fullPath);
      next({
        name: "login",
        params: {
          path: to.fullPath,
        },
      });
    } else {
      next();
    }
  }
}
