<template>
  <div class="interest-card text8" :class="interest.is_liked && 'selected'">
    <div class="interest-image-container">
      <img :src="interestImage" alt="Interest image" />
      <FontIcon class="interest-check" name="ui_check" />
    </div>

    {{ $t(`common.interests.${interest.family}.${interest.subfamily}`) }}
  </div>
</template>

<style lang="scss">
.interest-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: rem(100px);
  height: rem(160px);
  line-height: rem(16px);
  text-align: center;
  cursor: pointer;
  @include setColor(--color-text);

  .interest-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: rem(10px);
    width: rem(100px);
    height: rem(100px);
    border-radius: 50%;
    overflow: hidden;
    @include setColor(--color-highlight, background-color);

    img {
      border-radius: 50%;
      opacity: 1;
      transition: 150ms ease opacity;
    }

    .interest-check {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 2px;
      transform: translate(-50%, -50%);
      font-size: rem(16px);
      @include setColor(--color-text-negative);
      border-width: 2px;
      border-style: solid;
      @include setColor(--color-text-negative, border-color);
      border-radius: 50%;
    }
  }

  &.selected {
    img {
      opacity: 0.3;
    }

    .interest-check {
      display: block;
    }
  }
}
</style>

<script>
import { getModuleConfig } from "@/utilities/config";
import { interestImages } from "@/constants/interests";

import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  data() {
    const moduleConfig = getModuleConfig(this.$config, "my-account/interests");

    return {
      images: {
        ...interestImages,
        ...moduleConfig.options.images,
      },
    };
  },

  props: {
    interest: {
      type: Object,
      required: true,
    },
  },

  computed: {
    interestImage() {
      return this.images[`${this.interest.family}/${this.interest.subfamily}`];
    },
  },

  methods: {
    getImage(interest) {
      return this.images[`${interest.family}/${interest.subfamily}`];
    },
  },
};
</script>
