<template>
  <div class="concierge-confirmation">
    <div class="overlay">
      <FontIcon name="ui_check" class="check-icon" />
      <div class="text5">
        {{ $t("request.concierge.confirmation.title") }}
      </div>
      <div class="text6">
        {{ $t("request.concierge.confirmation.emailSent") }}
        {{ $t("request.concierge.confirmation.links.requests.pre") }}
        <a @click.prevent="onRequestsLinkClick" class="requests-link">{{
          $t("request.concierge.confirmation.links.requests.link")
        }}</a>

        {{ $t("request.concierge.confirmation.links.requests.post") }}
      </div>
      <Button
        id="button--concierge-confirmation-done"
        type="secondary-negative"
        @click.native="$emit('close-click')"
      >
        <span class="text6">
          {{ $t("request.concierge.confirmation.buttonLabel") }}
        </span>
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.concierge-confirmation {
  position: absolute;
  height: calc(100% + 4rem);
  width: 100%;

  .overlay {
    position: absolute;
    top: rem(-64px);
    height: 100%;
    width: 100%;
    @include setColor(--color-background3, background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text6,
  .text5 {
    max-width: 90%;
    text-align: center;
  }

  .text6 {
    margin: rem(0 50px);
    text-align: center;
    @include setColor(--color-text-negative);
  }

  .text5 {
    margin: rem(20px) auto rem(6px);
    @include setColor(--color-title2-negative);
  }

  .requests-link {
    text-decoration: underline;
    @include setColor(--color-link-negative);
  }

  .button {
    margin-top: rem(45px);
    width: rem(250px);
  }

  .check-icon {
    height: rem(70px);
    width: rem(70px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(40px);
    @include setColor(--color-text-negative);
    border-width: 3px;
    border-style: solid;
    @include setColor(--color-color-text-negative, border-color);
    border-radius: 50%;
  }
}
</style>

<script>
import { mapState } from "vuex";

import { Button, FontIcon } from "@johnpaul/jp-vue-components";

import { getTrackingCategory } from "@/utilities/tracking";

export default {
  components: { Button, FontIcon },

  computed: mapState({
    category: state => state.request.category,
    requestId: state => state.request.createdRequestId,
  }),

  methods: {
    onRequestsLinkClick() {
      this.$emit("close-click");
      this.$root.$emit("concierge.confirmation.requests.link:click");
    },
  },

  mounted() {
    this.$root.$emit("mars.confirmation:mounted", {
      category: getTrackingCategory(this.category),
      requestId: this.requestId,
    });
  },
};
</script>
