let defaultlocale = "en";

const i18nStore = {
  namespaced: true,

  state: {
    locale: defaultlocale,
  },
  getters: {
    getLocale(state) {
      return state.locale;
    },
  },

  mutations: {
    updateLocale(state, locale) {
      state.locale = locale;
    },
  },

  actions: {
    async setLocale({ commit }, locale) {
      commit("updateLocale", locale);
      document.location.reload();
    },
  },

  persistPaths: ["i18n.locale"],
};

// must only be called prior to Vuex.Store's instanciation
export function init(config) {
  const lang = navigator.language.slice(0, 2);
  if (config.options) {
    defaultlocale = config.options.locales.includes(lang)
      ? lang
      : config.options.defaultLocale;
  }

  i18nStore.state.locale = defaultlocale;
}

export default i18nStore;
