const NeedHelp = () => import(/* webpackChunkName: "need-help" */ "./NeedHelp");

export default {
  path: "/need-help",
  name: "need-help",
  component: NeedHelp,
  meta: {
    title: "common.head.title.need-help",
  },
};
