<template>
  <component
    v-if="textComponent && mediaComponent"
    :is="blockTemplate"
    :id="details.slug"
    :title="title"
    :description="description"
    :image-url="imageUrl"
    :is-mobile="isMobile"
    :is-mobile-app="isMobileApp"
    :full-name="fullName"
    :company="company"
    :end-date="endDate"
    :externalId="externalId"
    :locale="locale"
    :is-fetching-pass="fetching"
    :adding-pass-error="addingPassToWalletError"
    @submitPdfPassForm="downloadPdf"
    @submitPkPassForm="downloadPkPass"
  />
</template>

<script>
import { BLOCK_TEMPLATE } from "../../blocks";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { isMobile } from "@/utilities/responsive";
import { addPassToAppleWallet, saveFile } from "@/utilities/cordova";
import client from "@/plugins/jp-api-client";
import CardMemberTemplate from "../../templates/blocks/card-member/CardMemberTemplate";

export default {
  components: {
    CardMemberTemplate,
  },

  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    isMobileApp: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      accessToken: state => state.auth.accessToken,
      fetching: state => state.passes.fetching,
      pdfData: state => state.passes.pdfData,
      pkPassData: state => state.passes.pkPassData,
      addingPassToWalletError: state => state.passes.addingPassToWalletError,
      member: state => state.member.data,
    }),
    ...mapGetters({
      fullName: "member/fullName",
      subscription: "subscriptions/primarySubscription",
    }),
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || CardMemberTemplate;
    },
    textComponent() {
      return this.components.find(component => component?.type === "text");
    },
    mediaComponent() {
      return this.components.find(component => component?.type === "media");
    },
    title() {
      return this.textComponent?.title;
    },
    description() {
      return this.textComponent?.description;
    },
    imageUrl() {
      return this.mediaComponent?.medias?.[0]?.url || "";
    },
    company() {
      return this.member?.organization?.name || "";
    },
    endDate() {
      return this.subscription.attributes["end-date"];
    },
    externalId() {
      return this.subscription.attributes.specifics["subscription-external-id"];
    },
    isMobile,
  },

  methods: {
    ...mapActions({
      fetchPdf: "passes/fetchPdf",
      fetchPkPass: "passes/fetchPkPass",
    }),
    ...mapMutations({
      resetAddingPassToWalletError: "passes/resetAddingPassToWalletError",
    }),
    async downloadPdf(formEvent) {
      if (this.isMobileApp) {
        if (
          window?.cordova?.platformId === "android" &&
          window.cordova?.file &&
          window.cordova?.plugins?.fileOpener2
        ) {
          await this.getMobilePdf(formEvent);
          this.trackClick("Download_Pdf_Mobile_APP");
        }
      } else {
        await this.getPdf(formEvent);
        this.trackClick("Download_Pdf");
      }
    },
    async downloadPkPass(formEvent) {
      if (this.isMobileApp) {
        await this.getMobilePkPass(formEvent);
        this.trackClick("Download_Pass_Mobile_APP");
      } else {
        await this.getPkPass(formEvent);
        this.trackClick("Download_Pass");
      }
    },
    async getMobilePdf(formEvent) {
      formEvent.preventDefault();
      const pdfName = "pass.pdf";

      await this.fetchPdf();
      saveFile(pdfName, this.pdfData, false);
    },
    async getMobilePkPass(formEvent) {
      formEvent.preventDefault();
      await this.fetchPkPass();
      addPassToAppleWallet(this.pkPassData);
    },
    async getPdf(formEvent) {
      formEvent.target.action = client.passes.pdfFormTarget();
      formEvent.target.elements.token.value = `Bearer ${this.accessToken}`;
    },
    async getPkPass(formEvent) {
      formEvent.target.action = client.passes.pkPassFormTarget();
      formEvent.target.elements.token.value = `Bearer ${this.accessToken}`;
    },
    trackClick(name) {
      this.$root.$emit("dynamic-content.component-action:click", {
        name: name,
        pageSlug: this.$router.currentRoute.path?.replace("/", ""),
        blockSlug: "card-member",
        blockDisplayType: "card-member",
      });
    },
  },
  mounted() {
    this.resetAddingPassToWalletError();
  },
};
</script>
