const Experiences = () =>
  import(/* webpackChunkName: "experiences" */ "./views/Experiences");

const ExperienceDetails = () =>
  import(/* webpackChunkName: "experiences" */ "./views/ExperienceDetails");

export default [
  {
    path: "/experiences",
    name: "experiences",
    component: Experiences,
    meta: {
      requiresAuth: true,
      title: "common.head.title.experiences",
      template: "inspirations",
    },
  },
  {
    path: "/experiences/details/:id",
    name: "experiences/details",
    component: ExperienceDetails,
    props: true,
    meta: {
      requiresAuth: true,
      hideWidgets: true,
      template: "inspiration-details",
    },
  },
];
