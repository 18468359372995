import client from "@/plugins/jp-api-client";

import { getSecretError } from "@/utilities/errors";
import { slugifyText } from "@/utilities/transforms";

export default {
  namespaced: true,

  state: {
    updating: false,
    error: null,
    updated: false,
  },

  getters: {
    successfullyUpdated(state) {
      return !!state.updated;
    },
  },

  mutations: {
    reset(state) {
      state.updating = false;
      state.error = null;
      state.updated = false;
    },
    start(state) {
      state.updating = true;
      state.error = null;
      state.updated = false;
    },
    success(state) {
      state.updating = false;
      state.updated = true;
    },
    error(state, error) {
      state.updating = false;
      state.error = error;
      state.updated = false;
    },
  },

  actions: {
    async update({ commit, rootGetters }, attributes) {
      commit("start");

      try {
        await client.members.secret.update({
          username: rootGetters["member/username"],
          password: attributes.password,
          secretQuestion: attributes.secret_question,
          secretAnswer: slugifyText(attributes.secret_answer),
        });
        commit("success");
        return { success: true };
      } catch (error) {
        commit("error", getSecretError(error));
        return { success: false };
      }
    },
  },
};
