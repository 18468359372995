<template>
  <div v-if="category" class="request-card">
    <CancellationLayer
      v-if="showCancellationLayer"
      :name="request.subject"
      :loading="cancellingRequest"
      @cancel="showCancellationLayer = false"
      @submit="onCancelSubmit"
    />
    <ConfirmationLayer
      v-if="showConfirmation"
      :message="confirmationMessage"
      @close="showConfirmation = false"
    />
    <Alert
      v-if="errorMessage"
      variant="error"
      class="alert-message"
      id="alert--request-error"
      @click.native="errorMessage = false"
    >
      {{ errorMessage }}
      <FontIcon class="error-icon" name="ui_close" />
    </Alert>

    <div class="request-header">
      <FontIcon
        :class="`status-icon status-${request.status}`"
        :name="statusIcon"
      />
      <span class="status-label text8">
        {{ $t(`request.my-requests.status.${request.status}`) }}
      </span>

      <RequestCardMenu
        v-if="hasRequestMenu"
        class="request-card-menu"
        :id="request.id"
        @cancel="
          showCancellationLayer = true;
          showUpdateForm = false;
        "
        @update="showUpdateForm = true"
      />
    </div>

    <div class="request-content">
      <div class="request-category">
        <FontIcon class="category-icon" :name="category.icon" />
        <span class="request-subject text5">{{ request.subject }} </span>
        <span class="request-date text8"> {{ formatedDate }} </span>
      </div>
      <div class="request-detail">
        <RequestCriteria
          v-for="criteria in requestMask"
          :class="[criteria.inline && 'inline-criteria']"
          :key="criteria.apiKey || criteria.type"
          :maskCriteria="criteria"
          :request="request"
          :locale="locale"
        />

        <UpdateForm
          v-if="showUpdateForm"
          class="mt-4"
          :pending="updatingRequest"
          @submit="onUpdateSubmit"
          @cancel="showUpdateForm = false"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.request-card {
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.09);

  .alert-message {
    position: fixed;
    top: rem(56px);
    left: 0;
    width: 100%;
    z-index: 10000;
    border-radius: 0;

    @include respond-min("medium") {
      top: rem(72px);
      max-width: rem(900px);
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
    }

    .error-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(10px);
      font-size: rem(16px);
    }
  }

  .request-header {
    position: relative;
    height: rem(38px);
    display: flex;
    align-items: center;
    padding: rem(0 5px);
    margin: rem(0 9px);
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    @include setColor(--color-divider, border-bottom-color);

    .status-icon {
      font-size: rem(14px);
      margin: rem(0 7px 0 5px);

      &.status-submitted {
        font-size: 12px;
        padding: 1px;
        border-radius: 50%;
        @include setColor(--color-inactive, background-color);
        @include setColor(--color-text-negative);
      }
      &.status-cancellation-requested,
      &.status-modification-requested,
      &.status-in-progress,
      &.status-recommended {
        @include setColor(--color-status-warning);
      }
      &.status-booked {
        @include setColor(--color-status-success);
      }
      &.status-cancelled {
        @include setColor(--color-status-error);
      }
      &.status-closed {
        @include setColor(--color-inactive);
      }
    }

    .status-label {
      @include setColor(--color-text);
    }

    .request-card-menu {
      position: absolute;
      right: rem(5px);
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .request-content {
    @include setColor(--color-text);

    .request-category {
      height: rem(45px);
      margin: rem(8px);
      display: flex;
      align-items: center;

      .text5 {
        font-size: rem(14px);
      }
    }

    .request-subject {
      @include setColor(--color-title1);
      padding-right: rem(4px);
    }

    .request-date {
      @include setColor(--color-title1);
    }

    .category-icon {
      font-size: 28px;
      padding: 8px;
      margin-right: rem(13px);
      border-radius: 50%;
      box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.09);
      @include setColor(--color-cta);
    }

    .request-detail {
      padding: rem(0 9px 16px);
    }
  }

  @include respond-min("medium") {
    .inline-criteria {
      display: inline-block;

      & + .inline-criteria {
        margin-left: rem(15px);
        padding-left: rem(12px);
        border-left-width: 1px;
        border-left-style: solid;
        @include setColor(--color-divider, border-left-color);
      }
    }
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { Alert, FontIcon } from "@johnpaul/jp-vue-components";
import CancellationLayer from "./CancellationLayer";
import ConfirmationLayer from "./ConfirmationLayer";
import UpdateForm from "./UpdateForm";
import RequestCardMenu from "./RequestCardMenu";
import RequestCriteria from "./RequestCriteria";
import moment from "moment";
import { formatDate } from "@/utilities/dates";

import { getCategory } from "@/utilities/categories";
import { getMask } from "../utilities/requestMasks";

const STATUS_ICONS = {
  default: "ui_more",
  submitted: "ui_more",
  "in-progress": "ui_status_pending",
  recommended: "ui_status_pending",
  booked: "ui_status_validation",
  closed: "ui_status_validation",
  cancelled: "ui_status_cancelled",
  "cancellation-requested": "ui_status_pending",
  "modification-requested": "ui_status_pending",
};

export default {
  components: {
    Alert,
    FontIcon,
    CancellationLayer,
    ConfirmationLayer,
    UpdateForm,
    RequestCardMenu,
    RequestCriteria,
  },

  data() {
    return {
      showCancellationLayer: false,
      showUpdateForm: false,
      showConfirmation: false,
      errorMessage: false,
      confirmationMessage: null,
    };
  },

  props: {
    request: {
      type: Object,
      required: true,
    },
    hasRequestMenu: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      cancellingRequest: state => state.request.cancellingRequest,
      updatingRequest: state => state.request.updatingRequest,
    }),

    statusIcon() {
      return STATUS_ICONS[this.request.status] || STATUS_ICONS.default;
    },

    category() {
      return getCategory(this.request.family, this.request.subfamily);
    },

    requestMask() {
      return getMask(this.request.family, this.request.subfamily);
    },
    formatedDate() {
      const date = moment(this.request.created_date);
      const time = moment(date).format("HH:mm");
      const dateValue = formatDate(date.toDate(), this.locale);
      let result = this.$t("request.my-requests.requested-at").replace(
        "{{date}}",
        dateValue,
      );
      result = result.replace("{{time}}", time);
      return result;
    },
  },

  methods: {
    ...mapActions({
      cancelRequest: "request/cancelRequest",
      updateRequest: "request/updateRequest",
    }),

    ...mapMutations({
      resetErrors: "request/resetErrors",
    }),

    async onCancelSubmit() {
      this.$root.$emit("my-requests.cancel:submit", {
        requestId: this.request.id,
      });

      const { success } = await this.cancelRequest({
        request: this.request,
      });

      this.showCancellationLayer = false;
      if (success) {
        this.showConfirmation = true;
        this.confirmationMessage = this.$t(
          "request.my-requests.cancellation.confirmation.message",
        );
      } else {
        this.errorMessage = this.$t("request.my-requests.cancellation.error");
      }
    },

    async onUpdateSubmit(message) {
      this.$root.$emit("my-requests.update:submit", {
        requestId: this.request.id,
      });
      const { success } = await this.updateRequest({
        request: this.request,
        message,
      });

      this.showUpdateForm = false;
      if (success) {
        this.showConfirmation = true;
        this.confirmationMessage = this.$t(
          "request.my-requests.modification.confirmation.message",
        );
      } else {
        this.errorMessage = this.$t("request.my-requests.modification.error");
      }
    },
  },
};
</script>
