<template>
  <div
    :class="[
      'block-item-filters',
      visible && 'visible',
      enabledFilters.length && 'filters',
      showSearch && 'search',
    ]"
  >
    <b-container>
      <b-row>
        <b-col class="filters-row">
          <div v-if="filters.length" class="desktop-filters">
            <FilterDropdown
              v-for="filter in filters"
              :key="filter.key"
              :filter="filter"
              :collection="blockItem"
              @option-change="onOptionChange"
              @reset="resetFilters(filter.key)"
              @close="trackPage"
            />
            <span
              :class="[
                'desktop-filters-reset',
                'text7',
                showResetButton && 'visible',
              ]"
              @click="resetFilters()"
            >
              {{ $t("common.filters.reset-all") }}
            </span>
          </div>

          <SearchInput
            v-if="showSearch"
            :block-item="blockItem"
            :placeholder="
              $t('common.dynamic-content.filters.search.placeholder')
            "
            :initial-value="blockItem.searchValue"
            @search="onSearch"
            @resetSearch="onResetSearch"
          />

          <FilterButton
            v-if="enabledFilters.length && filters.length"
            :class="['filters-button', nbValues && 'active']"
            @click.native="
              showOverlay = true;
              trackFilters();
            "
          >
            <FontIcon name="ui_filter" />
            {{ $t("common.filters.toggle-button.label") }} {{ nbValues || "" }}
          </FilterButton>

          <portal v-if="showOverlay" to="portal-root">
            <FiltersLayer
              :collection="blockItem"
              :filters="filters"
              @option-change="onOptionChange"
              @close="
                showOverlay = false;
                trackPage();
              "
              @reset="resetFilters($event)"
            />
          </portal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.block-item-filters {
  position: -webkit-sticky;
  position: sticky;
  top: rem(56px);

  @include respond-min("xlarge") {
    top: rem(72px);
  }

  width: 100%;
  opacity: 0;
  z-index: 1;
  transition: 200ms opacity ease;
  @include setColor(--color-background2, background-color);

  &.visible {
    opacity: 1;
  }

  @include respond-min("medium") {
    opacity: 1;
  }

  .filters-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(57px);
  }

  .desktop-filters {
    display: none;
  }

  .filters-button {
    margin-left: rem(5px);
    white-space: nowrap;

    @include respond-min("medium") {
      display: none;
    }

    .font-icon {
      font-size: rem(16px);
      margin-right: rem(5px);
    }
  }

  @include respond-min("medium") {
    &.search .filters-row {
      justify-content: flex-end;
    }

    &.filters .filters-row {
      justify-content: flex-start;
    }

    &.search .search-input {
      max-width: rem(300px);
    }

    &.filters.search {
      .filters-row {
        justify-content: space-between;
      }
    }

    .desktop-filters {
      display: flex;
      align-items: center;
    }

    .desktop-filters-reset {
      margin-left: rem(6px);
      @include setColor(--color-cta);
      opacity: 0;
      transition: 150ms opacity ease;
      cursor: pointer;

      &.visible {
        opacity: 1;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { FontIcon } from "@johnpaul/jp-vue-components";
import { getFilterValues } from "@/utilities/tracking";
import { updateRouteQuery } from "@/utilities/filters";
import scrollMixin from "@/mixins/scrollMixin";
import FilterButton from "@/components/filters/FilterButton";
import FilterDropdown from "@/components/filters/FilterDropdown";
import FiltersLayer from "@/components/filters/FiltersLayer";
import SearchInput from "./SearchInput";
import qs from "qs";

export default {
  components: {
    FilterButton,
    FilterDropdown,
    FiltersLayer,
    FontIcon,
    SearchInput,
  },

  mixins: [scrollMixin],

  data() {
    return {
      visible: true,
      showOverlay: false,
      waitingForParsing: true,
      initialPageToFetch: 1,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    enabledFilters: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      blockItemsById: state => state.contentv2.blockItemsById,
      fetchingBlockItem: state => state.contentv2.fetchingBlockItem,
    }),

    blockItem() {
      return this.blockItemsById[this.id];
    },

    filters() {
      if (!this.blockItem?.filters) return [];

      return this.enabledFilters
        .map(filterName => {
          return this.blockItem.filters.find(f => f.key === filterName);
        })
        .filter(Boolean);
    },

    trackingCategories() {
      const category = this.blockItem?.filters?.find(f => f.key === "family");
      return getFilterValues(category);
    },

    trackingCities() {
      const city = this.blockItem?.filters?.find(f => f.key === "city");
      return getFilterValues(city, "global_key");
    },

    showResetButton() {
      return (
        this.blockItem?.geolocation ||
        this.filters.some(filter => filter.options.some(option => option.value))
      );
    },

    nbValues() {
      let count = 0;
      this.filters.forEach(filter =>
        filter.options.forEach(option => {
          if (option.value) count++;
        }),
      );

      if (this.blockItem?.geolocation) {
        count++;
      }

      return count;
    },
  },

  watch: {
    scrollY(newValue, previousValue) {
      this.visible = newValue <= 30 || newValue < previousValue;
    },
  },

  methods: {
    ...mapActions({
      fetchBlockItem: "contentv2/fetchBlockItem",
    }),

    ...mapMutations({
      resetFilterValues: "contentv2/resetFilterValues",
      setFilterValue: "contentv2/setFilterValue",
      setSearchValue: "contentv2/setSearchValue",
    }),

    async onSearch() {
      if (this.blockItem?.searchValue) {
        this.$root.$emit("dynamic-content.list.search:submit", {
          category: this.trackingCategories,
          city: this.trackingCities,
        });
      }
      this.trackPage();
      if (!this.waitingForParsing) {
        await this.updateQuery();
      }
    },

    trackOption(filter, option, value) {
      if (value && option) {
        this.$root.$emit(`dynamic-content.list.filters.${filter}:select`, {
          option: option.replace(" ", "_"),
        });
      }
    },

    trackFilters() {
      this.$root.$emit("dynamic-content.list.filters:open");
    },

    trackPage() {
      if (this.blockItem?.searchValue) {
        this.$root.$emit("dynamic-content.list.search:mounted", {
          chapter2: "search",
        });
      } else {
        this.$root.$emit("dynamic-content.list.all:mounted", {
          category: this.trackingCategories,
          city: this.trackingCities,
        });
      }
    },

    async resetFilters(filter, updateContent = true) {
      if (!filter || filter === "city") {
        // reset geolocation
        this.setFilterValue({
          blockItem: this.id,
          filter: "geolocation",
          value: null,
        });
      }
      this.resetFilterValues({ blockItem: this.id, filter });
      if (updateContent) {
        await this.fetchBlockItem({ id: this.id, page: 1 });
        await this.updateQuery();
      }
    },

    async onResetSearch() {
      await this.fetchBlockItem({ id: this.id, page: 1 });
    },

    async onOptionChange({ filter, option, value }) {
      this.trackOption(filter, option, value);
      this.setFilterValue({
        blockItem: this.id,
        filter,
        option,
        value,
      });
      if (!this.waitingForParsing) {
        await this.fetchBlockItem({ id: this.id, page: 1 });
        await this.updateQuery();
      }
    },

    async updateQuery() {
      await updateRouteQuery(this.blockItem);
    },

    parseQueryFilters() {
      const parsedQuery = qs.parse(this.$route.query);
      const queryEntries = Object.entries(parsedQuery);
      const searchValue = queryEntries.find(
        queryItem => queryItem[0] === "search",
      )?.[1];
      const pageValue = queryEntries.find(
        queryItem => queryItem[0] === "page",
      )?.[1];
      if (searchValue) {
        this.setSearchValue({
          blockItem: this.id,
          value: searchValue,
        });
        queryEntries.splice(
          queryEntries.findIndex(queryItem => queryItem[0] === "search"),
          1,
        );
      }
      if (pageValue) {
        this.initialPageToFetch = pageValue;
        queryEntries.splice(
          queryEntries.findIndex(queryItem => queryItem[0] === "page"),
          1,
        );
      }
      for (const [key, values] of queryEntries) {
        const filterToUpdate = this.blockItem.filters.find(
          filter => filter.key === key,
        );
        if (filterToUpdate?.options) {
          filterToUpdate.options?.map(option => {
            if (values.includes(option.key)) {
              this.setFilterValue({
                blockItem: this.id,
                filter: key,
                option: option.key,
                value: true,
              });
            }
          });
        }
      }
    },
  },

  async mounted() {
    this.trackPage();
    this.parseQueryFilters();
    this.waitingForParsing = false;
    await this.fetchBlockItem({ id: this.id, page: this.initialPageToFetch });
  },

  beforeDestroy() {
    this.blockItem.filters.forEach(filter => {
      this.resetFilters(filter.key, false);
    });
  },
};
</script>
