const MyVehicle = () =>
  import(/* webpackChunkName: "my-vehicle" */ "./MyVehicle");

export default {
  path: "/my-vehicle",
  name: "my-vehicle",
  component: MyVehicle,
  meta: {
    title: "common.head.title.my-vehicle",
    requiresAuth: true,
    template: "my-vehicle",
  },
};
