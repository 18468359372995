<template>
  <div class="request-card-menu">
    <a :id="`tooltip-${id}`" class="tooltip-button" tabindex="0" @click.prevent>
      <FontIcon name="ui_more" class="request-menu-icon" />
    </a>

    <b-tooltip
      :target="`tooltip-${id}`"
      custom-class="request-tooltip-menu"
      triggers="focus"
      placement="topleft"
      delay="0"
      offset="40"
      ref="tooltip"
    >
      <div @click="closeTooltip">
        <div class="request-tooltip-title text7">
          {{ $t("request.my-requests.menu.actions") }}
        </div>

        <a @click="$emit('update')" class="text8 request-tooltip-link">
          <FontIcon class="link-icon" name="ui_edit" />
          {{ $t("request.my-requests.menu.request-modification") }}
        </a>
        <a @click="$emit('cancel')" class="text8 request-tooltip-link">
          <FontIcon class="link-icon" name="ui_close" />
          {{ $t("request.my-requests.menu.request-cancellation") }}
        </a>
      </div>
    </b-tooltip>
  </div>
</template>

<style lang="scss">
.request-card-menu {
  .tooltip-button {
    position: relative;
    text-decoration: none;
    outline: none;
  }

  .request-menu-icon {
    @include setColor(--color-cta);
  }
}

.request-tooltip-menu {
  &.bs-tooltip-bottom {
    margin-top: rem(24px);
  }

  .tooltip-inner {
    opacity: 1;
    padding: rem(10px 12px 10px 15px);

    .link-icon {
      font-size: rem(16px);
      margin-right: rem(16px);
    }

    .request-tooltip-title {
      text-align: left;
      margin-bottom: rem(20px);
      @include setColor(--color-text-negative);
    }

    .request-tooltip-link {
      display: flex;
      text-align: left;
      align-items: center;
      margin-bottom: rem(8px);
      text-decoration: none;
      @include setColor(--color-text-negative);
    }
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeTooltip() {
      this.$refs.tooltip.$emit("close");
    },
  },
};
</script>
