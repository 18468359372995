<template>
  <component
    :is="blockTemplate"
    :queryBlockItems="queryBlockItems"
    :title="title"
    @component-display="trackDisplay"
    @component-click="trackClick"
  />
</template>

<script>
import { mapState } from "vuex";
import {
  getDynamicComponentId,
  getDynamicComponentVendorId,
  getFilterValues,
} from "@/utilities/tracking";
import { ActivityIndicator } from "@johnpaul/jp-vue-components";
import { BLOCK_TEMPLATE } from "../../blocks";
import OfferCardLink from "@/components/OfferCardLink";
import ListTemplate from "../../templates/blocks/list/ListTemplate";

export default {
  components: {
    ActivityIndicator,
    OfferCardLink,
  },
  props: {
    queryBlockItems: {
      type: Array,
      required: true,
    },
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      blockItemsById: state => state.contentv2.blockItemsById,
      memberId: state => state.member.data?.id || null,
    }),
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || ListTemplate;
    },
    textComponent() {
      return this.simpleBlockItems
        .map(simpleBlockItem => simpleBlockItem.components?.[0])
        .find(component => component?.type === "text");
    },
    title() {
      return this.textComponent
        ? this.textComponent.title
        : this.$t(`common.${this.details.page_slug}.title`);
    },
  },
  methods: {
    getPublisherParams(idx) {
      const blockItemId = this.queryBlockItems[0]?.id;
      const category = this.blockItemsById[blockItemId]?.filters?.find(
        f => f.key === "family",
      );
      const component = this.blockItemsById[blockItemId]?.components?.[idx];
      return {
        pageSlug: this.$router.currentRoute.path?.replace("/", ""),
        category: getFilterValues(category),
        memberId: this.memberId,
        nbItems: this.blockItemsById[blockItemId]?.count,
        contentId: getDynamicComponentId(component),
        positionItem: idx + 1,
        vendorId: getDynamicComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },
    trackClick(idx) {
      this.tracker.publishers.dynamic.clickDynamic(
        this.getPublisherParams(idx),
      );
    },
    trackDisplay(idx) {
      this.tracker.publishers.dynamic.dynamic(this.getPublisherParams(idx));
    },
  },
};
</script>
