<template>
  <component
    :is="blockTemplate"
    :id="details.slug"
    :background-image-url="backgroundImageUrl"
    :visual-url="visualUrl"
    :title="title"
  />
</template>

<script>
import { BLOCK_TEMPLATE } from "../../blocks";
import VisualBannerTemplate from "../../templates/blocks/header/VisualBannerTemplate";

export default {
  components: {
    VisualBannerTemplate,
  },

  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || VisualBannerTemplate;
    },
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    cardComponent() {
      return this.components.find(component => component?.type === "card");
    },
    mediaComponent() {
      return this.components.find(component => component?.type === "media");
    },
    backgroundImageUrl() {
      return this.cardComponent?.medias?.[0]?.url || "";
    },
    visualUrl() {
      return this.mediaComponent?.medias?.[0]?.url || "";
    },
    title() {
      return this.cardComponent?.title;
    },
  },
};
</script>
