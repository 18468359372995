<template>
  <section class="home-list">
    <InViewWrapper
      v-for="(card, index) in components"
      :key="index"
      @in-view="$emit('component-display', index)"
    >
      <ListCard
        :src="imgSrc(card)"
        :position="index % 2 === 0 ? 'left' : 'right'"
        :class="`component odd-even--${oddEvenStart} `"
      >
        <div class="title super-title3">
          {{ card.name }}
        </div>
        <div class="subtitle text2">
          {{ card.subname }}
        </div>
        <div
          class="list-card-description text6"
          v-html="$t(card.description)"
        />
        <EventLink
          :id="`button--home-list--${card.id}`"
          class="see-more-button"
          type="button"
          event="common.cms.link:click"
          :linkComponent="card"
          @mousedown.native="$emit('component-click', index)"
        />
      </ListCard>
    </InViewWrapper>
  </section>
</template>

<style lang="scss">
.home-list {
  .odd-even {
    &--1:nth-child(even) {
      @include setColor(--color-background2, background-color);
    }
    &--1:nth-child(odd) {
      @include setColor(--color-background1, background-color);
    }
    &--2:nth-child(even) {
      @include setColor(--color-background1, background-color);
    }
    &--2:nth-child(odd) {
      @include setColor(--color-background2, background-color);
    }
  }

  .list-card-container {
    padding: rem(20px) rem(16px);
    max-width: rem(1060px);

    @include respond-min("medium") {
      padding: rem(20px) 0;
    }
  }

  .list-card-description {
    margin-top: rem(8px);
  }

  .see-more-button {
    width: auto;

    @include respond-min("medium") {
      display: inline-flex;
      padding: 0 rem(40px);
    }
  }
}
</style>

<script>
import { ListCard } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";
import InViewWrapper from "@/components/InViewWrapper";

export default {
  components: {
    ListCard,
    EventLink,
    InViewWrapper,
  },

  data() {
    return {
      oddEvenStart: 1,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    startBgColor: {
      type: String,
      default: "background1",
    },
  },

  methods: {
    imgSrc(card) {
      return card?.medias[0]?.url || "";
    },
  },

  updated() {
    this.oddEvenStart = this.startBgColor === "background1" ? 2 : 1;

    let nextBgColor;
    if (this.components.length % 2 === 0) {
      nextBgColor = this.startBgColor;
    } else {
      nextBgColor =
        this.startBgColor === "background1" ? "background2" : "background1";
    }
    this.$emit(
      "bgColorSet",
      nextBgColor === "background1" ? "background2" : "background1",
    );
  },
};
</script>
