import { SFMC_NOTIFICATION_OPENED } from "@/modules/sfmc";
import { getStore } from "@/store";
let routerRef;

const handlePushEvent = event => {
  const { info } = event?.detail;
  // The app will call the api. In case we are logged out or token expired, the api will return 401.
  // This will call utilities/autoLogout#exit() and the app will be in the login page.
  // So the line below set the redirectUrl after login.
  getStore().dispatch("setRedirectUrl", info.values.url);
  // router push here
  routerRef.push({
    path: info.values.url,
    query: {},
  });
};

export const sfmcRegisterEvents = router => {
  // créer la reference router
  routerRef = router;
  document.addEventListener(SFMC_NOTIFICATION_OPENED, handlePushEvent);
};

export const sfmcUnregisterEvents = () => {
  routerRef = null;
  document.removeEventListener(SFMC_NOTIFICATION_OPENED, handlePushEvent);
};
