export const UL_LAUNCHED_APP = "launchedAppFromLink";
export const ulSubscribe = () => {
  window.universalLinks.subscribe(null, eventData => {
    // router push
    const event = new CustomEvent(UL_LAUNCHED_APP, {
      detail: { eventData },
    });
    document.dispatchEvent(event);
  });
};

export const ulUnsubscribe = () => {
  window.universalLinks.unsubscribe(null);
};
