const Login = () => import(/* webpackChunkName: "login" */ "./Login");

export default {
  path: "/login",
  name: "login",
  component: Login,
  meta: {
    redirectAuth: true,
    footerVisible: true,
    title: "common.head.title.login",
    template: "login",
  },
  props: true,
};
