<template>
  <VendorsListLayout
    moduleName="benefits"
    @publisher-click="trackPublisherClick"
    @publisher-display="trackPublisherDisplay"
  />
</template>

<script>
import VendorsListLayout from "@/components/layout/VendorsListLayout";

export default {
  components: {
    VendorsListLayout,
  },

  methods: {
    trackPublisherClick(params) {
      this.tracker.publishers.contents.clickBenefit(params);
    },
    trackPublisherDisplay(params) {
      this.tracker.publishers.contents.benefit(params);
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
  },
};
</script>
