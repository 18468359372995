<template>
  <div class="rooted-device">
    <div class="rooted-device__wrapper">
      <div class="rooted-device__textarea">
        <div class="rooted-device__title title1">
          {{ $t("common.rooted-device.title") }}
        </div>
        <div class="rooted-device__text text4">
          {{ $t("common.rooted-device.text") }}
        </div>
        <Button id="rooted-device__cta" @click.native="closeApp">
          {{ $t("common.mobileapp.close") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@johnpaul/jp-vue-components";

export default {
  name: "RootedDevice",

  components: {
    Button,
  },

  methods: {
    closeApp() {
      if (window.cordova) {
        window.cordova.exec(null, null, "ExitApp", "exitApp", []);
      }
    },
  },
};
</script>

<style lang="scss">
.rooted-device {
  height: 100%;
  width: 100vw;
  position: absolute;
  z-index: 9999;
  @include setColor(--color-background1, background-color);

  &__wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__textarea {
    display: flex;
    flex-direction: column;
    margin: 0 3rem;
  }

  &__text {
    margin-bottom: 1.25rem;
  }
}
</style>
