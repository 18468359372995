const Benefits = () =>
  import(/* webpackChunkName: "benefits" */ "./views/Benefits");

const BenefitDetails = () =>
  import(/* webpackChunkName: "benefits" */ "./views/BenefitDetails");

export default [
  {
    path: "/benefits",
    name: "benefits",
    component: Benefits,
    meta: {
      requiresAuth: true,
      title: "common.head.title.benefits",
      template: "privileges",
    },
  },
  {
    path: "/benefits/details/:id",
    name: "benefits/details",
    component: BenefitDetails,
    props: true,
    meta: {
      requiresAuth: true,
      hideWidgets: true,
      template: "privilege-details",
    },
  },
];
