import client from "@/plugins/jp-api-client";
import { getErrorCode } from "@/utilities/errors";

export default {
  namespaced: true,

  state: {
    pkPassData: null,
    pdfData: null,

    fetching: false,
    fetchError: null,

    addingPassToWalletError: false,
  },
  mutations: {
    fetchPkPassStart(state) {
      state.fetching = true;
      state.fetchError = null;
    },
    fetchPkPassError(state, errorCode) {
      state.pkPassData = null;
      state.fetching = false;
      state.fetchError = errorCode;
    },
    fetchPkPassSuccess(state, data) {
      state.pkPassData = data;
      state.fetching = false;
      state.fetchError = null;
    },
    fetchPkPassCancel(state) {
      state.fetching = false;
    },
    fetchPdfStart(state) {
      state.fetching = true;
      state.fetchError = null;
    },
    fetchPdfError(state, errorCode) {
      state.pdfData = null;
      state.fetching = false;
      state.fetchError = errorCode;
    },
    fetchPdfSuccess(state, data) {
      state.pdfData = data;
      state.fetching = false;
      state.fetchError = null;
    },
    fetchPdfCancel(state) {
      state.fetching = false;
    },
    resetPkPass(state) {
      state.pkPassData = null;
    },
    addingPassToWalletFailed(state) {
      state.addingPassToWalletError = true;
    },
    resetAddingPassToWalletError(state) {
      state.addingPassToWalletError = false;
    },
  },
  actions: {
    async fetchPkPass({ commit }) {
      commit("fetchPkPassStart");
      try {
        const { data } = await client.passes.pkPass();
        commit("fetchPkPassSuccess", data);
        return { success: true };
      } catch (error) {
        if (client.isCancel(error)) {
          commit("fetchPkPassCancel");
        } else {
          commit("fetchPkPassError", getErrorCode(error));
        }
        return { success: false };
      }
    },
    async fetchPdf({ commit, state }) {
      if (state.pdfData) {
        return { success: true };
      }
      commit("fetchPdfStart");
      try {
        const { data } = await client.passes.pdf();
        commit("fetchPdfSuccess", data);
        return { success: true };
      } catch (error) {
        if (client.isCancel(error)) {
          commit("fetchPdfCancel");
        } else {
          commit("fetchPdfError", getErrorCode(error));
        }
        return { success: false };
      }
    },
  },
};
