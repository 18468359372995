const MyAssistant = () =>
  import(/* webpackChunkName: "my-assistant" */ "./MyAssistant");

export default {
  path: "/my-assistant",
  name: "my-assistant",
  component: MyAssistant,
  meta: {
    title: "common.head.title.my-assistant",
    requiresAuth: true,
    template: "my-assistant",
  },
};
