<template>
  <div class="participants-input">
    <TextInput
      :id="id"
      :value="computedValue"
      :label="label"
      :disabled="disabled"
      :error="error"
      readonly
      @focus="hasFocus = true"
      @blur="hasFocus = false"
    >
      <template slot="right">
        <FontIcon name="ui_profil" class="textinput-icon" />
      </template>
    </TextInput>

    <template v-if="hasFocus">
      <div class="fields-layer" @mousedown.prevent>
        <CounterInput
          v-for="(field, name) in types"
          :key="`participants-${name}`"
          :id="`${id}-${name}`"
          class="counter-input"
          :label="$t(field.label)"
          :description="$t(field.description)"
          :minimum="field.minimum"
          :maximum="field.maximum"
          :value="value[name]"
          @update:value="value[name] = $event"
        />
      </div>
      <div class="input-curtain" />
    </template>
  </div>
</template>

<style lang="scss">
.participants-input {
  position: relative;

  .textinput-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    @include setColor(--color-icon1);
    font-size: rem(22px);
  }

  .fields-layer {
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    @include setColor(--color-background1, background-color);
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
    z-index: 10;
  }

  .input-curtain {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .counter-input {
    margin: rem(16px);
  }
}
</style>

<script>
import { CounterInput, FontIcon, TextInput } from "@johnpaul/jp-vue-components";

export default {
  components: { CounterInput, FontIcon, TextInput },

  props: {
    id: { type: String, required: true },
    value: { type: Object, default: null },
    label: { type: String, required: false },
    valuesSeparator: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: null },
    types: { type: Object, required: true },
  },

  data() {
    return {
      hasFocus: false,
    };
  },

  computed: {
    computedValue() {
      return ["adults", "children", "babies"]
        .filter(field => !!this.value[field])
        .map(field =>
          this.$tc(this.types[field].pluralizeValue, this.value[field]),
        )
        .join(this.$t(this.valuesSeparator));
    },
  },
};
</script>
