import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import config from "./modules/config";
import content from "./modules/content";
import contentv2 from "./modules/content.v2";
import device from "./modules/device";
import i18n, { init as i18nInit } from "./modules/i18n";
import interests from "./modules/interests";
import member from "./modules/member";
import passes from "./modules/passes";
import subscriptions from "./modules/subscriptions";

Vue.use(Vuex);

import { getEnabledModules } from "@/utilities/config";
import { loadLanguageAsync } from "@/i18n";

let store;

function buildStore({ modules, persistPaths }) {
  store = new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",

    state: {
      event: null,
      isMobileApp: false,
      config: {},
      conductorConfig: {},
      redirectUrl: null,
      refreshTokenEnabled: true,
      shouldFetchConfig: false,
    },
    mutations: {
      setIsMobileApp(state, isMobile) {
        state.isMobileApp = isMobile;
      },
      setConfig(state, config) {
        state.config = config;
      },
      setConductorConfig(state, conductorConfig) {
        state.conductorConfig = conductorConfig;
      },
      setRedirectUrl(state, redirectUrl) {
        state.redirectUrl = redirectUrl;
      },
      setShouldFetchConfig(state, shouldFetchConfig) {
        state.shouldFetchConfig = shouldFetchConfig;
      },
    },
    actions: {
      setIsMobileApp({ commit }, isMobile) {
        commit("setIsMobileApp", isMobile);
      },
      setConfig({ commit }, config) {
        commit("setConfig", config);
      },
      setConductorConfig({ commit }, conductorConfig) {
        commit("setConductorConfig", conductorConfig);
      },
      setRedirectUrl({ commit }, redirectUrl) {
        commit("setRedirectUrl", redirectUrl);
      },
      setShouldFetchConfig({ commit }, shouldFetchConfig) {
        commit("setShouldFetchConfig", shouldFetchConfig);
      },
    },
    modules,

    plugins: [createPersistedState({
      paths: [
        ...persistPaths,
        "redirectUrl",
      ],
    })],
  });

  return store;
}

export function createStoreMocks(defaultStore, options = {}) {
  store = new Vuex.Store({ ...defaultStore, ...options });
  return store;
}

function initModules(modules, config) {
  i18nInit(config);
  return modules;
}

export function createStore(configData, initialTokens = null) {
  if (store) throw new Error("store already created");
  let modules = {
    auth,
    config,
    content,
    contentv2,
    device,
    i18n,
    interests,
    member,
    passes,
    subscriptions,
  };
  let persistPaths = [];
  getEnabledModules(configData).forEach(confModule => {
    try {
      modules[confModule] = require(`@/modules/${confModule}/store`).default;
    } catch (ex) {
      // No store config, nothing to do.
    }
  });
  modules = initModules(modules, configData);
  // Persisted values
  Object.values(modules).forEach(module => {
    persistPaths = [...persistPaths, ...(module.persistPaths || [])];
  });
  store = buildStore({ modules, persistPaths });
  store.watch(
    state => state.i18n.locale,
    (locale, prevLocale) => {
      if (!prevLocale) {
        loadLanguageAsync(locale, configData);
      }
    },
    {
      immediate: true,
    },
  );
  if (
    initialTokens?.accessToken &&
    initialTokens?.refreshToken &&
    initialTokens.refreshTokenExpiration
  ) {
    store.commit("auth/setTokens", {
      accessToken: initialTokens.accessToken,
      refreshToken: initialTokens.refreshToken,
    });
    store.commit(
      "auth/setRefreshTokenExpiration",
      initialTokens.refreshTokenExpiration,
    );
  }
  return store;
}

export function getStore() {
  if (!store) throw new Error("no store created");
  return store;
}

export function getConfig() {
  return store?.state?.config;
}
