const News = () => import(/* webpackChunkName: "news" */ "./views/News");
const NewsDetails = () =>
  import(/* webpackChunkName: "news" */ "./views/NewsDetails");

export default [
  {
    path: "/news",
    name: "news",
    component: News,
    meta: {
      requiresAuth: true,
      title: "common.head.title.news",
      template: "news",
    },
  },
  {
    path: "/news/details/:id",
    name: "news/details",
    component: NewsDetails,
    props: true,
    meta: {
      requiresAuth: true,
      hideWidgets: true,
      template: "news-details",
    },
  },
];
