<template>
  <component
    :is="blockTemplate"
    :title="title"
    :subtitle="subtitle"
    :cards="cards"
    @expand-accordion="$emit('expand-accordion', $event)"
  />
</template>

<script>
import { BLOCK_TEMPLATE } from "../../blocks";
import TabTemplate from "../../templates/blocks/tab/TabTemplate";

export default {
  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || TabTemplate;
    },
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    cards() {
      return this.components.filter(component => component.type === "card");
    },
    title() {
      return this.components.find(component => component.type === "text")
        ?.title;
    },
    subtitle() {
      return this.components.find(component => component.type === "text")
        ?.subtitle;
    },
  },
};
</script>
