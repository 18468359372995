<template>
  <div class="cancellation-layer">
    <div class="background" />

    <ActivityIndicator v-if="loading" />
    <div v-else class="content-wrapper">
      <div class="layer-title text5">
        {{ $t("request.my-requests.cancellation.title") }}
      </div>
      <div class="layer-description text6">
        {{ description }}
      </div>

      <div class="buttons-wrapper">
        <Button
          id="button--request-cancellation-abort"
          class="text6"
          type="secondary-negative"
          @click.native="$emit('cancel')"
        >
          {{ $t("request.my-requests.cancellation.buttons.no") }}
        </Button>
        <Button
          id="button--request-cancellation-submit"
          type="primary-negative"
          class="confirm text6"
          @click.native="$emit('submit')"
        >
          {{ $t("request.my-requests.cancellation.buttons.yes") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.cancellation-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: rem(0 30px);
  z-index: 2;
  display: flex;
  align-items: center;

  .activity-indicator {
    @include setColor(--color-text-negative, color, true);
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    @include setColor(--color-background3, background-color);
    opacity: 0.85;
  }

  .content-wrapper {
    text-align: center;
    max-width: rem(256px);
    margin: 0 auto;
    z-index: 1;
  }

  .layer-title {
    @include setColor(--color-text-negative);
  }

  .layer-description {
    margin-top: rem(16px);
    @include setColor(--color-text-negative);
  }

  .buttons-wrapper {
    margin-top: rem(20px);
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      width: rem(110px);
    }

    .confirm {
      margin-left: rem(8px);
    }
  }
}
</style>

<script>
import { ActivityIndicator, Button } from "@johnpaul/jp-vue-components";

export default {
  components: {
    ActivityIndicator,
    Button,
  },

  computed: {
    description() {
      return this.$t("request.my-requests.cancellation.description")
        .replace(" %name% ", " " + this.name + " ")
        .replace("%name%", this.name);
    },
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
