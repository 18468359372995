<template>
  <component
    v-if="mediaComponent"
    :is="blockTemplate"
    :id="details.slug"
    :title="title"
    :subtitle="subtitle"
    :description="description"
    :image-url="imgUrl"
    :button-component="buttonComponent"
  />
</template>

<script>
import { BLOCK_TEMPLATE } from "../../blocks";
import BannerTemplate from "../../templates/blocks/header/BannerTemplate";

export default {
  components: {
    BannerTemplate,
  },

  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || BannerTemplate;
    },
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    textComponent() {
      return this.components.find(component => component?.type === "text");
    },
    mediaComponent() {
      return this.components.find(component => component?.type === "media");
    },
    buttonComponent() {
      return this.components.find(component => component?.type === "button");
    },
    imgUrl() {
      return this.mediaComponent?.medias?.[0]?.url || "";
    },
    title() {
      return this.textComponent?.title;
    },
    subtitle() {
      return this.textComponent?.subtitle;
    },
    description() {
      return this.textComponent?.description;
    },
  },
};
</script>
