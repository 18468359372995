import client from "@/plugins/jp-api-client";

import { getErrorCode } from "@/utilities/errors";

import { DEFAULT_ERROR } from "@/constants/errors";
import jwtDecode from "jwt-decode";
import { slugifyText } from "../../utilities/transforms";

export default {
  namespaced: true,

  state: {
    recovering: false,
    recoverError: null,

    resetting: false,
    resetError: null,

    readingSecretQuestion: false,
    readSecretQuestionError: null,
    secretQuestion: null,
  },

  mutations: {
    recoverStart(state) {
      state.recovering = true;
      state.recoverError = null;
    },

    recoverSuccess(state) {
      state.recovering = false;
    },

    recoverError(state, error) {
      state.recovering = false;
      state.recoverError = error;
    },

    resetStart(state) {
      state.resetting = true;
      state.resetError = null;
    },

    resetError(state, error) {
      state.resetting = false;
      state.resetError = error;
    },

    resetSuccess(state) {
      state.resetting = false;
    },

    getSecretQuestionStart(state) {
      state.readingSecretQuestion = true;
      state.readSecretQuestionError = null;
    },

    getSecretQuestionError(state, error) {
      state.readingSecretQuestion = false;
      state.readSecretQuestionError = error;
    },

    getSecretQuestionSuccess(state) {
      state.readingSecretQuestion = false;
    },

    setSecretQuestion(state, value) {
      state.secretQuestion = value;
    },
  },

  actions: {
    async recover({ commit, dispatch }, { email }) {
      commit("recoverStart");

      try {
        const token = await dispatch(
          "auth/fetchClientToken",
          {},
          { root: true },
        );

        await client.password.recover({ email, token });

        commit("recoverSuccess");
        return { success: true };
      } catch (e) {
        commit("recoverError", DEFAULT_ERROR);
        return { success: false };
      }
    },

    async reset({ commit, dispatch }, { token, password, question, answer }) {
      commit("resetStart");

      const clientToken = await dispatch(
        "auth/fetchClientToken",
        {},
        { root: true },
      );
      const jwt = jwtDecode(token);

      try {
        await client.password.reset({
          password,
          token,
          clientToken,
          sub: jwt.sub,
          question,
          answer: slugifyText(answer),
        });
        commit("resetSuccess");
        return { success: true };
      } catch (error) {
        commit("resetError", getErrorCode(error));
        return { success: false };
      }
    },

    async getSecretQuestion({ commit }, token) {
      commit("getSecretQuestionStart");
      try {
        let secretQuestion;
        if (token) {
          const jwt = jwtDecode(token);
          if (jwt?.secret_question) secretQuestion = jwt.secret_question;
          else {
            commit("getSecretQuestionError");
            return { success: false };
          }
        } else {
          const { data } = await client.members.secret.get();
          secretQuestion = data.secret_question;
        }

        commit("setSecretQuestion", secretQuestion);
        commit("getSecretQuestionSuccess");
        return { success: true };
      } catch (error) {
        commit("getSecretQuestionError", getErrorCode(error));
        return { success: false };
      }
    },
  },
};
