import { getStore } from "@/store";

export function vibrate(time) {
  if (navigator.vibrate) navigator.vibrate(time);
}

export function hasEnabledLocation(state) {
  const { device, cordova } = window;
  return (
    (device.platform === "Android" &&
      state !== cordova.plugins.diagnostic.locationMode.LOCATION_OFF) ||
    (device.platform === "iOS" &&
      (state === cordova.plugins.diagnostic.permissionStatus.GRANTED ||
        state ===
          cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE))
  );
}

export async function requestIOSAuthorization() {
  return new Promise(resolve => {
    const idfaPlugin = window?.cordova?.plugins?.idfa;
    idfaPlugin
      .getInfo()
      .then(info => {
        if (!info.trackingLimited) {
          return {
            success: true,
            result: idfaPlugin.TRACKING_PERMISSION_AUTHORIZED,
          };
        } else if (
          info.trackingPermission ===
          idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
        ) {
          return idfaPlugin
            .requestPermission()
            .then(result => {
              return { success: true, result };
            })
            .catch(() => {
              return { success: false, result: null };
            });
        } else if (
          info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED
        ) {
          return {
            success: true,
            result: idfaPlugin.TRACKING_PERMISSION_AUTHORIZED,
          };
        } else {
          return {
            success: true,
            result: idfaPlugin.TRACKING_PERMISSION_DENIED,
          };
        }
      })
      .then(response => {
        resolve({
          ...response,
          result: response.result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED,
        });
      });
  });
}

export let secureStorage;
export const USERNAME_KEY = `${process.env.PROJECT}_${process.env.VUE_APP_ENVIRONMENT}_username`;
export const PWD_KEY = `${process.env.PROJECT}_${process.env.VUE_APP_ENVIRONMENT}_pwd`;

// Create "companion_secure_storage" secure storage space
export let initCordovaSecureStorage = screenLockDisabledMessage => {
  if (window.device.platform === "Android") {
    initSecureStorageOnAndroid(screenLockDisabledMessage);
  } else {
    initSecureStorageOniOS();
  }
};

// Write in secure storage
export const setInSecureStorage = (keyToSet, valueToSet, onSuccess) => {
  if (!secureStorage) return;
  return secureStorage.set(
    onSuccess ? onSuccess : () => {},
    () => {},
    keyToSet,
    valueToSet,
  );
};

// Retrieve key value from secure storage
export const getFromSecureStorage = (keyToGet, onSuccess, onFail) => {
  if (!secureStorage) return;
  return secureStorage.get(
    onSuccess ? onSuccess : () => {},
    onFail ? onFail : () => {},
    keyToGet,
  );
};

// Remove key from secure storage
export const removeFromSecureStorage = (keyToRemove, onSuccess) => {
  if (!secureStorage) return;
  return secureStorage.remove(
    onSuccess ? onSuccess : () => {},
    () => {},
    keyToRemove,
  );
};

// Android secure storage creation
const initSecureStorageOnAndroid = screenLockDisabledMessage => {
  secureStorage = new window.cordova.plugins.SecureStorage(
    () => {},
    () => {
      // Screen lock is required to use the Android app
      alert(screenLockDisabledMessage);
      // Open device screen-lock settings
      secureStorage.secureDevice(
        () => initSecureStorageOnAndroid(screenLockDisabledMessage),
        () => initSecureStorageOnAndroid(screenLockDisabledMessage),
      );
    },
    "companion_secure_storage",
  );
};

// iOS secure storage creation
const initSecureStorageOniOS = () => {
  secureStorage = new window.cordova.plugins.SecureStorage(
    () => {},
    () => {},
    "companion_secure_storage",
  );
};

export function saveFile(fileName, data, isPass = false) {
  window.resolveLocalFileSystemURL(
    window.cordova.file.dataDirectory,
    function(dirEntry) {
      createFile(dirEntry, fileName, data, isPass);
    },
    error => {
      // eslint-disable-next-line no-console
      console.error("Fail to resolve local file : ", error);
    },
  );
}

function createFile(dirEntry, fileName, data, isPass) {
  // Creates a new file
  dirEntry.getFile(
    fileName,
    { create: true, exclusive: false },
    function(fileEntry) {
      writeFile(fileEntry, data, isPass);
    },
    error => {
      // eslint-disable-next-line no-console
      console.error("Fail to get file : ", error);
    },
  );
}

function writeFile(fileEntry, dataObj, isPass) {
  // Create a FileWriter object for our FileEntry
  fileEntry.createWriter(function(fileWriter) {
    fileWriter.onwriteend = function() {
      if (isPass && window.device.platform === "iOS") {
        window.Passbook.addPass(
          fileEntry.nativeURL,
          () => {},
          error => {
            // eslint-disable-next-line no-console
            console.error("Error while adding pass to wallet :", error);
            getStore().commit("passes/addingPassToWalletFailed");
          },
        );
      } else {
        window.cordova.plugins.fileOpener2.open(
          fileEntry.nativeURL,
          "application/pdf",
          {
            error: e => {
              // eslint-disable-next-line no-console
              console.error("Failed file read: ", e);
            },
            success: () => {},
          },
        );
      }
    };

    fileWriter.onerror = error => {
      // eslint-disable-next-line no-console
      console.error("Fail to write file : ", error);
    };
    fileWriter.write(dataObj);
  });
}

// Wallet member pass
export const addPassToAppleWallet = pkPassArrayBuffer => {
  const blob = new Blob([pkPassArrayBuffer]);
  saveFile("pass.pkpass", blob, true);
};
