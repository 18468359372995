<template>
  <div class="trends" v-if="components">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="super-title3 mt-3">{{ $t(`trends.title`) }}</h1>
        </div>

        <MosaicLayout class="mosaic" mobileLayout="column">
          <template
            v-for="(component, idx) in components.slice(0, 4)"
            :slot="`slot-${idx}`"
          >
            <InViewWrapper
              :key="component.id"
              @in-view="trackPublisherDisplay(idx)"
            >
              <EventLink
                :id="`button--list-item--${component.id}`"
                class="offer-link"
                event="common.cms.link:click"
                :linkComponent="component"
                @mousedown.native="trackPublisherClick(idx)"
              >
                <ContentCard
                  :src="getMediaUrl(component)"
                  :title="component.name"
                  :description="component.subname"
                  :fixedHeight="idx === 0"
                  :tag="getTagFamily(component)"
                />
              </EventLink>
            </InViewWrapper>
          </template>
        </MosaicLayout>
      </div>

      <div class="row inline-cards">
        <div
          class="col-12 col-md-4"
          v-for="(component, index) in components.slice(4)"
          :key="`component-${index}`"
        >
          <InViewWrapper
            :key="component.id"
            @in-view="trackPublisherDisplay(index + 4)"
          >
            <EventLink
              :id="`button--list-item--${component.id}`"
              class="offer-link"
              event="common.cms.link:click"
              :linkComponent="component"
              @mousedown.native="trackPublisherClick(index + 4)"
            >
              <ContentCard
                :src="getMediaUrl(component)"
                :title="component.name"
                :description="component.subname"
                :tag="getTagFamily(component)"
              />
            </EventLink>
          </InViewWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.trends {
  width: 100%;
  max-width: rem(1400px);
  margin: rem(0 auto 10px) !important;
  padding-top: rem(16px);
  padding-bottom: rem(16px);

  .container {
    width: 100%;
    max-width: rem(1400px);
  }

  .mosaic {
    width: 100%;
    padding: rem(0 15px);
  }

  .cards-wrapper {
    position: relative;
    display: inline-flex;
    padding-left: rem(15px);
  }

  .offer-link {
    display: inline-block;
    width: 100%;
    margin-bottom: rem(15px);

    .title {
      text-transform: uppercase;
      font-weight: normal;
    }

    > * {
      height: 100%;
    }
  }

  .see-more-button {
    width: auto;
    margin: auto;
    padding: rem(0 50px);
  }

  @include respond-min("medium") {
    padding: rem(72px 0);

    .mosaic {
      margin-bottom: rem(10px);
      padding: 0;
    }

    .cards-wrapper {
      width: 100%;
      display: flex;
      padding: 0;
    }

    .card-large .offer-link {
      position: absolute;
      width: 100%;
      min-width: rem(200px);
      height: 100%;
    }

    .offer-link {
      display: block;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: rem(16px);
      text-decoration: none;
    }

    .offer-card {
      height: 100%;
    }

    .inline-cards .offer-link {
      padding: rem(0 0 26px);
    }
  }
}
</style>

<script>
import { mapState } from "vuex";

import { ContentCard } from "@johnpaul/jp-vue-components";
import InViewWrapper from "@/components/InViewWrapper";
import EventLink from "@/components/EventLink";
import MosaicLayout from "@/components/MosaicLayout";
import contentPageMixin from "@/mixins/contentPageMixin";

import { getModuleConfig } from "@/utilities/config";
import { getComponentVendorId } from "@/utilities/tracking";

export default {
  mixins: [contentPageMixin],

  components: {
    MosaicLayout,
    ContentCard,
    EventLink,
    InViewWrapper,
  },

  data() {
    const moduleConfig = getModuleConfig(this.$config, "trends");

    return {
      pageId: moduleConfig.options.pageId,
      tagFamily: moduleConfig.options.tagFamily,
    };
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
    }),

    trendsCollection() {
      return this.pageCollections?.[0];
    },

    collection() {
      return this.collections?.[this.trendsCollection?.slug];
    },

    components() {
      return this.collection?.components;
    },
  },

  methods: {
    getTagFamily(component) {
      return this.tagFamily ? component.family : null;
    },

    getMediaUrl(component) {
      return component.medias[0]?.url;
    },

    getPublisherParams(idx) {
      const component = this.components[idx];
      return {
        positionItem: idx + 1,
        nbItems: this.collection.count,
        memberId: this.memberId,
        vendorId: getComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },

    trackPublisherClick(idx) {
      const publisherParams = this.getPublisherParams(idx);
      this.tracker.publishers.contents.clickInspiration(publisherParams);
    },
    trackPublisherDisplay(idx) {
      const publisherParams = this.getPublisherParams(idx);
      this.tracker.publishers.contents.inspiration(publisherParams);
    },
  },

  mounted() {
    this.$root.$emit("trends.list:mounted");
    this.$emit("header", { transparent: false });
  },
};
</script>
