import defaultConductorConfig from "@/projects/default/conductor.json";
import router from "@/router";

import { getTemplateRoute } from "@/utilities/routing";
import i18n from "@/i18n";
import { getModuleConfig } from "@/utilities/config";
import { getStore } from "../store";
import Logger from "../mixins/Logger";

function merge(a, b) {
  var reduced = a.filter(function(aitem) {
    return !b.find(function(bitem) {
      return aitem.event === bitem.event;
    });
  });
  return reduced.concat(b);
}

/**
 * Merge conductor config with client conductor config
 * @param currentClientConductorConfig
 * @returns {*}
 */
function getMergedConductorConfig(currentClientConductorConfig) {
  return merge(defaultConductorConfig, currentClientConductorConfig);
}

function resolveRoute(routeParams) {
  const resolvedRoute = router.resolve(routeParams);
  const routeEnabled = resolvedRoute.resolved.matched.length > 0;

  return routeEnabled ? resolvedRoute : false;
}

function resolve(eventName, params) {
  let { conductorConfig, config } = getStore().state;
  const event = conductorConfig.find(entry => entry.event === eventName) || {};
  try {
    if (event.type === "widget") {
      return getModuleConfig(config, event.module) ? { type: "widget" } : false;
    }

    if (event.type === "toggle-cookie") {
      return getModuleConfig(config, event.module)
        ? { type: "toggle-cookie" }
        : false;
    }

    if (event.type === "widget") {
      return getModuleConfig(config, event.module) ? { type: "widget" } : false;
    }

    if (event.type === "external") {
      if (typeof event?.url === "string") {
        return event.url;
      }

      if (typeof params?.url === "string") {
        return params.url;
      }

      return event.url
        ? event.url[i18n.locale] || event.url[config.options.defaultLocale]
        : undefined;
    }

    if (event.type === "cms") {
      let resolvedRoute = false;
      if (params?.template) {
        const routeName = getTemplateRoute(params?.template);
        if (routeName) {
          resolvedRoute = resolveRoute({ name: routeName, params });
        }
      } else if (params?.internalPath) {
        resolvedRoute = resolveRoute(params.internalPath);
      }
      return resolvedRoute;
    }

    if (event.type === "router") {
      return params?.slug ? resolveRoute(params.slug) : resolveRoute(event);
    }

    return false;
  } catch (e) {
    Logger.log(e);
  }
}

export { getMergedConductorConfig, resolve };
