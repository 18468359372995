<template>
  <div class="home-partners" :class="darkTheme && 'dark'">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>
    <div class="list-wrapper">
      <div class="list" ref="scrollContainer">
        <div class="cards-wrapper">
          <span
            v-for="partner in partners"
            :key="partner.id"
            class="partner-link"
          >
            <img :src="partner.logo" :alt="partner.name" />
          </span>
        </div>
      </div>
      <template v-if="showControls">
        <Button
          class="navigation-button previous"
          :id="`button--partners-prev-${id}`"
          @click.native="scroll('left')"
        >
          <FontIcon name="ui_chevron_left" />
        </Button>
        <Button
          class="navigation-button next"
          :id="`button--partners-next-${id}`"
          @click.native="scroll('right')"
        >
          <FontIcon name="ui_chevron_right" />
        </Button>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.home-partners {
  padding: rem(20px 0 26px);

  &.dark {
    @include setColor(--color-background4, background-color);

    h1 {
      @include setColor(--color-text-negative);
    }
  }

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
  }

  h2 {
    @include setColor(--color-subtitle);
    text-align: center;
  }

  .list {
    position: relative;
    width: 100%;
    overflow-x: auto;
    padding: rem(14px 0);
    margin: rem(15px auto 10px);
    text-align: center;
  }

  .cards-wrapper {
    position: relative;
    display: inline-flex;
    padding: rem(0 8px);
  }

  .partner-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: rem(110px);
    width: rem(150px);
    min-width: rem(150px);
    margin: rem(0 7px);
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .navigation-button {
    display: none;
  }

  @include respond-min("medium") {
    padding: rem(40px 0);

    .list-wrapper {
      position: relative;
      margin: rem(0 50px);
    }

    .list {
      height: rem(138px);
      overflow: hidden;
    }

    .partner-link {
      margin: rem(0 23px);
    }

    .navigation-button {
      display: block;
      position: absolute;
      top: 50%;
      width: auto;
      padding: rem(0 10px);
      transform: translateY(-50%);
      font-size: rem(24px);
      border-radius: 0;

      &.previous {
        left: rem(-50px);
      }

      &.next {
        right: rem(-50px);
      }
    }
  }
}
</style>

<script>
import easyScroll from "easy-scroll";

import { Button, FontIcon } from "@johnpaul/jp-vue-components";

const LOGO_WIDTH = 196;

export default {
  components: {
    Button,
    FontIcon,
  },

  data() {
    return {
      offsetWidth: 0,
    };
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    partners() {
      return this.components.map(component => ({
        id: component.id,
        logo: component.medias?.[0].url,
        url: component.url,
      }));
    },
    showControls() {
      return this.offsetWidth < LOGO_WIDTH * this.components.length;
    },
  },

  methods: {
    scroll(direction) {
      easyScroll({
        scrollableDomEle: this.$refs.scrollContainer,
        direction,
        duration: 150,
        easingPreset: "easeInQuad",
        scrollAmount: LOGO_WIDTH,
      });
    },
  },

  mounted() {
    const el = this.$refs?.scrollContainer;
    this.offsetWidth = el.offsetWidth;
  },
};
</script>
