import client from "@/plugins/jp-api-client";

import { DEFAULT_ERROR } from "@/constants/errors";
import { slugifyText } from "@/utilities/transforms";

import activation from "./modules/activation";

export default {
  namespaced: true,

  state: {
    firstname: null,
    username: null,

    registering: false,
    registered: false,
    registerError: null,
  },

  modules: {
    activation,
  },

  mutations: {
    reset(state) {
      state.firstname = null;
      state.username = null;
      state.registering = false;
      state.registered = false;
      state.registerError = null;
    },
    registerStart(state) {
      state.registering = true;
      state.registered = false;
      state.registerError = null;
    },

    registerSuccess(state, { firstname, username }) {
      state.registering = false;
      state.registered = true;
      state.firstname = firstname;
      state.username = username;
    },

    registerError(state, error) {
      state.registering = false;
      state.registerError = error;
    },

    registerErrorCode(state, error) {
      state.registerErrorCode = error;
    },
  },

  actions: {
    async register({ rootState, commit, dispatch }, { attributes }) {
      commit("registerStart");

      const member = {
        birthdate: attributes.birthdate,
        firstname: attributes.firstname,
        language: rootState.i18n.locale,
        lastname: attributes.lastname,
        password: attributes.password,
        phone: attributes.phone,
        username: attributes.username,
        marketing: {
          sms: attributes.marketing?.sms || null,
          email: attributes.marketing?.email || null,
          sms_reminder: null,
          print: null,
        },
      };

      if (attributes.security) {
        member.secret_question = attributes.security.secret_question || null;
        member.secret_answer =
          slugifyText(attributes.security.secret_answer) || null;
      }

      if (attributes.specifics) {
        Object.keys(attributes.specifics).forEach(key => {
          attributes.specifics[key] =
            attributes.specifics[key] && key !== "subscription_external_id"
              ? attributes.specifics[key].replace(/ /g, "")
              : attributes.specifics[key];
        });
      }

      const subscription = {
        "end-date": null,
        "is-primary": true,
        "start-date": new Date(Date.now()).toISOString().split("T")[0],
        specifics: attributes.specifics,
      };

      try {
        await dispatch("auth/fetchClientToken", {}, { root: true });

        await client.members.create(member, subscription);

        commit("registerSuccess", {
          firstname: attributes.firstname,
          username: attributes.username,
        });
        return { success: true };
      } catch (e) {
        commit("registerError", DEFAULT_ERROR);
        return { success: false, code: e.response?.data?.code };
      }
    },
  },
};
