<template>
  <section
    :data-testid="`visual-banner-template--${id}`"
    :style="{ backgroundImage: backgroundImage }"
    class="visual-banner-template"
  >
    <div v-if="title" class="visual-banner-template__darken-background" />
    <div class="visual-banner-template__content">
      <div class="visual-banner-template__visual">
        <img
          :src="optimizedVisualUrl"
          data-testid="visual-banner-template__visual"
          alt="Visual inside the banner"
        />
      </div>

      <div
        data-testid="visual-banner-template__title"
        class="title3 visual-banner-template__title"
      >
        {{ title }}
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.visual-banner-template {
  position: relative;
  height: rem(310px);
  background-position: center;
  background-size: cover;

  &__darken-background {
    position: absolute;
    background-color: black;
    width: 100vw;
    height: inherit;
    opacity: 0.3;
  }

  &__content {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0 rem(16px);
  }

  &__visual {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: rem(130px);

    img {
      height: 100%;
    }
  }

  &__title {
    margin-top: rem(8px);
    @include setColor(--color-text-negative);
  }
}
</style>

<script>
import { MediaType } from "@/constants/media";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    backgroundImageUrl: {
      type: String,
      required: true,
    },
    visualUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },

  computed: {
    optimizedBackgroundImageUrl() {
      return getCloudinaryResponsiveUrl(
        this.backgroundImageUrl,
        MediaType.DETAIL,
      );
    },
    optimizedVisualUrl() {
      return getCloudinaryResponsiveUrl(this.visualUrl);
    },
    backgroundImage() {
      return "url('" + this.optimizedBackgroundImageUrl + "')";
    },
  },

  async mounted() {
    this.$emit("header", { transparent: false });
  },
};
</script>
