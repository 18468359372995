<template>
  <a
    :class="['event-link', type]"
    :id="id"
    :href="resolved"
    v-if="isExternalUrl"
  >
    {{ (isAthenaEnabled && buttonTitle) || "" }}
    <slot />
  </a>
  <a
    :class="['event-link', type]"
    :id="id"
    v-else-if="isToggleCookie"
    @click.prevent="$root.$emit(event, params)"
  >
    <slot />
  </a>
  <component
    v-else-if="showEventLink"
    :is="type === 'button' ? 'Button' : 'router-link'"
    :id="id"
    :to="resolved.route"
    :class="['event-link', type]"
    event=""
    @click.native.prevent="$root.$emit(event, params)"
  >
    {{ (type === "button" && buttonTitle) || "" }}
    <slot />
  </component>
  <div v-else v-show="false" />
</template>

<script>
import { Button } from "@johnpaul/jp-vue-components";
import { resolve } from "@/utilities/conductor";
import { FEATURES, isFeatureEnabled } from "../utilities/features";

export default {
  components: { Button },

  props: {
    id: {
      type: String,
      required: true,
    },
    event: {
      type: String,
      required: true,
    },
    linkComponent: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "link",
      validator: type => ["button", "link"].includes(type),
    },
    slug: {
      type: String,
      default: null,
    },
  },

  computed: {
    isAthenaEnabled() {
      return isFeatureEnabled(FEATURES.ATHENA);
    },
    buttonTitle() {
      return this.isAthenaEnabled
        ? this.linkComponent?.button_title
        : this.linkComponent?.button_name;
    },
    resolved() {
      return resolve(this.event, this.params);
    },
    isExternalUrl() {
      return (
        typeof this.resolved === "string" && this.resolved.startsWith("http")
      );
    },
    isToggleCookie() {
      return this.resolved?.type === "toggle-cookie";
    },
    showEventLink() {
      return this.resolved && (this.type !== "button" || this.buttonTitle);
    },
    params() {
      let parameters = null;
      if (this.linkComponent) {
        if (this.isAthenaEnabled) {
          if (this.linkComponent.button_type === "external") {
            parameters = {
              id: this.linkComponent.id,
              url: this.linkComponent.button_link,
            };
          } else if (this.linkComponent.button_type === "internal") {
            parameters = {
              id: this.linkComponent.id,
              internalPath: this.linkComponent.button_link,
            };
          } else {
            parameters = {
              id: this.linkComponent.link_id,
              template: this.linkComponent.link_template,
              url: this.linkComponent.url,
            };
          }
        } else {
          parameters = {
            id: this.linkComponent.link_id,
            template: this.linkComponent.link_template,
            url: this.linkComponent.url,
          };
        }
      }
      if (this.slug) {
        parameters = {
          ...parameters,
          slug: this.slug,
        };
      }
      return parameters;
    },
  },
};
</script>
