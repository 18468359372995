export default {
  data() {
    return {
      scrollY: window.scrollY || document.documentElement.scrollTop,
    };
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY || document.documentElement.scrollTop;
    },
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  mounted: [
    function() {
      window.addEventListener("scroll", this.handleScroll);
    },
  ],

  beforeDestroy: [
    function() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  ],
};
