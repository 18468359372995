<template>
  <div :class="['fingerprint-modal']">
    <div
      class="fingerprint-modal__background"
      @click.prevent="$emit('close')"
    ></div>
    <div class="fingerprint-modal__content">
      <div class="content">
        <FontIcon name="ui_faceid" class="icon" />
        <p class="text3">{{ $t(`common.modal.fingerprint.${type}.title`) }}</p>
        <p class="text6">
          {{ $t(`common.modal.fingerprint.${type}.text`) }}
        </p>
      </div>
      <div class="buttons">
        <a class="refuse text6" @click="$emit('refuse')">{{
          $t("common.modal.fingerprint.buttons.refuse")
        }}</a>
        <a class="authorize text5" @click="$emit('authorize')">{{
          $t("common.modal.fingerprint.buttons.authorize")
        }}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.fingerprint-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .fingerprint-modal__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(#000, 0.3);
    z-index: 1100;
  }

  .fingerprint-modal__content {
    position: relative;
    z-index: 1101;
    background: #fff;
    border-radius: rem(11px);
    margin-left: rem(33px);
    margin-right: rem(33px);
    overflow: hidden;
    width: 100%;

    .content {
      height: rem(222px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: rem(15px);
      padding-right: rem(15px);

      .icon {
        margin-bottom: rem(26px);
        font-size: rem(24px);
      }
    }

    .buttons {
      display: flex;

      a {
        width: 50%;
        height: rem(50px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }

      .refuse {
        @include setColor(--color-text, color, true);
        background-color: var(--color-cta-negative);
      }

      .authorize {
        background-color: var(--color-cta);
        color: var(--color-cta-negative);
      }
    }
  }
}

.fingerprint-modal__close {
  position: absolute;
  top: rem(24px);
  right: rem(24px);
  width: rem(30px);
  height: rem(30px);
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.fingerprint-modal__close__icon {
  max-width: 100%;
  path {
    fill: var(--color-primary);
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
