<template>
  <div class="trends-details" v-if="detailComponent">
    <div
      class="header-image d-flex justify-content-center align-items-center"
      :style="{
        backgroundImage: `url(${img})`,
      }"
    >
      <div class="image-gradient"></div>
      <a href="" class="back-link" @click.prevent="back">
        <FontIcon name="ui_chevron_left" />
      </a>
    </div>

    <div class="trend-content">
      <h1 class="title1">{{ detailComponent.name }}</h1>
      <div
        class="trend-description text6"
        v-html="detailComponent.description"
      ></div>
    </div>

    <div class="trend-suggestions">
      <div class="title-container">
        <h1 v-if="listComponents" class="suggestions-title text1">
          {{ $t("trends.details.suggestions.title") }}
        </h1>
        <hr class="title-separator" />
      </div>

      <div class="container">
        <div class="row">
          <OfferCardLink
            v-for="component in listComponents"
            :key="component.id"
            class="offer-link col-12 col-md-4"
            :component="component"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.trends-details {
  margin-top: rem(-56px);

  .header-image {
    width: 100%;
    height: rem(315px);
    background-size: cover;
    background-position: center center;
    margin-bottom: rem(5px);

    @include respond-min("medium") {
      margin-bottom: rem(20px);
    }

    .image-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem(160px);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.0001) 21.59%,
        #000000 132.91%
      );
    }
  }

  .back-link {
    position: absolute;
    top: rem(66px);
    left: rem(16px);
    text-decoration: none;

    .font-icon {
      display: block;
      width: rem(34px);
      height: rem(34px);
      padding-top: rem(5px);
      padding-left: rem(4px);
      @include setColor(--color-cta-negative);
      background: rgba(0, 0, 0, 0.5);
      font-size: rem(24px);
      border-radius: 50%;
    }

    @include respond-min("medium") {
      top: rem(94px);
      left: rem(20px);
    }
  }

  .trend-content {
    margin: auto;
    padding: rem(15px);
    @include setColor(--color-background1, background-color);

    @include respond-min("medium") {
      width: rem(900px);
      margin-top: rem(-70px);
      padding: rem(35px 100px);
    }
  }

  h1 {
    @include setColor(--color-title1);
  }

  .trend-description {
    @include setColor(--color-text);

    img {
      height: auto !important;
      width: auto !important;
      max-width: 100%;
    }
  }

  .title-container {
    position: relative;
    margin: rem(24px auto 60px);
    text-align: center;
  }

  .suggestions-title {
    display: inline-block;
    position: relative;
    padding: 0 8px;
    margin: 0;
    @include setColor(--color-title1);
    @include setColor(--color-background1, background-color);
    z-index: 1;
  }

  .title-separator {
    position: absolute;
    top: 50%;
    left: rem(15px);
    right: rem(15px);
    margin: 0;
    @include setColor(--color-divider, border-color);
  }

  .offer-link {
    margin-bottom: rem(15px);
  }

  @include respond-min("medium") {
    .trend-suggestions {
      width: 90vw;
      max-width: rem(1200px);
      margin: auto;
    }

    .offer-link {
      margin-bottom: rem(25px);
    }
  }

  @include respond-min("xlarge") {
    margin-top: rem(-72px);
  }
}
</style>

<script>
import router from "@/router";

import { FontIcon } from "@johnpaul/jp-vue-components";
import OfferCardLink from "@/components/OfferCardLink";

import contentPageMixin from "@/mixins/contentPageMixin";
import { getComponentVendorId } from "@/utilities/tracking";

export default {
  components: {
    FontIcon,
    OfferCardLink,
  },

  mixins: [contentPageMixin],

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    pageId() {
      return this.id;
    },

    detailComponent() {
      const collection = this.pageCollections.find(c => c.type === "detail");
      return this.getComponents(collection)[0];
    },

    listComponents() {
      const listCollection = this.pageCollections.filter(
        c => c.type === "list",
      )[0];

      return listCollection ? this.getComponents(listCollection) : null;
    },

    img() {
      return this.detailComponent?.medias?.[0]?.url || null;
    },
  },

  methods: {
    back() {
      if (window.history.length > 2) {
        router.go(-1);
      } else {
        this.$root.$emit("trend.details.back.fallback:click");
      }
    },
  },

  watch: {
    detailComponent() {
      this.$root.$emit("trend.details:mounted", {
        vendorId: getComponentVendorId(this.detailComponent),
      });
    },
  },

  mounted() {
    this.$emit("header", { transparent: true });
  },
};
</script>
