const ONETRUST = "onetrust";

export const cookieManager = {
  /**
   *
   * @param {String} provider "onetrust" or null, default visa (default in break)
   * @param {*} scriptUrl
   * @param {*} dataOptions
   * @param {*} callback
   */
  install: (provider, scriptUrl, dataOptions, callback) => {
    // dataOptions
    let script = document.createElement("script");
    script.id = "wscrconsent";
    script.src = scriptUrl;
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "UTF-8");
    for (const [key, value] of Object.entries(dataOptions)) {
      if (value) {
        script.setAttribute(key, value);
      }
    }
    document.head.appendChild(script);
    switch (provider) {
      case ONETRUST:
        // cookie onetrust
        window.OptanonWrapper = () => {
          window.OneTrust.OnConsentChanged(event => {
            callback(provider, event);
          });
        };
        break;
      default:
        // cookie visa
        document.addEventListener("wscr.consent", event => {
          callback(provider, event);
        });
    }
  },
  /**
   *
   * @param {String} provider "onetrust" or null
   * @param {String} cookieName
   */
  getCookie: (provider, cookieName) => {
    if (provider === ONETRUST) {
      const value = "; " + decodeURIComponent(document.cookie);
      const parts = value.split("; " + cookieName + "=");
      if (parts.length == 2) {
        return parts[1];
      }
    } else {
      const v = document.cookie.match("(^|;) ?" + cookieName + "=([^;]*)(;|$)");
      return v ? v[2] : null;
    }
  },
  /**
   *
   * @param {String} cookieValue
   * @param {String} trackingCookieIndex
   * @param {*} callback
   */
  checkCategories: (cookieValue, trackingCookieIndex, callback) => {
    const values = cookieValue.split("&");
    values.forEach(value => {
      const splitValue = value.split("=");
      // OneTrust cookies
      if (splitValue[0] === "groups") {
        const categories = splitValue[1].split(new RegExp(",|:|;"));
        const indexTrackingCategory = categories.indexOf(
          `C000${trackingCookieIndex.toString()}`,
        );
        if (
          indexTrackingCategory !== -1 &&
          categories[indexTrackingCategory + 1] === "1"
        ) {
          callback(true);
        }
      }
      // Visa cookies
      if (
        splitValue[0] === trackingCookieIndex.toString() &&
        splitValue[1] === "true"
      ) {
        callback(true);
      }
    });
  },
  /**
   *
   * For Onetrust cookies only
   * @param {String} categories
   * @param {String} trackingCookieIndex
   */
  getCategoriesConsent: (categories, trackingCookieIndex) => {
    return categories.includes(`C000${trackingCookieIndex}`);
  },
};
