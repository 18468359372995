import moment from "moment";

import i18nInstance from "@/i18n";
import { getConfig } from "@/config";
import { getGoogleLocationLink } from "@/utilities/address";
import { formatDate } from "@/utilities/dates";

function parseDate(request, locale) {
  const startDate = moment(request.start_date).utc();

  if (request.end_date) {
    const endDate = moment(request.end_date).utc();
    return i18nInstance.t(
      "request.my-requests.criteria.common.date-range.value",
      {
        startDate: formatDate(startDate.toDate(), locale),
        endDate: formatDate(endDate.toDate(), locale),
      },
    );
  }

  return formatDate(startDate.toDate(), locale);
}

function parseDatetime(request, config, locale) {
  const date = request[config.apiKey];
  const momentDate = moment(date).utc();
  return i18nInstance.t("request.my-requests.criteria.common.datetime.value", {
    date: formatDate(momentDate.toDate(), locale),
    time: momentDate.format("HH:mm"),
  });
}

function parseLocation(request, config) {
  const { street, city, zip_code } = request[config.apiKey];
  const zipCity = [zip_code, city].filter(Boolean).join(" ");
  return street ? `${street}, ${zipCity}` : zipCity;
}

function parseAdditionalInformation(request) {
  return request.description;
}

function parseAdditionalInformationDigital(request) {
  return request.additional_information_digital;
}

function parseRooms(request) {
  return request.number_of_rooms;
}

function parseParticipants(request) {
  const { participants } = request;

  return ["adults", "children", "babies"]
    .filter(field => !!participants[field])
    .map(field =>
      i18nInstance.tc(
        `common.participants.${field}.value`,
        participants[field],
      ),
    )
    .join(i18nInstance.t("common.participants.values-separator"));
}

function parseBudget(request, locale) {
  if (!request.max_budget) return;

  const maxBudget = request.max_budget / 100;
  const localiseKey =
    request.max_budget % 100 === 0
      ? "request.my-requests.criteria.common.budget.value"
      : "request.my-requests.criteria.common.budget.maxValue";

  const formattedValue = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: request.currency || getConfig().options.currency.iso,
    minimumFractionDigits: 0,
  }).format(Math.floor(maxBudget));

  return i18nInstance.t(localiseKey, { val: formattedValue });
}

function parsePaymentMethod(request) {
  if (!request.payment_type) return "";

  const translateKey = request.payment_type.toLowerCase().replace(" ", "-");
  return i18nInstance.t(
    `request.my-requests.criteria.common.payment-method.${translateKey}`,
  );
}

function parseVehicleType(request) {
  if (!request.category_class) return "";

  return i18nInstance.t(
    `common.family.car-rental.category-class.${request.category_class}`,
  );
}

export function getCriteriaValue(config, request, locale) {
  switch (config.type) {
    case "date":
      return parseDate(request, locale);
    case "datetime":
      return parseDatetime(request, config, locale);
    case "location":
    case "start_location":
    case "end_location":
      return parseLocation(request, config);
    case "rooms":
      return parseRooms(request);
    case "participants":
      return parseParticipants(request);
    case "budget":
      return parseBudget(request, locale);
    case "additional-information":
      return parseAdditionalInformation(request);
    case "additional-information-digital":
      return parseAdditionalInformationDigital(request);
    case "payment-method":
      return parsePaymentMethod(request);
    case "vehicle-type":
      return parseVehicleType(request);
    default:
      return request[config.type];
  }
}

export function getLocationLink(request, criteria) {
  const location = request[criteria.apiKey];

  return location.street
    ? {
        href: getGoogleLocationLink(location),
        event: "my-requests.criteria.location:click",
      }
    : null;
}
