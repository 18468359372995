const Trends = () => import(/* webpackChunkName: "trends" */ "./views/Trends");

const TrendDetails = () =>
  import(/* webpackChunkName: "trends" */ "./views/TrendDetails");

export default [
  {
    path: "/trends",
    name: "trends",
    component: Trends,
    meta: {
      requiresAuth: true,
      title: "common.head.title.trends",
      template: "trends",
    },
  },
  {
    path: "/trends/details/:id",
    name: "trends/details",
    component: TrendDetails,
    props: true,
    meta: {
      requiresAuth: true,
      template: "trend-details",
    },
  },
];
