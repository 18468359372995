<template>
  <transition name="pop-in-modal" v-if="showModal">
    <div
      class="pop-in-modal__backdrop"
      @mousedown="closeModal"
      @touchstart="closeModal"
    >
      <div
        class="pop-in-modal__wrapper"
        @mousedown="$event.stopPropagation()"
        @touchstart="$event.stopPropagation()"
      >
        <div class="pop-in-modal__container">
          <img
            :src="image"
            alt="Newsletter Modal Image"
            class="pop-in-modal__image"
          />
          <div class="pop-in-modal__content" v-show="!confirmation">
            <div class="pop-in-modal__title title1">
              {{ $t("common.newsletter-modal.title") }}
            </div>
            <div class="pop-in-modal__text text4">
              {{ $t("common.newsletter-modal.text") }}
            </div>
            <div class="pop-in-modal__cta">
              <Button :id="'pop-in-modal__cta'" @click.native="subscribe">
                {{ $t("common.newsletter-modal.cta") }}
              </Button>
            </div>
          </div>
          <transition name="pop-in-modal__confirmation">
            <div class="pop-in-modal__content" v-show="confirmation">
              <div class="pop-in-modal__title title1">
                {{ $t("common.newsletter-modal.confirmation.title") }}
              </div>
              <div class="pop-in-modal__text text4">
                <FontIcon name="ui_done" class="pop-in-modal__check" />
                {{ $t("common.newsletter-modal.confirmation.text") }}
              </div>
              <div class="pop-in-modal__cta">
                <Button :id="'pop-in-modal__cta'" @click.native="confirm">
                  {{ $t("common.newsletter-modal.confirmation.cta") }}
                </Button>
              </div>
            </div>
          </transition>
          <FontIcon
            name="ui_close"
            class="pop-in-modal__close"
            @click.native="closeModal"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.pop-in-modal {
  &__backdrop {
    position: fixed;
    overflow: auto;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease;

    @include respond-min("medium") {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__wrapper {
    z-index: 10000;

    @include respond-min("medium") {
      margin: auto;
      max-width: rem(434px);
    }

    @include respond-min("large") {
      max-width: none;
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: rem(96px) rem(16px) 0;
    padding: rem(46px);
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 12px;

    @include respond-min("medium") {
      margin-top: 0;
    }
  }

  &__image {
    max-width: rem(692px);
    width: 100%;
  }

  &__content {
    font-size: rem(16px);
    text-align: center;
    margin: 0 auto;

    @include respond-min("medium") {
      width: 60%;
    }
  }

  &__title {
    margin-top: rem(36px);
  }

  &__text {
    margin-bottom: rem(56px);
  }

  &__check {
    color: var(--color-status-success);
    margin-right: rem(8px);
  }

  &__cta {
    margin: 0 auto;
    width: 70%;
  }

  &__confirmation {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

  &__close {
    position: absolute;
    top: rem(16px);
    right: rem(16px);
    cursor: pointer;
    font-size: 1.5rem;
  }

  &-enter,
  &-leave-active {
    opacity: 0;
  }

  &-enter &__container,
  &-leave-active &__container {
    transform: scale(1.1);
  }
}
</style>

<script>
import { Button, FontIcon } from "@johnpaul/jp-vue-components";

import { getModuleConfig } from "@/utilities/config";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    Button,
    FontIcon,
  },

  data() {
    const newsletterConfig = getModuleConfig(this.$config, "newsletter-modal");

    const { options } = this.$modules["my-account"].modules.preferences;

    return {
      dismiss: false,
      confirmation: false,
      image: newsletterConfig.options.image,
      remindDelay: newsletterConfig.options.remindDelay,
      fields: options.form,
      values: {},
    };
  },

  computed: {
    ...mapState({
      member: state => state.member.data,
    }),

    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),

    showModal() {
      return (
        !this.dismiss &&
        this.member &&
        this.loggedIn &&
        ((!this.values["email"] && !this.confirmation) ||
          (this.values["email"] && this.confirmation)) &&
        this.shouldRemindNewsletter()
      );
    },
  },

  methods: {
    ...mapActions({
      update: "my-account/notifications/update",
    }),

    closeModal: function() {
      this.dismiss = true;
    },
    subscribe: function() {
      this.values["email"] = true;
      this.update(this.values);
      this.confirmation = true;
      this.$root.$emit("newsletter:validate");
      this.$root.$emit("newsletter.page:confirm");
    },
    shouldRemindNewsletter: function() {
      if (typeof window.localStorage !== "undefined") {
        let remindDate = window.localStorage.getItem("newsletterDelay");
        let now = new Date();
        if (!remindDate || new Date(remindDate) < now || this.confirmation)
          return true;
      }

      return false;
    },
    updateNewsletterDelay: function(date) {
      date.setDate(date.getDate() + parseInt(this.remindDelay));
      window.localStorage.setItem("newsletterDelay", date.toISOString());

      return true;
    },

    confirm() {
      this.$root.$emit("newsletter.click:confirm");
      this.closeModal();
    },
  },

  created() {
    if (this.member) {
      const attributes = this.member.marketing;
      for (const field of this.fields) {
        this.$set(this.values, field, attributes[field]);
      }
    }
  },
  mounted() {
    if (this.showModal) {
      let now = new Date();
      this.updateNewsletterDelay(now);
      this.$root.$emit("newsletter:mounted");
    }
  },
};
</script>
