<template>
  <component
    :is="blockTemplate"
    :title="title"
    :subtitle="subtitle"
    :description="description"
    :image-url="imgUrl"
  />
</template>

<script>
import { BLOCK_TEMPLATE } from "../../blocks";
import DetailTemplate from "../../templates/blocks/detail/DetailTemplate";

export default {
  props: {
    simpleBlockItems: {
      type: Array,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    blockTemplate() {
      return BLOCK_TEMPLATE[this.details.display_type] || DetailTemplate;
    },
    components() {
      return this.simpleBlockItems.map(
        simpleBlockItem => simpleBlockItem.components?.[0],
      );
    },
    cardComponent() {
      return this.components.find(component => component?.type === "card");
    },
    imgUrl() {
      return this.cardComponent?.medias?.[0]?.url;
    },
    title() {
      return this.cardComponent?.title;
    },
    subtitle() {
      return this.cardComponent?.subtitle;
    },
    description() {
      return this.cardComponent?.description;
    },
  },
};
</script>
