import { mapActions, mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      collections: state => state.content.collections,
      pageCollections(state) {
        return state.content.pagesById?.[this.pageId]?.results || [];
      },
    }),

    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),
  },

  watch: {
    async pageId() {
      await this.fetchPage(this.pageId);
      this.loadComponents();
    },
  },

  methods: {
    ...mapActions({
      fetchClientToken: "auth/fetchClientToken",
      fetchPage: "content/fetchPage",
      fetchComponents: "content/fetchComponents",
    }),

    getComponents(collection) {
      if (!collection) return [];
      return this.collections[collection.slug]?.components || [];
    },

    loadComponents() {
      this.pageCollections.forEach(collection => {
        this.fetchComponents({ collectionName: collection.slug });
      });
    },
  },

  mounted: [
    async function() {
      if (!this.loggedIn) {
        await this.fetchClientToken();
      }
      await this.fetchPage(this.pageId);
      this.loadComponents();
    },
  ],
};
