<template>
  <EventLink
    :id="`link--${component.id}`"
    class="offer-card-link"
    event="common.cms.link:click"
    :linkComponent="component"
  >
    <ContentCard
      :id="component.id"
      :src="src('image')"
      :logo="src('logo')"
      :title="title"
      :subtitle="subtitle"
      :date="date"
      :description="computedDescription"
      :fixedHeight="fixedHeight"
    />
  </EventLink>
</template>

<style lang="scss">
.offer-card-link {
  text-decoration: none !important;
}
</style>

<script>
import { mapState } from "vuex";

import { ContentCard } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";

import inViewport from "vue-in-viewport-mixin";

import { formatDate } from "@/utilities/dates";

import { getComponentBookingProcess } from "@/utilities/parsers";
import { FEATURES, isFeatureEnabled } from "@/utilities/features";

export default {
  components: {
    EventLink,
    ContentCard,
  },

  mixins: [inViewport],

  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    "in-viewport-active": {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
    }),

    isAthenaEnabled() {
      return isFeatureEnabled(FEATURES.ATHENA);
    },

    date() {
      const endDate = this.isAthenaEnabled
        ? this.component.end_date
        : this.component?.offers?.[0]?.end_date;
      return endDate
        ? this.$t("common.offer-card.end-date", {
            date: formatDate(new Date(endDate), this.locale),
          })
        : null;
    },

    title() {
      return this.isAthenaEnabled
        ? this.component.vendor?.name
        : this.component.name;
    },

    subtitle() {
      if (this.component.subname) return this.component.subname;

      const isOnline =
        getComponentBookingProcess(this.component) !== "concierge";

      if (isOnline) {
        return this.$t("common.details.subtitle.online");
      }
      return this.component.address?.formatted_address;
    },

    computedDescription() {
      return this.description || this.component.offers?.[0]?.name || null;
    },
  },

  methods: {
    src(mediaType) {
      const offerMedia = this.component?.offers?.[0]?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        null,
      );
      const vendorMedia = this.component?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        "",
      );
      return offerMedia ? offerMedia : vendorMedia;
    },
  },

  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        this.$emit("display");
      }
    },
  },
};
</script>
