import { getViewportWidth, isMobile } from "@/utilities/responsive";

export function getCloudinaryUrl(template, options = {}) {
  return template
    .replace("{size}", options.size || "dpr_auto")
    .replace("{name}", options.name)
    .replace("{extension}", options.extension || "png");
}

const scaleDownCloudinaryImage = (url, maximumWidth) => {
  const urlItems = url.split("/");
  let cScaleParameters = urlItems
    .find(urlItem => urlItem.includes("c_scale"))
    .split(",");
  const scaleWidthValue = cScaleParameters
    .find(parameter => parameter.includes("w_"))
    ?.split("_")?.[1];
  const scaleHeightValue = cScaleParameters
    .find(parameter => parameter.includes("h_"))
    ?.split("_")?.[1];
  if (scaleWidthValue && scaleHeightValue && scaleWidthValue > maximumWidth) {
    const reductionCoefficient = scaleWidthValue / maximumWidth;
    let newCScaleParams = [];
    cScaleParameters.forEach(parameter => {
      if (parameter.includes("w_"))
        parameter = `w_${Math.ceil(scaleWidthValue / reductionCoefficient)}`;
      else if (parameter.includes("h_"))
        parameter = `h_${Math.ceil(scaleHeightValue / reductionCoefficient)}`;
      newCScaleParams.push(parameter);
    });
    cScaleParameters = newCScaleParams;
  }
  return urlItems
    .map(urlItem => {
      if (urlItem.includes("c_scale")) return cScaleParameters.join(",");
      return urlItem;
    })
    .join("/");
};

const MAX_WIDTH_BY_MEDIA_TYPE = {
  detail: 2000,
  image: 600,
  logo: 400,
};

function pruneCloudinaryUrl(url) {
  // First we remove the manual "/w_2000" addition in URL
  url = url.replace("/w_2000", "");

  // Then, we remove default params when there is no crop or any other transformation
  // URL example for which we will remove the default parameters :
  //  - https://res.cloudinary.com/john-paul/image/upload/c_fill,g_center,h_5397,w_8095/v1/test
  // URL example for which we will NOT remove them :
  //  - https://res.cloudinary.com/john-paul/image/upload/a_90/a_hflip/a_vflip/c_fill,g_center,h_3598,w_5397/v1/test
  //  - https://res.cloudinary.com/john-paul/image/upload/c_crop,h_3116,w_4674,x_209,y_1001/c_scale,h_3116,w_4674/v1/test
  let urlParts = url.split("/");
  const indexOfDefaultParams = urlParts.indexOf("upload") + 1;
  if (indexOfDefaultParams !== 0) {
    const defaultParamsUrlParts = urlParts[indexOfDefaultParams];
    if (
      !defaultParamsUrlParts.includes("c_crop") &&
      defaultParamsUrlParts.includes("w_") &&
      defaultParamsUrlParts.includes("h_")
    ) {
      urlParts.splice(indexOfDefaultParams, 1);
    }
  }
  return urlParts.join("/");
}

export function getCloudinaryResponsiveUrl(
  url,
  mediaType = "",
  fitScreenWidth = false,
) {
  if (!url) return url;
  let newUrl = url;
  newUrl = pruneCloudinaryUrl(newUrl); // REMOVE DEFAULT PARAMS
  const isResizedAsset = url.includes("c_scale");

  const width =
    MAX_WIDTH_BY_MEDIA_TYPE[mediaType] && !fitScreenWidth
      ? MAX_WIDTH_BY_MEDIA_TYPE[mediaType]
      : getViewportWidth(100);
  if (isResizedAsset) newUrl = scaleDownCloudinaryImage(url, width);

  // Generate cloudinary image options
  const dpr = isMobile() ? 2 : 1;
  const responsiveOptions = isResizedAsset
    ? `q_auto,dpr_${dpr},c_fill`
    : `w_${width},q_auto,dpr_${dpr},c_fill,g_auto`;
  if (url.indexOf("cloudinary.com") === -1) return newUrl;

  // if no q_auto add it
  let parts = newUrl.split("/");
  if (!parts.includes("q_auto")) {
    parts = parts.map(part => {
      return part === "upload" ? "upload/q_auto" : part;
    });
    newUrl = parts.join("/");
  }

  // Replace options in the cloudinary url
  return newUrl
    .split("/")
    .map(urlPart => {
      return urlPart.indexOf("q_auto") !== -1 ||
        urlPart.indexOf("dpr_auto") !== -1
        ? responsiveOptions
        : urlPart;
    })
    .join("/");
}

export function parseComponentImages(component) {
  const medias = component.medias || [];
  const offers = component.offers || [];

  return {
    ...component,
    medias: medias.map(media => ({
      ...media,
      url: getCloudinaryResponsiveUrl(media.url),
    })),
    offers: offers.map(offer => ({
      ...offer,
      medias: offer.medias.map(media => ({
        ...media,
        url: getCloudinaryResponsiveUrl(media.url),
      })),
    })),
  };
}

export function parseBlockItemMedias(blockItem) {
  return {
    ...blockItem,
    components: blockItem.components?.map(component => ({
      ...component,
      medias: component.medias?.map(media => ({
        ...media,
        url: getCloudinaryResponsiveUrl(media.url, media.type),
      })),
      vendor: {
        ...component.vendor,
        medias: component.vendor?.medias?.map(media => ({
          ...media,
          url: getCloudinaryResponsiveUrl(media.url, media.type),
        })),
      },
    })),
  };
}

export function parseBlockItemComponentMedias(
  component,
  fitScreenWidth = false,
) {
  return {
    ...component,
    medias: component.medias?.map(media => ({
      ...media,
      url: getCloudinaryResponsiveUrl(media.url, media.type, fitScreenWidth),
    })),
    vendor: {
      ...component.vendor,
      medias: component.vendor?.medias?.map(media => ({
        ...media,
        url: getCloudinaryResponsiveUrl(media.url, media.type, fitScreenWidth),
      })),
    },
  };
}
