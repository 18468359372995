<template>
  <section
    :style="{ backgroundImage: backgroundImage }"
    class="banner-template"
  >
    <div v-if="hasText" class="banner-template__darken-background"></div>
    <div class="banner-template__content">
      <div class="text6 banner-template__title">{{ title }}</div>
      <div v-if="subtitle" class="super-title3 banner-template__subtitle">
        {{ subtitle }}
      </div>
      <div
        v-if="description"
        class="text6 banner-template__description"
        v-html="description"
      ></div>
      <EventLink
        v-if="buttonType"
        :id="`button--banner--${id}`"
        :linkComponent="buttonComponent"
        :event="event"
        type="button"
        class="banner-template__button"
      />
    </div>
  </section>
</template>

<style lang="scss">
.banner-template {
  position: relative;
  height: rem(380px);
  background-position: center;
  background-size: cover;

  &__darken-background {
    position: absolute;
    background-color: black;
    width: 100vw;
    height: inherit;
    opacity: 0.3;
  }

  &__content {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0 rem(16px);
  }

  &__title {
    @include setColor(--color-text-negative);
  }

  &__subtitle {
    @include setColor(--color-title1-negative);
    text-align: center;
  }

  &__description {
    max-width: rem(575px);
    @include setColor(--color-title1-negative);
    text-align: center;
    padding: 0 rem(15px);
  }

  .banner-template__button,
  .banner-template__button:hover {
    margin-top: rem(12px);
    @include setColor(--color-cta-negative, border-color);
    @include setColor(--color-cta-negative);
    border: 1px solid;
    background-color: transparent;
    max-width: rem(343px);
  }

  @include respond-min("medium") {
    height: rem(415px);

    &__description {
      margin-top: rem(24px);
      padding: 0;
    }
  }
}
</style>

<script>
import EventLink from "@/components/EventLink";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";

export default {
  components: {
    EventLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      required: true,
    },
    buttonComponent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    buttonTitle() {
      return this.buttonComponent?.button_title || "";
    },
    buttonType() {
      return this.buttonComponent?.button_type;
    },
    hasText() {
      return this.title || this.subtitle || this.description;
    },
    event() {
      return this.buttonType === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },
    optimizedImageUrl() {
      return getCloudinaryResponsiveUrl(this.imageUrl, "detail");
    },
    backgroundImage() {
      return "url('" + this.optimizedImageUrl + "')";
    },
  },
};
</script>
