const Page = () =>
  import(/* webpackChunkName: "dynamic-content" */ "./views/Page");

const Faq = () =>
  import(/* webpackChunkName: "dynamic-content" */ "./views/Faq");

const ContentDetails = () =>
  import(/* webpackChunkName: "dynamic-content" */ "./views/ContentDetails");

const VendorDetails = () =>
  import(/* webpackChunkName: "dynamic-content" */ "./views/VendorDetails");

const EditorialDetails = () =>
  import(/* webpackChunkName: "dynamic-content" */ "./views/EditorialDetails");

export default [
  {
    path: "/:slug/details/:sublinks*/:id",
    name: "page-details-content",
    component: ContentDetails,
    props: true,
    meta: {
      requiresAuth: true,
      template: "page-details-content",
    },
  },
  {
    path: "/editorial-details/:id",
    name: "editorial-details",
    component: EditorialDetails,
    props: true,
    meta: {
      requiresAuth: true,
      template: "editorial-details",
    },
  },
  {
    path: "/vendor/:id",
    name: "vendor",
    component: VendorDetails,
    props: true,
    meta: {
      requiresAuth: true,
      hideWidgets: true,
      template: "vendor-details",
    },
  },
  {
    path: "/:slug",
    name: "page-content",
    component: Page,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/public/:slug",
    name: "page-content-unlogged",
    component: Page,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/public/faq/:topic",
    name: "page-content-faq",
    component: Faq,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "home-unlogged",
    component: Page,
    props: { slug: "home-unlogged" },
    meta: {
      title: "common.head.title.home-unlogged",
      redirectAuth: true,
    },
  },
];
