export const DEFAULT_ERROR = "unknown";

export const REGISTER_ACCOUNT_ALREADY_EXISTS = "alreadyExists";

export const LOGIN_ACCOUNT_NOT_VERIFIED = "email_verification";

export const TOKEN_WRONG_CREDENTIALS = "wrongCredentials";
export const TOKEN_LOCKED = "locked";

export const PASSWORD_WRONG = "wrongPrevious";
export const PASSWORD_SAME_AS_CURRENT = "sameAsCurrent";
export const PASSWORD_KNOWN = "known_password";

export default {
  // Register errors
  already_exists: REGISTER_ACCOUNT_ALREADY_EXISTS,

  // Token fetching errors
  invalid_grant: TOKEN_WRONG_CREDENTIALS,
  locked: TOKEN_LOCKED,

  // Update password errors
  wrong_password: PASSWORD_WRONG,
  same_as_previous_password: PASSWORD_SAME_AS_CURRENT,
  known_password: PASSWORD_KNOWN,

  // Login errors
  email_verification: LOGIN_ACCOUNT_NOT_VERIFIED,
};
