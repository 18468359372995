<template>
  <portal v-if="newsComponent" to="portal-root">
    <div class="news-banner text8">
      <span>
        {{ newsComponent.name }}
        <a
          v-if="newsComponent.url"
          class="news-banner-link text7"
          :href="newsComponent.url"
          target="_blank"
          rel="noopener noreferrer"
          >{{ newsComponent.button_name }}</a
        >
        <EventLink
          v-else
          :id="`link--news-banner`"
          class="news-banner-link text7"
          event="common.cms.link:click"
          :linkComponent="newsComponent"
          >{{ newsComponent.button_name }}</EventLink
        >
        <FontIcon
          class="close-icon"
          name="ui_close"
          @click.native="addId(newsComponent.id)"
        />
      </span>
    </div>
  </portal>
</template>

<style lang="scss">
.news-banner {
  position: relative;
  display: flex;
  align-items: center;
  min-height: rem(60px);
  padding: rem(10px 36px 10px 16px);
  @include setColor(--color-background3, background-color);
  @include setColor(--color-text-negative);
  z-index: 1;

  @include respond-min("medium") {
    padding: rem(10px 75px);
    justify-content: center;
  }

  .news-banner-link {
    display: inline;
    margin-left: rem(5px);
    @include setColor(--color-text-negative);
    text-decoration: underline;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(15px);
    font-size: rem(16px);
    cursor: pointer;

    @include respond-min("medium") {
      right: rem(45px);
    }
  }
}
</style>

<script>
import { mapMutations, mapState } from "vuex";

import { FontIcon } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";

import { getModuleConfig } from "@/utilities/config";
import contentPageMixin from "@/mixins/contentPageMixin";

export default {
  components: { EventLink, FontIcon },

  mixins: [contentPageMixin],

  data() {
    const moduleConfig = getModuleConfig(this.$config, "news-banner");
    return {
      pageId: moduleConfig.options.pageId,
    };
  },

  computed: {
    ...mapState({
      closedComponentIds: state => state["news-banner"].ids,
    }),

    newsComponent() {
      const collection = this.pageCollections.find(c => c.type === "banner");
      const currentNewsComponent = this.getComponents(collection)[0];

      if (
        currentNewsComponent &&
        !this.closedComponentIds.includes(currentNewsComponent.id)
      ) {
        return currentNewsComponent;
      }
      return null;
    },
  },

  methods: {
    ...mapMutations({
      addId: "news-banner/addId",
    }),
  },
};
</script>
