<template>
  <a class="filters-overlay" href="" @click.prevent>
    <div class="filters-header">
      <FontIcon name="ui_close" @click.native.prevent="$emit('close')" />
      <span class="title text5">{{ $t("common.filters.button-label") }}</span>
      <a href="" class="link2" @click.prevent="$emit('reset')">
        {{ $t("common.filters.reset") }}
      </a>
    </div>
    <div class="filters-wrapper">
      <div v-for="filter in filters" :key="filter.key">
        <div class="filter-name text5">
          {{ $t(`common.filters.name.${filter.key}`) }}
        </div>

        <CityFilter
          v-if="filter.key === 'city'"
          :filter="filter"
          :collection="collection"
          @option-change="$emit('option-change', $event)"
          @reset="$emit('reset', 'city')"
        />

        <div v-else class="mb-3">
          <FilterOption
            v-for="option in filter.options"
            :id="`input--filter-${filter.key}-${option.key}`"
            :key="option.key"
            :label="$t(`common.filters.${filter.key}.${option.key}`)"
            :value="option.value || false"
            @update:value="
              $emit('option-change', {
                filter: filter.key,
                option: option.key,
                value: $event,
              })
            "
          />
        </div>

        <hr class="separator" />
      </div>
    </div>
    <div class="filters-footer">
      <Button @click.native.prevent="$emit('close')" id="button--filters-apply">
        {{ $t("common.filters.see-results") }} ({{ collection.count }})
      </Button>
    </div>
  </a>
</template>

<style lang="scss">
a.filters-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  text-decoration: none;
  padding: rem(60px 0 45px);
  overflow-y: auto;
  z-index: 1045;
  @include setColor(--color-text);
  @include setColor(--color-background1, background-color);

  .filters-header {
    position: fixed;
    height: rem(56px);
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(0 16px);
    @include setColor(--color-background1, background-color);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    z-index: 2;

    .title {
      @include setColor(--color-title1);
    }

    a {
      @include setColor(--color-cta);
      text-decoration: none;
    }

    .font-icon {
      font-size: rem(24px);
      cursor: pointer;
    }
  }

  .filters-wrapper {
    padding: rem(15px);
  }

  .filter-name {
    @include setColor(--color-title1);
    margin-bottom: rem(25px);
  }

  .separator {
    margin: rem(36px 0 19px);
    @include setColor(--color-divider, border-color);
  }

  .filters-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: rem(20px 16px 16px);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    @include setColor(--color-background1, background-color);
  }
}
</style>

<script>
import { Button, FontIcon } from "@johnpaul/jp-vue-components";
import FilterOption from "./FilterOption";
import CityFilter from "./CityFilter";

export default {
  components: { Button, FilterOption, CityFilter, FontIcon },

  props: {
    collection: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: () => [],
    },
  },
};
</script>
