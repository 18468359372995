<template>
  <div :class="['description', expanded && 'expanded']">
    <div class="text6 short-description" v-html="shortDescription" />
    <div class="text6 long-description" v-html="description" />
    <a
      v-if="showToggleButton"
      :class="['toggle-button', 'text7']"
      @click.prevent="expanded = !expanded"
    >
      <template v-if="expanded">
        {{ $t("my-assistant.description.see-less") }}
      </template>
      <template v-else>
        {{ $t("my-assistant.description.see-more") }}
      </template>
      <FontIcon name="ui_chevron_dropdown" />
    </a>
  </div>
</template>

<style lang="scss">
.description {
  width: rem(900px);
  margin: auto;
  margin-top: rem(-35px);
  padding: rem(35px 100px);
  @include setColor(--color-background1, background-color);
  @include setColor(--color-text);
  overflow-y: hidden;

  @include respond-to("medium") {
    width: auto;
    margin-top: 0;
    padding: rem(15px);
  }

  .toggle-button {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
    position: relative;
    text-decoration: none;
    @include setColor(--color-cta);

    .font-icon {
      margin-left: 8px;
    }
  }

  .short-description {
    display: block;
    margin-bottom: rem(-10px);

    -webkit-mask-image: linear-gradient(
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    mask-image: linear-gradient(rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  }

  .long-description {
    display: none;
  }

  &.expanded {
    .short-description {
      display: none;
    }

    .long-description {
      display: block;
    }

    .toggle-button .font-icon {
      display: inline-block;
      transform: rotate(180deg);
    }
  }

  @include respond-min("medium") {
    .short-description {
      display: none !important;
    }

    .long-description {
      display: block !important;
    }

    .toggle-button {
      display: none;
    }
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

const descriptionLimit = 260;

export default {
  components: {
    FontIcon,
  },

  data() {
    return {
      expanded: false,
    };
  },

  props: {
    description: {
      type: String,
      required: true,
    },
  },

  computed: {
    showToggleButton() {
      return this.description.length > descriptionLimit;
    },

    shortDescription() {
      if (!this.showToggleButton) return;
      const limit = this.description.indexOf(" ", descriptionLimit);
      return limit > -1 ? this.description.substr(0, limit) : this.description;
    },
  },
};
</script>
