export const industryItems = [
  "agriculture",
  "apparel",
  "banking",
  "biotechnology",
  "chemicals",
  "communications",
  "construction",
  "consulting",
  "education",
  "electronics",
  "energy",
  "engineering",
  "entertainment",
  "environmental",
  "finance",
  "food-beverage",
  "government",
  "healthcare",
  "hospitality",
  "insurance",
  "machinery",
  "manufacturing",
  "media",
  "not-for-profit",
  "other",
  "recreation",
  "retail",
  "shipping",
  "technology",
  "telecommunications",
  "transportation",
  "utilities",
];

export const organizationSizes = ["1", "2-10", "11-50", "51-250", "+250"];
