<template>
  <div class="city-guides-list">
    <p class="list-title text3">{{ textComponent.name }}</p>
    <div class="list">
      <div class="cards-wrapper">
        <template v-for="(component, idx) in mediaComponents">
          <InViewWrapper
            :key="component.id"
            @in-view="trackPublisherDisplay(idx)"
          >
            <EventLink
              :key="component.id"
              :id="`button--list-item--${component.id}`"
              class="offer"
              event="common.cms.link:click"
              :linkComponent="component"
              @mousedown.native="trackPublisherClick(idx)"
            >
              <ContentCard
                :src="prepareBenefit(component).url"
                :title="component.name"
                :subtitle="
                  (component.address && component.address.formatted_address) ||
                    $t('city-guides.content.vendor.online')
                "
              />
            </EventLink>
          </InViewWrapper>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.city-guides-list {
  margin: auto;

  .list {
    width: 100%;
    overflow-x: auto;
    padding-bottom: rem(24px);
  }

  .cards-wrapper {
    display: inline-flex;
    padding-left: rem(15px);
  }

  .list-title {
    margin: rem(0 15px 15px);
    @include setColor(--color-title1);
    text-transform: uppercase;
  }

  .offer {
    display: inline-block;
    width: rem(300px);
    min-width: rem(300px);
    margin-right: rem(15px);

    > * {
      height: 100%;
    }
  }

  @include respond-min("medium") {
    .cards-wrapper {
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: rem(40px);
    }

    .offer {
      display: block;
      width: 32%;
      text-decoration: none;
      margin-bottom: rem(15px);
    }
  }
}
</style>

<script>
import { ContentCard } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";
import InViewWrapper from "@/components/InViewWrapper";
import {
  getComponentVendorId,
  getComponentBenefitId,
} from "@/utilities/tracking";
import { mapState } from "vuex";

export default {
  components: {
    EventLink,
    ContentCard,
    InViewWrapper,
  },

  props: {
    title: {
      type: String,
    },
    slug: {
      type: String,
    },
    components: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
    }),

    mediaComponents() {
      return this.components.filter(c => c.type !== "text");
    },

    textComponent() {
      return this.components.find(c => c.type === "text") || {};
    },
  },

  methods: {
    prepareBenefit(benefit) {
      return {
        offerName: benefit?.offers?.[0]?.name || null,
        url: benefit?.medias?.[0]?.url || null,
        id: benefit.id,
      };
    },
    getPublisherParams(idx) {
      const component = this.mediaComponents[idx];
      const benefitId = getComponentBenefitId(component);
      return {
        city: "cities",
        category: this.slug,
        benefitId,
        positionItem: idx + 1,
        nbItems: this.mediaComponents.length,
        memberId: this.memberId,
        vendorId: getComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },
    trackPublisherClick(idx) {
      const publisherParams = this.getPublisherParams(idx);
      this.tracker.publishers.contents.clickBenefit(publisherParams);
    },
    trackPublisherDisplay(idx) {
      const publisherParams = this.getPublisherParams(idx);
      this.tracker.publishers.contents.benefit(publisherParams);
    },
  },
};
</script>
