import client from "@/plugins/jp-api-client";

import { DEFAULT_ERROR } from "@/constants/errors";
import { getErrorCode } from "@/utilities/errors";
import { parseGoogleAddress } from "@/utilities/parsers";
import { FEATURES, isFeatureEnabled } from "@/utilities/features";

import { getStore } from "@/store";

export default {
  namespaced: true,

  state: {
    requests: null,
    fetchingRequests: false,
    fetchRequestsError: null,

    creatingRequest: false,
    createRequestError: null,

    cancellingRequest: false,
    cancelRequestError: null,

    updatingRequest: false,
    updateRequestError: null,

    category: null,
    vendor: null,
    content: null,
    createdRequestId: null,

    hasRecommendation: true,

    firstConnectedVisit: true,

    campaignName: null,
    firstCampaignVisit: false,
  },

  mutations: {
    fetchRequestsStart(state) {
      state.fetchingRequests = true;
      state.fetchRequestsError = null;
    },
    fetchRequestsSuccess(state, requests) {
      state.fetchingRequests = false;
      state.requests = requests;
    },
    fetchRequestsError(state, error) {
      state.fetchingRequests = false;
      state.fetchRequestsError = error;
      state.requests = null;
    },

    createRequestStart(state) {
      state.creatingRequest = true;
      state.createRequestError = null;
    },
    createRequestSuccess(state, request) {
      state.creatingRequest = false;
      state.createdRequestId = request.id;
    },
    createRequestError(state, error) {
      state.creatingRequest = false;
      state.createRequestError = error;
    },

    cancelRequestStart(state) {
      state.cancellingRequest = true;
      state.cancelRequestError = null;
    },
    cancelRequestSuccess(state) {
      state.cancellingRequest = false;
    },
    cancelRequestError(state, error) {
      state.cancellingRequest = false;
      state.cancelRequestError = error;
    },

    updateRequestStart(state) {
      state.updatingRequest = true;
      state.updateRequestError = null;
    },
    updateRequestSuccess(state) {
      state.updatingRequest = false;
    },
    updateRequestError(state, error) {
      state.updatingRequest = false;
      state.cancelRequestError = error;
    },

    setCategory(state, category) {
      state.category = category || null;
    },

    setVendor(state, vendor) {
      state.vendor = vendor || null;
    },

    setContent(state, content = null) {
      state.content = content;
    },

    setRecommendation(state, hasRecommendation) {
      state.hasRecommendation = hasRecommendation;
    },

    updateRequestStatus(state, payload) {
      const requestId = payload.id;
      const newStatus = payload.attributes.status;

      const request = state.requests.find(r => r.id === requestId);
      request.status = newStatus;
    },

    resetErrors(state) {
      state.createRequestError = null;
      state.cancelRequestError = null;
    },

    resetRequest(state) {
      state.category = null;
      state.vendor = null;
      state.content = null;
      state.hasRecommendation = true;
    },

    resetRequests(state) {
      state.requests = null;
    },

    setFirstConnectedVisit(state, payload) {
      state.firstConnectedVisit = payload || false;
    },

    setCampaignName(state, campaignName = null) {
      state.campaignName = campaignName;
      state.firstCampaignVisit = true;
    },
  },

  actions: {
    async fetchRequests({ commit }) {
      commit("fetchRequestsStart");

      try {
        const response = await client.requests.fetch("me", {
          "items-per-page": 200,
        });

        commit("fetchRequestsSuccess", response.data.results);

        return { success: true };
      } catch (error) {
        commit("fetchRequestsError", getErrorCode(error));

        return { success: false };
      }
    },

    async createRequest(
      { rootState, state, commit, dispatch },
      { attributes },
    ) {
      commit("createRequestStart");

      const isAthenaEnabled = isFeatureEnabled(FEATURES.ATHENA);
      const vendor = state.vendor || {};
      const content = state.content || {};
      const contentId = isAthenaEnabled
        ? content.parent_id
        : vendor.offers?.[0]?._id_legacy;
      const vendorId = isAthenaEnabled ? vendor.id : vendor._id_legacy;

      const payload = {
        ...attributes,
        family: state.category.familyId,
        subfamily: state.category.subfamilyId || vendor.subfamily,
        language: rootState.i18n.locale,
        currency: attributes.max_budget
          ? getStore().state.config.options.currency.iso
          : null,
        notes: null,

        benefit_id: contentId || null,
        vendor_id: vendorId || null,
        location: vendor.address
          ? parseGoogleAddress(vendor.address)
          : attributes.location,
        subject: vendor.name || attributes.subject,
        source_type: isAthenaEnabled ? "athena" : "content",

        options: [],
        "is-recommandation-reservation": state.hasRecommendation
          ? "Recommendation"
          : "Reservation",

        participants: {
          adults: attributes.participants?.adults || 0,
          children: attributes.participants?.children || 0,
          babies: attributes.participants?.babies || 0,
        },
      };

      if (state.campaignName) {
        payload["ref-push"] = state.campaignName;
      }

      try {
        const request = await client.requests.post(
          state.category.familyId,
          payload,
        );
        commit("createRequestSuccess", request.data.data);

        if (state.requests !== null) {
          commit("resetRequests");
          dispatch("fetchRequests");
        }

        return { success: true };
      } catch (e) {
        commit("createRequestError", DEFAULT_ERROR);
        return { success: false };
      }
    },

    async updateRequest({ commit }, { request, message }) {
      commit("updateRequestStart");

      try {
        const res = await client.requests.comment(
          request.id,
          request.family,
          message,
        );

        commit("updateRequestStatus", res.data.data);
        commit("updateRequestSuccess");

        return { success: true };
      } catch (error) {
        commit("updateRequestError", DEFAULT_ERROR);

        return { success: false };
      }
    },

    async cancelRequest({ commit }, { request }) {
      commit("cancelRequestStart");

      try {
        const res = await client.requests.delete(request.id, request.family);

        commit("updateRequestStatus", res.data.data);
        commit("cancelRequestSuccess");

        return { success: true };
      } catch (error) {
        commit("cancelRequestError", DEFAULT_ERROR);

        return { success: false };
      }
    },

    setFirstConnectedVisit({ commit }, payload) {
      commit("setFirstConnectedVisit", payload);
    },
  },

  getters: {
    firstConnectedVisit: state => state.firstConnectedVisit,
  },

  persistPaths: ["request.campaignName", "request.firstConnectedVisit"],
};
