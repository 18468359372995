import { getConfig } from "@/store";
import { getModuleConfig } from "@/utilities/config";

const REQUEST_DETAIL_MASK = {
  assistance: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "additional-information-digital",
      icon: "ui_plus",
      label:
        "request.my-requests.criteria.assistance.additional-information-digital.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  fair: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.fair.location.label",
    },
    {
      type: "date",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.fair.start-date.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.fair.participants.label",
    },
    {
      type: "additional-information-digital",
      icon: "ui_plus",
      label:
        "request.my-requests.criteria.fair.additional-information-digital.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  hotel: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.date.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "rooms",
      icon: "category_hotel",
      label: "request.my-requests.criteria.common.rooms.label",
      inline: true,
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.hotel.participants.label",
      inline: true,
    },
    {
      type: "budget",
      icon: "ui_money",
      label: "request.my-requests.criteria.hotel.budget.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  dining: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.dining.participants.label",
      inline: true,
    },
    {
      type: "budget",
      icon: "ui_money",
      label: "request.my-requests.criteria.dining.budget.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  culture: [
    { type: "offer" },
    {
      type: "additional_information_digital",
      label: "request.my-requests.criteria.culture.name.label",
      icon: "ui_event",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.culture.location.label",
    },
    {
      type: "date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.date.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.culture.participants.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  messenger: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "start_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.messenger.start-location.label",
    },
    {
      type: "location",
      apiKey: "end_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.messenger.end-location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
    {
      type: "additional-information-digital",
      icon: "ui_plus",
      label:
        "request.my-requests.criteria.messenger.additional-information-digital.label",
    },
  ],

  shopping: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.shopping.location.label",
    },
    {
      type: "budget",
      icon: "ui_money",
      label: "request.my-requests.criteria.common.budget.label",
    },
    {
      type: "additional_information_digital",
      label: "request.my-requests.criteria.shopping.purchase.label",
      icon: "ui_info",
    },
    {
      type: "date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.date.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  travel: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "start_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.travel.start-location.label",
    },
    {
      type: "location",
      apiKey: "end_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.travel.end-location.label",
    },
    {
      type: "date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.date.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.travel.participants.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  taxi: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "start_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.taxi.start-location.label",
    },
    {
      type: "location",
      apiKey: "end_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.taxi.end-location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.taxi.participants.label",
    },
    {
      type: "payment-method",
      icon: "ui_money",
      label: "request.my-requests.criteria.taxi.payment-method.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  "car-rental": [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "start_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.car-rental.start-location.label",
    },
    {
      type: "location",
      apiKey: "end_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.car-rental.end-location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.car-rental.departure-date.label",
    },
    {
      type: "datetime",
      apiKey: "end_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.car-rental.return-date.label",
    },
    {
      type: "vehicle-type",
      icon: "category_car_rental",
      label: "request.my-requests.criteria.car-rental.vehicle-type.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  "dry-cleaning": [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  "child-care": [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.child-care.start-date.label",
    },
    {
      type: "datetime",
      apiKey: "end_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.child-care.end-date.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.child-care.participants.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  "reception-organisation": [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "additional-information-digital",
      icon: "ui_plus",
      label:
        "request.my-requests.criteria.reception-organisation.additional-information-digital.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label:
        "request.my-requests.criteria.reception-organisation.participants.label",
      inline: true,
    },
    {
      type: "budget",
      icon: "ui_money",
      label: "request.my-requests.criteria.common.budget.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  repair: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  gourmet: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "datetime",
      apiKey: "start_date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.datetime.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.gourmet.participants.label",
      inline: true,
    },
    {
      type: "budget",
      icon: "ui_money",
      label: "request.my-requests.criteria.gourmet.budget.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  stay: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "location",
      apiKey: "start_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.stay.start-location.label",
    },
    {
      type: "location",
      apiKey: "end_location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.stay.end-location.label",
    },
    {
      type: "date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.stay.date.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.stay.participants.label",
    },
    {
      type: "budget",
      icon: "ui_money",
      label: "request.my-requests.criteria.stay.budget.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  training: [
    {
      type: "offer",
      label: "request.my-requests.criteria.common.offer.label",
    },
    {
      type: "additional-information-digital",
      icon: "ui_plus",
      label:
        "request.my-requests.criteria.training.additional-information-digital.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.training.location.label",
    },
    {
      type: "participants",
      icon: "ui_profil",
      label: "request.my-requests.criteria.training.participants.label",
      inline: true,
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.common.additional-information.label",
    },
  ],

  other: [
    {
      type: "offer",
      label: "request.my-requests.criteria.other.offer.label",
    },
    {
      type: "additional-information",
      icon: "ui_plus",
      label: "request.my-requests.criteria.other.additional-information.label",
    },
    {
      type: "location",
      apiKey: "location",
      icon: "ui_pin",
      label: "request.my-requests.criteria.common.location.label",
    },
    {
      type: "date",
      icon: "ui_calendar",
      label: "request.my-requests.criteria.common.date.label",
    },
  ],
};

export const CATEGORY_CONFIG = {
  accommodation: "hotel",
  "bars-club": "dining",
  "arts-entertainment": "culture",
  "daily-services": "other",
  dining: "dining",
  shopping: "shopping",
  transportation: "travel",
  "ground-transfer": "taxi",
  "transportation_car-rental": "car-rental",
  "daily-services_dry-cleaning": "dry-cleaning",
  "daily-services_child-care": "child-care",
  "repair-maintenance_electrician": "repair",
  "repair-maintenance_plumber": "repair",
  "repair-maintenance_locksmith": "repair",
  gourmet_other: "gourmet",
  "tours_bespoke-travel-planning": "stay",
  "daily-services_messenger": "messenger",
  "assistance_legal-information": "assistance",
  "arts-entertainment_convention-fair": "fair",
  "daily-services_learning-training": "training",
  "special-occasion": "reception-organisation",

  // Fallback on default template
  "sports-events_other": "culture",
  "ground-transfer_limo": "taxi",
  "ground-transfer_car-service": "taxi",
};

export function getMask(family, subfamily) {
  const moduleConfig = getModuleConfig(getConfig(), "request/my-requests");
  const masksConfig = moduleConfig.options?.masks;
  const categoryName = [family, subfamily].filter(Boolean).join("_");

  return (
    masksConfig?.[categoryName] ||
    REQUEST_DETAIL_MASK[CATEGORY_CONFIG[categoryName]] ||
    masksConfig?.[family] ||
    REQUEST_DETAIL_MASK[CATEGORY_CONFIG[family]]
  );
}
