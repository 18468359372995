<template>
  <div :class="['secondary-nav', sideNav && 'side-nav']">
    <template v-for="link in links">
      <EventLink
        v-if="isVisible(link.visibility)"
        :key="link.name"
        :id="`link--secondary-${link.name}`"
        :event="getEvent(link.name)"
        class="secondary-link text8"
      >
        {{ $t(`common.links.${link.name}`) }}
      </EventLink>
    </template>
  </div>
</template>

<style lang="scss">
.secondary-nav {
  font-size: rem(12px);
  padding: 0;
  margin: 0;

  &.side-nav {
    display: flex;
    flex-direction: column;
  }

  .secondary-link {
    display: inline-block;
    vertical-align: top;
    line-height: 2;
    margin-right: rem(40px);
  }

  &.side-nav .secondary-link {
    margin: rem(12px 0 0);
  }

  .text8 {
    color: inherit;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { FEATURES, isFeatureEnabled } from "@/utilities/features";
import EventLink from "@/components/EventLink";

export default {
  components: {
    EventLink,
  },

  data() {
    return {
      links: this.$config.options.footerLinks || [],
    };
  },

  props: {
    sideNav: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),
    isAthenaEnabled() {
      return isFeatureEnabled(FEATURES.ATHENA);
    },
  },

  methods: {
    isVisible(status) {
      return (
        status === "all" ||
        (status === "logged" && this.loggedIn) ||
        (status === "unlogged" && !this.loggedIn)
      );
    },
    getEvent(link) {
      return this.isAthenaEnabled
        ? `dynamic-content.footer.links.${link}:click`
        : `secondary-nav.links.${link}:click`;
    },
  },
};
</script>
