<template>
  <b-row>
    <b-col class="my-account--preferences pl-0 pr-0">
      <h1 class="text5">
        {{ $t("my-account.preferences.title") }}
      </h1>
      <form @submit.prevent="onSubmit" novalidate>
        <Alert variant="error" v-if="error">
          <span>{{ error }}</span>
        </Alert>
        <b-list-group>
          <b-list-group-item
            class="pl-0 pr-0"
            v-for="(field, index) in fields"
            :key="index"
          >
            <h2 class="text5 w-100 mb-0">
              {{ $t(`my-account.form.inputs.${field}`) }}
            </h2>
            <b-row class="w-100 mx-0">
              <b-col cols="10" class="text8 pl-0 pr-1">
                {{ $t(`my-account.form.inputs.content.${field}`) }}
              </b-col>
              <b-col cols="2" class="px-1 text-right">
                <CheckboxInput
                  :id="`switch-${index}`"
                  :value="values[field]"
                  :disabled="updating"
                  @update:value="
                    onChange(field, $event);
                    $event && $root.$emit('my-account.switch:activate');
                  "
                  checkboxType="switch"
                  class="preferences-switch"
                />
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item
            class="pl-0 pr-0"
            v-if="hasSettingsPlugins"
            @click="openGeolocationSettings"
          >
            <b-row class="w-100 mx-0">
              <b-col cols="9" class="text8 pl-0 pr-1">
                <h2 class="text5 w-100 mb-0">
                  {{ $t(`my-account.form.inputs.location`) }}
                </h2>
                {{ $t(`my-account.form.inputs.content.location`) }}
              </b-col>
              <b-col cols="3" class="px-1 text-right">
                <span class="text8">{{
                  $t(
                    `my-account.form.inputs.status.location.${
                      deviceLocation ? "activated" : "deactivated"
                    }`,
                  )
                }}</span>
                <FontIcon name="ui_chevron_right" />
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </form>
    </b-col>
  </b-row>
</template>

<style lang="scss">
.my-account--preferences {
  padding-bottom: rem(20px);

  h1 {
    @include setColor(--color-title1);
    margin-bottom: rem(30px);
  }

  .list-group-item {
    border: none;
  }

  .preferences-switch {
    width: auto;
    margin: 0;
    line-height: 1.2;
    transition: 0.4s;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    @include setColor(--color-text, border-color);
    @include setColor(--color-text, background-color);
  }

  .font-icon {
    vertical-align: middle;
  }
}
</style>

<script>
import { Alert, CheckboxInput, FontIcon } from "@johnpaul/jp-vue-components";
import { mapActions, mapState } from "vuex";

export default {
  name: "MyAccountPreferences",

  components: {
    Alert,
    CheckboxInput,
    FontIcon,
  },

  data() {
    const { options } = this.$modules["my-account"].modules.preferences;
    return {
      values: {},
      fields: options.form,
    };
  },

  computed: {
    ...mapState({
      member: state => state.member.data,
      updating: state => state["my-account"].notifications.updating,
      error: state => state["my-account"].notifications.error,
      deviceLocation: state => state.device.location,
    }),
    hasSettingsPlugins() {
      return window.cordova && window.cordova.plugins.settings;
    },
  },

  methods: {
    ...mapActions({
      update: "my-account/notifications/update",
    }),

    onChange(field, value) {
      this.values[field] = value;
      this.update({ ...this.values });
      if (field === "email")
        this.$root.$emit("my-account--email:click", { optin: value });
      if (field === "sms")
        this.$root.$emit("my-account--sms:click", { optin: value });
    },
    openGeolocationSettings() {
      if (this.hasSettingsPlugins) {
        window.cordova.plugins.settings.open("location");
      }
    },
  },

  mounted() {
    const attributes = this.member.marketing;
    for (const field of this.fields) {
      this.$set(this.values, field, attributes[field]);
    }
    this.$root.$emit("my-account--preferences:mounted");
  },
};
</script>
