const BREAKPOINT = 768;

export const VIEWPORT_WIDTH = document.documentElement.clientWidth;

export function getViewportWidth(ceil = 1) {
  return Math.ceil(VIEWPORT_WIDTH / ceil) * ceil;
}

export function isMobile() {
  return VIEWPORT_WIDTH < BREAKPOINT;
}
