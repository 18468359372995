const HomeUnlogged = () =>
  import(/* webpackChunkName: "home-unlogged" */ "./HomeUnlogged");

export default {
  path: "/",
  name: "home-unlogged",
  component: HomeUnlogged,
  meta: {
    title: "common.head.title.home-unlogged",
    redirectAuth: true,
    template: "home-page-offline",
  },
};
