<template>
  <div class="my-card-service">
    <div class="service-header text5" @click="$emit('toggle')">
      {{ headerText }}
      <FontIcon :name="expanded ? 'ui_moins' : 'ui_plus'" />
    </div>
    <div v-if="expanded" class="service-content text6">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.my-card-service {
  margin-bottom: rem(16px);

  .service-header {
    position: relative;
    min-height: rem(50px);
    padding: rem(15px 40px 15px 15px);
    @include setColor(--color-cta);
    @include setColor(--color-background1, background-color);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: pointer;

    .font-icon {
      position: absolute;
      right: rem(15px);
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(24px);
    }
  }

  .service-content {
    padding: rem(20px);
    @include setColor(--color-text);
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.065);

    p {
      margin: 0;
    }
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  props: {
    headerText: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
