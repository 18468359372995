export function isApplication() {
  return navigator.userAgent.includes("jp-companion-app");
}

export function isCordovaEnabled() {
  return !!window.cordova;
}

export const isAppleDevice = () =>
  !window.MSStream &&
  /iPad|iPhone|iPod|iPhone Simulator|iPod Simulator|iPad Simulator|Macintosh|MacIntel|MacPPC|Mac68K/.test(
    navigator.userAgent,
  );
