<template>
  <div class="city-filter clearfix">
    <div class="position-relative mb-3">
      <TextInput
        :id="`input--city-filter-${filter.key}`"
        :class="['city-filter-input', hasFocus && 'has-focus']"
        value=""
        :label="$t('common.filters.city.placeholder')"
        readonly
        @click.native="hasFocus = true"
      >
        <template slot="right">
          <FontIcon name="ui_chevron_dropdown" class="textinput-icon" />
        </template>
      </TextInput>

      <template v-if="hasFocus">
        <CityFilterLayer
          :filter="filter"
          :collection="collection"
          @option-change="$emit('option-change', $event)"
          @reset="$emit('reset')"
        />

        <a class="input-curtain" href="" @click.prevent />
      </template>
    </div>

    <div v-if="collection.geolocation" class="city-tag text7">
      {{ $t("common.filters.city.around-me") }}
      <FontIcon
        name="ui_close"
        @click.native="
          $emit('option-change', {
            filter: 'geolocation',
            value: null,
          })
        "
      />
    </div>

    <div
      v-for="option in selectedOptions"
      :key="option.key"
      class="city-tag text7"
    >
      {{ option.key }}
      <FontIcon
        name="ui_close"
        @click.native="
          $emit('option-change', {
            filter: filter.key,
            option: option.key,
            value: false,
          })
        "
      />
    </div>
  </div>
</template>

<style lang="scss">
.city-filter {
  position: relative;

  .city-filter-input {
    margin-bottom: 0;

    &.has-focus input {
      @include setColor(--color-selection, border-color);
    }
  }

  .textinput-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: rem(22px);
    @include setColor(--color-icon1);
  }

  .input-curtain {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .city-tag {
    float: left;
    display: flex;
    align-items: center;
    height: rem(40px);
    padding: rem(0 15px);
    margin: rem(0 5px 5px 0);
    @include setColor(--color-background2, background-color);
    @include setColor(--color-selection);
    border-radius: 100px;

    .font-icon {
      margin-left: rem(5px);
      font-size: rem(16px);
      cursor: pointer;
    }
  }
}
</style>

<script>
import { TextInput, FontIcon } from "@johnpaul/jp-vue-components";
import CityFilterLayer from "./CityFilterLayer";

export default {
  components: { CityFilterLayer, FontIcon, TextInput },

  data() {
    return {
      hasFocus: false,
    };
  },

  props: {
    filter: {
      type: Object,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },

  computed: {
    selectedOptions() {
      return this.filter.options.filter(o => o.value);
    },
  },

  methods: {
    close() {
      this.hasFocus = false;
    },
  },

  mounted() {
    window.addEventListener("mousedown", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("mousedown", this.close);
  },
};
</script>
