import { fetch } from "whatwg-fetch";
/**
 * Fetch resources from server
 * This is a centralized method to get all resources. Useful to unify the way we load assets (images, i18n ....)
 * @param src
 * @param options
 */
// eslint-disable-next-line no-unused-vars
export function fetchResources(src, options) {
  return fetch(src);
}

// eslint-disable-next-line no-unused-vars
export const fetchResourcesAsJson = (src, options) =>
  fetchResources(src).then(res => res.json());
