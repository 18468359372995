export function parseGoogleAddress(address) {
  const street = [address.street_number, address.route]
    .filter(Boolean)
    .join(" ");

  const location = {
    city: address.city,
    street,
    latitude: address.latitude,
    longitude: address.longitude,
    "country-code": address.country_code,
    state: address.state,
    viewport: address.viewport,
  };

  if (address.zipcode) {
    location["zip-code"] = address.zipcode;
  }

  return location;
}

export function getComponentBookingProcess(component) {
  return (
    component.offers?.[0]?.booking_processes?.[0]?.type ||
    component.booking_processes?.[0]?.type ||
    null
  );
}

export function addTargetBlankOnLinks(string) {
  return string.replace("<a ", '<a target="_blank" ');
}
