<template>
  <ImageHeaderLayout class="legal" :imageSrc="backgroundImage" v-if="component">
    <template slot="title">
      <span class="title3">
        {{ component.name }}
      </span>
    </template>

    <div class="legal-content text6" v-html="component.description"></div>
  </ImageHeaderLayout>
</template>

<style lang="scss">
.legal {
  .title3 {
    @include setColor(--color-title1-negative);
  }

  .legal-content {
    width: rem(900px);
    max-width: 100%;
    margin: auto;
    margin-top: rem(35px);
    @include setColor(--color-background1, background-color);
    @include setColor(--color-text);
    padding: rem(35px 100px);

    @include respond-to("medium") {
      width: auto;
      margin-top: 0;
      padding: rem(15px);
    }
  }

  a {
    word-break: break-all;
  }
}
</style>

<script>
import contentPageMixin from "@/mixins/contentPageMixin";
import { getModuleConfig } from "@/utilities/config";

import ImageHeaderLayout from "@/components/layout/ImageHeaderLayout";

export default {
  components: {
    ImageHeaderLayout,
  },

  mixins: [contentPageMixin],

  props: {
    section: {
      type: String,
      required: true,
    },
  },

  computed: {
    pageId() {
      const moduleConfig = getModuleConfig(
        this.$config,
        `legal/${this.section}`,
      );
      return moduleConfig?.options?.collection || null;
    },
    component() {
      return this.getComponents(this.pageCollections?.[0])?.[0] || null;
    },

    backgroundImage() {
      return this.component?.medias?.[0]?.url || null;
    },
  },

  watch: {
    pageId() {
      this.pageMounted();
    },
  },

  methods: {
    pageMounted() {
      this.$root.$emit(`legal--${this.section}:mounted`);
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
    this.pageMounted();
  },
};
</script>
