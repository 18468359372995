export const SFMC_NOTIFICATION_OPENED = "notifOpenedListener";
export const mcRegisterEvents = () => {
  window.MCCordovaPlugin.setOnNotificationOpenedListener(
    info => {
      // router push
      const event = new CustomEvent(SFMC_NOTIFICATION_OPENED, {
        detail: { info, source: "opener" },
      });
      document.dispatchEvent(event);
    },
    () => {},
  );

  window.MCCordovaPlugin.setOnUrlActionListener(
    info => {
      const event = new CustomEvent(SFMC_NOTIFICATION_OPENED, {
        detail: { info, source: "url" },
      });
      document.dispatchEvent(event);
    },
    () => {},
  );
};

export const mcRegisterUser = sid => {
  window.MCCordovaPlugin.setContactKey(
    sid,
    () => {
      if (!sid) {
        // disable push notif
        window.MCCordovaPlugin.disablePush(
          () => {},
          () => {},
        );
        return;
      }
      // enable push notif
      window.MCCordovaPlugin.enablePush(
        () => {},
        () => {},
      );
    },
    () => {},
  );
};

export const mcUnregisterUser = () => {
  mcRegisterUser("");
};
