<script>
import inViewport from "vue-in-viewport-mixin";

export default {
  props: {
    "in-viewport-once": {
      type: Boolean,
      default: true,
    },
  },

  mixins: [inViewport],

  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        this.$emit("in-view");
      }
    },
  },

  render() {
    return this.$slots.default[0];
  },
};
</script>
