<template>
  <section class="onboarding" v-if="isDisplayed">
    <div class="onboarding-container">
      <div class="onboarding-header">
        <FontIcon
          v-if="currentStep > 0 || currentStep === steps.length - 1"
          name="ui_close"
          class="onboarding-close-icon"
          @click.native="setStatus('skipped')"
        />
        <img
          :src="require('assets/images/logo.png')"
          class="onboarding-logo"
          alt="logo"
        />
        <div class="step-title title7">
          {{ $t(title) }}
        </div>
        <div class="step-number text5" v-if="this.steps.length > 1">
          {{ stepNumber }}
        </div>
        <div class="step-line" v-if="this.steps.length > 1">
          <div
            class="step-line-inner"
            :style="`width: ${((currentStep + 1) * 100) / this.steps.length}%`"
          />
        </div>
      </div>
      <div class="onboarding-content">
        <div v-if="showDescription" class="onboarding-fill text6">
          {{ subtitle }}
        </div>
        <ActivityIndicator
          v-if="fetching || submitting || interestsFetching || savingInterests"
        />
        <Alert variant="error" v-if="error">
          <span v-html="error"></span>
        </Alert>
        <DynamicForm
          v-if="form"
          :form="form"
          class="onboarding-form"
          :id="`onBoarding-steps-${currentStep}`"
          :buttonText="submitText"
          :cancelText="$t('onboarding.complete-later')"
          :submitting="submitting"
          :moduleState="member"
          title-class="onboarding-title-form"
          btnContainerClass="onboarding-btns"
          @submit="onSubmit"
          @reset="onReset"
        />
        <div
          v-if="steps[currentStep].type === 'interest' && !interestsFetching"
          class="onboarding-interest"
        >
          <div class="onboarding-interest-cards">
            <InterestCard
              v-for="(interest, idx) in interests"
              :key="`${interest.subfamily}-${idx}`"
              class="onboarding-interest-card"
              :interest="interest"
              @click.native="
                setInterest({ ...interest, is_liked: !interest.is_liked })
              "
            />
          </div>
          <b-row class="onboarding-btns">
            <b-col>
              <ActivityIndicator v-if="submitting" />
              <Button
                id="interest-cancel"
                type="secondary"
                @click.native="onReset"
                v-else
              >
                {{ $t("onboarding.complete-later") }}
              </Button>
            </b-col>
            <b-col>
              <ActivityIndicator v-if="submitting" />
              <Button
                id="interest-submit"
                class="text6"
                type="primary"
                v-else
                @click.native="submitInterests"
              >
                {{ submitText }}
              </Button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.onboarding {
  position: fixed;
  z-index: 9999;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;

  @include respond-min("medium") {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .onboarding-container {
    position: relative;
    height: 100vh;
    @include setColor(--color-background1, background-color);
    overflow-y: auto;

    @include respond-min("medium") {
      overflow-y: hidden;
      margin: 5% auto 0;
      max-width: rem(700px);
      height: 80vh;
      border-radius: rem(12px);
    }

    .onboarding-header {
      @include setColor(--color-background1, background-color);
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.103065);

      .onboarding-close-icon {
        display: none;
        position: absolute;
        top: rem(24px);
        right: rem(16px);
        font-size: rem(18px);
        font-weight: 600;
        cursor: pointer;

        @include respond-min("medium") {
          display: block;
        }
      }

      .onboarding-logo {
        max-width: rem(80px);
        display: block;
        padding: rem(8px 0 17px);
        margin: 0 auto;

        @include respond-min("medium") {
          display: none;
        }
      }

      .step-title {
        @include respond-min("medium") {
          padding: rem(16px 0);
        }
      }

      .step-number {
        @include setColor(--color-inactive);

        @include respond-min("medium") {
          margin-top: rem(12px);
        }
      }

      .step-line {
        position: relative;
        margin: rem(16px 0);
        border-bottom: solid rem(3px);
        @include setColor(--color-inactive, border-color);
      }

      .step-line-inner {
        position: absolute;
        border-bottom: solid rem(3px);
        @include setColor(--color-link, border-color);
      }
    }

    .onboarding-content {
      padding-bottom: rem(200px);

      @include respond-min("medium") {
        padding-bottom: 0;
        overflow-y: auto;
        height: calc(100% - 200px);
      }

      .onboarding-fill {
        padding: 0 rem(16px);
        text-align: left;

        @include respond-min("medium") {
          width: rem(340px);
          margin: rem(23px auto 0);
          text-align: center;
        }
      }

      .onboarding-form {
        margin: rem(25px auto 0);
        text-align: left;

        @include respond-min("medium") {
          width: rem(340px);
        }

        .activity-indicator {
          margin: 0 auto;
        }

        .onboarding-title-form {
          font-size: rem(14px);
          font-weight: 600;
        }
      }

      .onboarding-btns {
        position: fixed;
        bottom: 0;
        left: rem(16px);
        right: rem(16px);
        padding: rem(20px);
        @include setColor(--color-background1, background-color);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.103065);
        z-index: 10000;

        .col {
          padding: 0;
        }

        @include respond-min("medium") {
          position: absolute;
        }

        .button-secondary {
          border: none;
          font-weight: 600;
          @include setColor(--color-inactive);
        }
      }

      .onboarding-interest {
        .onboarding-interest-cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .onboarding-interest-card {
            width: rem(100px);
            margin: rem(18px 8px);
          }
        }
      }
    }
  }
}
</style>

<script>
import {
  ActivityIndicator,
  Alert,
  Button,
  FontIcon,
} from "@johnpaul/jp-vue-components";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DynamicForm from "@/components/DynamicForm";
import { getModuleConfig } from "@/utilities/config";
import { isMobile } from "@/utilities/responsive";
import InterestCard from "@/components/InterestCard";
import clonedeep from "lodash.clonedeep";
import formMixin from "@/mixins/formMixin";

export default {
  name: "Onboarding",
  mixins: [formMixin],

  components: {
    DynamicForm,
    ActivityIndicator,
    Alert,
    InterestCard,
    Button,
    FontIcon,
  },

  data() {
    const { options } = getModuleConfig(this.$config, "onboarding");
    return {
      steps: options.steps,
      showDescription: options.showDescription,
      currentStep: 0,
      phoneTypes: [
        "personal-mobile",
        "personal-other",
        "professional-mobile",
        "professional-other",
      ],
      phones: [],
      organization: {
        size: null,
        industry: null,
      },
    };
  },

  computed: {
    ...mapState({
      member: state => state.member.data,
      fetching: state => state.member.fetching,
      submitting: state => state.onboarding.updating,
      status: state => state.onboarding.status,
      error: state => state.onboarding.updateError,
      interests: state => state.interests.data,
      interestsFetching: state => state.interests.fetching,
      savingInterests: state => state.interests.saving,
    }),
    ...mapGetters({
      lastConnectionDate: "member/lastConnectionDate",
      loggedIn: "auth/loggedIn",
    }),
    submitText() {
      let key;
      let completeTranslatedKey;
      if (this.currentStep === this.steps.length - 1) {
        key = "onboarding.complete";
        key +=
          this.steps[this.currentStep].type === "interest"
            ? ".with-value"
            : ".0";
        completeTranslatedKey = this.$t(key);
        if (isMobile()) {
          // On mobile devices, remove additional text after quantity
          completeTranslatedKey = this.$t(key).replace(
            /\(([^)]+)\)/,
            "(%quantity%)",
          );
        }
      } else {
        key = "common.next";
        completeTranslatedKey = this.$t(key);
      }
      return completeTranslatedKey.replace("%quantity%", this.likedInterests);
    },
    stepNumber() {
      const key = "onboarding.step-number";
      return this.$t(key)
        .replace("%1%", this.currentStep + 1)
        .replace("%2%", this.steps.length);
    },
    form() {
      return this.steps[this.currentStep].form;
    },
    title() {
      return this.steps[this.currentStep].title;
    },
    isDisplayed() {
      if (
        !this.loggedIn ||
        this.lastConnectionDate ||
        this.status === "skipped" ||
        this.status === "done"
      ) {
        this.$emit("overflow", "visible");
        return false;
      }
      this.$emit("overflow", "hidden");
      return true;
    },
    subtitle() {
      if (this.currentStep === 1) return this.$t("onboarding.interest.choose");
      return this.$t("onboarding.fill-fields");
    },
    likedInterests() {
      if (!this.interests) return 0;
      return this.interests.reduce((acc, val) => {
        return acc + (val.is_liked ? 1 : 0);
      }, 0);
    },
  },

  methods: {
    ...mapMutations({
      setInterest: "interests/setInterest",
      saveInterests: "interests/saveInterests",
      setStatus: "onboarding/setStatus",
    }),

    ...mapActions({
      fetchMember: "member/fetch",
      update: "onboarding/update",
      fetchInterests: "interests/fetchInterests",
      saveInterests: "interests/saveInterests",
    }),

    nextStep() {
      this.currentStep++;
      if (this.currentStep < this.steps.length) {
        this.setStatus(this.currentStep);
      } else {
        this.setStatus("done");
      }
    },

    trackStep(event = "mounted") {
      if (!this.steps[this.currentStep]) return;
      const type = this.steps[this.currentStep].type;
      this.$root.$emit(`onboarding.${type}:${event}`, {
        stepNumber: this.currentStep + 3, // step 1 = register, step 2 = confirmation
      });
    },

    async onSubmit(apiValues) {
      const attributes = {};
      for (const [key, value] of Object.entries(apiValues)) {
        if (!value) continue;
        if (this.phoneTypes.includes(key)) {
          let phone = this.phones.find(p => p.type === key);
          if (phone) {
            phone.phone = value;
          } else {
            this.phones.push({
              type: key,
              phone: value,
              "is-preferred-contact-method": false,
            });
          }
        } else {
          attributes[key] = value;
        }
      }

      if (this.phones.length) attributes.phones = this.phones;
      await this.update(attributes);
      this.trackStep("submitted");
      this.nextStep();
    },

    async submitInterests() {
      await this.saveInterests();
      this.trackStep("submitted");
      this.nextStep();
    },

    onReset() {
      this.trackStep("skip");
      if (this.currentStep < this.steps.length - 1) this.nextStep();
      else this.setStatus("skipped");
    },
  },

  watch: {
    currentStep() {
      this.trackStep("mounted");
    },
  },

  async mounted() {
    if (!this.isDisplayed) return;
    await Promise.all([this.fetchMember(), this.fetchInterests()]);
    this.phones = clonedeep(this.member.phones);
    let stepNumber = parseInt(this.status);
    if (!isNaN(stepNumber)) {
      this.currentStep = stepNumber;
    } else {
      this.trackStep("mounted");
    }
  },
};
</script>
