var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['footer-wrapper', _vm.footerVisible && 'is-visible']},[_c('LegalNav',{class:[
      'footer-secondary-nav',
      'd-none',
      'd-xl-block',
      _vm.isLogoOffset && 'footer-secondary-nav--offset',
    ]}),(_vm.locales.length > 1)?_c('LanguageSwitcher',{attrs:{"options":_vm.locales.map(language => ({
        value: language,
        text: this.$t(`common.languages.${language}`),
      })),"locale":_vm.locale},on:{"update:locale":_vm.setLocale}}):_vm._e(),_c('a',{class:['logo', _vm.isLogoOffset && 'logo--offset'],on:{"click":_vm.onLogoClick}},[_c('img',{attrs:{"src":_vm.logo,"alt":"logo"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }