import client from "@/plugins/jp-api-client";
import Vue from "vue";

import { getErrorCode } from "@/utilities/errors";
import { getFiltersFromFacets } from "@/utilities/filters";
import { parseBlockItemMedias } from "@/utilities/images";

export default {
  namespaced: true,

  state: {
    pagesById: {},

    blocksById: {},
    fetchingBlock: false,

    blockItemsById: {},
    fetchingBlockItem: false,

    next: null,

    componentsById: {},
    fetchingComponentDetails: false,
    fetchComponentDetailsError: null,

    primaryBlockBackground: false,
  },

  mutations: {
    fetchComponentStart(state) {
      state.fetchingComponentDetails = true;
      state.fetchComponentDetailsError = null;
    },

    fetchComponentSuccess(state, { components }) {
      state.fetchingComponentDetails = false;

      const component = components[0];

      if (component.content_ct === "vendor") {
        component.address = component.google_place?.address;
      } else if (component.vendor?.google_place?.address) {
        component.vendor.address = component.vendor.google_place.address;
      }

      Vue.set(
        state.componentsById,
        component.parent_id,
        parseBlockItemMedias(component),
      );
    },

    fetchComponentError(state, error) {
      state.fetchingComponentDetails = false;
      state.fetchComponentDetailsError = error;
    },

    fetchPageSuccess(state, { slug, page }) {
      page.results.sort((blockA, blockB) => blockA.order - blockB.order);
      Vue.set(state.pagesById, slug, page);
    },

    fetchBlockStart(state) {
      state.fetchingBlock = true;
    },

    fetchBlockSuccess(state, { id, blockData, pageSize }) {
      state.fetchingBlock = false;
      const queryBlockItem = blockData?.results?.find(
        blockItem => blockItem.type === "query",
      );

      if (queryBlockItem) {
        const enabledFilters = queryBlockItem.query?.facet_fields;
        const enableSearch = true; // TODO: get this value from query attributes
        const enableConciergeLink = false; // TODO: get this value from query attributes
        const currentBlockItem = state.blockItemsById[queryBlockItem.id] || {};
        // Parse filters from data facets only if filters are not already defined
        const filters =
          currentBlockItem.filters ||
          getFiltersFromFacets(queryBlockItem.facets);

        Vue.set(state.blockItemsById, queryBlockItem.id, {
          ...queryBlockItem,
          searchValue: "",
          geolocation: null,
          components: [],
          count: queryBlockItem.components?.count,
          currentPage: 1,
          enabledFilters,
          enableSearch,
          enableConciergeLink,
          filters,
          pageSize,
        });
      }

      blockData.results = blockData?.results
        ?.sort((blockItemA, blockItemB) => blockItemA.order - blockItemB.order)
        ?.map(blockItem => ({
          ...blockItem,
          components: blockItem.components?.data,
        }));
      Vue.set(state.blocksById, id, blockData);
    },

    fetchBlockItemStart(state) {
      state.fetchingBlockItem = true;
    },

    fetchBlockItemSuccess(state, { id, blockItemData, page, pageSize }) {
      state.fetchingBlockItem = false;
      let currentBlockItem = state.blockItemsById[id] || {};

      if (currentBlockItem.type === "query") {
        const enabledFilters = currentBlockItem.query?.facet_fields;
        const enableSearch = true; // TODO: get this value from query attributes
        const enableConciergeLink = false; // TODO: get this value from query attributes
        // Parse filters from data facets only if filters are not already defined
        const filters =
          currentBlockItem.filters ||
          getFiltersFromFacets(currentBlockItem.facets);

        currentBlockItem = {
          ...currentBlockItem,
          count: blockItemData.count,
          currentPage: page,
          enabledFilters,
          enableSearch,
          enableConciergeLink,
          filters,
          pageSize,
        };
      }

      currentBlockItem.components = blockItemData.results;
      Vue.set(state.blockItemsById, id, currentBlockItem);
    },

    setSearchValue(state, { blockItem, value }) {
      Vue.set(state.blockItemsById[blockItem], "searchValue", value);
    },

    setFilterValue(state, { blockItem, filter, option, value }) {
      const stateBlockItem = state.blockItemsById[blockItem];
      if (filter === "geolocation") {
        stateBlockItem.geolocation = value;
      } else {
        const filters = stateBlockItem.filters || [];
        const stateFilter = filters.find(f => f.key === filter);
        const stateOption = stateFilter.options.find(o => o.key === option);
        stateOption.value = value;

        if (filter === "city") {
          stateBlockItem.geolocation = null;
        }
      }
    },

    resetFilterValues(state, { blockItem, filter }) {
      const filters = state.blockItemsById[blockItem].filters || [];
      const resetFilters = filters.map(stateFilter => {
        if (filter && filter !== stateFilter.key) return stateFilter;
        return {
          ...stateFilter,
          options: stateFilter.options.map(option => ({
            ...option,
            value: false,
          })),
        };
      });
      Vue.set(state.blockItemsById[blockItem], "filters", resetFilters);
    },
  },

  actions: {
    async fetchPage({ commit, state, rootState }, slug) {
      if (state.pagesById?.[slug]) {
        return { success: true };
      }

      try {
        const response = await client.content.v2.pages.getPageBlocks(slug, {
          lang: rootState.i18n.locale,
        });

        commit("fetchPageSuccess", {
          slug,
          page: response.data,
        });

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },

    async fetchBlock({ commit, state, rootState }, id) {
      commit("fetchBlockStart");
      if (state.blocksById?.[id]) {
        return { success: true };
      }

      try {
        const pageSize = 21;
        const response = await client.content.v2.blocks.getBlockItems(id, {
          components_lang: rootState.i18n.locale,
          components_page_size: pageSize,
        });

        commit("fetchBlockSuccess", {
          id,
          blockData: response.data,
          pageSize,
        });

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },

    async fetchBlockItem(
      { state, commit, rootState },
      { id, page, disablePagination = false },
    ) {
      commit("fetchBlockItemStart");
      try {
        // generate filters query params
        const stateBlockItem = state.blockItemsById[id] || {};
        const filters = stateBlockItem.filters || [];
        const queryFilters = filters.reduce((acc, filter) => {
          return {
            ...acc,
            [filter.key]:
              filter.options
                .filter(option => option.value)
                .map(option => option.key)
                .join() || [],
          };
        }, {});
        const pageSize = 21;

        queryFilters.ordering = stateBlockItem.order_by;

        if (stateBlockItem.searchValue) {
          queryFilters.search = stateBlockItem.searchValue;
        }

        if (stateBlockItem.geolocation) {
          const { latitude, longitude } = stateBlockItem.geolocation;
          queryFilters.location__geo_distance = `10km__${latitude}__${longitude}`;
        }

        const response = await client.content.v2.blocks.getBlockItemComponents(
          id,
          {
            ...queryFilters,
            lang: rootState.i18n.locale,
            page_size: disablePagination ? 1000 : pageSize,
            page,
          },
        );

        commit("fetchBlockItemSuccess", {
          id,
          blockItemData: response.data,
          page,
          pageSize,
        });

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },

    async fetchComponent(
      { commit, state, rootState },
      { id, isVendorComponent = false },
    ) {
      if (state.componentsById?.[id]) {
        return { success: true };
      }

      commit("fetchComponentStart");
      const params = {
        parent_id: id,
        lang: rootState.i18n.locale,
      };
      if (!isVendorComponent) {
        params.state = ["published", "scheduled", "draft"].join(",");
      }

      try {
        const response = await client.content.v2.components.get(false, params);

        commit("fetchComponentSuccess", {
          components: response.data.results,
        });

        return { success: true };
      } catch (error) {
        commit("fetchComponentError", getErrorCode(error));

        return { success: false };
      }
    },
  },
};
