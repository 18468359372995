<template>
  <div class="jp-accordeon">
    <template v-for="(component, idx) in this.components">
      <div
        class="jp-accordeon-section"
        :key="component.id"
        :ref="`jp-accordeon-${idx}`"
      >
        <div class="jp-accordeon-header text5" @click="toggle(idx)">
          {{ component.name }}
          <FontIcon :name="isExpanded(idx) ? 'ui_moins' : 'ui_plus'" />
        </div>
        <div v-if="isExpanded(idx)" class="jp-accordeon-content text6">
          <div
            v-html="component.description"
            class="jp-accordeon-description"
          />
          <router-link
            v-if="component.link"
            class="jp-accordeon-link"
            :to="{ name: 'faq/topic', params: { topic: component.slug } }"
          >
            {{ component.link }}
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.jp-accordeon {
  .jp-accordeon-section {
    margin-bottom: rem(16px);

    .jp-accordeon-header {
      position: relative;
      min-height: rem(50px);
      padding: rem(15px 40px 15px 15px);
      @include setColor(--color-cta);
      @include setColor(--color-background1, background-color);
      text-transform: uppercase;
      box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
      z-index: 1;
      cursor: pointer;

      .font-icon {
        position: absolute;
        right: rem(15px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
      }
    }

    .jp-accordeon-content {
      padding: rem(20px);
      @include setColor(--color-text);
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.065);

      p {
        margin: 0;
      }

      .jp-accordeon-description {
        strong {
          font-weight: bold;
        }

        .highlight {
          @include setColor(--color-highlight);
        }
      }

      .jp-accordeon-link {
        display: block;
        margin-top: rem(16px);
        @include setColor(--color-subtitle);
        outline: none;
      }
    }
  }
}
</style>

<script>
import easyScroll from "easy-scroll";
import { isMobile } from "@/utilities/responsive";
import { FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: {
    FontIcon,
  },

  props: {
    components: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expandedIdx: 0,
    };
  },

  methods: {
    toggle(idx) {
      this.expandedIdx = this.expandedIdx === idx ? null : idx;
      const component = this.components[idx];
      if (this.expandedIdx !== null) this.$emit("expand-subtopic", component);

      if (this.expandedIdx !== null && isMobile()) {
        setTimeout(() => {
          const el = this.$refs[`jp-accordeon-${idx}`][0];
          const position = el.getBoundingClientRect();
          const headerHeight = 60;

          easyScroll({
            scrollableDomEle: window,
            direction: "bottom",
            duration: 200,
            easingPreset: "easeInQuad",
            scrollAmount: position.top - headerHeight,
          });
        }, 10);
      }
    },
    isExpanded(idx) {
      return this.expandedIdx === idx;
    },
  },
};
</script>
