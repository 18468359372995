var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"dynamic-form",attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_c('b-container',[_c('b-row',[_c('b-col',[_vm._t("header")],2)],1),_vm._l((_vm.form.fields),function(row,i){return _c('b-row',{key:`row-${i}`},[_vm._l((row),function(field,fieldName){return [(_vm.transformedFields[fieldName].visible !== false)?_c('b-col',{key:`col-${fieldName}`,style:(_vm.getColumnStyle(field))},[(field.title)?_c('div',{staticClass:"text6 title",class:_vm.titleClass},[_vm._v(" "+_vm._s(_vm.$t(field.title))+" ")]):_vm._e(),(
              _vm.transformedFields &&
                _vm.transformedFields[fieldName].type === 'address'
            )?_c('AddressInput',_vm._b({on:{"update:value":function($event){_vm.resetError(fieldName);
              field.value = $event;},"blur":function($event){return _vm.checkField(fieldName)}}},'AddressInput',_vm.getFieldProps(fieldName),false)):(
              _vm.transformedFields &&
                _vm.transformedFields[fieldName].type === 'participants'
            )?_c('ParticipantsInput',_vm._b({on:{"update:value":function($event){field.value = $event}}},'ParticipantsInput',_vm.getFieldProps(fieldName),false)):_c('DynamicInput',{attrs:{"type":_vm.transformedFields[fieldName].type,"inputProps":_vm.getFieldProps(fieldName)},on:{"update:value":function($event){_vm.resetError(fieldName);
              field.value = $event;},"blur":function($event){return _vm.checkField(fieldName)}}})],1):_vm._e()]})],2)}),_vm._t("default"),_c('b-row',{class:_vm.btnContainerClass},[(_vm.cancelText)?_c('b-col',[(_vm.submitting)?_c('ActivityIndicator'):_c('Button',{attrs:{"id":`${_vm.buttonId}-cancel`,"type":"secondary","nativeType":"reset"}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")])],1):_vm._e(),_c('b-col',[(_vm.submitting)?_c('ActivityIndicator'):_c('Button',{staticClass:"text6",attrs:{"id":_vm.buttonId,"type":"primary","nativeType":"submit","disabled":!_vm.canBeSubmitted()}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }