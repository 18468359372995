import ErrorView from "@/views/Error";

export default [
  {
    path: "/config-failure",
    name: "config-failure",
    component: ErrorView,
    meta: {
      backLink: false,
      hideWidgets: true,
      footerVisible: false,
      configFailed: true,
    },
    props: { httpCode: "500" },
  },
  {
    path: "/error",
    name: "errors-500",
    component: ErrorView,
    meta: {
      backLink: false,
      hideWidgets: true,
      footer: false,
    },
    props: { httpCode: "500" },
  },
  {
    path: "/not-found",
    name: "errors-404",
    component: ErrorView,
    meta: {
      backLink: false,
      footer: false,
    },
    props: { httpCode: "404" },
  },
  {
    path: "*",
    name: "errors-fallback",
    component: ErrorView,
    meta: {
      backLink: false,
      footer: false,
    },
    props: { httpCode: "404" },
  },
];
