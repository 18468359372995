const Register = () =>
  import(/* webpackChunkName: "register" */ "./views/Register");
const RegisterForm = () =>
  import(/* webpackChunkName: "register" */ "./views/RegisterForm");
const RegisterActivation = () =>
  import(
    /* webpackChunkName: "register" */ "./views/activation/RegisterActivation"
  );

export default {
  path: "/register",
  component: Register,
  meta: {
    title: "common.head.title.register.home",
  },
  children: [
    {
      name: "register",
      path: "",
      component: RegisterForm,
    },
    {
      name: "register/activation",
      path: "activation/:email",
      component: RegisterActivation,
      props: true,
    },
  ],
};
