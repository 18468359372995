import { mapMutations, mapState } from "vuex";
import {
  initCordovaSecureStorage,
  setInSecureStorage,
  PWD_KEY,
  USERNAME_KEY,
} from "@/utilities/cordova";

export default {
  data() {
    return {
      faio: null,
    };
  },

  computed: {
    ...mapState({
      fingerprint: state => state.auth.fingerprint,
    }),
  },

  methods: {
    ...mapMutations({
      enableFingerprint: "auth/enableFingerprint",
      disableFingerprint: "auth/disableFingerprint",
      enableLocation: "device/enableLocation",
      disableLocation: "device/disableLocation",
    }),

    getAuthenticationType() {
      return new Promise((resolve, reject) => {
        if (window.Fingerprint) {
          window.Fingerprint.isAvailable(
            type => {
              type !== "none" ? resolve(type) : reject();
            },
            () => reject(),
          );
        } else {
          reject();
        }
      });
    },
    showFingerprint({ title, description }) {
      return new Promise((resolve, reject) => {
        if (window.Fingerprint) {
          window.Fingerprint.show(
            {
              title,
              description,
            },
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            },
          );
        } else reject(false);
      });
    },
    authorizeFingerprint({ username, pwd }) {
      return this.showFingerprint({
        title: this.$t("common.plugin.fingerprint.login.usefingerprint.title"),
        description: this.$t(
          "common.plugin.fingerprint.login.usefingerprint.text",
        ),
      })
        .then(() => {
          setInSecureStorage(USERNAME_KEY, username, () =>
            setInSecureStorage(PWD_KEY, pwd, () => this.enableFingerprint()),
          );
          return { success: true };
        })
        .catch(() => {
          this.disableFingerprint();
          return { success: false };
        });
    },
    initSecureStorage() {
      if (window.cordova) {
        initCordovaSecureStorage(
          this.$t("common.plugin.fingerprint.error.screen-lock-disabled"),
        );
      }
    },
  },
};
