<template>
  <ImageHeaderLayout
    :imageSrc="optimizedImageUrl"
    class="detail-template"
    darken
  >
    <template slot="title">
      <span class="title3">
        {{ title }}
      </span>
    </template>

    <div class="detail-template__content">
      <span class="detail-template__content-subtitle text1">
        {{ subtitle }}
      </span>

      <div
        class="detail-template__content-description text6"
        v-html="description"
      ></div>
    </div>
  </ImageHeaderLayout>
</template>

<style lang="scss">
.detail-template {
  @include setColor(--color-background1, background-color);

  .title3 {
    @include setColor(--color-title1-negative);
  }

  &__content {
    width: rem(900px);
    max-width: 100%;
    margin: auto;
    margin-top: rem(35px);
    @include setColor(--color-background1, background-color);
    @include setColor(--color-text);
    padding: rem(35px 100px);

    @include respond-to("medium") {
      width: auto;
      margin-top: 0;
      padding: rem(15px);
    }

    &-subtitle {
      display: block;
      margin-bottom: rem(24px);
    }
  }

  a {
    word-break: break-all;
  }
}
</style>

<script>
import ImageHeaderLayout from "@/components/layout/ImageHeaderLayout";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";
import { MediaType } from "@/constants/media";

export default {
  components: {
    ImageHeaderLayout,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },

  computed: {
    optimizedImageUrl() {
      return getCloudinaryResponsiveUrl(this.imageUrl, MediaType.DETAIL);
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
  },
};
</script>
