<template>
  <div class="home-push">
    <h1 class="super-title3">{{ title }}</h1>
    <h2 v-if="subtitle" class="text4">{{ subtitle }}</h2>
    <div class="list">
      <div class="cards-wrapper">
        <OfferCardLink
          v-for="(component, index) in components.slice(0, maxOfferPerRow)"
          :key="component.id"
          :component="component"
          class="offer-link"
          :in-viewport-active="true"
          :in-viewport-once="true"
          @display="$emit('component-display', index)"
          @mousedown.native="$emit('component-click', index)"
        />
      </div>
    </div>

    <EventLink
      v-if="button"
      :id="`button--push--${id}`"
      class="see-more-button"
      type="button"
      event="common.cms.link:click"
      :linkComponent="button"
    />
  </div>
</template>

<style lang="scss">
.home-push {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20px 0);

  @include respond-min("medium") {
    padding: rem(40px 0);
  }

  h1 {
    margin: rem(0 15px 5px);
    @include setColor(--color-title1);
    text-align: center;
  }

  h2 {
    @include setColor(--color-subtitle);
    text-align: center;
  }

  .list {
    width: 100%;
    overflow-x: auto;
    max-width: rem(1400px);
    padding-bottom: rem(14px);
    margin: rem(27px auto 10px);
  }

  .cards-wrapper {
    position: relative;
    display: inline-flex;
    padding-left: rem(15px);
  }

  .offer-link {
    display: inline-block;
    width: rem(300px);
    min-width: rem(300px);
    margin-right: rem(15px);

    > * {
      height: 100%;
    }
  }

  .see-more-button {
    display: inline-flex;
    width: auto;
    margin: auto;
    padding: rem(0 50px);
  }

  @include respond-min("medium") {
    overflow-x: none;

    .list {
      margin-bottom: rem(26px);
    }

    .cards-wrapper {
      width: 100%;
      justify-content: space-between;
    }

    .offer-link {
      min-width: none;
      width: 32%;
      text-decoration: none;
    }
  }
}
</style>

<script>
import EventLink from "@/components/EventLink";
import OfferCardLink from "@/components/OfferCardLink";

export default {
  data() {
    return {
      maxOfferPerRow: 4,
    };
  },

  components: {
    EventLink,
    OfferCardLink,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    button: {
      type: Object,
      default: null,
    },
  },
};
</script>
