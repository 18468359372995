<template>
  <div class="home-contrast-list">
    <ListCard
      v-for="(card, index) in components"
      :key="index"
      :src="imgSrc(card)"
      position="right"
    >
      <div class="title super-title3">
        {{ card.name }}
      </div>
      <div class="subtitle title8">
        {{ card.subname }}
      </div>
      <div class="list-card-description text6" v-html="card.description" />
      <EventLink
        :id="`button--home-contrast-list--${card.id}`"
        class="button button-secondary-negative see-more-button"
        :event="cardEvent(card)"
        :linkComponent="card"
        @mousedown.native="$emit('component-click', index)"
      >
        {{ card.button_name }}
      </EventLink>
    </ListCard>
  </div>
</template>

<style lang="scss">
.home-contrast-list {
  .list-card {
    @include setColor(--color-background4, background-color);
  }

  .list-card-container {
    padding: rem(20px 16px);
    max-width: rem(1060px);

    @include respond-min("medium") {
      padding: rem(20px 0);
    }
  }

  .title {
    @include setColor(--color-text-negative, color);
  }

  .subtitle {
    @include setColor(--color-subtitle-negative, color);
  }

  .list-card-description {
    @include setColor(--color-cta-negative, color);
  }

  .see-more-button {
    width: auto;
    @include setColor(--color-cta-negative, border-color);

    @include respond-min("medium") {
      display: inline-flex;
      padding: 0 rem(40px);
    }
  }
}
</style>

<script>
import { ListCard } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";

export default {
  components: {
    ListCard,
    EventLink,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    imgSrc(card) {
      return card?.medias[0]?.url || "";
    },
    cardEvent(card) {
      return card.link_template === "external"
        ? "common.cms.external-link:click"
        : "common.cms.link:click";
    },
  },
};
</script>
