<template>
  <label
    :class="['filter-option', 'text6', disabled && 'disabled']"
    @click.prevent="$emit('update:value', !value)"
  >
    <span class="filter-option-label">
      <FontIcon v-if="icon" class="option-icon" :name="icon" />
      {{ label }}
    </span>
    <CheckboxInput :id="id" class="option-checkbox" :value="value" />
  </label>
</template>

<style lang="scss">
.filter-option {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(16px);
  @include setColor(--color-text);
  cursor: pointer;

  &[disabled="disabled"] {
    opacity: 0.4;
  }

  .filter-option-label {
    display: flex;
  }

  .option-checkbox {
    width: rem(21px);
    margin-bottom: 0;
  }

  .option-icon {
    margin-right: rem(5px);
    font-size: rem(21px);
    @include setColor(--color-selection);
  }
}
</style>

<script>
import { CheckboxInput, FontIcon } from "@johnpaul/jp-vue-components";

export default {
  components: { CheckboxInput, FontIcon },

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
