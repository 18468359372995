<template>
  <div class="my-card-section">
    <div v-if="introComponent" class="my-card-section__description">
      <div class="title text3">
        {{ introComponent.name }}
      </div>
      <img v-if="introImage" :src="introImage" :alt="introComponent.name" />
      <div class="text6" v-html="introComponent.description" />
    </div>

    <MyCardService
      v-for="(component, idx) in components"
      :key="component.id"
      :ref="`service-${idx}`"
      :headerText="component.name"
      :expanded="expandedIdx === idx"
      @toggle="toggle(idx)"
    >
      <div v-html="addTargetBlankOnLinks(component.description)" />
    </MyCardService>
  </div>
</template>

<style lang="scss">
.my-card-section {
  width: rem(900px);
  margin: auto;
  padding: rem(28px 100px 200px);
  @include setColor(--color-background1, background-color);
  @include setColor(--color-text);
  overflow-y: hidden;

  .title {
    margin-bottom: rem(19px);
    color: setColor(--color-subtitle);
  }

  &__description {
    margin-bottom: 16px;
    color: setColor(--color-text);

    img {
      width: 100%;
      margin-bottom: rem(22px);
    }
  }

  @include respond-to("medium") {
    width: auto;
    margin-top: 0;
    padding: rem(18px);
    padding-bottom: rem(100px);

    .title {
      margin-bottom: rem(18px);
    }
  }
}
</style>

<script>
import easyScroll from "easy-scroll";

import MyCardService from "./MyCardService";

import { isMobile } from "@/utilities/responsive";
import { addTargetBlankOnLinks } from "@/utilities/parsers";

export default {
  components: {
    MyCardService,
  },

  props: {
    introComponent: {
      type: Object,
      default: null,
    },
    components: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expandedIdx: 0,
    };
  },

  computed: {
    introImage() {
      return this.introComponent?.medias[0].url;
    },
  },

  methods: {
    toggle(idx) {
      this.expandedIdx = this.expandedIdx === idx ? null : idx;
      if (this.expandedIdx !== null && isMobile()) {
        setTimeout(() => {
          const el = this.$refs[`service-${idx}`][0].$el;
          const position = el.getBoundingClientRect();
          const headerHeight = 60;

          easyScroll({
            scrollableDomEle: window,
            direction: "bottom",
            duration: 200,
            easingPreset: "easeInQuad",
            scrollAmount: position.top - headerHeight,
          });
        }, 10);
      }
    },
    addTargetBlankOnLinks,
  },
};
</script>
