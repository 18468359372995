<template>
  <ColumnLayout class="need-help">
    <template slot="left" v-if="$modules['need-help'].options.icon">
      <div class="text-right">
        <img src="~assets/images/need-help/icon.svg" alt="need help" />
      </div>
    </template>
    <template slot="right" class="right">
      <template v-if="success">
        <h1 class="super-title3">{{ $t("need-help.confirmation.title") }}</h1>
        <a
          id="link--need-help-back"
          class="text5"
          @click="$root.$emit('need-help.links.back:click')"
        >
          {{ confirmationLink }}
        </a>
      </template>
      <template v-else>
        <b-container>
          <b-row>
            <b-col>
              <h1 class="super-title3">{{ $t("need-help.title") }}</h1>
              <p class="need-help-description text6">
                {{ $t("need-help.text") }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <Alert variant="error" v-if="error" id="alert--need-help-error">
                <span v-html="$t(`need-help.error.${error}`)"></span>
              </Alert>
            </b-col>
          </b-row>
        </b-container>
        <DynamicForm
          v-if="form"
          :form="form"
          prefixInputId="need-help"
          :buttonText="$t('need-help.form.buttons.submit')"
          :submitting="submitting"
          :moduleState="formState"
          @submit="onSubmit"
        />
      </template>
    </template>
  </ColumnLayout>
</template>

<style lang="scss">
.need-help {
  padding-bottom: rem(20px);

  h1 {
    @include setColor(--color-title1);
  }

  .need-help-description {
    @include setColor(--color-text);
  }

  .left {
    img {
      width: rem(180px);
    }
  }
  .right {
    #link--need-help-back {
      color: var(--color-link);
    }
  }
}
</style>

<script>
import ColumnLayout from "@/components/layout/ColumnLayout";
import DynamicForm from "@/components/DynamicForm";
import formMixin from "@/mixins/formMixin";
import { mapActions, mapGetters, mapState } from "vuex";
import client from "@/plugins/jp-api-client";
import { Alert } from "@johnpaul/jp-vue-components";
import { getErrorCode } from "@/utilities/errors";

export default {
  name: "NeedHelp",
  mixins: [formMixin],

  components: {
    Alert,
    ColumnLayout,
    DynamicForm,
  },

  data() {
    const { options } = this.$modules["need-help"];

    return {
      success: false,
      form: null,
      submitting: false,
      error: null,
      options,
    };
  },

  computed: {
    ...mapState({
      member: state => state.member.data,
      subscriptionExternalId: state => state.auth.subscriptionExternalId,
      queryParams: state => state.route.query,
    }),

    ...mapGetters({
      subscription: "subscriptions/primarySubscription",
      loggedIn: "auth/loggedIn",
    }),

    formState() {
      return {
        ...this.member,
        ...this.queryParams,
      };
    },

    confirmationLink() {
      return this.loggedIn
        ? this.$t("need-help.confirmation.link-logged")
        : this.$t("need-help.confirmation.link-unlogged");
    },
  },

  methods: {
    ...mapActions({
      fetchClientToken: "auth/fetchClientToken",
    }),

    async onSubmit(apiValues) {
      this.submitting = true;
      try {
        if (!this.loggedIn) await this.fetchClientToken();
        const payload = {
          ...apiValues,
          ...(this.member
            ? {
                firstname: this.member.firstname,
                lastname: this.member.lastname,
                email: this.member.emails[0]?.email,
                phone: this.member.phones[0]?.phone,
              }
            : null),
        };

        payload.specifics = this.member
          ? {
              birthdate: this.member?.birthdate,
              last_digits: this.subscription?.attributes?.specifics?.[
                "last-digits"
              ],
              subscription_external_id: this.subscription?.attributes
                ?.specifics?.["subscription-external-id"],
            }
          : apiValues.specifics;

        await client.auth.assistance(payload);

        this.success = true;
        this.$root.$emit("need-help:success");
      } catch (error) {
        this.error = getErrorCode(error);
      }
      this.submitting = false;
    },
  },

  async mounted() {
    await this.loadFormConfig("needHelpForm");
    this.$emit("header", { transparent: false });
    this.$root.$emit("need-help:mounted");
  },
};
</script>
