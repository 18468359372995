<template>
  <Details
    v-if="componentObject"
    :content-type="contentType"
    :title="componentTitle"
    :subtitle="vendorName"
    :description="componentDescription"
    :about="vendorDescription"
    :conditions="componentConditions"
    :imgDetail="imgDetail"
    :imgLogo="imgLogo"
    :startDate="componentObject.start_date"
    :address="address"
    :addressUrl="addressUrl"
    :family="vendorFamily"
    :marker="marker"
    :legalNotices="legalNotices"
    :rightBlockTitle="rightBlockTitle"
    :link="componentLink"
    :isOnline="onlineComponent"
    showInformation
    @trackClick="trackClick"
    @trackMapLink="trackMapLink"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import router from "@/router";

import Details from "../templates/Details";

import { getGoogleLocationLink } from "@/utilities/address";
import { replaceNewLineWithTag } from "@/utilities/text";
import {
  getDynamicComponentId,
  getDynamicComponentVendorId,
} from "@/utilities/tracking";
import { parseBlockItemComponentMedias } from "@/utilities/images";

export default {
  components: {
    Details,
  },

  props: {
    slug: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      componentsById: state => state.contentv2.componentsById,
    }),
    componentObject() {
      return this.componentsById[this.id]
        ? parseBlockItemComponentMedias(this.componentsById[this.id])
        : this.componentsById[this.id];
    },
    componentDescription() {
      return replaceNewLineWithTag(this.componentObject?.description);
    },
    componentConditions() {
      return replaceNewLineWithTag(
        this.componentObject?.booking_process?.conditions,
      );
    },
    vendor() {
      return this.componentObject?.vendor || null;
    },
    vendorName() {
      return this.vendor?.title || this.vendor?.name || "";
    },
    vendorFamily() {
      return this.vendor?.family || "";
    },
    marker() {
      return {
        lat: this.vendor?.address?.latitude,
        lng: this.vendor?.address?.longitude,
      };
    },
    contentType() {
      return this.componentObject?.content_ct;
    },
    contentSubType() {
      return this.componentObject?.type;
    },
    legalNotices() {
      return replaceNewLineWithTag(this.componentObject?.legal_notice);
    },
    vendorDescription() {
      return replaceNewLineWithTag(this.vendor?.description);
    },
    onlineComponent() {
      return this.componentObject?.booking_process?.type !== "concierge";
    },
    rightBlockTitle() {
      return this.onlineComponent
        ? this.$t(
            `common.${this.contentType}.details.cta.${this.contentSubType}.online.title`,
          )
        : this.$t(
            `common.${this.contentType}.details.cta.${this.contentSubType}.place.title`,
          );
    },
    componentTitle() {
      return this.componentObject?.title || this.vendor?.title;
    },
    componentLink() {
      return this.componentObject?.booking_process?.website;
    },
    addressUrl() {
      if (!this.vendor?.address) return;
      return this.vendor.google_place?.url
        ? this.vendor.google_place?.url
        : getGoogleLocationLink(this.vendor.address);
    },
    address() {
      return this.vendor?.address?.formatted_address;
    },
    imgDetail() {
      return this.src("detail", "image");
    },
    imgLogo() {
      return this.src("logo");
    },
  },

  watch: {
    "$route.path": function() {
      this.fetchComponent({ id: this.id });
    },
    componentObject() {
      this.$root.$emit("dynamic-content.page:loaded", {
        slug: this.slug,
        vendorId: getDynamicComponentVendorId(this.componentObject),
        contentId: getDynamicComponentId(this.componentObject),
      });
    },
  },

  methods: {
    ...mapActions({
      fetchComponent: "contentv2/fetchComponent",
    }),
    trackClick(event) {
      this.$root.$emit(event, {
        vendor: this.vendor,
        content: this.componentObject,
        stepNumber: 0,
        vendorId: getDynamicComponentVendorId(this.componentObject),
        contentId: getDynamicComponentId(this.componentObject),
        fulfillmentMode: this.componentObject?.booking_process?.type || null,
      });
    },
    trackMapLink() {
      this.$root.$emit("vendor.details.map:click", {
        fulfillmentMode: this.componentObject?.booking_process?.type || null,
      });
    },
    back() {
      if (window.history.length > 2) {
        router.go(-1);
      } else {
        this.$root.$emit("vendor.details.back.fallback:click");
      }
    },
    src(mediaType, fallback) {
      let componentMedia = this.componentObject?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        null,
      );
      let vendorMedia = this.vendor?.medias?.reduce(
        (acc, media) => (media.type === mediaType ? media.url : acc),
        "",
      );
      if (!(componentMedia || vendorMedia)) {
        componentMedia = this.componentObject?.medias?.reduce(
          (acc, media) => (media.type === fallback ? media.url : acc),
          null,
        );
        vendorMedia = this.vendor?.medias?.reduce(
          (acc, media) => (media.type === fallback ? media.url : acc),
          "",
        );
      }
      return componentMedia ? componentMedia : vendorMedia;
    },
  },

  mounted() {
    this.fetchComponent({ id: this.id });
    this.$emit("header", { transparent: true });
  },
};
</script>
