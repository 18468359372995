import { mount, createLocalVue, createWrapper } from "@vue/test-utils";
import { BootstrapVue } from "bootstrap-vue";
import Vuex from "vuex";
import { createStoreMocks } from "@/store";

import * as fixtures from "./fixtures";
import Benefits from "../views/Benefits";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

export default (config, conductor) => {
  if (!config.modules.benefits) return;

  const moduleConfig = config.modules["benefits"];

  let fetchComponentsStub;
  let resetFilterValuesStub;
  let setFilterValueStub;
  let setSearchValueStub;

  function getWrapper(storeFixture = {}) {
    fetchComponentsStub = jest.fn(() => null);
    resetFilterValuesStub = jest.fn(() => null);
    setFilterValueStub = jest.fn(() => null);
    setSearchValueStub = jest.fn(() => null);

    const store = createStoreMocks({
      state: {
        config: config,
        conductorConfig: conductor,
        i18n: {
          locale: "en",
        },
        login: {},
        auth: {},
        device: {
          location: true,
        },
        route: {
          query: {
            vtoken: null,
          },
        },
        member: {
          data: {
            id: "123456",
          },
        },
        content: {
          pagesById: {
            privileges: {
              results: [
                {
                  name: "Privileges",
                  order_by: "order",
                  slug: "privileges",
                  type: "list",
                },
              ],
            },
          },
          fetchingComponents: false,
          collections: storeFixture.content?.collections || [],
        },
      },
      actions: {
        "auth/fetchClientToken": () => {},
        "content/fetchComponents": fetchComponentsStub,
        "content/fetchPage": () => {},
      },
      mutations: {
        "content/resetFilterValues": resetFilterValuesStub,
        "content/setFilterValue": setFilterValueStub,
        "content/setSearchValue": setSearchValueStub,
      },
    });

    return mount(Benefits, {
      store,
      localVue,
      mocks: {
        $config: config,
        $modules: config.modules,
      },
      stubs: {
        "router-link": true,
        "router-view": true,
      },
    });
  }

  describe("when there are benefits in the store", () => {
    let wrapper;
    beforeEach(() => {
      wrapper = getWrapper(fixtures.withResults);
    });

    it("should render the results", () => {
      const cards = wrapper.findAll(".offer-card-link");
      expect(cards.length).toEqual(
        fixtures.withResults.content.collections.privileges.components.length,
      );
    });

    describe("when i click on an offer", () => {
      it("should trigger a cms root event", () => {
        const rootWrapper = createWrapper(wrapper.vm.$root);
        wrapper
          .findAll(".offer-card-link")
          .at(0)
          .trigger("click");
        wrapper.vm.$nextTick(() => {
          expect(rootWrapper.emitted("common.cms.link:click")).toBeTruthy();
        });
      });
    });
  });

  // City filter
  if (
    !moduleConfig.options.filters ||
    !moduleConfig.options.filters.includes("city")
  ) {
    describe("when 'city' filter is disabled", () => {
      let wrapper;
      beforeEach(() => {
        wrapper = getWrapper(fixtures.withResults);
      });

      it("should not render the filters bar", () => {
        const cityFilter = wrapper.find("#button--filter-city");
        expect(cityFilter.exists()).toBe(false);
      });
    });
  } else {
    describe("when 'city' filter is enabled", () => {
      let wrapper;
      beforeEach(() => {
        wrapper = getWrapper(fixtures.withResults);
      });

      it("should render the enabled filters", () => {
        const cityFilter = wrapper.find("#button--filter-city");
        expect(cityFilter.exists()).toBe(true);
      });

      describe("when i select a city", () => {
        it("should set the filter in the store", async () => {
          // Open the city filter dropdown
          await wrapper.find("#button--filter-city").trigger("click");

          // Select a filter
          await wrapper.find("#input--filter-city-Angleterre").trigger("click");

          expect(setFilterValueStub.mock.calls.length).toEqual(1);
          expect(setFilterValueStub.mock.calls[0][1]).toEqual({
            collection: "privileges",
            filter: "city",
            option: "Angleterre",
            value: true,
          });
        });
      });

      describe("when i select 'around me'", () => {
        it("should set the 'around me' filter in the store", async () => {
          // Open the city filter dropdown
          await wrapper.find("#button--filter-city").trigger("click");

          // Select a filter
          await wrapper.find("#input--filter-city-around-me").trigger("click");

          expect(setFilterValueStub.mock.calls.length).toEqual(1);
          expect(setFilterValueStub.mock.calls[0][1]).toEqual({
            collection: "privileges",
            filter: "geolocation",
            value: null,
          });
        });
      });
    });
  }

  // Family filter
  if (
    !moduleConfig.options.filters ||
    !moduleConfig.options.filters.includes("family")
  ) {
    describe("when 'family' filter is disabled", () => {
      let wrapper;
      beforeEach(() => {
        wrapper = getWrapper(fixtures.withResults);
      });

      it("should not render the filters bar", () => {
        const familyFilter = wrapper.find("#button--filter-family");
        expect(familyFilter.exists()).toBe(false);
      });
    });
  } else {
    describe("when 'family' filter is enabled", () => {
      let wrapper;
      beforeEach(() => {
        wrapper = getWrapper(fixtures.withResults);
      });

      it("should render the enabled filters", () => {
        const familyFilter = wrapper.find("#button--filter-family");
        expect(familyFilter.exists()).toBe(true);
      });

      describe("when i select a family", () => {
        it("should set the filter in the store", async () => {
          // Open the family filter dropdown
          await wrapper.find("#button--filter-family").trigger("click");

          // Select a filter
          await wrapper
            .find("#input--filter-family-accommodation")
            .trigger("click");

          expect(setFilterValueStub.mock.calls.length).toEqual(1);
          expect(setFilterValueStub.mock.calls[0][1]).toEqual({
            collection: "privileges",
            filter: "family",
            option: "accommodation",
            value: true,
          });
        });
      });
    });
  }

  // Search input
  if (moduleConfig.options.search) {
    describe("when search is enabled in the project configuration", () => {
      let wrapper;
      beforeEach(() => {
        wrapper = getWrapper(fixtures.withResults);
      });

      it("should render search input", () => {
        const searchInput = wrapper.find("#input--search");
        expect(searchInput.exists()).toBe(true);
      });

      describe("when i search something", () => {
        beforeEach(async () => {
          wrapper = await getWrapper(fixtures.withResults);
        });

        it("should call the correct action in the store", async () => {
          const searchString = "foobar";

          // Simulate value change
          const searchInput = wrapper.find("#input--search");
          searchInput.setValue(searchString);
          await searchInput.trigger("change");

          // Submit form
          await wrapper.find("form.search-input").trigger("submit");

          expect(setSearchValueStub.mock.calls.length).toEqual(1);
          expect(setSearchValueStub.mock.calls[0][1]).toEqual({
            collection: "privileges",
            value: searchString,
          });
          expect(fetchComponentsStub.mock.calls.length).toEqual(2); // first load + search submit
        });
      });
    });
  } else {
    describe("when search is disabled in the project configuration", () => {
      let wrapper;
      beforeEach(() => {
        wrapper = getWrapper(fixtures.withResults);
      });

      it("should NOT render search input", () => {
        const searchInput = wrapper.find("#input--search");
        expect(searchInput.exists()).toBe(false);
      });
    });
  }
};
