const CityGuides = () =>
  import(/* webpackChunkName: "city-guides" */ "./views/CityGuides");

const CityGuideDetails = () =>
  import(/* webpackChunkName: "city-guides" */ "./views/CityDetails");

export default [
  {
    path: "/city-guides",
    name: "city-guides",
    component: CityGuides,
    meta: {
      requiresAuth: true,
      title: "common.head.title.city-guides",
      template: "city-guide",
    },
  },
  {
    path: "/city-guides/details/:id",
    name: "city-guides/details",
    component: CityGuideDetails,
    props: true,
    meta: {
      requiresAuth: true,
      template: "city-guide-details",
    },
  },
];
