<template>
  <div class="update-form">
    <div class="form-title text7">
      {{ $t("request.my-requests.modification.title") }}
    </div>
    <div class="text8">
      {{ $t("request.my-requests.modification.description") }}
    </div>

    <form @submit.prevent="onSubmit">
      <TextArea
        id="input--my-requests-modification-description"
        :label="$t('request.my-requests.modification.label')"
        class="form-input mt-2"
        rows="5"
        :autofocus="true"
        :disabled="!!pending"
        :maxLength="200"
        :value.sync="value"
      />
      <div class="buttons-wrapper">
        <Button
          id="button--my-requests-modification-cancel"
          class="cancel-button"
          type="secondary"
          :disabled="!!pending"
          @click.native="$emit('cancel')"
        >
          {{ $t("request.my-requests.modification.buttons.cancel") }}
        </Button>
        <Button
          id="button--my-requests-modification-submit"
          nativeType="submit"
          type="primary"
          class="submit-button"
          :disabled="disabled"
        >
          {{ $t("request.my-requests.modification.buttons.submit") }}
        </Button>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
.update-form {
  .form-input {
    margin: rem(16px 0 10px);
  }

  .buttons-wrapper {
    display: flex;

    .submit-button {
      margin-left: rem(12px);
    }

    @include respond-min("medium") {
      justify-content: flex-end;
      align-items: flex-end;

      .cancel-button,
      .submit-button {
        width: auto;
        padding: 0 rem(20px);
        min-width: 150px;
      }
    }
  }
}
</style>

<script>
import { Button, TextArea } from "@johnpaul/jp-vue-components";

export default {
  components: {
    Button,
    TextArea,
  },

  data() {
    return {
      value: "",
    };
  },

  props: {
    pending: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    disabled() {
      return this.pending || !this.value;
    },
  },

  methods: {
    onSubmit() {
      if (!this.disabled) {
        this.$emit("submit", this.value);
      }
    },
  },
};
</script>
